import React from 'react';
import { AppInnerPageWrapper } from '../../Components/Modules/InnerPageWrapper';
import { AppMenuComponent } from '../../Components/Ogranisms/Restaurant/AppMenu';

export const AppMenuScreen:React.FC =()=>{
    return(
        <React.Fragment>
            <AppInnerPageWrapper>
                <AppMenuComponent />
            </AppInnerPageWrapper>
        </React.Fragment>
    )
}