import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Providers";

import { each, groupBy } from "lodash";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  addCart,
  removeCart,
  removeCartsByName,
} from "../../../Providers/order";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { fCurrency } from "../../../Helpers";
import { productProps } from "../../../Interfaces/response";

export const AppItemTable: React.FC = () => {
  const { products } = useSelector((state: RootState) => state.order);
  const calculatSubTotal = (products: productProps[]): number => {
    let subTotal = 0;
    each(products, (p:productProps) => {
      subTotal += Number(p._regular_price);
    });
    return Number(Number(subTotal).toFixed(2));
  };

  const dispatch = useDispatch();
  const emptyImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18103142/woocommerce-placeholder-450x450.png";
  return (
    <React.Fragment>
      {/* desktop */}
      <div className="w-full grid grid-cols-12 py-2 gap-3 border-b border-white">
        <div className="col-span-12 md:col-span-6">
          <span className="text-sm font-bold text-white text-center">
            Product
          </span>
        </div>
        <div className="col-span-6 md:col-span-2">
          <span className="text-sm font-bold text-white text-right">Price</span>
        </div>
        <div className="col-span-6 md:col-span-2">
          <span className="text-sm font-bold text-white text-center">
            Quanity
          </span>
        </div>
        <div className="col-span-6 md:col-span-2">
          <span className="text-sm font-bold text-white text-right">
            Sub Total
          </span>
        </div>
      </div>
      {Object.keys(groupBy(products, "title")).map((key, i) => (
        <div
          key={i}
          className="w-full justify-center items-center content-center grid grid-cols-12 py-2 gap-3 border-b border-white"
        >
          <div className="col-span-12 md:col-span-6">
            <div className="w-full flex flex-row justify-center items-center content-center space-x-2">
              <button
                onClick={() =>
                  dispatch(removeCartsByName(groupBy(products, "title")[key][0]))
                }
                className="cursor-pointer px-2 py-2 outline-none"
              >
                <RiDeleteBinLine className="w-auto h-6 text-white" />
              </button>
              <img
                className="w-auto h-12 rounded-md"
                src={
                  groupBy(products, "title")[key][0].img
                    ? groupBy(products, "title")[key][0].img
                    : emptyImg
                }
                alt=""
              />
              <div className="flex-grow flex justify-start items-center content-center">
                <span className="text-sm font-light ">{key}</span>
              </div>
            </div>
          </div>
          <div className="col-span-4 md:col-span-2">
            <span className="text-sm font-bold text-yellow-600 text-right">
              {fCurrency(Number(groupBy(products, "title")[key][0]._regular_price))}
            </span>
          </div>
          <div className="col-span-4 md:col-span-2">
            <div className="w-full flex flex-row justify-center items-center content-center">
              <div
                onClick={() =>
                  dispatch(removeCart(groupBy(products, "title")[key][0]))
                }
                className="px-2 cursor-pointer py-2 justify-center items-center content-center"
              >
                <CiSquareMinus className="w-auto h-6" />
              </div>
              <div className=" px-2 py-2 justify-center items-center content-center">
                <p className="text-xs font-bold">
                  {groupBy(products, "title")[key].length}
                </p>
              </div>
              <div
                onClick={() =>
                  dispatch(addCart(groupBy(products, "title")[key][0]))
                }
                className="px-2 cursor-pointer py-2 justify-center items-center content-center"
              >
                <CiSquarePlus className="w-auto h-6" />
              </div>
            </div>
          </div>
          <div className="col-span-4 md:col-span-2">
            <span className="text-sm font-bold text-yellow-600 text-right">
              {fCurrency(calculatSubTotal(groupBy(products, "title")[key]))}
            </span>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};
