import React from 'react';
import { AppInnerPageWrapper } from '../../Components/Modules/InnerPageWrapper';
import { AppQualityComponent } from '../../Components/Ogranisms/Restaurant/AppQuality';

export const AppQualityScreen:React.FC =()=>{
    return(
        <React.Fragment>
            <AppInnerPageWrapper>
                 <AppQualityComponent />
            </AppInnerPageWrapper>
        </React.Fragment>
    )
}