import React, { useCallback, useEffect, useState } from "react";
import { AppOrderTypeComponent } from "./OrderType";
import { AppPaymentFormComponent } from "./PaymentForm";
import { AppYourInfoComponent } from "./YourInfo";
import { AppYourCartComponent } from "./YourCart";
import { useSelector } from "react-redux";
import { RootState } from "../../../Providers";
import { RiArrowRightSLine } from "react-icons/ri";
import { AppShippingFormComponent } from "./ShippingForm";
import { useForm } from "react-hook-form";
import {
  linkProps,
  orderFormProps,
  orderReponseProps,
  paymentResultProps,
} from "../../../Interfaces/response";
import { useAuthContext } from "../../../Providers/auth";
import { API, APIURLS } from "../../../Services";
import toast from "react-hot-toast";
import { CiShoppingCart } from "react-icons/ci";
import { AppUserLoginBox } from "../../Atoms/AppUserLoginBox";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { AppApplyCouponForm } from "./applyCoupon";
import { AppBillingFormComponent } from "./BillingForm";
import { find } from "lodash";
import { AppPickupForm } from "./PickupForm";

export const AppCheckoutComponent: React.FC = () => {
  const { products,is_lunch_menu } = useSelector((state: RootState) => state.order);
  const { user, accessToken, isAuth } = useAuthContext();

  const [isOpen, setisOpen] = useState<boolean>(!isAuth);
  const {
    control,
    handleSubmit,
    watch,
    setValue,

    formState: { isValid, errors },
  } = useForm<orderFormProps>({
    mode: "onChange",
    defaultValues: {
      cartId: undefined,
      paymentId: undefined,
      productIds: products.map((p) => p.id),
      _order_schedule: "immediately",
      _order_type: "",
      couponId: undefined,
      shipppingId: undefined,
      info_email: user?.email ? user.email : "",
      info_first_name: user?.first_name ? user.first_name : "",
      info_last_name: user?.last_name ? user.last_name : "",
      info_phone: user?.phone ? user.phone : "",
      billing_name: "",
      billing_firma_name: "",
      billing_address: "",
      billing_city: "",
      billing_house_number: "",
      billing_zip: "",
      billing_street: "",
      billing_phone: "",
      shipping_etage: "",
      shipping_street: "",
      shipping_city: "",
      shipping_name: "",
      shipping_firma_name: "",
      shipping_klingelname: "",
      shipping_house_number: "",
      shipping_phone: "",
      shipping_email: "",
      order_comments: "",
      status: "pending",
      collection_discount: 0,
      coupon_amount: 0,
      isSameBillingandShipping: false,
      is_accept: true,
      datum:"",
      uhrzeit:""
    },
  });

  const syncUserData = useCallback(() => {
    if (user) {
      setValue("info_email", user?.email ? user.email : "");
      setValue("info_phone", user?.phone ? user.phone : "");
      setValue("info_first_name", user.first_name ? user.first_name : "");
      setValue("info_last_name", user.last_name ? user.last_name : "");
    }
  }, [setValue, user]);

  useEffect(() => {
    syncUserData();
  }, [syncUserData]);

  const navigate = useNavigate();

  const PaypalRedirect = (orderId: number) => {
    try {
      API.post(`${APIURLS.paypal.create_payment}`, {
        orderId: orderId.toString(),
      })
        .then((res: AxiosResponse) => {
          const paymentResult: paymentResultProps = res.data;
          if (
            paymentResult &&
            paymentResult.links &&
            paymentResult.links.length > 0
          ) {
            const link = find(
              paymentResult.links,
              (link: linkProps) => link.rel === "approval_url"
            );
            if (link) {
              window.location.href = link.href;
            }
          }
        })
        .catch((err: any) => {
          const axiosError = err as AxiosError;
          if (
            axiosError &&
            axiosError.response &&
            axiosError.response.status === 400
          ) {
          }
        });
    } catch (error: any) {
      toast.error(`${error.message}`);
    }
  };

  const SubmitForm = (data: orderFormProps) => {
    console.log(data,"orderform")
    try {
      if (data && isValid) {
        API.post(`${APIURLS.orders.create}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        })
          .then((res) => {
            const orderResult: orderReponseProps = res.data;
            if (orderResult && orderResult.orderId && orderResult.payment) {
              if (orderResult.payment.includes("paypal")) {
                PaypalRedirect(orderResult.orderId);
              } else {
                navigate(`/place-order-success/${orderResult.orderId}`);
                toast.success("Place order successful.", {
                  icon: (
                    <CiShoppingCart className="w-auto h-6 text-green-600" />
                  ),
                  className: "text-sm font-thin tracking-widest",
                });
              }
            }
          })
          .catch((err: any) => {
            const axiosError = err as AxiosError;
            console.log(axiosError.response?.data);
          });
      }
    } catch (error) {
      toast.error(`${JSON.stringify(error)}`);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(SubmitForm)} className="w-full">
        <React.Fragment>
          <div className="w-full  flex flex-col max-w-screen-lg mx-auto px-2 py-2 space-y-2">
            <AppOrderTypeComponent
              errors={errors}
              setValue={setValue}
              control={control}
              watch={watch}
            />

            <AppShippingFormComponent
              watch={watch}
              control={control}
              setValue={setValue}
            />
            <AppPickupForm watch={watch} control={control} />

            <AppBillingFormComponent
              watch={watch}
              setValue={setValue}
              control={control}
            />

            <AppYourInfoComponent control={control} watch={watch} />
            <AppPaymentFormComponent
              errors={errors}
              watch={watch}
              control={control}
            />
            <AppYourCartComponent watch={watch} />
            {
              is_lunch_menu ?
              <React.Fragment>

              </React.Fragment>: <AppApplyCouponForm setValue={setValue} />
            }
            <button
              type="submit"
              className="w-full flex max-w-screen-sm rounded-full hover:bg-secondary   text-center mx-auto px-6 py-3 my-4 bg-primary text-white flex-row justify-center items-center content-center"
            >
              <span className="text-lg flex-grow font-bold uppercase">
                {`Bestellung aufgeben`}
              </span>
              <RiArrowRightSLine className="w-auto h-7" />
            </button>
          </div>
        </React.Fragment>
      </form>
      <AppUserLoginBox isOpen={isOpen} setIsOpen={setisOpen} />
    </React.Fragment>
  );
};
