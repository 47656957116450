import React, { useCallback, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../Providers/auth";

export const AppLogoutScreen: React.FC = () => {
  const { setIsAuth, setUser, setAccessToken, isAuth } = useAuthContext();
  const syncLogout = useCallback(() => {
    setIsAuth(false);
    setUser(undefined);
    setAccessToken("");
    localStorage.removeItem("sushi_auth_token");
    if (window && window.location) {
      window.location.reload();
    }
  }, [setIsAuth, setUser, setAccessToken]);

  useEffect(() => {
    syncLogout();
  }, [syncLogout]);

  if (isAuth) {
    return null;
  }

  return (
    <React.Fragment>
      <Navigate to={`/`} replace={true} />
    </React.Fragment>
  );
};
