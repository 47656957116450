import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthContext } from "../../../../Providers/auth";
import { userUpdateFormProps } from "../../../../Interfaces/response";
import { CiBookmarkPlus, CiEdit, CiSquareQuestion } from "react-icons/ci";
import { API, APIURLS } from "../../../../Services";
import { AxiosResponse } from "axios";
import toast from "react-hot-toast";

export const AppUserUpdateFormComponent: React.FC = () => {
  const { user, accessToken, setUser } = useAuthContext();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<userUpdateFormProps>({
    mode: "onChange",
    defaultValues: {
      first_name: user?.first_name ? user.first_name : "",
      last_name: user?.last_name ? user.last_name : "",
      email: user?.email ? user.email : "",
      phone: user?.phone ? user.phone : "",
      role: "user",
      billing_address_1: user?.billing_address_1 ? user.billing_address_1 : "",
      billing_address_2: user?.billing_address_2 ? user.billing_address_2 : "",
      billing_city: user?.billing_city ? user.billing_city : "",
      billing_etage: user?.billing_etage ? user.billing_etage : "",
    },
  });
  const SubmitForm = (data: userUpdateFormProps) => {
    try {
      API.post(`${APIURLS.auth.update_profile}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res: AxiosResponse) => {
          if (res.data) {
            setUser(res.data);
            toast.success("Profile updated successfully", {
              icon: <CiBookmarkPlus className="text-green-600 w-auto h-8" />,
              className: "text-xs font-thin text-slate-600",
            });
          }
        })
        .catch((err: any) => {
          toast.error("Profile updated fail", {
            icon: <CiSquareQuestion className="text-red-600 w-auto h-8" />,
            className: "text-xs font-thin text-slate-600",
          });
        })
        .finally(() => {
          console.log(`API auth update done.`);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div data-aos="fade-left" className="w-full">
        <form
          onSubmit={handleSubmit(SubmitForm)}
          className="w-full flex flex-col space-y-3   px-8 py-6 "
        >
          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="first_name">First Name</label>
            <Controller
              control={control}
              name="first_name"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors && errors.first_name && errors.first_name.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.first_name.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>

          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="last_name">Last Name</label>
            <Controller
              control={control}
              name="last_name"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors && errors.last_name && errors.last_name.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.last_name.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>

          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="email">Email</label>
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="email"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors && errors.email && errors.email.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.email.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>

          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="phone">Phone</label>
            <Controller
              control={control}
              name="phone"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors && errors.phone && errors.phone.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.phone.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>

          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="billing_address_1">billing_address_1</label>
            <Controller
              control={control}
              name="billing_address_1"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors &&
                  errors.billing_address_1 &&
                  errors.billing_address_1.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.billing_address_1.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>

          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="billing_address_2">billing_address_2</label>
            <Controller
              control={control}
              name="billing_address_2"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors &&
                  errors.billing_address_2 &&
                  errors.billing_address_2.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.billing_address_2.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="billing_city">billing_city</label>
            <Controller
              control={control}
              name="billing_city"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors &&
                  errors.billing_city &&
                  errors.billing_city.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.billing_city.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className="w-full flex flex-col space-y-1">
            <label htmlFor="billing_etage">billing_etage</label>
            <Controller
              control={control}
              name="billing_etage"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors &&
                  errors.billing_etage &&
                  errors.billing_etage.message ? (
                    <React.Fragment>
                      <span className="text-xs text-primary font-light">
                        {errors.billing_etage.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className="w-full py-6 flex space-y-2 md:space-y-0 flex-col md:flex-row justify-center md:justify-between items-center content-center">
            <button
              className="flex text-white bg-primary px-2 py-2 text-sm uppercase font-semibold flex-row justify-between items-center content-center space-x-2"
              type="submit"
              disabled={!isValid}
            >
              <CiEdit className="w-auto h-5" />
              <span className="flex-grow">Update</span>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
