import React from "react";
import {} from "lodash";
interface cateringProps {
  title: string;
  description: string;
  img: string;
  index: number;
}
interface props {
  catering?: cateringProps;
}
export const AppCateringCard: React.FC<props> = ({ catering }) => {
  return (
    <React.Fragment>
      {catering &&
        (catering.index % 2 === 0 ? (
          <div
            data-aos="fade-up-right"
            className="w-full grid grid-cols-12 gap-3"
            key={catering.index}
          >
            <div className="col-span-12 md:col-span-6">
              <img
                src={catering.img}
                className=" w-auto h-52 rounded-md object-cover object-center"
                alt=""
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-2 justify-center content-center h-full ">
                <h4 className="text-lg font-bold text-left">
                  {catering.title}
                </h4>
                <p className="text-sm font-light">{catering.description}</p>
              </div>
            </div>
          </div>
        ) : (
          <div
            data-aos="fade-up-left"
            className="w-full grid grid-cols-12 gap-3"
            key={catering.index}
          >
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-2 justify-center content-center h-full ">
                <h4 className="text-lg font-bold text-left">
                  {catering.title}
                </h4>
                <p className="text-sm font-light">{catering.description}</p>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <img
                src={catering.img}
                className="w-auto h-52 rounded-md object-cover object-center"
                alt=""
              />
            </div>
          </div>
        ))}
    </React.Fragment>
  );
};
