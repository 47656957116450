import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RiCloseLine, RiLoginBoxLine, RiRegisteredLine, RiUser5Line } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
interface props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppUserLoginBox: React.FC<props> = ({ isOpen, setIsOpen }) => {
  const bannerImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/10/17074855/ann_header-1.jpg";
  const footerImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/10/17074901/ann_footer.jpg";
  const {pathname} = useLocation()
  return (
    <React.Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(!isOpen)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden  bg-black relative  text-left align-middle shadow-xl transition-all">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="absolute top-0 right-0 px-2 py-2 cursor-pointer outline-none"
                  >
                    <RiCloseLine className="w-auto h-6 text-white" />
                  </button>
                  <img
                    src={bannerImg}
                    className="w-full h-auto object-contain object-center"
                    alt=""
                  />
                  <div className="w-full flex flex-col space-y-3 px-2 py-3">
                    <Link
                      to={`/login?path=${pathname}`}
                      className="w-full flex bg-secondary hover:bg-primary px-2 py-2 text-white text-sm flex-row justify-center items-center content-center space-x-2"
                    >
                      <React.Fragment>
                        <RiLoginBoxLine className="w-auto h-6 text-white" />
                        <span className="text-sm uppercase text-center flex-grow tracking-wider font-bold">
                          Anmeldung
                        </span>
                      </React.Fragment>
                    </Link>
                    <Link
                      to={`/signup?path=${pathname}`}
                      className="w-full flex bg-secondary hover:bg-primary px-2 py-2 text-white text-sm flex-row justify-center items-center content-center space-x-2"
                    >
                      <React.Fragment>
                        <RiRegisteredLine className="w-auto h-6 text-white" />
                        <span className="text-sm uppercase text-center flex-grow tracking-wider font-bold">
                        registrieren
                        </span>
                      </React.Fragment>
                    </Link>
                    <button
                       onClick={()=>setIsOpen(!isOpen)}
                      className="w-full flex bg-secondary hover:bg-primary px-2 py-2 text-white text-sm flex-row justify-center items-center content-center space-x-2"
                    >
                      <React.Fragment>
                        <RiUser5Line className="w-auto h-6 text-white" />
                        <span className="text-sm uppercase text-center flex-grow tracking-wider font-bold">
                          Als Gast
                        </span>
                      </React.Fragment>
                    </button>
                  </div>
                  <img
                    src={footerImg}
                    className="w-full h-auto object-contain object-center"
                    alt=""
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};
