import { createSlice } from "@reduxjs/toolkit";
import { concat, each, filter } from "lodash";
import {
  additionalInfoProps,
  billingProps,
  orderProps,
  productProps,
  shippingProps,
} from "../Interfaces/response";

const initialState: orderProps = {
  products: [],
  total: 0,
  coupon_amount: 0,
  sub_total: 0,
  billing_first_name: "",
  billing_last_name: "",
  billing_address_1: "",
  billing_address_2: "",
  billing_etage: "",
  billing_city: "",
  billing_phone: "",
  billing_email: "",
  calc_shipping_cost: 0,
  calc_shipping_extra: 0,
  calc_shipping_code: "",
  calc_shipping_name: "",
  order_comments: "",
  additional_date: "",
  paymentId: undefined,
  productIds: [],
  shippingId: undefined,
  order_schedule_timestamps: undefined,
  _order_schedule: "immediately",
  _order_type: "delivery",
  status: "pending",
  is_lunch_menu:false
};

const orderSlice = createSlice({
  name: "order",
  initialState: initialState,

  reducers: {
    addCart: (state, action) => {
      const payloadProduct: productProps = action.payload;
      state.products.push(payloadProduct);
      let total = 0;
      each(state.products, (p) => {
        total += Number(p._regular_price);
      });
      state.total = total;
      state.sub_total = total - state.coupon_amount;
    },
    setOrderSchedule: (state, action) => {
      state._order_schedule = action.payload;
    },
    setOrderType: (state, action) => {
      state._order_type = action.payload;
    },
    setOrderScheduleTimeStamp: (state, action) => {
      state.order_schedule_timestamps = action.payload;
    },
    addExistingCarts: (state, action) => {
      const payloadProdcuts: productProps[] = action.payload;
      state.products.concat(payloadProdcuts);
      let total = 0;
      each(state.products, (p) => {
        total += Number(p._regular_price);
      });
      state.total = total;
      state.sub_total = total - state.coupon_amount;
    },
    clearCart:(state,action)=>{
      state.products=[];
      state.sub_total=0;
      state.total=0;
      state.paymentId=undefined;
      state.couponId=undefined;
      state.shippingId=undefined;
    },
    removeCart: (state, action) => {
      const removeProduct: productProps = action.payload;
      let toRemoveProducts = filter(
        state.products,
        (p) => p.id === removeProduct.id
      );
      if (toRemoveProducts.length > 0) {
        const existingProducts = filter(
          state.products,
          (p) => p.id !== removeProduct.id
        );
        const removedProducts = toRemoveProducts.slice(1);
        state.products = concat(existingProducts, removedProducts);
        let total = 0;
        each(state.products, (p) => {
          total += Number(p._regular_price);
        });
        state.total = total;
        state.sub_total = total - state.coupon_amount;
      }
    },
    removeCartsByName: (state, action) => {
      const removeProduct: productProps = action.payload;
      state.products = filter(state.products, (p) => p.id !== removeProduct.id);
      let total = 0;
      each(state.products, (p) => {
        total += Number(p._regular_price);
      });
      state.total = total;
      state.sub_total = total - state.coupon_amount;
    },
    addCoupon: (state, action) => {
      state.coupon_amount = Number(action.payload);
      state.sub_total = state.total - Number(action.payload);
    },
    addPayment: (state, action) => {
      state.paymentId = action.payload;
    },
    addBillingData: (state, action) => {
      const billingData: billingProps = action.payload;
      state.billing_first_name = billingData.billing_first_name;
      state.billing_last_name = billingData.billing_last_name;
      state.billing_phone = billingData.billing_phone;
      state.billing_address_1 = billingData.billing_address_1;
      state.billing_address_2 = billingData.billing_address_2;
      state.billing_city = billingData.billing_city;
      state.billing_email = billingData.billing_email;
      state.billing_etage = billingData.billing_etage;
    },
    addShippingData: (state, action) => {},
    setShippingType: (state, action) => {
      state._order_type = action.payload;
      if (action.payload === "collection") {
        state.calc_shipping_code = "";
        state.calc_shipping_cost = 0;
        state.calc_shipping_extra = 0;
        state.sub_total =
          state.sub_total +
          (Number(state.calc_shipping_cost) +
            Number(state.calc_shipping_extra));
      }
    },
    updatePostCode: (state, action) => {
      state.calc_shipping_code = action.payload;
    },
    updateDeliveryCost: (state, action) => {
      const deliveryData: shippingProps = action.payload;
      state.calc_shipping_code = deliveryData.code;
      state.calc_shipping_cost = Number(deliveryData.calc_shipping_cost);
      state.calc_shipping_extra = Number(deliveryData.calc_shipping_extra);
      state.calc_shipping_name = deliveryData.name;
      state.sub_total =
        state.sub_total +
        (Number(deliveryData.calc_shipping_cost) +
          Number(deliveryData.calc_shipping_extra));
    },
    addAdditionalData: (state, aciton) => {
      const addionalData: additionalInfoProps = aciton.payload;
      state.order_comments = addionalData.order_comments;
      state.additional_date = addionalData.additional_date;

      state.additional_time = addionalData.additional_time;
    },
    setIsLunchMenu:(state,action)=>{
      state.is_lunch_menu = Boolean(action.payload)
    }
  },
});

export const {
  addCart,
  removeCart,
  addCoupon,
  removeCartsByName,
  addShippingData,
  setShippingType,
  updateDeliveryCost,
  updatePostCode,
  addBillingData,
  addExistingCarts,
  addPayment,
  addAdditionalData,
  setOrderSchedule,
  setOrderScheduleTimeStamp,
  setOrderType,
  clearCart,
  setIsLunchMenu
  
} = orderSlice.actions;

export default orderSlice.reducer;
