import React from "react";
import { Link } from "react-router-dom";

export const AppRestaurantHomeComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col px-4 tracking-wider space-y-2 ">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          SUSHI AM MAIN – RESTAURANT
        </h4>
        <p className="text-sm md:text-lg py-3 font-bold tracking-wider">
          TRADITIONALLES JAPANISCHES SUSHI RESTAURANT IN FRANKFURT AM MAIN
        </p>
        <p className="text-sm font-normal py-2">
          Das Japanische Sushi-Restaurant „SUSHI AM MAIN“ im Reuterweg 61 wurde
          im Jahr 2009 als 2. Restaurant, zu dem seit 1995 bestehenden Sushi
          Restaurant in der Feuerbachstrasse, eröffnet. Das Restaurant hat es
          sich zur Aufgabe gemacht, eine traditionelle und moderne Sushi – Küche
          in bester Qualität anzubieten. Zu unserem Angebot aus der vielfältigen
          und gesunden japanischen Küche gehört auch die naturbelassene,
          authentische japanische Washoku – Küche, die 2013 in das
          Weltkulturerbe aufgenommen wurde.
        </p>
        <p className="text-sm font-normal py-2">
          Gerne nehmen wir telefonisch{" "}
          <a
            className="text-primary underline"
            href="tel:+6966967554"
            target="_blank"
            rel="noreferrer"
          >
            (069 / 6696 7554)
          </a>{" "}
          oder auch{" "}
          <Link className="text-primary underline" to={`/reservieren`}>
            Online
          </Link>{" "}
          Ihre Tischreservierung entgegen. Auf Wunsch können Sie unser
          Speisenangebot auch gerne direkt telefonisch oder Online bestellen und
          sich über unseren Lieferservice bequem nach Hause bringen lassen.
        </p>
        <p className="text-sm font-normal py-2">
          Wir freuen uns auf Ihren Besuch in unserem Restaurant
        </p>
        <div className="px-2 py-2 flex justify-center items-center content-center flex-col space-y-2 w-full max-w-screen-sm mx-auto">
          <img
            data-aos="zoom-in-up"
            className="w-full h-auto object-cover object-center rounded-xl"
            src="https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/23165151/restraurant_3.jpg"
            alt=""
          />
          <p className="text-xs font-thin">Sushi Am Main</p>
        </div>
      </div>
    </React.Fragment>
  );
};
