import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Providers";
import { find, groupBy } from "lodash";
import { calculateCollectionDiscount, fCurrency } from "../../../Helpers";
import { orderFormProps, shippingProps } from "../../../Interfaces/response";
import { useUserData } from "../../../Providers/user-data";
import { UseFormWatch } from "react-hook-form";

interface props {
  watch: UseFormWatch<orderFormProps>;
}
export const AppYourCartComponent: React.FC<props> = ({ watch }) => {
  const { products } = useSelector((state: RootState) => state.order);
  const { shippings, settting } = useUserData();
  const shippingId = watch("shipppingId");
  const orderType = watch("_order_type");
  const coupon_amount = Number(watch("coupon_amount"));

  const shippingTotal: number = useMemo(() => {
    if (shippingId && shippings) {
      const shipping = find(
        shippings,
        (shipping: shippingProps) => shipping.id === Number(shippingId)
      );
      return (
        Number(shipping?.calc_shipping_cost) +
        Number(shipping?.calc_shipping_extra)
      );
    } else {
      return 0;
    }
  }, [shippings, shippingId]);

  const itemTotal: number = useMemo(() => {
    return products.reduce((total, product) => {
      const regularPrice: number = Number(product._regular_price);
      return total + regularPrice;
    }, 0);
  }, [products]);

  
  const discount: number = useMemo(() => {
    if (orderType === "collection") {
      if (
        settting &&
        settting.COLLECTION_DISCOUNT &&
        settting.COLLECTION_DISCOUNT_TYPE &&
        settting.MIN_COLLECTION_DISCOUNT &&
        settting.MAX_COLLECTION_DISCOUNT
      ) {
        const COLLECTION_DISCOUNT = Number(settting.COLLECTION_DISCOUNT);
        const MIN_COLLECTION_DISCOUNT = Number(
          settting.MIN_COLLECTION_DISCOUNT
        );
        const MAX_COLLECTION_DISCOUNT = Number(
          settting.MAX_COLLECTION_DISCOUNT
        );
        const discount = calculateCollectionDiscount(
          COLLECTION_DISCOUNT,
          settting.COLLECTION_DISCOUNT_TYPE,
          MIN_COLLECTION_DISCOUNT,
          MAX_COLLECTION_DISCOUNT,
          itemTotal
        );

        return discount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [orderType, itemTotal, settting]);
  const taxTotal: number = useMemo(() => {
    const rate = 0.07;
    return ((itemTotal - (discount+coupon_amount)) / (rate + 1)) * rate;
  }, [itemTotal, discount,coupon_amount]);

  const grandTotal = useMemo(() => {
    return itemTotal+ shippingTotal - (discount + coupon_amount);
  }, [coupon_amount, shippingTotal, discount, itemTotal]);

  return (
    <React.Fragment>
      <div
        data-aos="fade-up"
        className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
      >
        <div className="w-full col-span-12">
          <h6 className="text-lg font-bold tracking-wider">Ihren Warenkorb</h6>
        </div>
        <div className="col-span-12">
          {Object.keys(groupBy(products, "id")).map((key, i) => (
            <div
              key={i}
              className="w-full px-2 mb-4 py-2 grid grid-cols-12 gap-3 max-w-screen-lg"
            >
              <div className="col-span-12 md:col-span-6">
                <p className="text-sm font-light text-white">
                  {groupBy(products, "id")[key][0].title} x
                  {groupBy(products, "id")[key].length}
                </p>
              </div>
              <div className="col-span-12 md:col-span-6">
                <p className="text-sm font-normal text-yellow-600">
                  {fCurrency(
                    Number(
                      Number(
                        Number(groupBy(products, "id")[key][0]._regular_price) *
                          Number(groupBy(products, "id")[key].length)
                      ).toFixed(2)
                    )
                  )}
                </p>
              </div>
            </div>
          ))}
          <div className="w-full px-2 my-4 border-t border-white mb-4 py-2 grid grid-cols-12 gap-3 max-w-screen-lg">
            <div className="col-span-12 md:col-span-6">
              <p className="text-sm font-light text-white">Summe:</p>
            </div>
            <div className="col-span-12 md:col-span-6">
              <p className="text-sm font-semibold text-yellow-600">
                {fCurrency(Number(itemTotal.toFixed(2)))}
              </p>
            </div>
          </div>

          {orderType === "delivery" ? (
            <div className="w-full px-2 my-4 border-b border-white mb-4 py-2 grid grid-cols-12 gap-3 max-w-screen-lg">
              <div className="col-span-12 md:col-span-6">
                <p className="text-sm font-light text-white">Shipping</p>
              </div>

              <React.Fragment>
                <div className="col-span-12 md:col-span-6">
                  <p className="text-sm font-semibold text-yellow-600">
                    <span className="text-white font-light text-sm">
                      Flat rate:
                    </span>{" "}
                    {fCurrency(shippingTotal)}
                  </p>
                </div>
              </React.Fragment>
            </div>
          ) : null}
          {orderType === "collection" ? (
            <div className="w-full px-2 my-4  border-white mb-4 py-2 grid grid-cols-12 gap-3 max-w-screen-lg">
              <div className="col-span-12 md:col-span-6">
                <p className="text-sm font-light text-white"> Abholrabatt</p>
              </div>

              <React.Fragment>
                <div className="col-span-12 md:col-span-6">
                  <p className="text-sm font-semibold text-red-600">
                    <span className="text-white font-light text-sm">-</span>{" "}
                    {fCurrency(discount)} ({settting?.COLLECTION_DISCOUNT}{" "}
                    %Rabatt)
                  </p>
                </div>
              </React.Fragment>
            </div>
          ) : null}
          {watch("couponId") ? (
            <React.Fragment>
              <div className="w-full px-2 my-4 border-b border-white mb-4 py-2 grid grid-cols-12 gap-3 max-w-screen-lg">
                <div className="col-span-12 md:col-span-6">
                  <p className="text-sm font-light text-white">Couponbetrag</p>
                </div>
                {watch("coupon_amount") ? (
                  <React.Fragment>
                    <div className="col-span-12 md:col-span-6">
                      <p className="text-sm font-semibold text-red-600">
                        <span className="text-white font-light text-sm">-</span>{" "}
                        {fCurrency(
                          Number(Number(watch("coupon_amount")).toFixed(2))
                        )}
                      </p>
                    </div>
                  </React.Fragment>
                ) : (
                  "-"
                )}
              </div>
            </React.Fragment>
          ) : null}
          <div className="w-full px-2 my-4   mb-4 py-2 grid grid-cols-12 gap-3 max-w-screen-lg">
            <div className="col-span-12 md:col-span-6">
              <p className="text-sm font-light text-white">Tax (7% VAT)</p>
            </div>
            <div className="col-span-12 md:col-span-6">
              <p className="text-sm font-semibold text-yellow-600">
                {fCurrency(Number(taxTotal.toFixed(2)))}
              </p>
            </div>
          </div>
          <div className="w-full px-2 my-4 border-white mb-4 py-2 grid grid-cols-12 gap-3 max-w-screen-lg">
            <div className="col-span-12 md:col-span-6">
              <p className="text-sm font-light text-white">Gesamtsumme:</p>
            </div>
            <div className="col-span-12 md:col-span-6">
              <p className="text-sm font-semibold text-yellow-600">
                {`${fCurrency(grandTotal)} (includes ${fCurrency(
                  itemTotal
                )}  Price incl. ${settting?._tax_standard}% VAT) `}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};