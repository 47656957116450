import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CiPhone } from "react-icons/ci";
import { IoBasket } from "react-icons/io5";
import { AppAddToCartDrawer } from "../Atoms/AppAddToCartDrawer";
import { AppPageFooter } from "./PageFooter";
import { useSelector } from "react-redux";
import { RootState } from "../../Providers";
import { useUserData } from "../../Providers/user-data";
import { groupBy } from "lodash";
interface props {
  children: React.ReactNode;
  withFooter?: boolean;
}
export const AppPageWrapper: React.FC<props> = ({
  children,
  withFooter = true,
}) => {
  const { products = [] } = useSelector((state: RootState) => state.order);
  const { pathname } = useLocation();
  const { isOpenAddToCartDrawer, setIsOpenAddToCartDrawer } = useUserData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <div className="w-full min-h-screen select-none relative">
        <div className="fixed z-50 flex-row rotate-90 space-x-0 flex left-0 -ml-20 top-96">
          <Link
            className="text-slate-200  hover:text-white uppercase  text-sm  underline-offset-1 hover:underline-offset-2 font-bold bg-primary px-2 py-2"
            to={`/online-bestellen`}
          >
            Online bestellen
          </Link>
          <Link
            className=" uppercase group text-sm     underline-offset-1 hover:underline-offset-2 font-bold bg-white px-3 py-2"
            to={`tel:+4969-6696%207554`}
          >
            <CiPhone className="w-auto h-5 text-slate-900  group-hover:text-slate-700" />
          </Link>
        </div>
        <main className="w-full min-h-screen ">{children}</main>
        {
          products && products.length>0 ?
          <button
          onClick={() => setIsOpenAddToCartDrawer(!isOpenAddToCartDrawer)}
          className="fixed  cursor-pointer group select-none z-50 bottom-5   right-5"
        >
          <div
            className={`relative px-2 py-2 bg-white  rounded-full ${
              products.length > 0
                ? "animate-bounce group-hover:animate-none"
                : "animate-none"
            }`}
          >
            <span className="px-2 py-1 font-normal absolute -top-3 -left-3 text-xs  text-white bg-slate-900 rounded-full">
              {Object.keys(groupBy(products,'id')).length}
            </span>
            <IoBasket className="w-auto h-8 text-slate-800 " />
          </div>
        </button>:null
        }
        <AppAddToCartDrawer />
        {withFooter && <AppPageFooter />}
      </div>
    </React.Fragment>
  );
};
