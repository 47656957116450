import React, { useMemo, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { couponProps, orderFormProps } from "../../../Interfaces/response";
import { useSelector } from "react-redux";
import { RootState } from "../../../Providers";
import toast from "react-hot-toast";
import { API, APIURLS } from "../../../Services";
import { AxiosError, AxiosResponse } from "axios";
interface props {
  setValue: UseFormSetValue<orderFormProps>;
}
export const AppApplyCouponForm: React.FC<props> = ({ setValue }) => {
  const [couponCode, setcouponCode] = useState("");
  const [message, setmessage] = useState("");
  const { products } = useSelector((state: RootState) => state.order);
  const getTotal = useMemo(() => {
    if (products) {
      return products.reduce((total, product) => {
        const regularPrice: number = parseFloat(product._regular_price);
        return total + regularPrice;
      }, 0);
    } else {
      return 0;
    }
  }, [products]);

  const ApplyCoupon = () => {
    try {
      API.post(`${APIURLS.coupons.apply}`, {
        code: couponCode,
        amount: Number(getTotal.toFixed(2)),
      })
        .then((res: AxiosResponse) => {
          if (
            res.data &&
            res.data.status === 200 &&
            res.data.coupon_amount &&
            res.data.coupon
          ) {
            const coupon: couponProps = res.data.coupon;

            setValue(
              "coupon_amount",
              Number(Number(res.data.coupon_amount).toFixed(2))
            );
            setValue("couponId", coupon.id);
            setmessage(res.data.message);
          } else if (res.data && res.data.status && res.data.status === 400) {
            setmessage(res.data.message);
          } else {
            setmessage("");
          }
        })
        .catch((err: AxiosError) => {
          setmessage(err.message);
        })
        .finally(() => {
          console.log("done");
        });
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  return (
    <React.Fragment>
      <div
        data-aos="fade-up"
        className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
      >
        <div className="w-full col-span-12">
          <h6 className="text-lg font-bold tracking-wider">Apply Coupon</h6>
        </div>
        <div className="w-full col-span-12">
          <div className="w-full mx-auto max-w-screen-sm flex flex-row justify-between space-x-3 items-center content-center">
            <input
              type="text"
              value={couponCode}
              onChange={(e) => setcouponCode(e.target.value)}
              className="flex-grow form-input bg-transparent text-white  border-white text-sm"
            />
            <button
              type="button"
              onClick={() => ApplyCoupon()}
              disabled={couponCode === ""}
              className="px-2 py-2 bg-primary text-sm uppercase hover:bg-secondary"
            >
              Apply
            </button>
          </div>
        </div>
        <div className="w-full col-span-12">
          {message ? (
            <span className="text-sm font-thin text-primary tracking-wider">
              {message}
            </span>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
