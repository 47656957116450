import React from 'react';
import { AppInnerPageWrapper } from '../Components/Modules/InnerPageWrapper';
import { AppTermandConditionComponent } from '../Components/Ogranisms/TermandCondition';

export const AppTermandConditionScreen:React.FC =()=>{
    return(
        <React.Fragment>
             <AppInnerPageWrapper>
                 <React.Fragment>
                     <AppTermandConditionComponent />
                 </React.Fragment>
             </AppInnerPageWrapper>
        </React.Fragment>
    )
}