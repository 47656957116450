import React from "react";
import { AppProductListCard } from "../../Atoms/AppProductListCard";
import { AppProductCard } from "../../Atoms/AppProductCard";
import { Link } from "react-router-dom";
import { categoryProps } from "../../../Interfaces/response";

interface props {
  category?: categoryProps;
}

export const AppProductByCategoryComponent: React.FC<props> = ({
  category,
}) => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col space-y-4 relative overflow-hidden">
        <Link to={`/product-by-category/${category?.slug}`} className="text-lg px-4 md:text-xl font-semibold underline underline-offset-8">
          {category?.name}
        </Link>
        {Boolean(category?.is_list) ? (
          <React.Fragment>
            <div className="w-full grid grid-cols-12 gap-3">
              {category?.products.map((p, i) => (
                <div data-aos={i%2===0?'fade-left':'fade-right'} key={i} className="col-span-12">
                  <AppProductListCard IsLunchMenu={category.name.includes('LUNCH')} product={p} />
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="w-full grid grid-cols-12 gap-6">
              {category?.products.map((p, i) => (
                <div data-aos={i%2===0?'fade-left':'fade-right'} key={i} className="col-span-12 md:col-span-3">
                  <AppProductCard IsLunchMenu={category.name.includes('LUNCH')} product={p} />
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
