import { replace } from "lodash";
import moment from "moment";
import numeral from "numeral";
export const generateTimeSlots = () => {
  const timeSlots = [];
  const totalMinutes = 24 * 60 + 15; // Total minutes in a day

  for (let minutes = 0; minutes < totalMinutes; minutes += 15) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      mins
    ).padStart(2, "0")}`;
    timeSlots.push(formattedTime);
  }

  return timeSlots;
};

export const fCurrency = (number: number) => {
  return `${String(Number(number).toFixed(2)).replace(".", ",")} €`;
};

export const fPercent = (number: number) => {
  return numeral(number / 100).format("0.0%");
};

export const fNumber = (number: number) => {
  return numeral(number).format();
};

export const fShortenNumber = (number: number) => {
  return replace(numeral(number).format("0.00a"), ".00", "");
};

export const getMomentDateTime = (time: string | undefined) => {
  return moment(
    moment().format("YYYY-MM-DD") + " " + time,
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const calculateCollectionDiscount = (
  COLLECTION_DISCOUNT: number,
  COLLECTION_DISCOUNT_TYPE: string,
  MIN_COLLECTION_DISCOUNT: number,
  MAX_COLLECTION_DISCOUNT: number,
  total: number
): number => {
  if (COLLECTION_DISCOUNT_TYPE === "PERCENTAGE") {
    if (total >= MIN_COLLECTION_DISCOUNT && total <= MAX_COLLECTION_DISCOUNT) {
      const result = total * Number(COLLECTION_DISCOUNT / 100);
      return Number(result.toFixed(2));
    } else if (
      total >= MIN_COLLECTION_DISCOUNT &&
      total >= MAX_COLLECTION_DISCOUNT
    ) {
      const result = MAX_COLLECTION_DISCOUNT * (COLLECTION_DISCOUNT / 100);
      return Number(result.toFixed(2));
    } else {
      return 0;
    }
  } else {
    if (total >= MIN_COLLECTION_DISCOUNT && total <= MAX_COLLECTION_DISCOUNT) {
      return COLLECTION_DISCOUNT;
    } else if (
      total >= MIN_COLLECTION_DISCOUNT &&
      total >= MAX_COLLECTION_DISCOUNT
    ) {
      return COLLECTION_DISCOUNT;
    } else {
      return 0;
    }
  }
};

export const SetAnnounceShowT = () => {
  localStorage.setItem(
    "announcement_show_t",
    moment().format("YYYY-MM-DD HH:mm")
  );
};

export const getAnnouncShowT = (): boolean => {
  try {
    const shotT = localStorage.getItem("announcement_show_t");
    if (shotT) {
      const now = moment();
      const showTm = moment(shotT);
      const diff = now.diff(showTm) / 3600;
      console.log(diff/3600,"announcement_hour")
      if (diff/3600 <= 2) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};
