import React from "react";
import { AppReserveForm } from "./reserveForm";

export const AppReservationComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-3 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          RESERVIEREN
        </h4>
        <p className="text-sm font-normal py-2">
          Sehr gerne können Sie Online bei uns eine Reservierung machen, die von
          unserem Team per Email innerhalb von 24 Std. bestätigt wird. Wir
          bitten Sie, Online-Reservierungen mindestens 1 Tag im voraus zu
          machen. Für kurzfristige Reservierungen rufen Sie uns unter der Nummer
          069 / 6696 7554 an.
        </p>
        <p className="text-sm font-normal py-2">
          Wir freuen uns auf Ihren Besuch
        </p>
        <p className="text-sm font-normal py-2">Sushi am Main Team</p>
        <AppReserveForm />
      </div>
    </React.Fragment>
  );
};
