import React from 'react';
import { AppInnerPageWrapper } from '../Components/Modules/InnerPageWrapper';
import { AppOnelineSellerComponent } from '../Components/Ogranisms/OnlineSeller';
import { AppNoticeBox } from '../Components/Atoms/AppNoticeBox';

export const AppOnlineSellerScreen:React.FC =()=>{
    return(
        <React.Fragment>
             <AppInnerPageWrapper>
                  <AppNoticeBox />
                 <AppOnelineSellerComponent />
             </AppInnerPageWrapper>
        </React.Fragment>
    )
}