import React from 'react';
import { AppInnerPageWrapper } from '../../Components/Modules/InnerPageWrapper';
import { AppRestaurantHomeComponent } from '../../Components/Ogranisms/Restaurant/AppRestaurant';

export const AppRestaurantScreen:React.FC =()=>{
    return(
        <React.Fragment>
             <AppInnerPageWrapper>
                 <AppRestaurantHomeComponent />
             </AppInnerPageWrapper>
        </React.Fragment>
    )
}