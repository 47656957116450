export interface postCodesProps{
    city:string;
    codes:string[]
}
export const  germanPostalCodes:postCodesProps[] = [
    {
        "city": "Dresden",
        "codes": [
            "01067",
            "01069",
            "01097",
            "01099",
            "01108",
            "01109",
            "01127",
            "01129",
            "01139",
            "01157",
            "01159",
            "01169",
            "01187",
            "01189",
            "01217",
            "01219",
            "01237",
            "01239",
            "01257",
            "01259",
            "01277",
            "01279",
            "01307",
            "01309",
            "01324",
            "01326",
            "01328"
        ]
    },
    {
        "city": "Cossebaude",
        "codes": [
            "01156"
        ]
    },
    {
        "city": "Radebeul",
        "codes": [
            "01445"
        ]
    },
    {
        "city": "Radeberg",
        "codes": [
            "01454"
        ]
    },
    {
        "city": "Ottendorf-Okrilla",
        "codes": [
            "01458"
        ]
    },
    {
        "city": "Langebrück",
        "codes": [
            "01465"
        ]
    },
    {
        "city": "Reichenberg",
        "codes": [
            "01468",
            "97234"
        ]
    },
    {
        "city": "Radeburg",
        "codes": [
            "01471"
        ]
    },
    {
        "city": "Schönfeld-Weißig",
        "codes": [
            "01474"
        ]
    },
    {
        "city": "Arnsdorf bei Dresden",
        "codes": [
            "01477"
        ]
    },
    {
        "city": "Weixdorf",
        "codes": [
            "01478"
        ]
    },
    {
        "city": "Großenhain",
        "codes": [
            "01558",
            "01561"
        ]
    },
    {
        "city": "Riesa",
        "codes": [
            "01587",
            "01589",
            "01591",
            "01594"
        ]
    },
    {
        "city": "Gröditz",
        "codes": [
            "01609"
        ]
    },
    {
        "city": "Nünchritz",
        "codes": [
            "01612"
        ]
    },
    {
        "city": "Strehla",
        "codes": [
            "01616"
        ]
    },
    {
        "city": "Zeithain",
        "codes": [
            "01619"
        ]
    },
    {
        "city": "Lommatzsch",
        "codes": [
            "01623"
        ]
    },
    {
        "city": "Coswig",
        "codes": [
            "01640"
        ]
    },
    {
        "city": "Meißen",
        "codes": [
            "01662",
            "01665"
        ]
    },
    {
        "city": "Nossen",
        "codes": [
            "01683"
        ]
    },
    {
        "city": "Weinböhla",
        "codes": [
            "01689"
        ]
    },
    {
        "city": "Freital",
        "codes": [
            "01705"
        ]
    },
    {
        "city": "Wilsdruff",
        "codes": [
            "01723"
        ]
    },
    {
        "city": "Bannewitz",
        "codes": [
            "01728"
        ]
    },
    {
        "city": "Kreischa",
        "codes": [
            "01731"
        ]
    },
    {
        "city": "Rabenau",
        "codes": [
            "01734",
            "35466"
        ]
    },
    {
        "city": "Tharandt",
        "codes": [
            "01737"
        ]
    },
    {
        "city": "Colmnitz",
        "codes": [
            "01738"
        ]
    },
    {
        "city": "Dippoldiswalde",
        "codes": [
            "01744"
        ]
    },
    {
        "city": "Schmiedeberg",
        "codes": [
            "01762"
        ]
    },
    {
        "city": "Glashütte",
        "codes": [
            "01768"
        ]
    },
    {
        "city": "Altenberg",
        "codes": [
            "01773"
        ]
    },
    {
        "city": "Höckendorf",
        "codes": [
            "01774"
        ]
    },
    {
        "city": "Hermsdorf / Erzgebirge",
        "codes": [
            "01776"
        ]
    },
    {
        "city": "Geising",
        "codes": [
            "01778"
        ]
    },
    {
        "city": "Pirna",
        "codes": [
            "01796"
        ]
    },
    {
        "city": "Heidenau",
        "codes": [
            "01809",
            "21258"
        ]
    },
    {
        "city": "Bad Schandau",
        "codes": [
            "01814"
        ]
    },
    {
        "city": "Bad Gottleuba",
        "codes": [
            "01816"
        ]
    },
    {
        "city": "Berggießhübel",
        "codes": [
            "01819"
        ]
    },
    {
        "city": "Königstein / Sächsische Schweiz",
        "codes": [
            "01824"
        ]
    },
    {
        "city": "Liebstadt",
        "codes": [
            "01825"
        ]
    },
    {
        "city": "Graupa",
        "codes": [
            "01827"
        ]
    },
    {
        "city": "Stadt Wehlen",
        "codes": [
            "01829"
        ]
    },
    {
        "city": "Stolpen",
        "codes": [
            "01833"
        ]
    },
    {
        "city": "Neustadt in Sachsen",
        "codes": [
            "01844"
        ]
    },
    {
        "city": "Lohmen",
        "codes": [
            "01847"
        ]
    },
    {
        "city": "Hohnstein",
        "codes": [
            "01848"
        ]
    },
    {
        "city": "Sebnitz",
        "codes": [
            "01855"
        ]
    },
    {
        "city": "Bischofswerda",
        "codes": [
            "01877",
            "01906"
        ]
    },
    {
        "city": "Pulsnitz",
        "codes": [
            "01896"
        ]
    },
    {
        "city": "Großröhrsdorf",
        "codes": [
            "01900"
        ]
    },
    {
        "city": "Neukirch / Lausitz",
        "codes": [
            "01904"
        ]
    },
    {
        "city": "Großharthau",
        "codes": [
            "01909"
        ]
    },
    {
        "city": "Kamenz",
        "codes": [
            "01917"
        ]
    },
    {
        "city": "Deutschbaselitz",
        "codes": [
            "01920"
        ]
    },
    {
        "city": "Königsbrück",
        "codes": [
            "01936"
        ]
    },
    {
        "city": "Ruhland",
        "codes": [
            "01945"
        ]
    },
    {
        "city": "Senftenberg",
        "codes": [
            "01968"
        ]
    },
    {
        "city": "Lauchhammer",
        "codes": [
            "01979"
        ]
    },
    {
        "city": "Großräschen",
        "codes": [
            "01983"
        ]
    },
    {
        "city": "Schwarzheide Niederlausitz",
        "codes": [
            "01987"
        ]
    },
    {
        "city": "Ortrand",
        "codes": [
            "01990"
        ]
    },
    {
        "city": "Schipkau",
        "codes": [
            "01993"
        ]
    },
    {
        "city": "Annahütte",
        "codes": [
            "01994"
        ]
    },
    {
        "city": "Hosena",
        "codes": [
            "01996"
        ]
    },
    {
        "city": "Klettwitz",
        "codes": [
            "01998"
        ]
    },
    {
        "city": "Bautzen",
        "codes": [
            "02625"
        ]
    },
    {
        "city": "Weißenberg",
        "codes": [
            "02627"
        ]
    },
    {
        "city": "Göda",
        "codes": [
            "02633"
        ]
    },
    {
        "city": "Wilthen",
        "codes": [
            "02681"
        ]
    },
    {
        "city": "Sohland an der Spree",
        "codes": [
            "02689"
        ]
    },
    {
        "city": "Obergurig",
        "codes": [
            "02692"
        ]
    },
    {
        "city": "Großdubrau",
        "codes": [
            "02694"
        ]
    },
    {
        "city": "Königswartha",
        "codes": [
            "02699"
        ]
    },
    {
        "city": "Löbau",
        "codes": [
            "02708"
        ]
    },
    {
        "city": "Neugersdorf",
        "codes": [
            "02727"
        ]
    },
    {
        "city": "Ebersbach",
        "codes": [
            "02730"
        ]
    },
    {
        "city": "Cunewalde",
        "codes": [
            "02733"
        ]
    },
    {
        "city": "Oppach",
        "codes": [
            "02736"
        ]
    },
    {
        "city": "Eibau",
        "codes": [
            "02739"
        ]
    },
    {
        "city": "Neusalza-Spremberg",
        "codes": [
            "02742"
        ]
    },
    {
        "city": "Oberoderwitz",
        "codes": [
            "02744"
        ]
    },
    {
        "city": "Herrnhut",
        "codes": [
            "02747"
        ]
    },
    {
        "city": "Bernstadt",
        "codes": [
            "02748",
            "89182"
        ]
    },
    {
        "city": "Zittau",
        "codes": [
            "02763"
        ]
    },
    {
        "city": "Großschönau",
        "codes": [
            "02779"
        ]
    },
    {
        "city": "Seifhennersdorf",
        "codes": [
            "02782"
        ]
    },
    {
        "city": "Olbersdorf",
        "codes": [
            "02785"
        ]
    },
    {
        "city": "Hirschfelde",
        "codes": [
            "02788"
        ]
    },
    {
        "city": "Niederoderwitz",
        "codes": [
            "02791"
        ]
    },
    {
        "city": "Leutersdorf",
        "codes": [
            "02794"
        ]
    },
    {
        "city": "Jonsdorf",
        "codes": [
            "02796"
        ]
    },
    {
        "city": "Oybin",
        "codes": [
            "02797"
        ]
    },
    {
        "city": "Waltersdorf",
        "codes": [
            "02799"
        ]
    },
    {
        "city": "Görlitz",
        "codes": [
            "02826",
            "02827",
            "02828",
            "02829",
            "02899"
        ]
    },
    {
        "city": "Reichenbach / Oberlausitz",
        "codes": [
            "02894"
        ]
    },
    {
        "city": "Niesky",
        "codes": [
            "02906",
            "02923"
        ]
    },
    {
        "city": "Rothenburg / Oberlausitz",
        "codes": [
            "02929"
        ]
    },
    {
        "city": "Weißwasser",
        "codes": [
            "02943"
        ]
    },
    {
        "city": "Bad Muskau",
        "codes": [
            "02953"
        ]
    },
    {
        "city": "Rietschen",
        "codes": [
            "02956"
        ]
    },
    {
        "city": "Krauschwitz",
        "codes": [
            "02957"
        ]
    },
    {
        "city": "Schleife",
        "codes": [
            "02959"
        ]
    },
    {
        "city": "Hoyerswerda",
        "codes": [
            "02977",
            "02979"
        ]
    },
    {
        "city": "Lauta",
        "codes": [
            "02991"
        ]
    },
    {
        "city": "Bernsdorf",
        "codes": [
            "02994"
        ]
    },
    {
        "city": "Wittichenau",
        "codes": [
            "02997"
        ]
    },
    {
        "city": "Lohsa",
        "codes": [
            "02999"
        ]
    },
    {
        "city": "Cottbus",
        "codes": [
            "03042",
            "03044",
            "03046",
            "03048",
            "03050",
            "03051",
            "03052",
            "03053",
            "03054",
            "03055"
        ]
    },
    {
        "city": "Gallinchen",
        "codes": [
            "03058"
        ]
    },
    {
        "city": "Burg / Spreewald",
        "codes": [
            "03096"
        ]
    },
    {
        "city": "Kolkwitz",
        "codes": [
            "03099"
        ]
    },
    {
        "city": "Neupetershain",
        "codes": [
            "03103"
        ]
    },
    {
        "city": "Drebkau",
        "codes": [
            "03116"
        ]
    },
    {
        "city": "Welzow",
        "codes": [
            "03119"
        ]
    },
    {
        "city": "Spremberg",
        "codes": [
            "03130"
        ]
    },
    {
        "city": "Schwarze Pumpe",
        "codes": [
            "03139"
        ]
    },
    {
        "city": "Forst / Lausitz",
        "codes": [
            "03149"
        ]
    },
    {
        "city": "Döbern",
        "codes": [
            "03159"
        ]
    },
    {
        "city": "Guben",
        "codes": [
            "03172"
        ]
    },
    {
        "city": "Peitz",
        "codes": [
            "03185"
        ]
    },
    {
        "city": "Jänschwalde",
        "codes": [
            "03197"
        ]
    },
    {
        "city": "Calau",
        "codes": [
            "03205"
        ]
    },
    {
        "city": "Lübbenau / Spreewald",
        "codes": [
            "03222"
        ]
    },
    {
        "city": "Vetschau",
        "codes": [
            "03226"
        ]
    },
    {
        "city": "Altdöbern",
        "codes": [
            "03229"
        ]
    },
    {
        "city": "Finsterwalde",
        "codes": [
            "03238"
        ]
    },
    {
        "city": "Crinitz",
        "codes": [
            "03246"
        ]
    },
    {
        "city": "Sonnewalde",
        "codes": [
            "03249"
        ]
    },
    {
        "city": "Doberlug-Kirchhain",
        "codes": [
            "03253"
        ]
    },
    {
        "city": "Leipzig",
        "codes": [
            "04103",
            "04105",
            "04107",
            "04109",
            "04129",
            "04155",
            "04157",
            "04158",
            "04159",
            "04177",
            "04178",
            "04179",
            "04205",
            "04207",
            "04209",
            "04229",
            "04249",
            "04275",
            "04277",
            "04279",
            "04288",
            "04289",
            "04299",
            "04315",
            "04316",
            "04317",
            "04318",
            "04319",
            "04328",
            "04329",
            "04347",
            "04349",
            "04356",
            "04357"
        ]
    },
    {
        "city": "Markkleeberg",
        "codes": [
            "04416",
            "04445"
        ]
    },
    {
        "city": "Markranstädt",
        "codes": [
            "04420"
        ]
    },
    {
        "city": "Taucha",
        "codes": [
            "04425"
        ]
    },
    {
        "city": "Böhlitz-Ehrenberg",
        "codes": [
            "04430"
        ]
    },
    {
        "city": "Schkeuditz",
        "codes": [
            "04435"
        ]
    },
    {
        "city": "Engelsdorf",
        "codes": [
            "04439"
        ]
    },
    {
        "city": "Zwenkau",
        "codes": [
            "04442",
            "04460"
        ]
    },
    {
        "city": "Wiederitzsch",
        "codes": [
            "04448"
        ]
    },
    {
        "city": "Borsdorf",
        "codes": [
            "04451"
        ]
    },
    {
        "city": "Holzhausen",
        "codes": [
            "04454"
        ]
    },
    {
        "city": "Mölkau",
        "codes": [
            "04457"
        ]
    },
    {
        "city": "Großpösna",
        "codes": [
            "04463"
        ]
    },
    {
        "city": "Lindenthal",
        "codes": [
            "04466"
        ]
    },
    {
        "city": "Lützschema-Stahmeln",
        "codes": [
            "04469"
        ]
    },
    {
        "city": "Delitzsch",
        "codes": [
            "04509"
        ]
    },
    {
        "city": "Rackwitz",
        "codes": [
            "04519"
        ]
    },
    {
        "city": "Pegau",
        "codes": [
            "04523"
        ]
    },
    {
        "city": "Groitzsch",
        "codes": [
            "04539"
        ]
    },
    {
        "city": "Borna",
        "codes": [
            "04552"
        ]
    },
    {
        "city": "Böhlen",
        "codes": [
            "04564"
        ]
    },
    {
        "city": "Regis-Breitingen",
        "codes": [
            "04565"
        ]
    },
    {
        "city": "Kitzscher",
        "codes": [
            "04567"
        ]
    },
    {
        "city": "Rötha",
        "codes": [
            "04571"
        ]
    },
    {
        "city": "Neukieritzsch",
        "codes": [
            "04574",
            "04575"
        ]
    },
    {
        "city": "Espenhain",
        "codes": [
            "04579"
        ]
    },
    {
        "city": "Altenburg",
        "codes": [
            "04600",
            "04603",
            "04617"
        ]
    },
    {
        "city": "Meuselwitz",
        "codes": [
            "04610"
        ]
    },
    {
        "city": "Lucka",
        "codes": [
            "04613"
        ]
    },
    {
        "city": "Langenleuba-Niederhain",
        "codes": [
            "04618"
        ]
    },
    {
        "city": "Schmölln",
        "codes": [
            "04626"
        ]
    },
    {
        "city": "Gößnitz",
        "codes": [
            "04639"
        ]
    },
    {
        "city": "Geithain",
        "codes": [
            "04643"
        ]
    },
    {
        "city": "Bad Lausick",
        "codes": [
            "04651"
        ]
    },
    {
        "city": "Frohburg",
        "codes": [
            "04654"
        ]
    },
    {
        "city": "Kohren-Sahlis",
        "codes": [
            "04655"
        ]
    },
    {
        "city": "Langensteinbach",
        "codes": [
            "04657"
        ]
    },
    {
        "city": "Grimma",
        "codes": [
            "04668"
        ]
    },
    {
        "city": "Colditz",
        "codes": [
            "04680"
        ]
    },
    {
        "city": "Naunhof",
        "codes": [
            "04683"
        ]
    },
    {
        "city": "Nerchau",
        "codes": [
            "04685"
        ]
    },
    {
        "city": "Trebsen / Mulde",
        "codes": [
            "04687"
        ]
    },
    {
        "city": "Mutschen",
        "codes": [
            "04688"
        ]
    },
    {
        "city": "Leisnig",
        "codes": [
            "04703"
        ]
    },
    {
        "city": "Döbeln",
        "codes": [
            "04720"
        ]
    },
    {
        "city": "Waldheim",
        "codes": [
            "04736"
        ]
    },
    {
        "city": "Roßwein",
        "codes": [
            "04741"
        ]
    },
    {
        "city": "Hartha",
        "codes": [
            "04746"
        ]
    },
    {
        "city": "Ostrau",
        "codes": [
            "04749"
        ]
    },
    {
        "city": "Oschatz",
        "codes": [
            "04758"
        ]
    },
    {
        "city": "Mügeln",
        "codes": [
            "04769"
        ]
    },
    {
        "city": "Dahlen",
        "codes": [
            "04774"
        ]
    },
    {
        "city": "Wermsdorf",
        "codes": [
            "04779"
        ]
    },
    {
        "city": "Wurzen",
        "codes": [
            "04808"
        ]
    },
    {
        "city": "Brandis",
        "codes": [
            "04821"
        ]
    },
    {
        "city": "Beucha",
        "codes": [
            "04824"
        ]
    },
    {
        "city": "Machern",
        "codes": [
            "04827"
        ]
    },
    {
        "city": "Bennewitz",
        "codes": [
            "04828"
        ]
    },
    {
        "city": "Eilenburg",
        "codes": [
            "04838"
        ]
    },
    {
        "city": "Bad Düben",
        "codes": [
            "04849"
        ]
    },
    {
        "city": "Torgau",
        "codes": [
            "04860",
            "04861",
            "04886"
        ]
    },
    {
        "city": "Belgern",
        "codes": [
            "04874"
        ]
    },
    {
        "city": "Dommitzsch",
        "codes": [
            "04880"
        ]
    },
    {
        "city": "Schildau",
        "codes": [
            "04889"
        ]
    },
    {
        "city": "Falkenberg / Elster",
        "codes": [
            "04895"
        ]
    },
    {
        "city": "Elsterwerda",
        "codes": [
            "04910"
        ]
    },
    {
        "city": "Herzberg / Elster",
        "codes": [
            "04916"
        ]
    },
    {
        "city": "Bad Liebenwerda",
        "codes": [
            "04924",
            "04931"
        ]
    },
    {
        "city": "Plessa",
        "codes": [
            "04928"
        ]
    },
    {
        "city": "Prösen",
        "codes": [
            "04932"
        ]
    },
    {
        "city": "Hohenleipisch",
        "codes": [
            "04934"
        ]
    },
    {
        "city": "Schlieben",
        "codes": [
            "04936"
        ]
    },
    {
        "city": "Uebigau",
        "codes": [
            "04938"
        ]
    },
    {
        "city": "Halle / Saale",
        "codes": [
            "06108",
            "06110",
            "06112",
            "06114",
            "06116",
            "06118",
            "06120",
            "06122",
            "06124",
            "06126",
            "06128",
            "06130",
            "06132"
        ]
    },
    {
        "city": "Teutschenthal",
        "codes": [
            "06179"
        ]
    },
    {
        "city": "Dieskau",
        "codes": [
            "06184"
        ]
    },
    {
        "city": "Landsberg",
        "codes": [
            "06188"
        ]
    },
    {
        "city": "Löbejün",
        "codes": [
            "06193"
        ]
    },
    {
        "city": "Wettin",
        "codes": [
            "06198"
        ]
    },
    {
        "city": "Merseburg / Saale",
        "codes": [
            "06217"
        ]
    },
    {
        "city": "Bad Dürrenberg",
        "codes": [
            "06231"
        ]
    },
    {
        "city": "Leuna",
        "codes": [
            "06237"
        ]
    },
    {
        "city": "Braunsbedra",
        "codes": [
            "06242"
        ]
    },
    {
        "city": "Bad Lauchstädt",
        "codes": [
            "06246"
        ]
    },
    {
        "city": "Mücheln / Geiseltal",
        "codes": [
            "06249"
        ]
    },
    {
        "city": "Wallendorf / Luppe",
        "codes": [
            "06254"
        ]
    },
    {
        "city": "Schafstädt",
        "codes": [
            "06255"
        ]
    },
    {
        "city": "Schkopau",
        "codes": [
            "06258"
        ]
    },
    {
        "city": "Frankleben",
        "codes": [
            "06259"
        ]
    },
    {
        "city": "Querfurt",
        "codes": [
            "06268"
        ]
    },
    {
        "city": "Schraplau",
        "codes": [
            "06279"
        ]
    },
    {
        "city": "Eisleben",
        "codes": [
            "06295"
        ]
    },
    {
        "city": "Benndorf",
        "codes": [
            "06308"
        ]
    },
    {
        "city": "Helbra",
        "codes": [
            "06311"
        ]
    },
    {
        "city": "Hergisdorf",
        "codes": [
            "06313"
        ]
    },
    {
        "city": "Röblingen am See",
        "codes": [
            "06317"
        ]
    },
    {
        "city": "Wansleben am See",
        "codes": [
            "06318"
        ]
    },
    {
        "city": "Hettstedt",
        "codes": [
            "06333"
        ]
    },
    {
        "city": "Mansfeld",
        "codes": [
            "06343"
        ]
    },
    {
        "city": "Gerbstedt",
        "codes": [
            "06347"
        ]
    },
    {
        "city": "Großörner",
        "codes": [
            "06348"
        ]
    },
    {
        "city": "Köthen / Anhalt",
        "codes": [
            "06366",
            "06369"
        ]
    },
    {
        "city": "Kleinzerbst",
        "codes": [
            "06385"
        ]
    },
    {
        "city": "Osternienburg",
        "codes": [
            "06386"
        ]
    },
    {
        "city": "Gröbzig",
        "codes": [
            "06388"
        ]
    },
    {
        "city": "Bernburg / Saale",
        "codes": [
            "06406"
        ]
    },
    {
        "city": "Ilberstedt",
        "codes": [
            "06408"
        ]
    },
    {
        "city": "Könnern",
        "codes": [
            "06420"
        ]
    },
    {
        "city": "Alsleben / Saale",
        "codes": [
            "06425"
        ]
    },
    {
        "city": "Nienburg / Saale",
        "codes": [
            "06429"
        ]
    },
    {
        "city": "Aschersleben",
        "codes": [
            "06449"
        ]
    },
    {
        "city": "Sandersleben",
        "codes": [
            "06456"
        ]
    },
    {
        "city": "Hedersleben",
        "codes": [
            "06458"
        ]
    },
    {
        "city": "Ermsleben",
        "codes": [
            "06463"
        ]
    },
    {
        "city": "Frose",
        "codes": [
            "06464"
        ]
    },
    {
        "city": "Gatersleben",
        "codes": [
            "06466"
        ]
    },
    {
        "city": "Hoym",
        "codes": [
            "06467"
        ]
    },
    {
        "city": "Nachterstedt",
        "codes": [
            "06469"
        ]
    },
    {
        "city": "Quedlinburg",
        "codes": [
            "06484",
            "06485"
        ]
    },
    {
        "city": "Ballenstedt",
        "codes": [
            "06493"
        ]
    },
    {
        "city": "Thale / Harz",
        "codes": [
            "06502"
        ]
    },
    {
        "city": "Gernrode",
        "codes": [
            "06507"
        ]
    },
    {
        "city": "Sangerhausen",
        "codes": [
            "06526"
        ]
    },
    {
        "city": "Wallhausen",
        "codes": [
            "06528",
            "74599"
        ]
    },
    {
        "city": "Roßla",
        "codes": [
            "06536"
        ]
    },
    {
        "city": "Kelbra / Kyffhäuser",
        "codes": [
            "06537"
        ]
    },
    {
        "city": "Allstedt",
        "codes": [
            "06542"
        ]
    },
    {
        "city": "Wippra",
        "codes": [
            "06543"
        ]
    },
    {
        "city": "Stolberg / Harz",
        "codes": [
            "06547"
        ]
    },
    {
        "city": "Rottleberode",
        "codes": [
            "06548"
        ]
    },
    {
        "city": "Artern / Unstrut",
        "codes": [
            "06556"
        ]
    },
    {
        "city": "Bad Frankenhausen / Kyffhäuser",
        "codes": [
            "06567"
        ]
    },
    {
        "city": "Roßleben",
        "codes": [
            "06571"
        ]
    },
    {
        "city": "Heldrungen",
        "codes": [
            "06577"
        ]
    },
    {
        "city": "Oldisleben",
        "codes": [
            "06578"
        ]
    },
    {
        "city": "Naumburg / Saale",
        "codes": [
            "06618"
        ]
    },
    {
        "city": "Bad Kösen",
        "codes": [
            "06628"
        ]
    },
    {
        "city": "Freyburg / Unstrut",
        "codes": [
            "06632"
        ]
    },
    {
        "city": "Laucha / Unstrut",
        "codes": [
            "06636"
        ]
    },
    {
        "city": "Karsdorf",
        "codes": [
            "06638"
        ]
    },
    {
        "city": "Nebra / Unstrut",
        "codes": [
            "06642"
        ]
    },
    {
        "city": "Bad Bibra",
        "codes": [
            "06647"
        ]
    },
    {
        "city": "Eckartsberga",
        "codes": [
            "06648"
        ]
    },
    {
        "city": "Weißenfels",
        "codes": [
            "06667"
        ]
    },
    {
        "city": "Hohenmölsen",
        "codes": [
            "06679"
        ]
    },
    {
        "city": "Teuchern",
        "codes": [
            "06682"
        ]
    },
    {
        "city": "Lützen",
        "codes": [
            "06686"
        ]
    },
    {
        "city": "Großkorbetha",
        "codes": [
            "06688"
        ]
    },
    {
        "city": "Zeitz",
        "codes": [
            "06712"
        ]
    },
    {
        "city": "Osterfeld",
        "codes": [
            "06721"
        ]
    },
    {
        "city": "Droyßig",
        "codes": [
            "06722"
        ]
    },
    {
        "city": "Kayna",
        "codes": [
            "06724"
        ]
    },
    {
        "city": "Profen",
        "codes": [
            "06725"
        ]
    },
    {
        "city": "Theißen",
        "codes": [
            "06727"
        ]
    },
    {
        "city": "Tröglitz",
        "codes": [
            "06729"
        ]
    },
    {
        "city": "Bitterfeld",
        "codes": [
            "06749"
        ]
    },
    {
        "city": "Wolfen",
        "codes": [
            "06766"
        ]
    },
    {
        "city": "Gräfenhainichen",
        "codes": [
            "06772",
            "06773"
        ]
    },
    {
        "city": "Pouch",
        "codes": [
            "06774"
        ]
    },
    {
        "city": "Dessau",
        "codes": [
            "06779",
            "06842",
            "06844",
            "06846",
            "06847",
            "06849"
        ]
    },
    {
        "city": "Zörbig",
        "codes": [
            "06780"
        ]
    },
    {
        "city": "Oranienbaum",
        "codes": [
            "06785"
        ]
    },
    {
        "city": "Vockerode",
        "codes": [
            "06786"
        ]
    },
    {
        "city": "Zschornewitz",
        "codes": [
            "06791"
        ]
    },
    {
        "city": "Sandersdorf",
        "codes": [
            "06792"
        ]
    },
    {
        "city": "Zscherndorf",
        "codes": [
            "06794"
        ]
    },
    {
        "city": "Brehna",
        "codes": [
            "06796"
        ]
    },
    {
        "city": "Jeßnitz",
        "codes": [
            "06800"
        ]
    },
    {
        "city": "Greppin",
        "codes": [
            "06803"
        ]
    },
    {
        "city": "Muldenstein",
        "codes": [
            "06804"
        ]
    },
    {
        "city": "Holzweißig",
        "codes": [
            "06808"
        ]
    },
    {
        "city": "Roitzsch",
        "codes": [
            "06809"
        ]
    },
    {
        "city": "Dessau-Roßlau",
        "codes": [
            "06861"
        ]
    },
    {
        "city": "Roßlau / Elbe",
        "codes": [
            "06862"
        ]
    },
    {
        "city": "Coswig / Anhalt",
        "codes": [
            "06869"
        ]
    },
    {
        "city": "Wittenberg",
        "codes": [
            "06886",
            "06888",
            "06889",
            "06896"
        ]
    },
    {
        "city": "Zahna",
        "codes": [
            "06895"
        ]
    },
    {
        "city": "Kemberg",
        "codes": [
            "06901"
        ]
    },
    {
        "city": "Bad Schmiedeberg",
        "codes": [
            "06905"
        ]
    },
    {
        "city": "Pretzsch / Elbe",
        "codes": [
            "06909"
        ]
    },
    {
        "city": "Jessen / Elster",
        "codes": [
            "06917",
            "06928"
        ]
    },
    {
        "city": "Elster / Elbe",
        "codes": [
            "06918"
        ]
    },
    {
        "city": "Prettin",
        "codes": [
            "06922"
        ]
    },
    {
        "city": "Annaburg",
        "codes": [
            "06925"
        ]
    },
    {
        "city": "Holzdorf",
        "codes": [
            "06926",
            "24364"
        ]
    },
    {
        "city": "Saalfeld / Saale",
        "codes": [
            "07318"
        ]
    },
    {
        "city": "Probstzella",
        "codes": [
            "07330"
        ]
    },
    {
        "city": "Unterwellenborn",
        "codes": [
            "07333"
        ]
    },
    {
        "city": "Kamsdorf",
        "codes": [
            "07334"
        ]
    },
    {
        "city": "Könitz",
        "codes": [
            "07336"
        ]
    },
    {
        "city": "Kaulsdorf",
        "codes": [
            "07338"
        ]
    },
    {
        "city": "Wurzbach",
        "codes": [
            "07343"
        ]
    },
    {
        "city": "Lehesten",
        "codes": [
            "07349"
        ]
    },
    {
        "city": "Lobenstein. Moorbad",
        "codes": [
            "07356",
            "07366"
        ]
    },
    {
        "city": "Ebersdorf / Thüringen",
        "codes": [
            "07368"
        ]
    },
    {
        "city": "Pößneck",
        "codes": [
            "07381"
        ]
    },
    {
        "city": "Krölpa",
        "codes": [
            "07387"
        ]
    },
    {
        "city": "Ranis",
        "codes": [
            "07389"
        ]
    },
    {
        "city": "Rudolstadt",
        "codes": [
            "07407"
        ]
    },
    {
        "city": "Bad Blankenburg",
        "codes": [
            "07422"
        ]
    },
    {
        "city": "Königsee",
        "codes": [
            "07426"
        ]
    },
    {
        "city": "Schwarzburg",
        "codes": [
            "07427"
        ]
    },
    {
        "city": "Sitzendorf",
        "codes": [
            "07429"
        ]
    },
    {
        "city": "Gera",
        "codes": [
            "07545",
            "07546",
            "07548",
            "07549",
            "07551",
            "07552",
            "07554",
            "07557"
        ]
    },
    {
        "city": "Töppeln",
        "codes": [
            "07558"
        ]
    },
    {
        "city": "Weida",
        "codes": [
            "07570"
        ]
    },
    {
        "city": "Ronneburg",
        "codes": [
            "07580",
            "63549"
        ]
    },
    {
        "city": "Bad Köstritz",
        "codes": [
            "07586"
        ]
    },
    {
        "city": "Münchenbernsdorf",
        "codes": [
            "07589"
        ]
    },
    {
        "city": "Eisenberg",
        "codes": [
            "07607"
        ]
    },
    {
        "city": "Krossen / Elster",
        "codes": [
            "07613"
        ]
    },
    {
        "city": "Bürgel",
        "codes": [
            "07616"
        ]
    },
    {
        "city": "Schkölen",
        "codes": [
            "07619"
        ]
    },
    {
        "city": "Hermsdorf",
        "codes": [
            "07629"
        ]
    },
    {
        "city": "Bad Klosterlausnitz",
        "codes": [
            "07639"
        ]
    },
    {
        "city": "Stadtroda",
        "codes": [
            "07646"
        ]
    },
    {
        "city": "Jena",
        "codes": [
            "07743",
            "07745",
            "07747",
            "07749",
            "07751"
        ]
    },
    {
        "city": "Kahla",
        "codes": [
            "07768"
        ]
    },
    {
        "city": "Camburg",
        "codes": [
            "07774"
        ]
    },
    {
        "city": "Dorndorf-Steudnitz",
        "codes": [
            "07778"
        ]
    },
    {
        "city": "Neustadt / Orla",
        "codes": [
            "07806"
        ]
    },
    {
        "city": "Triptis",
        "codes": [
            "07819"
        ]
    },
    {
        "city": "Schleiz",
        "codes": [
            "07907",
            "07919"
        ]
    },
    {
        "city": "Tanna",
        "codes": [
            "07922"
        ]
    },
    {
        "city": "Ziegenrück",
        "codes": [
            "07924"
        ]
    },
    {
        "city": "Gefell",
        "codes": [
            "07926"
        ]
    },
    {
        "city": "Hirschberg",
        "codes": [
            "07927"
        ]
    },
    {
        "city": "Saalburg",
        "codes": [
            "07929"
        ]
    },
    {
        "city": "Zeulenroda",
        "codes": [
            "07937"
        ]
    },
    {
        "city": "Triebes",
        "codes": [
            "07950"
        ]
    },
    {
        "city": "Pausa / Vogtland",
        "codes": [
            "07952"
        ]
    },
    {
        "city": "Auma",
        "codes": [
            "07955"
        ]
    },
    {
        "city": "Langenwetzendorf",
        "codes": [
            "07957"
        ]
    },
    {
        "city": "Hohenleuben",
        "codes": [
            "07958"
        ]
    },
    {
        "city": "Greiz",
        "codes": [
            "07973"
        ]
    },
    {
        "city": "Berga / Elster",
        "codes": [
            "07980"
        ]
    },
    {
        "city": "Elsterberg",
        "codes": [
            "07985"
        ]
    },
    {
        "city": "Mohlsdorf",
        "codes": [
            "07987"
        ]
    },
    {
        "city": "Teichwolframsdorf",
        "codes": [
            "07989"
        ]
    },
    {
        "city": "Zwickau",
        "codes": [
            "08056",
            "08058",
            "08060",
            "08062",
            "08064",
            "08066"
        ]
    },
    {
        "city": "Kirchberg",
        "codes": [
            "08107",
            "84434",
            "94259"
        ]
    },
    {
        "city": "Wilkau-Haßlau",
        "codes": [
            "08112"
        ]
    },
    {
        "city": "Lichtentanne",
        "codes": [
            "08115"
        ]
    },
    {
        "city": "Hartenstein",
        "codes": [
            "08118"
        ]
    },
    {
        "city": "Wiesenburg",
        "codes": [
            "08121",
            "14827"
        ]
    },
    {
        "city": "Cainsdorf",
        "codes": [
            "08124"
        ]
    },
    {
        "city": "Mosel",
        "codes": [
            "08129"
        ]
    },
    {
        "city": "Mülsen Sankt Jacob",
        "codes": [
            "08132"
        ]
    },
    {
        "city": "Langenbach",
        "codes": [
            "08134",
            "85416"
        ]
    },
    {
        "city": "Thurm",
        "codes": [
            "08138"
        ]
    },
    {
        "city": "Reinsdorf",
        "codes": [
            "08141"
        ]
    },
    {
        "city": "Ebersbrunn",
        "codes": [
            "08144"
        ]
    },
    {
        "city": "Ortmannsdorf",
        "codes": [
            "08146"
        ]
    },
    {
        "city": "Crinitzberg",
        "codes": [
            "08147"
        ]
    },
    {
        "city": "Vielau",
        "codes": [
            "08149"
        ]
    },
    {
        "city": "Auerbach / Vogtland",
        "codes": [
            "08209"
        ]
    },
    {
        "city": "Falkenstein / Vogtland",
        "codes": [
            "08223"
        ]
    },
    {
        "city": "Rodewisch",
        "codes": [
            "08228"
        ]
    },
    {
        "city": "Treuen",
        "codes": [
            "08233"
        ]
    },
    {
        "city": "Ellefeld",
        "codes": [
            "08236"
        ]
    },
    {
        "city": "Steinberg",
        "codes": [
            "08237",
            "92449"
        ]
    },
    {
        "city": "Bergen",
        "codes": [
            "08239",
            "29303",
            "55608",
            "83346"
        ]
    },
    {
        "city": "Klingenthal / Sachsen",
        "codes": [
            "08248"
        ]
    },
    {
        "city": "Markneukirchen",
        "codes": [
            "08258"
        ]
    },
    {
        "city": "Schöneck / Vogtland",
        "codes": [
            "08261"
        ]
    },
    {
        "city": "Tannenbergsthal / Vogtland",
        "codes": [
            "08262"
        ]
    },
    {
        "city": "Erlbach",
        "codes": [
            "08265",
            "84567"
        ]
    },
    {
        "city": "Zwota",
        "codes": [
            "08267"
        ]
    },
    {
        "city": "Hammerbrücke",
        "codes": [
            "08269"
        ]
    },
    {
        "city": "Aue",
        "codes": [
            "08280"
        ]
    },
    {
        "city": "Schneeberg",
        "codes": [
            "08289",
            "63936"
        ]
    },
    {
        "city": "Lößnitz",
        "codes": [
            "08294"
        ]
    },
    {
        "city": "Zwönitz",
        "codes": [
            "08297"
        ]
    },
    {
        "city": "Schlema",
        "codes": [
            "08301"
        ]
    },
    {
        "city": "Schönheide",
        "codes": [
            "08304"
        ]
    },
    {
        "city": "Eibenstock",
        "codes": [
            "08309"
        ]
    },
    {
        "city": "Lauter / Sachsen",
        "codes": [
            "08312"
        ]
    },
    {
        "city": "Bernsbach",
        "codes": [
            "08315"
        ]
    },
    {
        "city": "Hundshübel",
        "codes": [
            "08318"
        ]
    },
    {
        "city": "Zschorlau",
        "codes": [
            "08321"
        ]
    },
    {
        "city": "Bockau",
        "codes": [
            "08324"
        ]
    },
    {
        "city": "Carlsfeld",
        "codes": [
            "08325"
        ]
    },
    {
        "city": "Sosa",
        "codes": [
            "08326"
        ]
    },
    {
        "city": "Stützengrün",
        "codes": [
            "08328"
        ]
    },
    {
        "city": "Schwarzenberg / Erzgebirge",
        "codes": [
            "08340"
        ]
    },
    {
        "city": "Johanngeorgenstadt",
        "codes": [
            "08349"
        ]
    },
    {
        "city": "Raschau",
        "codes": [
            "08352"
        ]
    },
    {
        "city": "Rittersgrün",
        "codes": [
            "08355"
        ]
    },
    {
        "city": "Grünhain",
        "codes": [
            "08358"
        ]
    },
    {
        "city": "Breitenbrunn / Erzgebirge",
        "codes": [
            "08359"
        ]
    },
    {
        "city": "Glauchau",
        "codes": [
            "08371",
            "08373"
        ]
    },
    {
        "city": "Meerane",
        "codes": [
            "08393"
        ]
    },
    {
        "city": "Waldenburg",
        "codes": [
            "08396",
            "74638"
        ]
    },
    {
        "city": "Wolkenburg-Kaufungen",
        "codes": [
            "08399"
        ]
    },
    {
        "city": "Werdau",
        "codes": [
            "08412"
        ]
    },
    {
        "city": "Fraureuth",
        "codes": [
            "08427"
        ]
    },
    {
        "city": "Langenbernsdorf",
        "codes": [
            "08428"
        ]
    },
    {
        "city": "Steinpleis",
        "codes": [
            "08432"
        ]
    },
    {
        "city": "Crimmitschau",
        "codes": [
            "08439",
            "08451"
        ]
    },
    {
        "city": "Neukirchen / Pleiße",
        "codes": [
            "08459"
        ]
    },
    {
        "city": "Reichenbach / Vogtland",
        "codes": [
            "08468"
        ]
    },
    {
        "city": "Lengenfeld",
        "codes": [
            "08485"
        ]
    },
    {
        "city": "Netzschkau",
        "codes": [
            "08491"
        ]
    },
    {
        "city": "Neumark",
        "codes": [
            "08496"
        ]
    },
    {
        "city": "Mylau",
        "codes": [
            "08499"
        ]
    },
    {
        "city": "Plauen",
        "codes": [
            "08523",
            "08525",
            "08527",
            "08529"
        ]
    },
    {
        "city": "Weischlitz",
        "codes": [
            "08538"
        ]
    },
    {
        "city": "Mehltheuer",
        "codes": [
            "08539"
        ]
    },
    {
        "city": "Neuensalz",
        "codes": [
            "08541"
        ]
    },
    {
        "city": "Pöhl",
        "codes": [
            "08543"
        ]
    },
    {
        "city": "Jößnitz",
        "codes": [
            "08547"
        ]
    },
    {
        "city": "Syrau",
        "codes": [
            "08548"
        ]
    },
    {
        "city": "Oelsnitz",
        "codes": [
            "08606"
        ]
    },
    {
        "city": "Adorf",
        "codes": [
            "08626"
        ]
    },
    {
        "city": "Bad Elster",
        "codes": [
            "08645"
        ]
    },
    {
        "city": "Bad Brambach",
        "codes": [
            "08648"
        ]
    },
    {
        "city": "Chemnitz",
        "codes": [
            "09111",
            "09112",
            "09113",
            "09114",
            "09116",
            "09117",
            "09119",
            "09120",
            "09122",
            "09123",
            "09125",
            "09126",
            "09127",
            "09128",
            "09130",
            "09131"
        ]
    },
    {
        "city": "Limbach-Oberfrohna",
        "codes": [
            "09212"
        ]
    },
    {
        "city": "Burgstädt",
        "codes": [
            "09217"
        ]
    },
    {
        "city": "Neukirchen / Erzgebirge",
        "codes": [
            "09221"
        ]
    },
    {
        "city": "Grüna",
        "codes": [
            "09224"
        ]
    },
    {
        "city": "Wittgensdorf",
        "codes": [
            "09228"
        ]
    },
    {
        "city": "Hartmannsdorf",
        "codes": [
            "09232"
        ]
    },
    {
        "city": "Burkhardtsdorf",
        "codes": [
            "09235"
        ]
    },
    {
        "city": "Claußnitz",
        "codes": [
            "09236"
        ]
    },
    {
        "city": "Auerswalde",
        "codes": [
            "09238"
        ]
    },
    {
        "city": "Kemtau",
        "codes": [
            "09240"
        ]
    },
    {
        "city": "Mühlau",
        "codes": [
            "09241"
        ]
    },
    {
        "city": "Niederfrohna",
        "codes": [
            "09243"
        ]
    },
    {
        "city": "Lichtenau",
        "codes": [
            "09244",
            "33165",
            "77839",
            "91586"
        ]
    },
    {
        "city": "Pleißa",
        "codes": [
            "09246"
        ]
    },
    {
        "city": "Röhrsdorf",
        "codes": [
            "09247"
        ]
    },
    {
        "city": "Taura bei Burgstädt",
        "codes": [
            "09249"
        ]
    },
    {
        "city": "Rochlitz",
        "codes": [
            "09306"
        ]
    },
    {
        "city": "Penig",
        "codes": [
            "09322"
        ]
    },
    {
        "city": "Geringswalde",
        "codes": [
            "09326"
        ]
    },
    {
        "city": "Lunzenau",
        "codes": [
            "09328"
        ]
    },
    {
        "city": "Hohenstein-Ernstthal",
        "codes": [
            "09337"
        ]
    },
    {
        "city": "Lichtenstein / Sachsen",
        "codes": [
            "09350"
        ]
    },
    {
        "city": "Oberlungwitz",
        "codes": [
            "09353"
        ]
    },
    {
        "city": "Gersdorf",
        "codes": [
            "09355"
        ]
    },
    {
        "city": "Sankt Egidien",
        "codes": [
            "09356"
        ]
    },
    {
        "city": "Wüstenbrand",
        "codes": [
            "09358"
        ]
    },
    {
        "city": "Stollberg / Erzgebirge",
        "codes": [
            "09366"
        ]
    },
    {
        "city": "Oelsnitz / Erzgebirge",
        "codes": [
            "09376"
        ]
    },
    {
        "city": "Thalheim / Erzgebirge",
        "codes": [
            "09380"
        ]
    },
    {
        "city": "Lugau / Erzgebirge",
        "codes": [
            "09385"
        ]
    },
    {
        "city": "Jahnsdorf",
        "codes": [
            "09387"
        ]
    },
    {
        "city": "Gornsdorf",
        "codes": [
            "09390"
        ]
    },
    {
        "city": "Auerbach",
        "codes": [
            "09392",
            "94530"
        ]
    },
    {
        "city": "Hohndorf",
        "codes": [
            "09394"
        ]
    },
    {
        "city": "Hormersdorf",
        "codes": [
            "09395"
        ]
    },
    {
        "city": "Neuwürschnitz",
        "codes": [
            "09397"
        ]
    },
    {
        "city": "Niederwürschnitz",
        "codes": [
            "09399"
        ]
    },
    {
        "city": "Zschopau",
        "codes": [
            "09405"
        ]
    },
    {
        "city": "Thum",
        "codes": [
            "09419"
        ]
    },
    {
        "city": "Gelenau / Erzgebirge",
        "codes": [
            "09423"
        ]
    },
    {
        "city": "Ehrenfriedersdorf",
        "codes": [
            "09427"
        ]
    },
    {
        "city": "Wolkenstein",
        "codes": [
            "09429"
        ]
    },
    {
        "city": "Drebach",
        "codes": [
            "09430"
        ]
    },
    {
        "city": "Großolbersdorf",
        "codes": [
            "09432"
        ]
    },
    {
        "city": "Krumhermersdorf",
        "codes": [
            "09434"
        ]
    },
    {
        "city": "Scharfenstein",
        "codes": [
            "09435"
        ]
    },
    {
        "city": "Waldkirchen / Erzgebirge",
        "codes": [
            "09437"
        ]
    },
    {
        "city": "Amtsberg",
        "codes": [
            "09439"
        ]
    },
    {
        "city": "Annaberg-Buchholz",
        "codes": [
            "09456"
        ]
    },
    {
        "city": "Sehma",
        "codes": [
            "09465"
        ]
    },
    {
        "city": "Geyer",
        "codes": [
            "09468"
        ]
    },
    {
        "city": "Bärenstein",
        "codes": [
            "09471"
        ]
    },
    {
        "city": "Crottendorf",
        "codes": [
            "09474"
        ]
    },
    {
        "city": "Jöhstadt",
        "codes": [
            "09477"
        ]
    },
    {
        "city": "Scheibenberg",
        "codes": [
            "09481"
        ]
    },
    {
        "city": "Oberwiesenthal",
        "codes": [
            "09484"
        ]
    },
    {
        "city": "Schlettau",
        "codes": [
            "09487"
        ]
    },
    {
        "city": "Wiesa",
        "codes": [
            "09488"
        ]
    },
    {
        "city": "Marienberg",
        "codes": [
            "09496"
        ]
    },
    {
        "city": "Pockau / Flöhatal",
        "codes": [
            "09509"
        ]
    },
    {
        "city": "Lengefeld",
        "codes": [
            "09514"
        ]
    },
    {
        "city": "Zöblitz",
        "codes": [
            "09517"
        ]
    },
    {
        "city": "Großrückerswalde",
        "codes": [
            "09518"
        ]
    },
    {
        "city": "Olbernhau",
        "codes": [
            "09526"
        ]
    },
    {
        "city": "Neuhausen / Erzgebirge",
        "codes": [
            "09544"
        ]
    },
    {
        "city": "Seiffen / Erzgebirge",
        "codes": [
            "09548"
        ]
    },
    {
        "city": "Flöha",
        "codes": [
            "09557"
        ]
    },
    {
        "city": "Oederan",
        "codes": [
            "09569"
        ]
    },
    {
        "city": "Leubsdorf",
        "codes": [
            "09573"
        ]
    },
    {
        "city": "Eppendorf",
        "codes": [
            "09575"
        ]
    },
    {
        "city": "Niederwiesa",
        "codes": [
            "09577"
        ]
    },
    {
        "city": "Borstendorf",
        "codes": [
            "09579"
        ]
    },
    {
        "city": "Freiberg",
        "codes": [
            "09599",
            "09600"
        ]
    },
    {
        "city": "Großschirma",
        "codes": [
            "09603"
        ]
    },
    {
        "city": "Brand-Erbisdorf",
        "codes": [
            "09618"
        ]
    },
    {
        "city": "Mulda / Sachsen",
        "codes": [
            "09619"
        ]
    },
    {
        "city": "Frauenstein",
        "codes": [
            "09623"
        ]
    },
    {
        "city": "Bobritzsch",
        "codes": [
            "09627"
        ]
    },
    {
        "city": "Reinsberg",
        "codes": [
            "09629"
        ]
    },
    {
        "city": "Halsbrücke",
        "codes": [
            "09633"
        ]
    },
    {
        "city": "Siebenlehn",
        "codes": [
            "09634"
        ]
    },
    {
        "city": "Langenau",
        "codes": [
            "09636",
            "89129"
        ]
    },
    {
        "city": "Lichtenberg / Erzgebirge",
        "codes": [
            "09638"
        ]
    },
    {
        "city": "Mittweida",
        "codes": [
            "09648"
        ]
    },
    {
        "city": "Hainichen",
        "codes": [
            "09661"
        ]
    },
    {
        "city": "Frankenberg",
        "codes": [
            "09669"
        ]
    },
    {
        "city": "Berlin",
        "codes": [
            "10115",
            "10117",
            "10119",
            "10178",
            "10179",
            "10243",
            "10245",
            "10247",
            "10249",
            "10315",
            "10317",
            "10318",
            "10319",
            "10365",
            "10367",
            "10369",
            "10405",
            "10407",
            "10409",
            "10435",
            "10437",
            "10439",
            "10551",
            "10553",
            "10555",
            "10557",
            "10559",
            "10585",
            "10587",
            "10589",
            "10623",
            "10625",
            "10627",
            "10629",
            "10707",
            "10709",
            "10711",
            "10713",
            "10715",
            "10717",
            "10719",
            "10777",
            "10779",
            "10781",
            "10783",
            "10785",
            "10787",
            "10789",
            "10823",
            "10825",
            "10827",
            "10829",
            "10961",
            "10963",
            "10965",
            "10967",
            "10969",
            "10997",
            "10999",
            "12043",
            "12045",
            "12047",
            "12049",
            "12051",
            "12053",
            "12055",
            "12057",
            "12059",
            "12099",
            "12101",
            "12103",
            "12105",
            "12107",
            "12109",
            "12157",
            "12159",
            "12161",
            "12163",
            "12165",
            "12167",
            "12169",
            "12203",
            "12205",
            "12207",
            "12209",
            "12247",
            "12249",
            "12277",
            "12279",
            "12305",
            "12307",
            "12309",
            "12347",
            "12349",
            "12351",
            "12353",
            "12355",
            "12357",
            "12359",
            "12435",
            "12437",
            "12439",
            "12459",
            "12487",
            "12489",
            "12524",
            "12526",
            "12527",
            "12555",
            "12557",
            "12559",
            "12587",
            "12589",
            "12619",
            "12621",
            "12623",
            "12627",
            "12629",
            "12679",
            "12681",
            "12683",
            "12685",
            "12687",
            "12689",
            "13051",
            "13053",
            "13055",
            "13057",
            "13059",
            "13086",
            "13088",
            "13089",
            "13125",
            "13127",
            "13129",
            "13156",
            "13158",
            "13159",
            "13187",
            "13189",
            "13347",
            "13349",
            "13351",
            "13353",
            "13355",
            "13357",
            "13359",
            "13403",
            "13405",
            "13407",
            "13409",
            "13435",
            "13437",
            "13439",
            "13465",
            "13467",
            "13469",
            "13503",
            "13505",
            "13507",
            "13509",
            "13581",
            "13583",
            "13585",
            "13587",
            "13589",
            "13591",
            "13593",
            "13595",
            "13597",
            "13599",
            "13627",
            "13629",
            "14050",
            "14052",
            "14053",
            "14055",
            "14057",
            "14059",
            "14089",
            "14109",
            "14129",
            "14163",
            "14165",
            "14167",
            "14169",
            "14193",
            "14195",
            "14197",
            "14199"
        ]
    },
    {
        "city": "Schönefeld",
        "codes": [
            "12529"
        ]
    },
    {
        "city": "Dahlwitz-Hoppegarten",
        "codes": [
            "12625"
        ]
    },
    {
        "city": "Potsdam",
        "codes": [
            "14467",
            "14469",
            "14471",
            "14473",
            "14476",
            "14478",
            "14480",
            "14482"
        ]
    },
    {
        "city": "Teltow",
        "codes": [
            "14513"
        ]
    },
    {
        "city": "Kleinmachnow",
        "codes": [
            "14532"
        ]
    },
    {
        "city": "Werder / Havel",
        "codes": [
            "14542"
        ]
    },
    {
        "city": "Beelitz",
        "codes": [
            "14547"
        ]
    },
    {
        "city": "Caputh",
        "codes": [
            "14548"
        ]
    },
    {
        "city": "Groß Kreutz",
        "codes": [
            "14550"
        ]
    },
    {
        "city": "Michendorf",
        "codes": [
            "14552"
        ]
    },
    {
        "city": "Seddiner See",
        "codes": [
            "14554"
        ]
    },
    {
        "city": "Langerwisch",
        "codes": [
            "14557"
        ]
    },
    {
        "city": "Bergholz-Rehbrücke",
        "codes": [
            "14558"
        ]
    },
    {
        "city": "Falkensee",
        "codes": [
            "14612"
        ]
    },
    {
        "city": "Schönwalde",
        "codes": [
            "14621",
            "15910"
        ]
    },
    {
        "city": "Dallgow",
        "codes": [
            "14624"
        ]
    },
    {
        "city": "Elstal",
        "codes": [
            "14627"
        ]
    },
    {
        "city": "Nauen",
        "codes": [
            "14641"
        ]
    },
    {
        "city": "Brieselang",
        "codes": [
            "14656"
        ]
    },
    {
        "city": "Friesack",
        "codes": [
            "14662"
        ]
    },
    {
        "city": "Ketzin",
        "codes": [
            "14669"
        ]
    },
    {
        "city": "Rathenow",
        "codes": [
            "14712"
        ]
    },
    {
        "city": "Milow",
        "codes": [
            "14715"
        ]
    },
    {
        "city": "Premnitz",
        "codes": [
            "14727"
        ]
    },
    {
        "city": "Rhinow",
        "codes": [
            "14728"
        ]
    },
    {
        "city": "Brandenburg / Havel",
        "codes": [
            "14770",
            "14772",
            "14774",
            "14776",
            "14778"
        ]
    },
    {
        "city": "Wusterwitz",
        "codes": [
            "14789"
        ]
    },
    {
        "city": "Ziesar",
        "codes": [
            "14793"
        ]
    },
    {
        "city": "Lehnin",
        "codes": [
            "14797"
        ]
    },
    {
        "city": "Pritzerbe",
        "codes": [
            "14798"
        ]
    },
    {
        "city": "Belzig",
        "codes": [
            "14806"
        ]
    },
    {
        "city": "Brück",
        "codes": [
            "14822"
        ]
    },
    {
        "city": "Niemegk",
        "codes": [
            "14823"
        ]
    },
    {
        "city": "Görzke",
        "codes": [
            "14828"
        ]
    },
    {
        "city": "Jüterbog",
        "codes": [
            "14913"
        ]
    },
    {
        "city": "Treuenbrietzen",
        "codes": [
            "14929"
        ]
    },
    {
        "city": "Luckenwalde",
        "codes": [
            "14943"
        ]
    },
    {
        "city": "Nuthe-Urstromtal",
        "codes": [
            "14947"
        ]
    },
    {
        "city": "Trebbin",
        "codes": [
            "14959"
        ]
    },
    {
        "city": "Ludwigsfelde",
        "codes": [
            "14974"
        ]
    },
    {
        "city": "Großbeeren",
        "codes": [
            "14979"
        ]
    },
    {
        "city": "Frankfurt / Oder",
        "codes": [
            "15230",
            "15232",
            "15234",
            "15236"
        ]
    },
    {
        "city": "Brieskow-Finkenheerd",
        "codes": [
            "15295"
        ]
    },
    {
        "city": "Müllrose",
        "codes": [
            "15299"
        ]
    },
    {
        "city": "Seelow",
        "codes": [
            "15306"
        ]
    },
    {
        "city": "Neuhardenberg",
        "codes": [
            "15320"
        ]
    },
    {
        "city": "Letschin",
        "codes": [
            "15324"
        ]
    },
    {
        "city": "Lebus",
        "codes": [
            "15326"
        ]
    },
    {
        "city": "Manschnow",
        "codes": [
            "15328"
        ]
    },
    {
        "city": "Strausberg",
        "codes": [
            "15344",
            "15345"
        ]
    },
    {
        "city": "Neuenhagen bei Berlin",
        "codes": [
            "15366"
        ]
    },
    {
        "city": "Fredersdorf-Vogelsdorf",
        "codes": [
            "15370"
        ]
    },
    {
        "city": "Müncheberg",
        "codes": [
            "15374"
        ]
    },
    {
        "city": "Buckow / Märkische Schweiz",
        "codes": [
            "15377"
        ]
    },
    {
        "city": "Hennickendorf",
        "codes": [
            "15378"
        ]
    },
    {
        "city": "Fürstenwalde / Spree",
        "codes": [
            "15517",
            "15518"
        ]
    },
    {
        "city": "Bad Saarow-Pieskow",
        "codes": [
            "15526"
        ]
    },
    {
        "city": "Spreenhagen",
        "codes": [
            "15528"
        ]
    },
    {
        "city": "Erkner",
        "codes": [
            "15537"
        ]
    },
    {
        "city": "Rüdersdorf bei Berlin",
        "codes": [
            "15562"
        ]
    },
    {
        "city": "Schöneiche bei Berlin",
        "codes": [
            "15566"
        ]
    },
    {
        "city": "Woltersdorf",
        "codes": [
            "15569",
            "29497"
        ]
    },
    {
        "city": "Königs Wusterhausen",
        "codes": [
            "15711",
            "15712",
            "15713"
        ]
    },
    {
        "city": "Schulzendorf bei Eichenwade",
        "codes": [
            "15732"
        ]
    },
    {
        "city": "Zeuthen",
        "codes": [
            "15738"
        ]
    },
    {
        "city": "Bestensee",
        "codes": [
            "15741"
        ]
    },
    {
        "city": "Wildau",
        "codes": [
            "15745"
        ]
    },
    {
        "city": "Groß Köris",
        "codes": [
            "15746"
        ]
    },
    {
        "city": "Märkisch Buchholz",
        "codes": [
            "15748"
        ]
    },
    {
        "city": "Mittenwalde",
        "codes": [
            "15749"
        ]
    },
    {
        "city": "Niederlehme",
        "codes": [
            "15751"
        ]
    },
    {
        "city": "Prieros",
        "codes": [
            "15752"
        ]
    },
    {
        "city": "Senzig",
        "codes": [
            "15754"
        ]
    },
    {
        "city": "Teupitz",
        "codes": [
            "15755"
        ]
    },
    {
        "city": "Halbe",
        "codes": [
            "15757"
        ]
    },
    {
        "city": "Zernsdorf",
        "codes": [
            "15758"
        ]
    },
    {
        "city": "Zossen",
        "codes": [
            "15806"
        ]
    },
    {
        "city": "Blankenfelde",
        "codes": [
            "15827"
        ]
    },
    {
        "city": "Mahlow",
        "codes": [
            "15831"
        ]
    },
    {
        "city": "Rangsdorf",
        "codes": [
            "15834"
        ]
    },
    {
        "city": "Baruth",
        "codes": [
            "15837"
        ]
    },
    {
        "city": "Wünsdorf",
        "codes": [
            "15838"
        ]
    },
    {
        "city": "Beeskow",
        "codes": [
            "15848"
        ]
    },
    {
        "city": "Storkow",
        "codes": [
            "15859"
        ]
    },
    {
        "city": "Wendisch Rietz",
        "codes": [
            "15864"
        ]
    },
    {
        "city": "Lieberose",
        "codes": [
            "15868"
        ]
    },
    {
        "city": "Eisenhüttenstadt",
        "codes": [
            "15890"
        ]
    },
    {
        "city": "Neuzelle",
        "codes": [
            "15898"
        ]
    },
    {
        "city": "Lübben / Spreewald",
        "codes": [
            "15907"
        ]
    },
    {
        "city": "Straupitz",
        "codes": [
            "15913"
        ]
    },
    {
        "city": "Luckau",
        "codes": [
            "15926",
            "29487"
        ]
    },
    {
        "city": "Dahme",
        "codes": [
            "15936",
            "23747"
        ]
    },
    {
        "city": "Golßen",
        "codes": [
            "15938"
        ]
    },
    {
        "city": "Eberswalde",
        "codes": [
            "16225",
            "16227",
            "16230"
        ]
    },
    {
        "city": "Finowfurt",
        "codes": [
            "16244"
        ]
    },
    {
        "city": "Joachimsthal",
        "codes": [
            "16247"
        ]
    },
    {
        "city": "Oderberg",
        "codes": [
            "16248"
        ]
    },
    {
        "city": "Bad Freienwalde",
        "codes": [
            "16259"
        ]
    },
    {
        "city": "Wriezen",
        "codes": [
            "16269"
        ]
    },
    {
        "city": "Angermünde",
        "codes": [
            "16278"
        ]
    },
    {
        "city": "Schwedt / Oder",
        "codes": [
            "16303",
            "16306"
        ]
    },
    {
        "city": "Gartz / Oder",
        "codes": [
            "16307"
        ]
    },
    {
        "city": "Bernau bei Berlin",
        "codes": [
            "16321"
        ]
    },
    {
        "city": "Zepernick",
        "codes": [
            "16341"
        ]
    },
    {
        "city": "Klosterfelde",
        "codes": [
            "16348"
        ]
    },
    {
        "city": "Basdorf",
        "codes": [
            "16352"
        ]
    },
    {
        "city": "Werneuchen",
        "codes": [
            "16356"
        ]
    },
    {
        "city": "Biesenthal",
        "codes": [
            "16359"
        ]
    },
    {
        "city": "Oranienburg",
        "codes": [
            "16515"
        ]
    },
    {
        "city": "Hohen Neuendorf bei Berlin",
        "codes": [
            "16540",
            "16556",
            "16562"
        ]
    },
    {
        "city": "Birkenwerder bei Berlin",
        "codes": [
            "16547"
        ]
    },
    {
        "city": "Glienicke / Nordbahn",
        "codes": [
            "16548"
        ]
    },
    {
        "city": "Schildow",
        "codes": [
            "16552"
        ]
    },
    {
        "city": "Liebenwalde",
        "codes": [
            "16559"
        ]
    },
    {
        "city": "Lehnitz / Nordbahn",
        "codes": [
            "16565"
        ]
    },
    {
        "city": "Mühlenbeck",
        "codes": [
            "16567"
        ]
    },
    {
        "city": "Velten",
        "codes": [
            "16727"
        ]
    },
    {
        "city": "Hennigsdorf bei Berlin",
        "codes": [
            "16761"
        ]
    },
    {
        "city": "Kremmen",
        "codes": [
            "16766"
        ]
    },
    {
        "city": "Leegebruch",
        "codes": [
            "16767"
        ]
    },
    {
        "city": "Gransee",
        "codes": [
            "16775"
        ]
    },
    {
        "city": "Zehdenick",
        "codes": [
            "16792"
        ]
    },
    {
        "city": "Fürstenberg",
        "codes": [
            "16798",
            "37699"
        ]
    },
    {
        "city": "Neuruppin",
        "codes": [
            "16816",
            "16818",
            "16827"
        ]
    },
    {
        "city": "Rheinsberg",
        "codes": [
            "16831"
        ]
    },
    {
        "city": "Fehrbellin",
        "codes": [
            "16833"
        ]
    },
    {
        "city": "Lindow",
        "codes": [
            "16835"
        ]
    },
    {
        "city": "Flecken Zechlin",
        "codes": [
            "16837"
        ]
    },
    {
        "city": "Neustadt / Dosse",
        "codes": [
            "16845"
        ]
    },
    {
        "city": "Kyritz",
        "codes": [
            "16866"
        ]
    },
    {
        "city": "Wusterhausen / Dosse",
        "codes": [
            "16868"
        ]
    },
    {
        "city": "Wittstock",
        "codes": [
            "16909"
        ]
    },
    {
        "city": "Freyenstein",
        "codes": [
            "16918"
        ]
    },
    {
        "city": "Pritzwalk",
        "codes": [
            "16928"
        ]
    },
    {
        "city": "Meyenburg",
        "codes": [
            "16945"
        ]
    },
    {
        "city": "Putlitz",
        "codes": [
            "16949"
        ]
    },
    {
        "city": "Neubrandenburg",
        "codes": [
            "17033",
            "17034",
            "17036"
        ]
    },
    {
        "city": "Brunn",
        "codes": [
            "17039"
        ]
    },
    {
        "city": "Altentreptow",
        "codes": [
            "17087"
        ]
    },
    {
        "city": "Burow",
        "codes": [
            "17089"
        ]
    },
    {
        "city": "Rosenow",
        "codes": [
            "17091"
        ]
    },
    {
        "city": "Burg Stargard",
        "codes": [
            "17094"
        ]
    },
    {
        "city": "Friedland",
        "codes": [
            "17098",
            "37133"
        ]
    },
    {
        "city": "Kotelow",
        "codes": [
            "17099"
        ]
    },
    {
        "city": "Demmin",
        "codes": [
            "17109",
            "17111"
        ]
    },
    {
        "city": "Loitz",
        "codes": [
            "17121"
        ]
    },
    {
        "city": "Jarmen",
        "codes": [
            "17126"
        ]
    },
    {
        "city": "Bentzin",
        "codes": [
            "17129"
        ]
    },
    {
        "city": "Malchin",
        "codes": [
            "17139"
        ]
    },
    {
        "city": "Stavenhagen",
        "codes": [
            "17153"
        ]
    },
    {
        "city": "Neukalen",
        "codes": [
            "17154"
        ]
    },
    {
        "city": "Dargun",
        "codes": [
            "17159"
        ]
    },
    {
        "city": "Teterow",
        "codes": [
            "17166"
        ]
    },
    {
        "city": "Jördenstorf",
        "codes": [
            "17168"
        ]
    },
    {
        "city": "Gnoien",
        "codes": [
            "17179"
        ]
    },
    {
        "city": "Waren / Müritz",
        "codes": [
            "17192"
        ]
    },
    {
        "city": "Grabowhöfe",
        "codes": [
            "17194"
        ]
    },
    {
        "city": "Röbel / Müritz",
        "codes": [
            "17207"
        ]
    },
    {
        "city": "Wredenhagen",
        "codes": [
            "17209"
        ]
    },
    {
        "city": "Malchow",
        "codes": [
            "17213"
        ]
    },
    {
        "city": "Nossentiner Hütte",
        "codes": [
            "17214"
        ]
    },
    {
        "city": "Penzlin",
        "codes": [
            "17217"
        ]
    },
    {
        "city": "Möllenhagen",
        "codes": [
            "17219"
        ]
    },
    {
        "city": "Neustrelitz",
        "codes": [
            "17235"
        ]
    },
    {
        "city": "Blankensee",
        "codes": [
            "17237"
        ]
    },
    {
        "city": "Rechlin",
        "codes": [
            "17248"
        ]
    },
    {
        "city": "Mirow",
        "codes": [
            "17252"
        ]
    },
    {
        "city": "Wesenberg",
        "codes": [
            "17255"
        ]
    },
    {
        "city": "Feldberg",
        "codes": [
            "17258"
        ]
    },
    {
        "city": "Lichtenberg",
        "codes": [
            "17259",
            "95192"
        ]
    },
    {
        "city": "Templin",
        "codes": [
            "17268"
        ]
    },
    {
        "city": "Lychen",
        "codes": [
            "17279"
        ]
    },
    {
        "city": "Prenzlau",
        "codes": [
            "17291"
        ]
    },
    {
        "city": "Pasewalk",
        "codes": [
            "17309"
        ]
    },
    {
        "city": "Löcknitz",
        "codes": [
            "17321"
        ]
    },
    {
        "city": "Grambow",
        "codes": [
            "17322"
        ]
    },
    {
        "city": "Brüssow",
        "codes": [
            "17326"
        ]
    },
    {
        "city": "Penkun",
        "codes": [
            "17328"
        ]
    },
    {
        "city": "Nadrensee",
        "codes": [
            "17329"
        ]
    },
    {
        "city": "Strasburg",
        "codes": [
            "17335"
        ]
    },
    {
        "city": "Trebenow",
        "codes": [
            "17337"
        ]
    },
    {
        "city": "Woldegk",
        "codes": [
            "17348"
        ]
    },
    {
        "city": "Groß-Miltzow",
        "codes": [
            "17349"
        ]
    },
    {
        "city": "Torgelow",
        "codes": [
            "17358"
        ]
    },
    {
        "city": "Eggesin",
        "codes": [
            "17367"
        ]
    },
    {
        "city": "Ueckermünde",
        "codes": [
            "17373",
            "17375"
        ]
    },
    {
        "city": "Ferdinandshof",
        "codes": [
            "17379"
        ]
    },
    {
        "city": "Anklam",
        "codes": [
            "17389"
        ]
    },
    {
        "city": "Klein Bünzow",
        "codes": [
            "17390"
        ]
    },
    {
        "city": "Krien",
        "codes": [
            "17391"
        ]
    },
    {
        "city": "Spantekow",
        "codes": [
            "17392"
        ]
    },
    {
        "city": "Ducherow",
        "codes": [
            "17398"
        ]
    },
    {
        "city": "Usedom",
        "codes": [
            "17406"
        ]
    },
    {
        "city": "Ahlbeck",
        "codes": [
            "17419"
        ]
    },
    {
        "city": "Heringsdorf",
        "codes": [
            "17424",
            "23777"
        ]
    },
    {
        "city": "Bansin",
        "codes": [
            "17429"
        ]
    },
    {
        "city": "Wolgast",
        "codes": [
            "17438"
        ]
    },
    {
        "city": "Kröslin",
        "codes": [
            "17440"
        ]
    },
    {
        "city": "Karlshagen",
        "codes": [
            "17449"
        ]
    },
    {
        "city": "Zinnowitz",
        "codes": [
            "17454"
        ]
    },
    {
        "city": "Koserow",
        "codes": [
            "17459"
        ]
    },
    {
        "city": "Greifswald",
        "codes": [
            "17489",
            "17491",
            "17493"
        ]
    },
    {
        "city": "Karlsburg",
        "codes": [
            "17495"
        ]
    },
    {
        "city": "Neuenkirchen",
        "codes": [
            "17498",
            "21763",
            "25792",
            "27251",
            "29643",
            "48485",
            "49586"
        ]
    },
    {
        "city": "Gützkow",
        "codes": [
            "17506"
        ]
    },
    {
        "city": "Lubmin",
        "codes": [
            "17509"
        ]
    },
    {
        "city": "Rostock",
        "codes": [
            "18055",
            "18057",
            "18059",
            "18069",
            "18106",
            "18107",
            "18109",
            "18119",
            "18146",
            "18147"
        ]
    },
    {
        "city": "Graal-Müritz",
        "codes": [
            "18181"
        ]
    },
    {
        "city": "Gelbensande",
        "codes": [
            "18182"
        ]
    },
    {
        "city": "Broderstorf",
        "codes": [
            "18184"
        ]
    },
    {
        "city": "Sanitz",
        "codes": [
            "18190"
        ]
    },
    {
        "city": "Tessin",
        "codes": [
            "18195"
        ]
    },
    {
        "city": "Dummerstorf",
        "codes": [
            "18196"
        ]
    },
    {
        "city": "Kritzmow",
        "codes": [
            "18198"
        ]
    },
    {
        "city": "Bad Doberan",
        "codes": [
            "18209"
        ]
    },
    {
        "city": "Börgerende-Rethwisch",
        "codes": [
            "18211"
        ]
    },
    {
        "city": "Kühlungsborn",
        "codes": [
            "18225"
        ]
    },
    {
        "city": "Rerik",
        "codes": [
            "18230"
        ]
    },
    {
        "city": "Neubukow",
        "codes": [
            "18233"
        ]
    },
    {
        "city": "Kröpelin",
        "codes": [
            "18236"
        ]
    },
    {
        "city": "Satow",
        "codes": [
            "18239"
        ]
    },
    {
        "city": "Bützow",
        "codes": [
            "18246"
        ]
    },
    {
        "city": "Warnow",
        "codes": [
            "18249"
        ]
    },
    {
        "city": "Schwaan",
        "codes": [
            "18258"
        ]
    },
    {
        "city": "Güstrow",
        "codes": [
            "18273",
            "18276"
        ]
    },
    {
        "city": "Lalendorf",
        "codes": [
            "18279"
        ]
    },
    {
        "city": "Krakow am See",
        "codes": [
            "18292"
        ]
    },
    {
        "city": "Laage",
        "codes": [
            "18299"
        ]
    },
    {
        "city": "Ribnitz-Damgarten",
        "codes": [
            "18311"
        ]
    },
    {
        "city": "Löbnitz",
        "codes": [
            "18314"
        ]
    },
    {
        "city": "Saal",
        "codes": [
            "18317"
        ]
    },
    {
        "city": "Trinwillershagen",
        "codes": [
            "18320"
        ]
    },
    {
        "city": "Bad Sülze",
        "codes": [
            "18334"
        ]
    },
    {
        "city": "Marlow",
        "codes": [
            "18337"
        ]
    },
    {
        "city": "Dierhagen",
        "codes": [
            "18347"
        ]
    },
    {
        "city": "Barth",
        "codes": [
            "18356"
        ]
    },
    {
        "city": "Zingst am Darß",
        "codes": [
            "18374"
        ]
    },
    {
        "city": "Prerow am Darß",
        "codes": [
            "18375"
        ]
    },
    {
        "city": "Stralsund",
        "codes": [
            "18435",
            "18437",
            "18439"
        ]
    },
    {
        "city": "Niepars",
        "codes": [
            "18442"
        ]
    },
    {
        "city": "Prohn",
        "codes": [
            "18445"
        ]
    },
    {
        "city": "Franzburg",
        "codes": [
            "18461"
        ]
    },
    {
        "city": "Tribsees",
        "codes": [
            "18465"
        ]
    },
    {
        "city": "Velgast",
        "codes": [
            "18469"
        ]
    },
    {
        "city": "Grimmen",
        "codes": [
            "18507"
        ]
    },
    {
        "city": "Wittenhagen",
        "codes": [
            "18510"
        ]
    },
    {
        "city": "Glewitz",
        "codes": [
            "18513"
        ]
    },
    {
        "city": "Rakow",
        "codes": [
            "18516"
        ]
    },
    {
        "city": "Miltzow",
        "codes": [
            "18519"
        ]
    },
    {
        "city": "Bergen / Rügen",
        "codes": [
            "18528"
        ]
    },
    {
        "city": "Sassnitz",
        "codes": [
            "18546"
        ]
    },
    {
        "city": "Sagard",
        "codes": [
            "18551"
        ]
    },
    {
        "city": "Dranske",
        "codes": [
            "18556"
        ]
    },
    {
        "city": "Insel Hiddensee",
        "codes": [
            "18565"
        ]
    },
    {
        "city": "Gingst",
        "codes": [
            "18569"
        ]
    },
    {
        "city": "Samtens",
        "codes": [
            "18573"
        ]
    },
    {
        "city": "Garz / Rügen",
        "codes": [
            "18574"
        ]
    },
    {
        "city": "Putbus",
        "codes": [
            "18581"
        ]
    },
    {
        "city": "Sellin",
        "codes": [
            "18586"
        ]
    },
    {
        "city": "Binz",
        "codes": [
            "18609"
        ]
    },
    {
        "city": "Schwerin",
        "codes": [
            "19053",
            "19055",
            "19057",
            "19059",
            "19061",
            "19063"
        ]
    },
    {
        "city": "Pinnow",
        "codes": [
            "19065"
        ]
    },
    {
        "city": "Leezen",
        "codes": [
            "19067",
            "23816"
        ]
    },
    {
        "city": "Lübstorf",
        "codes": [
            "19069"
        ]
    },
    {
        "city": "Brüsewitz",
        "codes": [
            "19071"
        ]
    },
    {
        "city": "Wittenförden",
        "codes": [
            "19073"
        ]
    },
    {
        "city": "Pampow",
        "codes": [
            "19075"
        ]
    },
    {
        "city": "Rastow",
        "codes": [
            "19077"
        ]
    },
    {
        "city": "Banzkow",
        "codes": [
            "19079"
        ]
    },
    {
        "city": "Plate",
        "codes": [
            "19086"
        ]
    },
    {
        "city": "Crivitz",
        "codes": [
            "19089"
        ]
    },
    {
        "city": "Gadebusch",
        "codes": [
            "19205"
        ]
    },
    {
        "city": "Lützow",
        "codes": [
            "19209"
        ]
    },
    {
        "city": "Rehna",
        "codes": [
            "19217"
        ]
    },
    {
        "city": "Hagenow",
        "codes": [
            "19230"
        ]
    },
    {
        "city": "Wittenburg",
        "codes": [
            "19243"
        ]
    },
    {
        "city": "Zarrentin",
        "codes": [
            "19246"
        ]
    },
    {
        "city": "Lübtheen",
        "codes": [
            "19249"
        ]
    },
    {
        "city": "Boizenburg / Elbe",
        "codes": [
            "19258"
        ]
    },
    {
        "city": "Vellahn",
        "codes": [
            "19260"
        ]
    },
    {
        "city": "Amt Neuhaus",
        "codes": [
            "19273"
        ]
    },
    {
        "city": "Ludwigslust",
        "codes": [
            "19288"
        ]
    },
    {
        "city": "Neu Kaliß",
        "codes": [
            "19294"
        ]
    },
    {
        "city": "Grabow",
        "codes": [
            "19300"
        ]
    },
    {
        "city": "Dömitz",
        "codes": [
            "19303"
        ]
    },
    {
        "city": "Neustadt-Glewe",
        "codes": [
            "19306"
        ]
    },
    {
        "city": "Lenzen / Elbe",
        "codes": [
            "19309"
        ]
    },
    {
        "city": "Wittenberge",
        "codes": [
            "19322"
        ]
    },
    {
        "city": "Bad Wilsnack",
        "codes": [
            "19336"
        ]
    },
    {
        "city": "Glöwen",
        "codes": [
            "19339"
        ]
    },
    {
        "city": "Perleberg",
        "codes": [
            "19348"
        ]
    },
    {
        "city": "Karstädt",
        "codes": [
            "19357"
        ]
    },
    {
        "city": "Parchim",
        "codes": [
            "19370"
        ]
    },
    {
        "city": "Spornitz",
        "codes": [
            "19372"
        ]
    },
    {
        "city": "Mestlin",
        "codes": [
            "19374"
        ]
    },
    {
        "city": "Siggelkow",
        "codes": [
            "19376"
        ]
    },
    {
        "city": "Lübz",
        "codes": [
            "19386"
        ]
    },
    {
        "city": "Plau am See",
        "codes": [
            "19395"
        ]
    },
    {
        "city": "Goldberg",
        "codes": [
            "19399"
        ]
    },
    {
        "city": "Sternberg",
        "codes": [
            "19406"
        ]
    },
    {
        "city": "Brüel",
        "codes": [
            "19412"
        ]
    },
    {
        "city": "Warin",
        "codes": [
            "19417"
        ]
    },
    {
        "city": "Hamburg",
        "codes": [
            "20095",
            "20097",
            "20099",
            "20144",
            "20146",
            "20148",
            "20149",
            "20249",
            "20251",
            "20253",
            "20255",
            "20257",
            "20259",
            "20354",
            "20355",
            "20357",
            "20359",
            "20457",
            "20459",
            "20535",
            "20537",
            "20539",
            "21029",
            "21031",
            "21033",
            "21035",
            "21037",
            "21039",
            "21073",
            "21075",
            "21077",
            "21079",
            "21107",
            "21109",
            "21129",
            "21147",
            "21149",
            "22041",
            "22043",
            "22045",
            "22047",
            "22049",
            "22081",
            "22083",
            "22085",
            "22087",
            "22089",
            "22111",
            "22115",
            "22117",
            "22119",
            "22143",
            "22145",
            "22147",
            "22149",
            "22159",
            "22175",
            "22177",
            "22179",
            "22297",
            "22299",
            "22301",
            "22303",
            "22305",
            "22307",
            "22309",
            "22335",
            "22337",
            "22339",
            "22359",
            "22391",
            "22393",
            "22395",
            "22397",
            "22399",
            "22415",
            "22417",
            "22419",
            "22453",
            "22455",
            "22457",
            "22459",
            "22523",
            "22525",
            "22527",
            "22529",
            "22547",
            "22549",
            "22559",
            "22587",
            "22589",
            "22605",
            "22607",
            "22609",
            "22761",
            "22763",
            "22765",
            "22767",
            "22769",
            "27499"
        ]
    },
    {
        "city": "Seevetal",
        "codes": [
            "21217",
            "21218",
            "21220"
        ]
    },
    {
        "city": "Rosengarten",
        "codes": [
            "21224",
            "74538"
        ]
    },
    {
        "city": "Bendestorf",
        "codes": [
            "21227"
        ]
    },
    {
        "city": "Harmstorf",
        "codes": [
            "21228"
        ]
    },
    {
        "city": "Buchholz in der Nordheide",
        "codes": [
            "21244"
        ]
    },
    {
        "city": "Tostedt",
        "codes": [
            "21255"
        ]
    },
    {
        "city": "Handeloh",
        "codes": [
            "21256"
        ]
    },
    {
        "city": "Otter",
        "codes": [
            "21259"
        ]
    },
    {
        "city": "Welle",
        "codes": [
            "21261"
        ]
    },
    {
        "city": "Jesteburg",
        "codes": [
            "21266"
        ]
    },
    {
        "city": "Hanstedt",
        "codes": [
            "21271",
            "29582"
        ]
    },
    {
        "city": "Egestorf",
        "codes": [
            "21272"
        ]
    },
    {
        "city": "Undeloh",
        "codes": [
            "21274"
        ]
    },
    {
        "city": "Hollenstedt",
        "codes": [
            "21279"
        ]
    },
    {
        "city": "Lüneburg",
        "codes": [
            "21335",
            "21337",
            "21339"
        ]
    },
    {
        "city": "Bleckede",
        "codes": [
            "21354"
        ]
    },
    {
        "city": "Bardowick",
        "codes": [
            "21357"
        ]
    },
    {
        "city": "Mechtersen",
        "codes": [
            "21358"
        ]
    },
    {
        "city": "Vögelsen",
        "codes": [
            "21360"
        ]
    },
    {
        "city": "Adendorf",
        "codes": [
            "21365"
        ]
    },
    {
        "city": "Dahlenburg",
        "codes": [
            "21368"
        ]
    },
    {
        "city": "Nahrendorf",
        "codes": [
            "21369"
        ]
    },
    {
        "city": "Tosterglope",
        "codes": [
            "21371"
        ]
    },
    {
        "city": "Salzhausen",
        "codes": [
            "21376"
        ]
    },
    {
        "city": "Scharnebeck",
        "codes": [
            "21379"
        ]
    },
    {
        "city": "Artlenburg",
        "codes": [
            "21380"
        ]
    },
    {
        "city": "Brietlingen",
        "codes": [
            "21382"
        ]
    },
    {
        "city": "Amelinghausen",
        "codes": [
            "21385"
        ]
    },
    {
        "city": "Betzendorf",
        "codes": [
            "21386"
        ]
    },
    {
        "city": "Soderstorf",
        "codes": [
            "21388"
        ]
    },
    {
        "city": "Reppenstedt",
        "codes": [
            "21391"
        ]
    },
    {
        "city": "Kirchgellersen",
        "codes": [
            "21394"
        ]
    },
    {
        "city": "Tespe",
        "codes": [
            "21395"
        ]
    },
    {
        "city": "Barendorf",
        "codes": [
            "21397"
        ]
    },
    {
        "city": "Neetze",
        "codes": [
            "21398"
        ]
    },
    {
        "city": "Reinstorf",
        "codes": [
            "21400"
        ]
    },
    {
        "city": "Thomasburg",
        "codes": [
            "21401"
        ]
    },
    {
        "city": "Wendisch Evern",
        "codes": [
            "21403"
        ]
    },
    {
        "city": "Melbeck",
        "codes": [
            "21406"
        ]
    },
    {
        "city": "Deutsch Evern",
        "codes": [
            "21407"
        ]
    },
    {
        "city": "Embsen",
        "codes": [
            "21409"
        ]
    },
    {
        "city": "Winsen (Luhe)",
        "codes": [
            "21423"
        ]
    },
    {
        "city": "Stelle",
        "codes": [
            "21435"
        ]
    },
    {
        "city": "Marschacht",
        "codes": [
            "21436"
        ]
    },
    {
        "city": "Brackel",
        "codes": [
            "21438"
        ]
    },
    {
        "city": "Marxen",
        "codes": [
            "21439"
        ]
    },
    {
        "city": "Garstedt",
        "codes": [
            "21441"
        ]
    },
    {
        "city": "Toppenstedt",
        "codes": [
            "21442"
        ]
    },
    {
        "city": "Vierhöfen",
        "codes": [
            "21444"
        ]
    },
    {
        "city": "Wulfsen",
        "codes": [
            "21445"
        ]
    },
    {
        "city": "Handorf",
        "codes": [
            "21447"
        ]
    },
    {
        "city": "Radbruch",
        "codes": [
            "21449"
        ]
    },
    {
        "city": "Reinbek",
        "codes": [
            "21465"
        ]
    },
    {
        "city": "Lauenburg / Elbe",
        "codes": [
            "21481"
        ]
    },
    {
        "city": "Gülzow",
        "codes": [
            "21483"
        ]
    },
    {
        "city": "Schwarzenbek",
        "codes": [
            "21493"
        ]
    },
    {
        "city": "Geesthacht",
        "codes": [
            "21502"
        ]
    },
    {
        "city": "Glinde",
        "codes": [
            "21509"
        ]
    },
    {
        "city": "Büchen",
        "codes": [
            "21514"
        ]
    },
    {
        "city": "Müssen",
        "codes": [
            "21516"
        ]
    },
    {
        "city": "Aumühle",
        "codes": [
            "21521"
        ]
    },
    {
        "city": "Hohnstorf (Elbe)",
        "codes": [
            "21522"
        ]
    },
    {
        "city": "Brunstorf",
        "codes": [
            "21524"
        ]
    },
    {
        "city": "Hohenhorn",
        "codes": [
            "21526"
        ]
    },
    {
        "city": "Kollow",
        "codes": [
            "21527"
        ]
    },
    {
        "city": "Kröppelshagen-Fahrendorf",
        "codes": [
            "21529"
        ]
    },
    {
        "city": "Buxtehude",
        "codes": [
            "21614"
        ]
    },
    {
        "city": "Neu Wulmstorf",
        "codes": [
            "21629"
        ]
    },
    {
        "city": "Jork",
        "codes": [
            "21635"
        ]
    },
    {
        "city": "Horneburg",
        "codes": [
            "21640"
        ]
    },
    {
        "city": "Apensen",
        "codes": [
            "21641"
        ]
    },
    {
        "city": "Beckdorf",
        "codes": [
            "21643"
        ]
    },
    {
        "city": "Sauensiek",
        "codes": [
            "21644"
        ]
    },
    {
        "city": "Halvesbostel",
        "codes": [
            "21646"
        ]
    },
    {
        "city": "Moisburg",
        "codes": [
            "21647"
        ]
    },
    {
        "city": "Regesbostel",
        "codes": [
            "21649"
        ]
    },
    {
        "city": "Stade",
        "codes": [
            "21680",
            "21682",
            "21683",
            "21684"
        ]
    },
    {
        "city": "Harsefeld",
        "codes": [
            "21698"
        ]
    },
    {
        "city": "Ahlerstedt",
        "codes": [
            "21702"
        ]
    },
    {
        "city": "Drochtersen",
        "codes": [
            "21706"
        ]
    },
    {
        "city": "Himmelpforten",
        "codes": [
            "21709"
        ]
    },
    {
        "city": "Engelschoff",
        "codes": [
            "21710"
        ]
    },
    {
        "city": "Großenwörden",
        "codes": [
            "21712"
        ]
    },
    {
        "city": "Hammah",
        "codes": [
            "21714"
        ]
    },
    {
        "city": "Fredenbeck",
        "codes": [
            "21717"
        ]
    },
    {
        "city": "Grünendeich",
        "codes": [
            "21720"
        ]
    },
    {
        "city": "Hollern-Twielenfleth",
        "codes": [
            "21723"
        ]
    },
    {
        "city": "Oldendorf",
        "codes": [
            "21726",
            "25588"
        ]
    },
    {
        "city": "Estorf",
        "codes": [
            "21727",
            "31629"
        ]
    },
    {
        "city": "Freiburg (Elbe)",
        "codes": [
            "21729"
        ]
    },
    {
        "city": "Balje",
        "codes": [
            "21730"
        ]
    },
    {
        "city": "Krummendeich",
        "codes": [
            "21732"
        ]
    },
    {
        "city": "Oederquart",
        "codes": [
            "21734"
        ]
    },
    {
        "city": "Wischhafen",
        "codes": [
            "21737"
        ]
    },
    {
        "city": "Dollern",
        "codes": [
            "21739"
        ]
    },
    {
        "city": "Hemmoor",
        "codes": [
            "21745"
        ]
    },
    {
        "city": "Hechthausen",
        "codes": [
            "21755"
        ]
    },
    {
        "city": "Osten",
        "codes": [
            "21756"
        ]
    },
    {
        "city": "Otterndorf",
        "codes": [
            "21762"
        ]
    },
    {
        "city": "Nordleda",
        "codes": [
            "21765"
        ]
    },
    {
        "city": "Lamstedt",
        "codes": [
            "21769"
        ]
    },
    {
        "city": "Mittelstenahe",
        "codes": [
            "21770"
        ]
    },
    {
        "city": "Stinstedt",
        "codes": [
            "21772"
        ]
    },
    {
        "city": "Ihlienworth",
        "codes": [
            "21775"
        ]
    },
    {
        "city": "Wanna",
        "codes": [
            "21776"
        ]
    },
    {
        "city": "Cadenberge",
        "codes": [
            "21781"
        ]
    },
    {
        "city": "Bülkau",
        "codes": [
            "21782"
        ]
    },
    {
        "city": "Geversdorf",
        "codes": [
            "21784"
        ]
    },
    {
        "city": "Neuhaus (Oste)",
        "codes": [
            "21785"
        ]
    },
    {
        "city": "Oberndorf",
        "codes": [
            "21787"
        ]
    },
    {
        "city": "Wingst",
        "codes": [
            "21789"
        ]
    },
    {
        "city": "Oststeinbek",
        "codes": [
            "22113"
        ]
    },
    {
        "city": "Norderstedt",
        "codes": [
            "22844",
            "22846",
            "22848",
            "22850",
            "22851"
        ]
    },
    {
        "city": "Schenefeld",
        "codes": [
            "22869",
            "25560"
        ]
    },
    {
        "city": "Wedel (Holstein)",
        "codes": [
            "22880"
        ]
    },
    {
        "city": "Barsbüttel",
        "codes": [
            "22885"
        ]
    },
    {
        "city": "Tangstedt",
        "codes": [
            "22889",
            "25499"
        ]
    },
    {
        "city": "Ahrensburg",
        "codes": [
            "22926"
        ]
    },
    {
        "city": "Großhansdorf",
        "codes": [
            "22927"
        ]
    },
    {
        "city": "Delingsdorf",
        "codes": [
            "22929"
        ]
    },
    {
        "city": "Bargteheide",
        "codes": [
            "22941"
        ]
    },
    {
        "city": "Trittau",
        "codes": [
            "22946"
        ]
    },
    {
        "city": "Ammersbek",
        "codes": [
            "22949"
        ]
    },
    {
        "city": "Lütjensee",
        "codes": [
            "22952"
        ]
    },
    {
        "city": "Hoisdorf",
        "codes": [
            "22955",
            "22961"
        ]
    },
    {
        "city": "Grönwohld",
        "codes": [
            "22956"
        ]
    },
    {
        "city": "Kuddewörde",
        "codes": [
            "22958"
        ]
    },
    {
        "city": "Linau",
        "codes": [
            "22959"
        ]
    },
    {
        "city": "Siek",
        "codes": [
            "22962"
        ]
    },
    {
        "city": "Steinburg",
        "codes": [
            "22964"
        ]
    },
    {
        "city": "Todendorf",
        "codes": [
            "22965"
        ]
    },
    {
        "city": "Tremsbüttel",
        "codes": [
            "22967"
        ]
    },
    {
        "city": "Witzhave",
        "codes": [
            "22969"
        ]
    },
    {
        "city": "Lübeck",
        "codes": [
            "23552",
            "23554",
            "23556",
            "23558",
            "23560",
            "23562",
            "23564",
            "23566",
            "23568",
            "23569",
            "23570"
        ]
    },
    {
        "city": "Bad Schwartau",
        "codes": [
            "23611"
        ]
    },
    {
        "city": "Stockelsdorf",
        "codes": [
            "23617"
        ]
    },
    {
        "city": "Zarpen",
        "codes": [
            "23619"
        ]
    },
    {
        "city": "Ahrensbök",
        "codes": [
            "23623"
        ]
    },
    {
        "city": "Ratekau",
        "codes": [
            "23626",
            "23689"
        ]
    },
    {
        "city": "Groß Grönau",
        "codes": [
            "23627"
        ]
    },
    {
        "city": "Krummesse",
        "codes": [
            "23628"
        ]
    },
    {
        "city": "Scharbeutz",
        "codes": [
            "23629",
            "23683",
            "23684"
        ]
    },
    {
        "city": "Timmendorfer Strand",
        "codes": [
            "23669"
        ]
    },
    {
        "city": "Eutin",
        "codes": [
            "23701"
        ]
    },
    {
        "city": "Malente",
        "codes": [
            "23714"
        ]
    },
    {
        "city": "Bosau",
        "codes": [
            "23715"
        ]
    },
    {
        "city": "Kasseedorf",
        "codes": [
            "23717"
        ]
    },
    {
        "city": "Glasau",
        "codes": [
            "23719"
        ]
    },
    {
        "city": "Neustadt in Holstein",
        "codes": [
            "23730"
        ]
    },
    {
        "city": "Lensahn",
        "codes": [
            "23738"
        ]
    },
    {
        "city": "Grömitz",
        "codes": [
            "23743"
        ]
    },
    {
        "city": "Schönwalde am Bungsberg",
        "codes": [
            "23744"
        ]
    },
    {
        "city": "Kellenhusen (Ostsee)",
        "codes": [
            "23746"
        ]
    },
    {
        "city": "Grube",
        "codes": [
            "23749"
        ]
    },
    {
        "city": "Oldenburg in Holstein",
        "codes": [
            "23758"
        ]
    },
    {
        "city": "Burg auf Fehmarn",
        "codes": [
            "23769"
        ]
    },
    {
        "city": "Heiligenhafen",
        "codes": [
            "23774"
        ]
    },
    {
        "city": "Großenbrode",
        "codes": [
            "23775"
        ]
    },
    {
        "city": "Neukirchen",
        "codes": [
            "23779",
            "25927",
            "34626",
            "94362"
        ]
    },
    {
        "city": "Bad Segeberg",
        "codes": [
            "23795"
        ]
    },
    {
        "city": "Wahlstedt",
        "codes": [
            "23812"
        ]
    },
    {
        "city": "Nehms",
        "codes": [
            "23813"
        ]
    },
    {
        "city": "Geschendorf",
        "codes": [
            "23815"
        ]
    },
    {
        "city": "Neuengörs",
        "codes": [
            "23818"
        ]
    },
    {
        "city": "Pronstorf",
        "codes": [
            "23820"
        ]
    },
    {
        "city": "Rohlstorf",
        "codes": [
            "23821"
        ]
    },
    {
        "city": "Seedorf",
        "codes": [
            "23823"
        ]
    },
    {
        "city": "Tensfeld",
        "codes": [
            "23824"
        ]
    },
    {
        "city": "Bark",
        "codes": [
            "23826"
        ]
    },
    {
        "city": "Wensin",
        "codes": [
            "23827"
        ]
    },
    {
        "city": "Wittenborn",
        "codes": [
            "23829"
        ]
    },
    {
        "city": "Bad Oldesloe",
        "codes": [
            "23843"
        ]
    },
    {
        "city": "Seth",
        "codes": [
            "23845"
        ]
    },
    {
        "city": "Lasbek",
        "codes": [
            "23847"
        ]
    },
    {
        "city": "Reinfeld (Holstein)",
        "codes": [
            "23858"
        ]
    },
    {
        "city": "Klein Wesenberg",
        "codes": [
            "23860"
        ]
    },
    {
        "city": "Bargfeld-Stegen",
        "codes": [
            "23863"
        ]
    },
    {
        "city": "Nahe",
        "codes": [
            "23866"
        ]
    },
    {
        "city": "Sülfeld",
        "codes": [
            "23867"
        ]
    },
    {
        "city": "Elmenhorst",
        "codes": [
            "23869"
        ]
    },
    {
        "city": "Mölln",
        "codes": [
            "23879"
        ]
    },
    {
        "city": "Breitenfelde",
        "codes": [
            "23881"
        ]
    },
    {
        "city": "Sterley",
        "codes": [
            "23883"
        ]
    },
    {
        "city": "Nusse",
        "codes": [
            "23896"
        ]
    },
    {
        "city": "Sandesneben",
        "codes": [
            "23898"
        ]
    },
    {
        "city": "Gudow",
        "codes": [
            "23899"
        ]
    },
    {
        "city": "Ratzeburg",
        "codes": [
            "23909"
        ]
    },
    {
        "city": "Ziethen",
        "codes": [
            "23911"
        ]
    },
    {
        "city": "Berkenthin",
        "codes": [
            "23919"
        ]
    },
    {
        "city": "Schönberg",
        "codes": [
            "23923",
            "84573",
            "94513"
        ]
    },
    {
        "city": "Grevesmühlen",
        "codes": [
            "23936"
        ]
    },
    {
        "city": "Dassow",
        "codes": [
            "23942"
        ]
    },
    {
        "city": "Boltenhagen",
        "codes": [
            "23946"
        ]
    },
    {
        "city": "Klütz",
        "codes": [
            "23948"
        ]
    },
    {
        "city": "Wismar",
        "codes": [
            "23966",
            "23968",
            "23970"
        ]
    },
    {
        "city": "Dorf Mecklenburg",
        "codes": [
            "23972"
        ]
    },
    {
        "city": "Neuburg-Steinhausen",
        "codes": [
            "23974"
        ]
    },
    {
        "city": "Neukloster",
        "codes": [
            "23992"
        ]
    },
    {
        "city": "Bad Kleinen",
        "codes": [
            "23996"
        ]
    },
    {
        "city": "Insel Poel",
        "codes": [
            "23999"
        ]
    },
    {
        "city": "Kiel",
        "codes": [
            "24103",
            "24105",
            "24106",
            "24107",
            "24109",
            "24111",
            "24113",
            "24114",
            "24116",
            "24118",
            "24143",
            "24145",
            "24146",
            "24147",
            "24148",
            "24149",
            "24159"
        ]
    },
    {
        "city": "Kronshagen",
        "codes": [
            "24119"
        ]
    },
    {
        "city": "Altenholz",
        "codes": [
            "24161"
        ]
    },
    {
        "city": "Preetz",
        "codes": [
            "24211"
        ]
    },
    {
        "city": "Gettorf",
        "codes": [
            "24214"
        ]
    },
    {
        "city": "Schönberg (Holstein)",
        "codes": [
            "24217"
        ]
    },
    {
        "city": "Flintbek",
        "codes": [
            "24220"
        ]
    },
    {
        "city": "Raisdorf",
        "codes": [
            "24223"
        ]
    },
    {
        "city": "Heikendorf",
        "codes": [
            "24226"
        ]
    },
    {
        "city": "Dänischenhagen",
        "codes": [
            "24229"
        ]
    },
    {
        "city": "Schönkirchen",
        "codes": [
            "24232"
        ]
    },
    {
        "city": "Laboe",
        "codes": [
            "24235"
        ]
    },
    {
        "city": "Selent",
        "codes": [
            "24238"
        ]
    },
    {
        "city": "Achterwehr",
        "codes": [
            "24239"
        ]
    },
    {
        "city": "Blumenthal",
        "codes": [
            "24241"
        ]
    },
    {
        "city": "Felde",
        "codes": [
            "24242"
        ]
    },
    {
        "city": "Felm",
        "codes": [
            "24244"
        ]
    },
    {
        "city": "Kirchbarkau",
        "codes": [
            "24245"
        ]
    },
    {
        "city": "Mielkendorf",
        "codes": [
            "24247"
        ]
    },
    {
        "city": "Mönkeberg",
        "codes": [
            "24248"
        ]
    },
    {
        "city": "Nettelsee",
        "codes": [
            "24250"
        ]
    },
    {
        "city": "Osdorf",
        "codes": [
            "24251"
        ]
    },
    {
        "city": "Probsteierhagen",
        "codes": [
            "24253"
        ]
    },
    {
        "city": "Rumohr",
        "codes": [
            "24254"
        ]
    },
    {
        "city": "Fargau-Pratjau",
        "codes": [
            "24256"
        ]
    },
    {
        "city": "Hohenfelde",
        "codes": [
            "24257"
        ]
    },
    {
        "city": "Westensee",
        "codes": [
            "24259"
        ]
    },
    {
        "city": "Plön",
        "codes": [
            "24306"
        ]
    },
    {
        "city": "Lütjenburg",
        "codes": [
            "24321"
        ]
    },
    {
        "city": "Ascheberg (Holstein)",
        "codes": [
            "24326"
        ]
    },
    {
        "city": "Blekendorf",
        "codes": [
            "24327"
        ]
    },
    {
        "city": "Grebin",
        "codes": [
            "24329"
        ]
    },
    {
        "city": "Eckernförde",
        "codes": [
            "24340"
        ]
    },
    {
        "city": "Damp",
        "codes": [
            "24351"
        ]
    },
    {
        "city": "Rieseby",
        "codes": [
            "24354"
        ]
    },
    {
        "city": "Fleckeby",
        "codes": [
            "24357"
        ]
    },
    {
        "city": "Ascheffel",
        "codes": [
            "24358"
        ]
    },
    {
        "city": "Barkelsby",
        "codes": [
            "24360"
        ]
    },
    {
        "city": "Groß Wittensee",
        "codes": [
            "24361"
        ]
    },
    {
        "city": "Holtsee",
        "codes": [
            "24363"
        ]
    },
    {
        "city": "Loose",
        "codes": [
            "24366"
        ]
    },
    {
        "city": "Osterby",
        "codes": [
            "24367"
        ]
    },
    {
        "city": "Waabs",
        "codes": [
            "24369"
        ]
    },
    {
        "city": "Kappeln",
        "codes": [
            "24376"
        ]
    },
    {
        "city": "Süderbrarup",
        "codes": [
            "24392"
        ]
    },
    {
        "city": "Gelting",
        "codes": [
            "24395"
        ]
    },
    {
        "city": "Dörphof",
        "codes": [
            "24398"
        ]
    },
    {
        "city": "Arnis",
        "codes": [
            "24399"
        ]
    },
    {
        "city": "Böel",
        "codes": [
            "24401"
        ]
    },
    {
        "city": "Esgrus",
        "codes": [
            "24402"
        ]
    },
    {
        "city": "Maasholm",
        "codes": [
            "24404"
        ]
    },
    {
        "city": "Mohrkirch",
        "codes": [
            "24405"
        ]
    },
    {
        "city": "Rabenkirchen-Faulück",
        "codes": [
            "24407"
        ]
    },
    {
        "city": "Stoltebüll",
        "codes": [
            "24409"
        ]
    },
    {
        "city": "Neumünster",
        "codes": [
            "24534",
            "24536",
            "24537",
            "24539"
        ]
    },
    {
        "city": "Henstedt-Ulzburg",
        "codes": [
            "24558"
        ]
    },
    {
        "city": "Kaltenkirchen",
        "codes": [
            "24568"
        ]
    },
    {
        "city": "Bad Bramstedt",
        "codes": [
            "24576"
        ]
    },
    {
        "city": "Bordesholm",
        "codes": [
            "24582"
        ]
    },
    {
        "city": "Nortorf",
        "codes": [
            "24589"
        ]
    },
    {
        "city": "Hohenwestedt",
        "codes": [
            "24594"
        ]
    },
    {
        "city": "Boostedt",
        "codes": [
            "24598"
        ]
    },
    {
        "city": "Wankendorf",
        "codes": [
            "24601"
        ]
    },
    {
        "city": "Trappenkamp",
        "codes": [
            "24610"
        ]
    },
    {
        "city": "Aukrug",
        "codes": [
            "24613"
        ]
    },
    {
        "city": "Brokstedt",
        "codes": [
            "24616"
        ]
    },
    {
        "city": "Bornhöved",
        "codes": [
            "24619"
        ]
    },
    {
        "city": "Bönebüttel",
        "codes": [
            "24620"
        ]
    },
    {
        "city": "Gnutz",
        "codes": [
            "24622"
        ]
    },
    {
        "city": "Großenaspe",
        "codes": [
            "24623"
        ]
    },
    {
        "city": "Großharrie",
        "codes": [
            "24625"
        ]
    },
    {
        "city": "Groß Kummerfeld",
        "codes": [
            "24626"
        ]
    },
    {
        "city": "Hartenholm",
        "codes": [
            "24628"
        ]
    },
    {
        "city": "Kisdorf",
        "codes": [
            "24629"
        ]
    },
    {
        "city": "Langwedel",
        "codes": [
            "24631",
            "27299"
        ]
    },
    {
        "city": "Lentföhrden",
        "codes": [
            "24632"
        ]
    },
    {
        "city": "Padenstedt",
        "codes": [
            "24634"
        ]
    },
    {
        "city": "Rickling",
        "codes": [
            "24635"
        ]
    },
    {
        "city": "Schillsdorf",
        "codes": [
            "24637"
        ]
    },
    {
        "city": "Schmalensee",
        "codes": [
            "24638"
        ]
    },
    {
        "city": "Schmalfeld",
        "codes": [
            "24640"
        ]
    },
    {
        "city": "Sievershütten",
        "codes": [
            "24641"
        ]
    },
    {
        "city": "Struvenhütten",
        "codes": [
            "24643"
        ]
    },
    {
        "city": "Timmaspe",
        "codes": [
            "24644"
        ]
    },
    {
        "city": "Warder",
        "codes": [
            "24646"
        ]
    },
    {
        "city": "Wasbek",
        "codes": [
            "24647"
        ]
    },
    {
        "city": "Wiemersdorf",
        "codes": [
            "24649"
        ]
    },
    {
        "city": "Rendsburg",
        "codes": [
            "24768"
        ]
    },
    {
        "city": "Büdelsdorf",
        "codes": [
            "24782"
        ]
    },
    {
        "city": "Osterrönfeld",
        "codes": [
            "24783"
        ]
    },
    {
        "city": "Westerrönfeld",
        "codes": [
            "24784"
        ]
    },
    {
        "city": "Fockbek",
        "codes": [
            "24787"
        ]
    },
    {
        "city": "Schacht-Audorf",
        "codes": [
            "24790"
        ]
    },
    {
        "city": "Alt Duvenstedt",
        "codes": [
            "24791"
        ]
    },
    {
        "city": "Bargstedt",
        "codes": [
            "24793"
        ]
    },
    {
        "city": "Borgstedt",
        "codes": [
            "24794"
        ]
    },
    {
        "city": "Bredenbek",
        "codes": [
            "24796"
        ]
    },
    {
        "city": "Breiholz",
        "codes": [
            "24797"
        ]
    },
    {
        "city": "Meggerdorf",
        "codes": [
            "24799"
        ]
    },
    {
        "city": "Elsdorf-Westermühlen",
        "codes": [
            "24800"
        ]
    },
    {
        "city": "Emkendorf",
        "codes": [
            "24802"
        ]
    },
    {
        "city": "Erfde",
        "codes": [
            "24803"
        ]
    },
    {
        "city": "Hamdorf",
        "codes": [
            "24805"
        ]
    },
    {
        "city": "Hohn",
        "codes": [
            "24806"
        ]
    },
    {
        "city": "Jevenstedt",
        "codes": [
            "24808"
        ]
    },
    {
        "city": "Nübbel",
        "codes": [
            "24809"
        ]
    },
    {
        "city": "Owschlag",
        "codes": [
            "24811"
        ]
    },
    {
        "city": "Schülp bei Rendsburg",
        "codes": [
            "24813"
        ]
    },
    {
        "city": "Sehestedt",
        "codes": [
            "24814"
        ]
    },
    {
        "city": "Hamweddel",
        "codes": [
            "24816"
        ]
    },
    {
        "city": "Tetenhusen",
        "codes": [
            "24817"
        ]
    },
    {
        "city": "Todenbüttel",
        "codes": [
            "24819"
        ]
    },
    {
        "city": "Schleswig",
        "codes": [
            "24837"
        ]
    },
    {
        "city": "Kropp",
        "codes": [
            "24848"
        ]
    },
    {
        "city": "Schuby",
        "codes": [
            "24850"
        ]
    },
    {
        "city": "Eggebek",
        "codes": [
            "24852"
        ]
    },
    {
        "city": "Jübek",
        "codes": [
            "24855"
        ]
    },
    {
        "city": "Fahrdorf",
        "codes": [
            "24857"
        ]
    },
    {
        "city": "Böklund",
        "codes": [
            "24860"
        ]
    },
    {
        "city": "Bergenhusen",
        "codes": [
            "24861"
        ]
    },
    {
        "city": "Börm",
        "codes": [
            "24863"
        ]
    },
    {
        "city": "Brodersby",
        "codes": [
            "24864"
        ]
    },
    {
        "city": "Busdorf",
        "codes": [
            "24866"
        ]
    },
    {
        "city": "Dannewerk",
        "codes": [
            "24867"
        ]
    },
    {
        "city": "Dörpstedt",
        "codes": [
            "24869"
        ]
    },
    {
        "city": "Ellingstedt",
        "codes": [
            "24870"
        ]
    },
    {
        "city": "Groß Rheide",
        "codes": [
            "24872"
        ]
    },
    {
        "city": "Havetoft",
        "codes": [
            "24873"
        ]
    },
    {
        "city": "Havetoftloit",
        "codes": [
            "24875"
        ]
    },
    {
        "city": "Hollingstedt",
        "codes": [
            "24876"
        ]
    },
    {
        "city": "Jagel",
        "codes": [
            "24878"
        ]
    },
    {
        "city": "Neuberend",
        "codes": [
            "24879"
        ]
    },
    {
        "city": "Nübel",
        "codes": [
            "24881"
        ]
    },
    {
        "city": "Schaalby",
        "codes": [
            "24882"
        ]
    },
    {
        "city": "Selk",
        "codes": [
            "24884"
        ]
    },
    {
        "city": "Sieverstedt",
        "codes": [
            "24885"
        ]
    },
    {
        "city": "Silberstedt",
        "codes": [
            "24887"
        ]
    },
    {
        "city": "Steinfeld",
        "codes": [
            "24888",
            "97854"
        ]
    },
    {
        "city": "Stolk",
        "codes": [
            "24890"
        ]
    },
    {
        "city": "Struxdorf",
        "codes": [
            "24891"
        ]
    },
    {
        "city": "Taarstedt",
        "codes": [
            "24893"
        ]
    },
    {
        "city": "Tolk",
        "codes": [
            "24894"
        ]
    },
    {
        "city": "Treia",
        "codes": [
            "24896"
        ]
    },
    {
        "city": "Ulsnis",
        "codes": [
            "24897"
        ]
    },
    {
        "city": "Wohlde",
        "codes": [
            "24899"
        ]
    },
    {
        "city": "Flensburg",
        "codes": [
            "24937",
            "24939",
            "24941",
            "24943",
            "24944"
        ]
    },
    {
        "city": "Harrislee",
        "codes": [
            "24955"
        ]
    },
    {
        "city": "Glücksburg (Ostsee)",
        "codes": [
            "24960"
        ]
    },
    {
        "city": "Tarp",
        "codes": [
            "24963"
        ]
    },
    {
        "city": "Sörup",
        "codes": [
            "24966"
        ]
    },
    {
        "city": "Großenwiehe",
        "codes": [
            "24969"
        ]
    },
    {
        "city": "Quern",
        "codes": [
            "24972"
        ]
    },
    {
        "city": "Husby",
        "codes": [
            "24975"
        ]
    },
    {
        "city": "Langballig",
        "codes": [
            "24977"
        ]
    },
    {
        "city": "Schafflund",
        "codes": [
            "24980"
        ]
    },
    {
        "city": "Handewitt",
        "codes": [
            "24983"
        ]
    },
    {
        "city": "Satrup",
        "codes": [
            "24986"
        ]
    },
    {
        "city": "Oeversee",
        "codes": [
            "24988"
        ]
    },
    {
        "city": "Dollerup",
        "codes": [
            "24989"
        ]
    },
    {
        "city": "Großsolt",
        "codes": [
            "24991"
        ]
    },
    {
        "city": "Jörl",
        "codes": [
            "24992"
        ]
    },
    {
        "city": "Medelby",
        "codes": [
            "24994"
        ]
    },
    {
        "city": "Sterup",
        "codes": [
            "24996"
        ]
    },
    {
        "city": "Wanderup",
        "codes": [
            "24997"
        ]
    },
    {
        "city": "Wees",
        "codes": [
            "24999"
        ]
    },
    {
        "city": "Elmshorn",
        "codes": [
            "25335",
            "25336",
            "25337"
        ]
    },
    {
        "city": "Glückstadt",
        "codes": [
            "25348"
        ]
    },
    {
        "city": "Barmstedt",
        "codes": [
            "25355"
        ]
    },
    {
        "city": "Horst (Holstein)",
        "codes": [
            "25358"
        ]
    },
    {
        "city": "Krempe",
        "codes": [
            "25361"
        ]
    },
    {
        "city": "Brande-Hörnerkirchen",
        "codes": [
            "25364"
        ]
    },
    {
        "city": "Klein Offenseth-Sparrieshoop",
        "codes": [
            "25365"
        ]
    },
    {
        "city": "Kiebitzreihe",
        "codes": [
            "25368"
        ]
    },
    {
        "city": "Seester",
        "codes": [
            "25370"
        ]
    },
    {
        "city": "Seestermühe",
        "codes": [
            "25371"
        ]
    },
    {
        "city": "Ellerhoop",
        "codes": [
            "25373"
        ]
    },
    {
        "city": "Borsfleth",
        "codes": [
            "25376"
        ]
    },
    {
        "city": "Kollmar",
        "codes": [
            "25377"
        ]
    },
    {
        "city": "Herzhorn",
        "codes": [
            "25379"
        ]
    },
    {
        "city": "Pinneberg",
        "codes": [
            "25421"
        ]
    },
    {
        "city": "Uetersen",
        "codes": [
            "25436"
        ]
    },
    {
        "city": "Quickborn",
        "codes": [
            "25451"
        ]
    },
    {
        "city": "Rellingen",
        "codes": [
            "25462"
        ]
    },
    {
        "city": "Halstenbek",
        "codes": [
            "25469"
        ]
    },
    {
        "city": "Ellerbek",
        "codes": [
            "25474"
        ]
    },
    {
        "city": "Ellerau",
        "codes": [
            "25479"
        ]
    },
    {
        "city": "Appen",
        "codes": [
            "25482"
        ]
    },
    {
        "city": "Hemdingen",
        "codes": [
            "25485"
        ]
    },
    {
        "city": "Alveslohe",
        "codes": [
            "25486"
        ]
    },
    {
        "city": "Holm",
        "codes": [
            "25488"
        ]
    },
    {
        "city": "Haseldorf",
        "codes": [
            "25489"
        ]
    },
    {
        "city": "Hetlingen",
        "codes": [
            "25491"
        ]
    },
    {
        "city": "Heist",
        "codes": [
            "25492"
        ]
    },
    {
        "city": "Borstel-Hohenraden",
        "codes": [
            "25494"
        ]
    },
    {
        "city": "Kummerfeld",
        "codes": [
            "25495"
        ]
    },
    {
        "city": "Prisdorf",
        "codes": [
            "25497"
        ]
    },
    {
        "city": "Itzehoe",
        "codes": [
            "25524"
        ]
    },
    {
        "city": "Brunsbüttel",
        "codes": [
            "25541"
        ]
    },
    {
        "city": "Kellinghusen",
        "codes": [
            "25548"
        ]
    },
    {
        "city": "Hohenlockstedt",
        "codes": [
            "25551"
        ]
    },
    {
        "city": "Wilster",
        "codes": [
            "25554"
        ]
    },
    {
        "city": "Hanerau-Hademarschen",
        "codes": [
            "25557"
        ]
    },
    {
        "city": "Wrist",
        "codes": [
            "25563"
        ]
    },
    {
        "city": "Lägerdorf",
        "codes": [
            "25566"
        ]
    },
    {
        "city": "Kremperheide",
        "codes": [
            "25569"
        ]
    },
    {
        "city": "Sankt Margarethen",
        "codes": [
            "25572"
        ]
    },
    {
        "city": "Beidenfleth",
        "codes": [
            "25573"
        ]
    },
    {
        "city": "Beringstedt",
        "codes": [
            "25575"
        ]
    },
    {
        "city": "Brokdorf",
        "codes": [
            "25576"
        ]
    },
    {
        "city": "Dägeling",
        "codes": [
            "25578"
        ]
    },
    {
        "city": "Fitzbek",
        "codes": [
            "25579"
        ]
    },
    {
        "city": "Hennstedt",
        "codes": [
            "25581",
            "25779"
        ]
    },
    {
        "city": "Hohenaspe",
        "codes": [
            "25582"
        ]
    },
    {
        "city": "Holstenniendorf",
        "codes": [
            "25584"
        ]
    },
    {
        "city": "Lütjenwestedt",
        "codes": [
            "25585"
        ]
    },
    {
        "city": "Münsterdorf",
        "codes": [
            "25587"
        ]
    },
    {
        "city": "Osterstedt",
        "codes": [
            "25590"
        ]
    },
    {
        "city": "Ottenbüttel",
        "codes": [
            "25591"
        ]
    },
    {
        "city": "Reher",
        "codes": [
            "25593"
        ]
    },
    {
        "city": "Vaale",
        "codes": [
            "25594"
        ]
    },
    {
        "city": "Wacken",
        "codes": [
            "25596"
        ]
    },
    {
        "city": "Westermoor",
        "codes": [
            "25597"
        ]
    },
    {
        "city": "Wewelsfleth",
        "codes": [
            "25599"
        ]
    },
    {
        "city": "Sankt Michaelisdonn",
        "codes": [
            "25693"
        ]
    },
    {
        "city": "Meldorf",
        "codes": [
            "25704"
        ]
    },
    {
        "city": "Marne",
        "codes": [
            "25709"
        ]
    },
    {
        "city": "Burg (Dithmarschen)",
        "codes": [
            "25712"
        ]
    },
    {
        "city": "Eddelak",
        "codes": [
            "25715"
        ]
    },
    {
        "city": "Friedrichskoog",
        "codes": [
            "25718"
        ]
    },
    {
        "city": "Barlt",
        "codes": [
            "25719"
        ]
    },
    {
        "city": "Eggstedt",
        "codes": [
            "25721"
        ]
    },
    {
        "city": "Neufeld",
        "codes": [
            "25724"
        ]
    },
    {
        "city": "Schafstedt",
        "codes": [
            "25725"
        ]
    },
    {
        "city": "Süderhastedt",
        "codes": [
            "25727"
        ]
    },
    {
        "city": "Windbergen",
        "codes": [
            "25729"
        ]
    },
    {
        "city": "Heide",
        "codes": [
            "25746"
        ]
    },
    {
        "city": "Büsum",
        "codes": [
            "25761"
        ]
    },
    {
        "city": "Wesselburen",
        "codes": [
            "25764"
        ]
    },
    {
        "city": "Albersdorf",
        "codes": [
            "25767"
        ]
    },
    {
        "city": "Hemmingstedt",
        "codes": [
            "25770"
        ]
    },
    {
        "city": "Lunden",
        "codes": [
            "25774"
        ]
    },
    {
        "city": "Rehm-Flehde-Bargen",
        "codes": [
            "25776"
        ]
    },
    {
        "city": "Tellingstedt",
        "codes": [
            "25782"
        ]
    },
    {
        "city": "Nordhastedt",
        "codes": [
            "25785"
        ]
    },
    {
        "city": "Dellstedt",
        "codes": [
            "25786"
        ]
    },
    {
        "city": "Delve",
        "codes": [
            "25788"
        ]
    },
    {
        "city": "Linden",
        "codes": [
            "25791",
            "35440"
        ]
    },
    {
        "city": "Pahlen",
        "codes": [
            "25794"
        ]
    },
    {
        "city": "Weddingstedt",
        "codes": [
            "25795"
        ]
    },
    {
        "city": "Wöhrden",
        "codes": [
            "25797"
        ]
    },
    {
        "city": "Wrohm",
        "codes": [
            "25799"
        ]
    },
    {
        "city": "Husum",
        "codes": [
            "25813",
            "31632"
        ]
    },
    {
        "city": "Bredstedt",
        "codes": [
            "25821"
        ]
    },
    {
        "city": "Sankt Peter-Ording",
        "codes": [
            "25826"
        ]
    },
    {
        "city": "Tönning",
        "codes": [
            "25832"
        ]
    },
    {
        "city": "Garding",
        "codes": [
            "25836"
        ]
    },
    {
        "city": "Friedrichstadt",
        "codes": [
            "25840"
        ]
    },
    {
        "city": "Langenhorn",
        "codes": [
            "25842"
        ]
    },
    {
        "city": "Nordstrand",
        "codes": [
            "25845"
        ]
    },
    {
        "city": "Pellworm",
        "codes": [
            "25849"
        ]
    },
    {
        "city": "Behrendorf",
        "codes": [
            "25850"
        ]
    },
    {
        "city": "Bordelum",
        "codes": [
            "25852"
        ]
    },
    {
        "city": "Drelsdorf",
        "codes": [
            "25853"
        ]
    },
    {
        "city": "Haselund",
        "codes": [
            "25855"
        ]
    },
    {
        "city": "Hattstedt",
        "codes": [
            "25856"
        ]
    },
    {
        "city": "Högel",
        "codes": [
            "25858"
        ]
    },
    {
        "city": "Hooge",
        "codes": [
            "25859"
        ]
    },
    {
        "city": "Horstedt",
        "codes": [
            "25860"
        ]
    },
    {
        "city": "Joldelund",
        "codes": [
            "25862"
        ]
    },
    {
        "city": "Langeneß",
        "codes": [
            "25863",
            "25869"
        ]
    },
    {
        "city": "Löwenstedt",
        "codes": [
            "25864"
        ]
    },
    {
        "city": "Mildstedt",
        "codes": [
            "25866"
        ]
    },
    {
        "city": "Norderstapel",
        "codes": [
            "25868"
        ]
    },
    {
        "city": "Oldenswort",
        "codes": [
            "25870"
        ]
    },
    {
        "city": "Ostenfeld (Husum)",
        "codes": [
            "25872"
        ]
    },
    {
        "city": "Rantrum",
        "codes": [
            "25873"
        ]
    },
    {
        "city": "Schobüll",
        "codes": [
            "25875"
        ]
    },
    {
        "city": "Schwabstedt",
        "codes": [
            "25876"
        ]
    },
    {
        "city": "Seeth",
        "codes": [
            "25878"
        ]
    },
    {
        "city": "Süderstapel",
        "codes": [
            "25879"
        ]
    },
    {
        "city": "Tating",
        "codes": [
            "25881"
        ]
    },
    {
        "city": "Tetenbüll",
        "codes": [
            "25882"
        ]
    },
    {
        "city": "Viöl",
        "codes": [
            "25884"
        ]
    },
    {
        "city": "Wester-Ohrstedt",
        "codes": [
            "25885"
        ]
    },
    {
        "city": "Winnert",
        "codes": [
            "25887"
        ]
    },
    {
        "city": "Witzwort",
        "codes": [
            "25889"
        ]
    },
    {
        "city": "Niebüll",
        "codes": [
            "25899"
        ]
    },
    {
        "city": "Leck",
        "codes": [
            "25917"
        ]
    },
    {
        "city": "Risum-Lindholm",
        "codes": [
            "25920"
        ]
    },
    {
        "city": "Süderlügum",
        "codes": [
            "25923"
        ]
    },
    {
        "city": "Emmelsbüll-Horsbüll",
        "codes": [
            "25924"
        ]
    },
    {
        "city": "Ladelund",
        "codes": [
            "25926"
        ]
    },
    {
        "city": "Wyk auf Föhr",
        "codes": [
            "25938"
        ]
    },
    {
        "city": "Nebel",
        "codes": [
            "25946"
        ]
    },
    {
        "city": "Westerland",
        "codes": [
            "25980"
        ]
    },
    {
        "city": "List",
        "codes": [
            "25992"
        ]
    },
    {
        "city": "Wenningstedt (Sylt)",
        "codes": [
            "25996"
        ]
    },
    {
        "city": "Hörnum (Sylt)",
        "codes": [
            "25997"
        ]
    },
    {
        "city": "Kampen (Sylt)",
        "codes": [
            "25999"
        ]
    },
    {
        "city": "Oldenburg (Oldenburg)",
        "codes": [
            "26121",
            "26122",
            "26123",
            "26125",
            "26127",
            "26129",
            "26131",
            "26133",
            "26135"
        ]
    },
    {
        "city": "Bad Zwischenahn",
        "codes": [
            "26160"
        ]
    },
    {
        "city": "Friesoythe",
        "codes": [
            "26169"
        ]
    },
    {
        "city": "Rastede",
        "codes": [
            "26180"
        ]
    },
    {
        "city": "Edewecht",
        "codes": [
            "26188"
        ]
    },
    {
        "city": "Großenkneten",
        "codes": [
            "26197"
        ]
    },
    {
        "city": "Wardenburg",
        "codes": [
            "26203"
        ]
    },
    {
        "city": "Hatten",
        "codes": [
            "26209"
        ]
    },
    {
        "city": "Wiefelstede",
        "codes": [
            "26215"
        ]
    },
    {
        "city": "Bösel",
        "codes": [
            "26219"
        ]
    },
    {
        "city": "Varel",
        "codes": [
            "26316"
        ]
    },
    {
        "city": "Zetel",
        "codes": [
            "26340"
        ]
    },
    {
        "city": "Bockhorn",
        "codes": [
            "26345",
            "85461"
        ]
    },
    {
        "city": "Jade",
        "codes": [
            "26349"
        ]
    },
    {
        "city": "Wilhelmshaven",
        "codes": [
            "26382",
            "26384",
            "26386",
            "26388",
            "26389"
        ]
    },
    {
        "city": "Wittmund",
        "codes": [
            "26409"
        ]
    },
    {
        "city": "Schortens",
        "codes": [
            "26419"
        ]
    },
    {
        "city": "Esens",
        "codes": [
            "26427"
        ]
    },
    {
        "city": "Wangerland",
        "codes": [
            "26434"
        ]
    },
    {
        "city": "Jever",
        "codes": [
            "26441"
        ]
    },
    {
        "city": "Friedeburg",
        "codes": [
            "26446"
        ]
    },
    {
        "city": "Sande",
        "codes": [
            "26452"
        ]
    },
    {
        "city": "Langeoog",
        "codes": [
            "26465"
        ]
    },
    {
        "city": "Spiekeroog",
        "codes": [
            "26474"
        ]
    },
    {
        "city": "Nordseebad Wangerooge",
        "codes": [
            "26486"
        ]
    },
    {
        "city": "Blomberg",
        "codes": [
            "26487",
            "32825"
        ]
    },
    {
        "city": "Ochtersum",
        "codes": [
            "26489"
        ]
    },
    {
        "city": "Norden",
        "codes": [
            "26506"
        ]
    },
    {
        "city": "Hage",
        "codes": [
            "26524"
        ]
    },
    {
        "city": "Upgant-Schott",
        "codes": [
            "26529"
        ]
    },
    {
        "city": "Großheide",
        "codes": [
            "26532"
        ]
    },
    {
        "city": "Norderney",
        "codes": [
            "26548"
        ]
    },
    {
        "city": "Dornum",
        "codes": [
            "26553"
        ]
    },
    {
        "city": "Westerholt",
        "codes": [
            "26556"
        ]
    },
    {
        "city": "Juist",
        "codes": [
            "26571"
        ]
    },
    {
        "city": "Baltrum",
        "codes": [
            "26579"
        ]
    },
    {
        "city": "Aurich",
        "codes": [
            "26603",
            "26605",
            "26607"
        ]
    },
    {
        "city": "Südbrookmerland",
        "codes": [
            "26624"
        ]
    },
    {
        "city": "Großefehn",
        "codes": [
            "26629"
        ]
    },
    {
        "city": "Ihlow",
        "codes": [
            "26632"
        ]
    },
    {
        "city": "Wiesmoor",
        "codes": [
            "26639"
        ]
    },
    {
        "city": "Westerstede",
        "codes": [
            "26655"
        ]
    },
    {
        "city": "Uplengen",
        "codes": [
            "26670"
        ]
    },
    {
        "city": "Barßel",
        "codes": [
            "26676"
        ]
    },
    {
        "city": "Saterland",
        "codes": [
            "26683"
        ]
    },
    {
        "city": "Apen",
        "codes": [
            "26689"
        ]
    },
    {
        "city": "Emden",
        "codes": [
            "26721",
            "26723",
            "26725"
        ]
    },
    {
        "city": "Krummhörn",
        "codes": [
            "26736"
        ]
    },
    {
        "city": "Borkum",
        "codes": [
            "26757"
        ]
    },
    {
        "city": "Hinte",
        "codes": [
            "26759"
        ]
    },
    {
        "city": "Leer (Ostfriesland)",
        "codes": [
            "26789"
        ]
    },
    {
        "city": "Moormerland",
        "codes": [
            "26802"
        ]
    },
    {
        "city": "Westoverledingen",
        "codes": [
            "26810"
        ]
    },
    {
        "city": "Rhauderfehn",
        "codes": [
            "26817"
        ]
    },
    {
        "city": "Weener",
        "codes": [
            "26826"
        ]
    },
    {
        "city": "Bunde",
        "codes": [
            "26831"
        ]
    },
    {
        "city": "Hesel",
        "codes": [
            "26835"
        ]
    },
    {
        "city": "Ostrhauderfehn",
        "codes": [
            "26842"
        ]
    },
    {
        "city": "Jemgum",
        "codes": [
            "26844"
        ]
    },
    {
        "city": "Nortmoor",
        "codes": [
            "26845"
        ]
    },
    {
        "city": "Detern",
        "codes": [
            "26847"
        ]
    },
    {
        "city": "Filsum",
        "codes": [
            "26849"
        ]
    },
    {
        "city": "Papenburg",
        "codes": [
            "26871"
        ]
    },
    {
        "city": "Dörpen",
        "codes": [
            "26892"
        ]
    },
    {
        "city": "Esterwegen",
        "codes": [
            "26897"
        ]
    },
    {
        "city": "Rhede (Ems)",
        "codes": [
            "26899"
        ]
    },
    {
        "city": "Lorup",
        "codes": [
            "26901"
        ]
    },
    {
        "city": "Surwold",
        "codes": [
            "26903"
        ]
    },
    {
        "city": "Börger",
        "codes": [
            "26904"
        ]
    },
    {
        "city": "Dersum",
        "codes": [
            "26906"
        ]
    },
    {
        "city": "Walchum",
        "codes": [
            "26907"
        ]
    },
    {
        "city": "Neubörger",
        "codes": [
            "26909"
        ]
    },
    {
        "city": "Brake (Unterweser)",
        "codes": [
            "26919"
        ]
    },
    {
        "city": "Elsfleth",
        "codes": [
            "26931"
        ]
    },
    {
        "city": "Stadland",
        "codes": [
            "26935",
            "26936",
            "26937"
        ]
    },
    {
        "city": "Ovelgönne",
        "codes": [
            "26939"
        ]
    },
    {
        "city": "Nordenham",
        "codes": [
            "26954"
        ]
    },
    {
        "city": "Butjadingen",
        "codes": [
            "26969"
        ]
    },
    {
        "city": "Bassum",
        "codes": [
            "27211"
        ]
    },
    {
        "city": "Sulingen",
        "codes": [
            "27232"
        ]
    },
    {
        "city": "Twistringen",
        "codes": [
            "27239"
        ]
    },
    {
        "city": "Harpstedt",
        "codes": [
            "27243"
        ]
    },
    {
        "city": "Kirchdorf",
        "codes": [
            "27245",
            "93348"
        ]
    },
    {
        "city": "Borstel",
        "codes": [
            "27246"
        ]
    },
    {
        "city": "Ehrenburg",
        "codes": [
            "27248"
        ]
    },
    {
        "city": "Mellinghausen",
        "codes": [
            "27249"
        ]
    },
    {
        "city": "Schwaförden",
        "codes": [
            "27252"
        ]
    },
    {
        "city": "Siedenburg",
        "codes": [
            "27254"
        ]
    },
    {
        "city": "Sudwalde",
        "codes": [
            "27257"
        ]
    },
    {
        "city": "Varrel",
        "codes": [
            "27259"
        ]
    },
    {
        "city": "Verden (Aller)",
        "codes": [
            "27283"
        ]
    },
    {
        "city": "Bruchhausen-Vilsen",
        "codes": [
            "27305"
        ]
    },
    {
        "city": "Kirchlinteln",
        "codes": [
            "27308"
        ]
    },
    {
        "city": "Dörverden",
        "codes": [
            "27313"
        ]
    },
    {
        "city": "Hoya",
        "codes": [
            "27318"
        ]
    },
    {
        "city": "Thedinghausen",
        "codes": [
            "27321"
        ]
    },
    {
        "city": "Eystrup",
        "codes": [
            "27324"
        ]
    },
    {
        "city": "Martfeld",
        "codes": [
            "27327"
        ]
    },
    {
        "city": "Asendorf",
        "codes": [
            "27330"
        ]
    },
    {
        "city": "Bücken",
        "codes": [
            "27333"
        ]
    },
    {
        "city": "Rethem (Aller)",
        "codes": [
            "27336"
        ]
    },
    {
        "city": "Blender",
        "codes": [
            "27337"
        ]
    },
    {
        "city": "Riede",
        "codes": [
            "27339"
        ]
    },
    {
        "city": "Rotenburg (Wümme)",
        "codes": [
            "27356"
        ]
    },
    {
        "city": "Sottrum",
        "codes": [
            "27367"
        ]
    },
    {
        "city": "Visselhövede",
        "codes": [
            "27374"
        ]
    },
    {
        "city": "Scheeßel",
        "codes": [
            "27383"
        ]
    },
    {
        "city": "Bothel",
        "codes": [
            "27386"
        ]
    },
    {
        "city": "Fintel",
        "codes": [
            "27389"
        ]
    },
    {
        "city": "Zeven",
        "codes": [
            "27404"
        ]
    },
    {
        "city": "Tarmstedt",
        "codes": [
            "27412"
        ]
    },
    {
        "city": "Sittensen",
        "codes": [
            "27419"
        ]
    },
    {
        "city": "Bremervörde",
        "codes": [
            "27432"
        ]
    },
    {
        "city": "Gnarrenburg",
        "codes": [
            "27442"
        ]
    },
    {
        "city": "Selsingen",
        "codes": [
            "27446"
        ]
    },
    {
        "city": "Kutenholz",
        "codes": [
            "27449"
        ]
    },
    {
        "city": "Cuxhaven",
        "codes": [
            "27472",
            "27474",
            "27476",
            "27478"
        ]
    },
    {
        "city": "Helgoland",
        "codes": [
            "27498"
        ]
    },
    {
        "city": "Bremerhaven",
        "codes": [
            "27568",
            "27570",
            "27572",
            "27574",
            "27576",
            "27578",
            "27580"
        ]
    },
    {
        "city": "Langen",
        "codes": [
            "27607",
            "63225"
        ]
    },
    {
        "city": "Loxstedt",
        "codes": [
            "27612"
        ]
    },
    {
        "city": "Beverstedt",
        "codes": [
            "27616"
        ]
    },
    {
        "city": "Schiffdorf",
        "codes": [
            "27619"
        ]
    },
    {
        "city": "Bederkesa",
        "codes": [
            "27624"
        ]
    },
    {
        "city": "Hagen im Bremischen",
        "codes": [
            "27628"
        ]
    },
    {
        "city": "Dorum",
        "codes": [
            "27632"
        ]
    },
    {
        "city": "Nordholz",
        "codes": [
            "27637"
        ]
    },
    {
        "city": "Wremen",
        "codes": [
            "27638"
        ]
    },
    {
        "city": "Osterholz-Scharmbeck",
        "codes": [
            "27711"
        ]
    },
    {
        "city": "Ritterhude",
        "codes": [
            "27721"
        ]
    },
    {
        "city": "Worpswede",
        "codes": [
            "27726"
        ]
    },
    {
        "city": "Hambergen",
        "codes": [
            "27729"
        ]
    },
    {
        "city": "Delmenhorst",
        "codes": [
            "27749",
            "27751",
            "27753",
            "27755"
        ]
    },
    {
        "city": "Ganderkesee",
        "codes": [
            "27777"
        ]
    },
    {
        "city": "Wildeshausen",
        "codes": [
            "27793"
        ]
    },
    {
        "city": "Hude (Oldenburg)",
        "codes": [
            "27798"
        ]
    },
    {
        "city": "Dötlingen",
        "codes": [
            "27801"
        ]
    },
    {
        "city": "Berne",
        "codes": [
            "27804"
        ]
    },
    {
        "city": "Lemwerder",
        "codes": [
            "27809"
        ]
    },
    {
        "city": "Bremen",
        "codes": [
            "28195",
            "28197",
            "28199",
            "28201",
            "28203",
            "28205",
            "28207",
            "28209",
            "28211",
            "28213",
            "28215",
            "28217",
            "28219",
            "28237",
            "28239",
            "28259",
            "28277",
            "28279",
            "28307",
            "28309",
            "28325",
            "28327",
            "28329",
            "28355",
            "28357",
            "28359",
            "28717",
            "28719",
            "28755",
            "28757",
            "28759",
            "28777",
            "28779"
        ]
    },
    {
        "city": "Schwanewede",
        "codes": [
            "28790"
        ]
    },
    {
        "city": "Stuhr",
        "codes": [
            "28816"
        ]
    },
    {
        "city": "Achim",
        "codes": [
            "28832"
        ]
    },
    {
        "city": "Weyhe",
        "codes": [
            "28844"
        ]
    },
    {
        "city": "Syke",
        "codes": [
            "28857"
        ]
    },
    {
        "city": "Lilienthal",
        "codes": [
            "28865"
        ]
    },
    {
        "city": "Ottersberg",
        "codes": [
            "28870"
        ]
    },
    {
        "city": "Oyten",
        "codes": [
            "28876"
        ]
    },
    {
        "city": "Grasberg",
        "codes": [
            "28879"
        ]
    },
    {
        "city": "Celle",
        "codes": [
            "29221",
            "29223",
            "29225",
            "29227",
            "29229"
        ]
    },
    {
        "city": "Winsen (Aller)",
        "codes": [
            "29308"
        ]
    },
    {
        "city": "Hambühren",
        "codes": [
            "29313"
        ]
    },
    {
        "city": "Hermannsburg",
        "codes": [
            "29320"
        ]
    },
    {
        "city": "Wietze",
        "codes": [
            "29323"
        ]
    },
    {
        "city": "Faßberg",
        "codes": [
            "29328"
        ]
    },
    {
        "city": "Lachendorf",
        "codes": [
            "29331"
        ]
    },
    {
        "city": "Nienhagen",
        "codes": [
            "29336"
        ]
    },
    {
        "city": "Wathlingen",
        "codes": [
            "29339"
        ]
    },
    {
        "city": "Wienhausen",
        "codes": [
            "29342"
        ]
    },
    {
        "city": "Unterlüß",
        "codes": [
            "29345"
        ]
    },
    {
        "city": "Eschede",
        "codes": [
            "29348"
        ]
    },
    {
        "city": "Eldingen",
        "codes": [
            "29351"
        ]
    },
    {
        "city": "Adelheidsdorf",
        "codes": [
            "29352"
        ]
    },
    {
        "city": "Ahnsbeck",
        "codes": [
            "29353"
        ]
    },
    {
        "city": "Beedenbostel",
        "codes": [
            "29355"
        ]
    },
    {
        "city": "Bröckel",
        "codes": [
            "29356"
        ]
    },
    {
        "city": "Eicklingen",
        "codes": [
            "29358"
        ]
    },
    {
        "city": "Habighorst",
        "codes": [
            "29359"
        ]
    },
    {
        "city": "Höfer",
        "codes": [
            "29361"
        ]
    },
    {
        "city": "Hohne",
        "codes": [
            "29362"
        ]
    },
    {
        "city": "Langlingen",
        "codes": [
            "29364"
        ]
    },
    {
        "city": "Sprakensehl",
        "codes": [
            "29365"
        ]
    },
    {
        "city": "Steinhorst",
        "codes": [
            "29367"
        ]
    },
    {
        "city": "Ummern",
        "codes": [
            "29369"
        ]
    },
    {
        "city": "Wittingen",
        "codes": [
            "29378",
            "29379"
        ]
    },
    {
        "city": "Hankensbüttel",
        "codes": [
            "29386"
        ]
    },
    {
        "city": "Bodenteich",
        "codes": [
            "29389"
        ]
    },
    {
        "city": "Wesendorf",
        "codes": [
            "29392"
        ]
    },
    {
        "city": "Groß Oesingen",
        "codes": [
            "29393"
        ]
    },
    {
        "city": "Lüder",
        "codes": [
            "29394"
        ]
    },
    {
        "city": "Schönewörde",
        "codes": [
            "29396"
        ]
    },
    {
        "city": "Wahrenholz",
        "codes": [
            "29399"
        ]
    },
    {
        "city": "Salzwedel",
        "codes": [
            "29410"
        ]
    },
    {
        "city": "Diesdorf",
        "codes": [
            "29413"
        ]
    },
    {
        "city": "Pretzier",
        "codes": [
            "29416"
        ]
    },
    {
        "city": "Lüchow",
        "codes": [
            "29439"
        ]
    },
    {
        "city": "Dannenberg (Elbe)",
        "codes": [
            "29451"
        ]
    },
    {
        "city": "Hitzacker",
        "codes": [
            "29456"
        ]
    },
    {
        "city": "Clenze",
        "codes": [
            "29459"
        ]
    },
    {
        "city": "Wustrow",
        "codes": [
            "29462"
        ]
    },
    {
        "city": "Schnega",
        "codes": [
            "29465"
        ]
    },
    {
        "city": "Bergen (Dumme)",
        "codes": [
            "29468"
        ]
    },
    {
        "city": "Gartow",
        "codes": [
            "29471"
        ]
    },
    {
        "city": "Damnatz",
        "codes": [
            "29472"
        ]
    },
    {
        "city": "Göhrde",
        "codes": [
            "29473"
        ]
    },
    {
        "city": "Gorleben",
        "codes": [
            "29475"
        ]
    },
    {
        "city": "Gusborn",
        "codes": [
            "29476"
        ]
    },
    {
        "city": "Höhbeck",
        "codes": [
            "29478"
        ]
    },
    {
        "city": "Jameln",
        "codes": [
            "29479"
        ]
    },
    {
        "city": "Karwitz",
        "codes": [
            "29481"
        ]
    },
    {
        "city": "Küsten",
        "codes": [
            "29482"
        ]
    },
    {
        "city": "Langendorf",
        "codes": [
            "29484"
        ]
    },
    {
        "city": "Lemgow",
        "codes": [
            "29485"
        ]
    },
    {
        "city": "Lübbow",
        "codes": [
            "29488"
        ]
    },
    {
        "city": "Neu Darchau",
        "codes": [
            "29490"
        ]
    },
    {
        "city": "Prezelle",
        "codes": [
            "29491"
        ]
    },
    {
        "city": "Schnackenburg",
        "codes": [
            "29493"
        ]
    },
    {
        "city": "Trebel",
        "codes": [
            "29494"
        ]
    },
    {
        "city": "Waddeweitz",
        "codes": [
            "29496"
        ]
    },
    {
        "city": "Zernien",
        "codes": [
            "29499"
        ]
    },
    {
        "city": "Uelzen",
        "codes": [
            "29525"
        ]
    },
    {
        "city": "Bad Bevensen",
        "codes": [
            "29549"
        ]
    },
    {
        "city": "Bienenbüttel",
        "codes": [
            "29553"
        ]
    },
    {
        "city": "Suderburg",
        "codes": [
            "29556"
        ]
    },
    {
        "city": "Wrestedt",
        "codes": [
            "29559"
        ]
    },
    {
        "city": "Suhlendorf",
        "codes": [
            "29562"
        ]
    },
    {
        "city": "Wriedel",
        "codes": [
            "29565"
        ]
    },
    {
        "city": "Wieren",
        "codes": [
            "29568"
        ]
    },
    {
        "city": "Rosche",
        "codes": [
            "29571"
        ]
    },
    {
        "city": "Ebstorf",
        "codes": [
            "29574"
        ]
    },
    {
        "city": "Altenmedingen",
        "codes": [
            "29575"
        ]
    },
    {
        "city": "Barum",
        "codes": [
            "29576"
        ]
    },
    {
        "city": "Eimke",
        "codes": [
            "29578"
        ]
    },
    {
        "city": "Emmendorf",
        "codes": [
            "29579"
        ]
    },
    {
        "city": "Gerdau",
        "codes": [
            "29581"
        ]
    },
    {
        "city": "Himbergen",
        "codes": [
            "29584"
        ]
    },
    {
        "city": "Jelmstorf",
        "codes": [
            "29585"
        ]
    },
    {
        "city": "Natendorf",
        "codes": [
            "29587"
        ]
    },
    {
        "city": "Oetzen",
        "codes": [
            "29588"
        ]
    },
    {
        "city": "Rätzlingen",
        "codes": [
            "29590"
        ]
    },
    {
        "city": "Römstedt",
        "codes": [
            "29591"
        ]
    },
    {
        "city": "Schwienau",
        "codes": [
            "29593"
        ]
    },
    {
        "city": "Soltendieck",
        "codes": [
            "29594"
        ]
    },
    {
        "city": "Stadensen",
        "codes": [
            "29596"
        ]
    },
    {
        "city": "Stoetze",
        "codes": [
            "29597"
        ]
    },
    {
        "city": "Weste",
        "codes": [
            "29599"
        ]
    },
    {
        "city": "Soltau",
        "codes": [
            "29614"
        ]
    },
    {
        "city": "Munster",
        "codes": [
            "29633"
        ]
    },
    {
        "city": "Schneverdingen",
        "codes": [
            "29640"
        ]
    },
    {
        "city": "Bispingen",
        "codes": [
            "29646"
        ]
    },
    {
        "city": "Wietzendorf",
        "codes": [
            "29649"
        ]
    },
    {
        "city": "Walsrode",
        "codes": [
            "29664"
        ]
    },
    {
        "city": "Fallingbostel",
        "codes": [
            "29683"
        ]
    },
    {
        "city": "Schwarmstedt",
        "codes": [
            "29690"
        ]
    },
    {
        "city": "Hodenhagen",
        "codes": [
            "29693"
        ]
    },
    {
        "city": "Bomlitz",
        "codes": [
            "29699"
        ]
    },
    {
        "city": "Hannover",
        "codes": [
            "30159",
            "30161",
            "30163",
            "30165",
            "30167",
            "30169",
            "30171",
            "30173",
            "30175",
            "30177",
            "30179",
            "30419",
            "30449",
            "30451",
            "30453",
            "30455",
            "30457",
            "30459",
            "30519",
            "30521",
            "30539",
            "30559",
            "30625",
            "30627",
            "30629",
            "30655",
            "30657",
            "30659",
            "30669"
        ]
    },
    {
        "city": "Garbsen",
        "codes": [
            "30823",
            "30826",
            "30827"
        ]
    },
    {
        "city": "Langenhagen",
        "codes": [
            "30851",
            "30853",
            "30855"
        ]
    },
    {
        "city": "Laatzen",
        "codes": [
            "30880"
        ]
    },
    {
        "city": "Barsinghausen",
        "codes": [
            "30890"
        ]
    },
    {
        "city": "Wedemark",
        "codes": [
            "30900"
        ]
    },
    {
        "city": "Isernhagen",
        "codes": [
            "30916"
        ]
    },
    {
        "city": "Seelze",
        "codes": [
            "30926"
        ]
    },
    {
        "city": "Burgwedel",
        "codes": [
            "30938"
        ]
    },
    {
        "city": "Ronnenberg",
        "codes": [
            "30952"
        ]
    },
    {
        "city": "Hemmingen",
        "codes": [
            "30966",
            "71282"
        ]
    },
    {
        "city": "Wennigsen (Deister)",
        "codes": [
            "30974"
        ]
    },
    {
        "city": "Pattensen",
        "codes": [
            "30982"
        ]
    },
    {
        "city": "Gehrden",
        "codes": [
            "30989"
        ]
    },
    {
        "city": "Elze",
        "codes": [
            "31008"
        ]
    },
    {
        "city": "Salzhemmendorf",
        "codes": [
            "31020"
        ]
    },
    {
        "city": "Gronau (Leine)",
        "codes": [
            "31028"
        ]
    },
    {
        "city": "Banteln",
        "codes": [
            "31029"
        ]
    },
    {
        "city": "Betheln",
        "codes": [
            "31032"
        ]
    },
    {
        "city": "Brüggen",
        "codes": [
            "31033",
            "41379"
        ]
    },
    {
        "city": "Despetal",
        "codes": [
            "31035"
        ]
    },
    {
        "city": "Eime",
        "codes": [
            "31036"
        ]
    },
    {
        "city": "Rheden",
        "codes": [
            "31039"
        ]
    },
    {
        "city": "Alfeld (Leine)",
        "codes": [
            "31061"
        ]
    },
    {
        "city": "Delligsen",
        "codes": [
            "31073"
        ]
    },
    {
        "city": "Sibbesse",
        "codes": [
            "31079"
        ]
    },
    {
        "city": "Freden (Leine)",
        "codes": [
            "31084"
        ]
    },
    {
        "city": "Everode",
        "codes": [
            "31085"
        ]
    },
    {
        "city": "Landwehr",
        "codes": [
            "31087"
        ]
    },
    {
        "city": "Winzenburg",
        "codes": [
            "31088"
        ]
    },
    {
        "city": "Duingen",
        "codes": [
            "31089"
        ]
    },
    {
        "city": "Coppengrave",
        "codes": [
            "31091"
        ]
    },
    {
        "city": "Hoyershausen",
        "codes": [
            "31093"
        ]
    },
    {
        "city": "Marienhagen",
        "codes": [
            "31094"
        ]
    },
    {
        "city": "Weenzen",
        "codes": [
            "31096"
        ]
    },
    {
        "city": "Harbarnsen",
        "codes": [
            "31097"
        ]
    },
    {
        "city": "Woltershausen",
        "codes": [
            "31099"
        ]
    },
    {
        "city": "Hildesheim",
        "codes": [
            "31134",
            "31135",
            "31137",
            "31139",
            "31141"
        ]
    },
    {
        "city": "Sarstedt",
        "codes": [
            "31157"
        ]
    },
    {
        "city": "Bad Salzdetfurth",
        "codes": [
            "31162"
        ]
    },
    {
        "city": "Bockenem",
        "codes": [
            "31167"
        ]
    },
    {
        "city": "Nordstemmen",
        "codes": [
            "31171"
        ]
    },
    {
        "city": "Schellerten",
        "codes": [
            "31174"
        ]
    },
    {
        "city": "Harsum",
        "codes": [
            "31177"
        ]
    },
    {
        "city": "Giesen",
        "codes": [
            "31180"
        ]
    },
    {
        "city": "Söhlde",
        "codes": [
            "31185"
        ]
    },
    {
        "city": "Holle",
        "codes": [
            "31188"
        ]
    },
    {
        "city": "Algermissen",
        "codes": [
            "31191"
        ]
    },
    {
        "city": "Lamspringe",
        "codes": [
            "31195"
        ]
    },
    {
        "city": "Sehlem",
        "codes": [
            "31196"
        ]
    },
    {
        "city": "Diekholzen",
        "codes": [
            "31199"
        ]
    },
    {
        "city": "Peine",
        "codes": [
            "31224",
            "31226",
            "31228"
        ]
    },
    {
        "city": "Edemissen",
        "codes": [
            "31234"
        ]
    },
    {
        "city": "Ilsede",
        "codes": [
            "31241"
        ]
    },
    {
        "city": "Lahstedt",
        "codes": [
            "31246"
        ]
    },
    {
        "city": "Hohenhameln",
        "codes": [
            "31249"
        ]
    },
    {
        "city": "Lehrte",
        "codes": [
            "31275"
        ]
    },
    {
        "city": "Burgdorf",
        "codes": [
            "31303",
            "38272"
        ]
    },
    {
        "city": "Uetze",
        "codes": [
            "31311"
        ]
    },
    {
        "city": "Sehnde",
        "codes": [
            "31319"
        ]
    },
    {
        "city": "Wunstorf",
        "codes": [
            "31515"
        ]
    },
    {
        "city": "Neustadt am Rübenberge",
        "codes": [
            "31535"
        ]
    },
    {
        "city": "Bad Nenndorf",
        "codes": [
            "31542"
        ]
    },
    {
        "city": "Rehburg-Loccum",
        "codes": [
            "31547"
        ]
    },
    {
        "city": "Rodenberg",
        "codes": [
            "31552"
        ]
    },
    {
        "city": "Sachsenhagen",
        "codes": [
            "31553"
        ]
    },
    {
        "city": "Suthfeld",
        "codes": [
            "31555"
        ]
    },
    {
        "city": "Wölpinghausen",
        "codes": [
            "31556"
        ]
    },
    {
        "city": "Hagenburg",
        "codes": [
            "31558"
        ]
    },
    {
        "city": "Haste",
        "codes": [
            "31559"
        ]
    },
    {
        "city": "Nienburg (Weser)",
        "codes": [
            "31582"
        ]
    },
    {
        "city": "Stolzenau",
        "codes": [
            "31592"
        ]
    },
    {
        "city": "Steyerberg",
        "codes": [
            "31595"
        ]
    },
    {
        "city": "Uchte",
        "codes": [
            "31600"
        ]
    },
    {
        "city": "Diepenau",
        "codes": [
            "31603"
        ]
    },
    {
        "city": "Raddestorf",
        "codes": [
            "31604"
        ]
    },
    {
        "city": "Warmsen",
        "codes": [
            "31606"
        ]
    },
    {
        "city": "Marklohe",
        "codes": [
            "31608"
        ]
    },
    {
        "city": "Balge",
        "codes": [
            "31609"
        ]
    },
    {
        "city": "Wietzen",
        "codes": [
            "31613"
        ]
    },
    {
        "city": "Liebenau",
        "codes": [
            "31618",
            "34396"
        ]
    },
    {
        "city": "Binnen",
        "codes": [
            "31619"
        ]
    },
    {
        "city": "Pennigsehl",
        "codes": [
            "31621"
        ]
    },
    {
        "city": "Heemsen",
        "codes": [
            "31622"
        ]
    },
    {
        "city": "Drakenburg",
        "codes": [
            "31623"
        ]
    },
    {
        "city": "Haßbergen",
        "codes": [
            "31626"
        ]
    },
    {
        "city": "Rohrsen",
        "codes": [
            "31627"
        ]
    },
    {
        "city": "Landesbergen",
        "codes": [
            "31628"
        ]
    },
    {
        "city": "Leese",
        "codes": [
            "31633"
        ]
    },
    {
        "city": "Steimbke",
        "codes": [
            "31634"
        ]
    },
    {
        "city": "Linsburg",
        "codes": [
            "31636"
        ]
    },
    {
        "city": "Rodewald",
        "codes": [
            "31637"
        ]
    },
    {
        "city": "Stöckse",
        "codes": [
            "31638"
        ]
    },
    {
        "city": "Stadthagen",
        "codes": [
            "31655"
        ]
    },
    {
        "city": "Bückeburg",
        "codes": [
            "31675"
        ]
    },
    {
        "city": "Obernkirchen",
        "codes": [
            "31683"
        ]
    },
    {
        "city": "Nienstädt",
        "codes": [
            "31688"
        ]
    },
    {
        "city": "Helpsen",
        "codes": [
            "31691"
        ]
    },
    {
        "city": "Hespe",
        "codes": [
            "31693"
        ]
    },
    {
        "city": "Lindhorst",
        "codes": [
            "31698"
        ]
    },
    {
        "city": "Beckedorf",
        "codes": [
            "31699"
        ]
    },
    {
        "city": "Heuerßen",
        "codes": [
            "31700"
        ]
    },
    {
        "city": "Lüdersfeld",
        "codes": [
            "31702"
        ]
    },
    {
        "city": "Bad Eilsen",
        "codes": [
            "31707"
        ]
    },
    {
        "city": "Ahnsen",
        "codes": [
            "31708"
        ]
    },
    {
        "city": "Buchholz",
        "codes": [
            "31710"
        ]
    },
    {
        "city": "Luhden",
        "codes": [
            "31711"
        ]
    },
    {
        "city": "Niedernwöhren",
        "codes": [
            "31712"
        ]
    },
    {
        "city": "Lauenhagen",
        "codes": [
            "31714"
        ]
    },
    {
        "city": "Meerbeck",
        "codes": [
            "31715"
        ]
    },
    {
        "city": "Nordsehl",
        "codes": [
            "31717"
        ]
    },
    {
        "city": "Pollhagen",
        "codes": [
            "31718"
        ]
    },
    {
        "city": "Wiedensahl",
        "codes": [
            "31719"
        ]
    },
    {
        "city": "Rinteln",
        "codes": [
            "31737"
        ]
    },
    {
        "city": "Auetal",
        "codes": [
            "31749"
        ]
    },
    {
        "city": "Hameln",
        "codes": [
            "31785",
            "31787",
            "31789"
        ]
    },
    {
        "city": "Bad Pyrmont",
        "codes": [
            "31812"
        ]
    },
    {
        "city": "Springe",
        "codes": [
            "31832"
        ]
    },
    {
        "city": "Hessisch Oldendorf",
        "codes": [
            "31840"
        ]
    },
    {
        "city": "Bad Münder am Deister",
        "codes": [
            "31848"
        ]
    },
    {
        "city": "Aerzen",
        "codes": [
            "31855"
        ]
    },
    {
        "city": "Emmerthal",
        "codes": [
            "31860"
        ]
    },
    {
        "city": "Coppenbrügge",
        "codes": [
            "31863"
        ]
    },
    {
        "city": "Lauenau",
        "codes": [
            "31867"
        ]
    },
    {
        "city": "Ottenstein",
        "codes": [
            "31868"
        ]
    },
    {
        "city": "Herford",
        "codes": [
            "32049",
            "32051",
            "32052"
        ]
    },
    {
        "city": "Bad Salzuflen",
        "codes": [
            "32105",
            "32107",
            "32108"
        ]
    },
    {
        "city": "Hiddenhausen",
        "codes": [
            "32120"
        ]
    },
    {
        "city": "Enger",
        "codes": [
            "32130"
        ]
    },
    {
        "city": "Spenge",
        "codes": [
            "32139"
        ]
    },
    {
        "city": "Bünde",
        "codes": [
            "32257"
        ]
    },
    {
        "city": "Kirchlengern",
        "codes": [
            "32278"
        ]
    },
    {
        "city": "Rödinghausen",
        "codes": [
            "32289"
        ]
    },
    {
        "city": "Lübbecke",
        "codes": [
            "32312"
        ]
    },
    {
        "city": "Espelkamp",
        "codes": [
            "32339"
        ]
    },
    {
        "city": "Stemwede",
        "codes": [
            "32351"
        ]
    },
    {
        "city": "Preußisch Oldendorf",
        "codes": [
            "32361"
        ]
    },
    {
        "city": "Rahden",
        "codes": [
            "32369"
        ]
    },
    {
        "city": "Minden",
        "codes": [
            "32423",
            "32425",
            "32427",
            "32429"
        ]
    },
    {
        "city": "Porta Westfalica",
        "codes": [
            "32457"
        ]
    },
    {
        "city": "Petershagen",
        "codes": [
            "32469"
        ]
    },
    {
        "city": "Hille",
        "codes": [
            "32479"
        ]
    },
    {
        "city": "Bad Oeynhausen",
        "codes": [
            "32545",
            "32547",
            "32549"
        ]
    },
    {
        "city": "Löhne",
        "codes": [
            "32584"
        ]
    },
    {
        "city": "Vlotho",
        "codes": [
            "32602"
        ]
    },
    {
        "city": "Hüllhorst",
        "codes": [
            "32609"
        ]
    },
    {
        "city": "Lemgo",
        "codes": [
            "32657"
        ]
    },
    {
        "city": "Lügde",
        "codes": [
            "32676"
        ]
    },
    {
        "city": "Barntrup",
        "codes": [
            "32683"
        ]
    },
    {
        "city": "Kalletal",
        "codes": [
            "32689"
        ]
    },
    {
        "city": "Dörentrup",
        "codes": [
            "32694"
        ]
    },
    {
        "city": "Extertal",
        "codes": [
            "32699"
        ]
    },
    {
        "city": "Detmold",
        "codes": [
            "32756",
            "32758",
            "32760"
        ]
    },
    {
        "city": "Lage",
        "codes": [
            "32791"
        ]
    },
    {
        "city": "Horn-Bad Meinberg",
        "codes": [
            "32805"
        ]
    },
    {
        "city": "Schieder-Schwalenberg",
        "codes": [
            "32816"
        ]
    },
    {
        "city": "Augustdorf",
        "codes": [
            "32832"
        ]
    },
    {
        "city": "Steinheim",
        "codes": [
            "32839"
        ]
    },
    {
        "city": "Bad Driburg",
        "codes": [
            "33014"
        ]
    },
    {
        "city": "Brakel",
        "codes": [
            "33034"
        ]
    },
    {
        "city": "Nieheim",
        "codes": [
            "33039"
        ]
    },
    {
        "city": "Paderborn",
        "codes": [
            "33098",
            "33100",
            "33102",
            "33104",
            "33106"
        ]
    },
    {
        "city": "Delbrück",
        "codes": [
            "33129"
        ]
    },
    {
        "city": "Büren",
        "codes": [
            "33142"
        ]
    },
    {
        "city": "Salzkotten",
        "codes": [
            "33154"
        ]
    },
    {
        "city": "Hövelhof",
        "codes": [
            "33161"
        ]
    },
    {
        "city": "Bad Lippspringe",
        "codes": [
            "33175"
        ]
    },
    {
        "city": "Borchen",
        "codes": [
            "33178"
        ]
    },
    {
        "city": "Wünnenberg",
        "codes": [
            "33181"
        ]
    },
    {
        "city": "Altenbeken",
        "codes": [
            "33184"
        ]
    },
    {
        "city": "Schlangen",
        "codes": [
            "33189"
        ]
    },
    {
        "city": "Gütersloh",
        "codes": [
            "33330",
            "33332",
            "33333",
            "33334",
            "33335"
        ]
    },
    {
        "city": "Rheda-Wiedenbrück",
        "codes": [
            "33378"
        ]
    },
    {
        "city": "Rietberg",
        "codes": [
            "33397"
        ]
    },
    {
        "city": "Verl",
        "codes": [
            "33415"
        ]
    },
    {
        "city": "Harsewinkel",
        "codes": [
            "33428"
        ]
    },
    {
        "city": "Herzebrock-Clarholz",
        "codes": [
            "33442"
        ]
    },
    {
        "city": "Langenberg",
        "codes": [
            "33449"
        ]
    },
    {
        "city": "Bielefeld",
        "codes": [
            "33602",
            "33604",
            "33605",
            "33607",
            "33609",
            "33611",
            "33613",
            "33615",
            "33617",
            "33619",
            "33647",
            "33649",
            "33659",
            "33689",
            "33699",
            "33719",
            "33729",
            "33739"
        ]
    },
    {
        "city": "Schloß Holte-Stukenbrock",
        "codes": [
            "33758"
        ]
    },
    {
        "city": "Versmold",
        "codes": [
            "33775"
        ]
    },
    {
        "city": "Halle (Westfalen)",
        "codes": [
            "33790"
        ]
    },
    {
        "city": "Steinhagen",
        "codes": [
            "33803"
        ]
    },
    {
        "city": "Oerlinghausen",
        "codes": [
            "33813"
        ]
    },
    {
        "city": "Leopoldshöhe",
        "codes": [
            "33818"
        ]
    },
    {
        "city": "Werther (Westfalen)",
        "codes": [
            "33824"
        ]
    },
    {
        "city": "Borgholzhausen",
        "codes": [
            "33829"
        ]
    },
    {
        "city": "Kassel",
        "codes": [
            "34117",
            "34119",
            "34121",
            "34123",
            "34125",
            "34127",
            "34128",
            "34130",
            "34131",
            "34132",
            "34134"
        ]
    },
    {
        "city": "Melsungen",
        "codes": [
            "34212"
        ]
    },
    {
        "city": "Baunatal",
        "codes": [
            "34225"
        ]
    },
    {
        "city": "Fuldatal",
        "codes": [
            "34233"
        ]
    },
    {
        "city": "Vellmar",
        "codes": [
            "34246"
        ]
    },
    {
        "city": "Lohfelden",
        "codes": [
            "34253"
        ]
    },
    {
        "city": "Kaufungen",
        "codes": [
            "34260"
        ]
    },
    {
        "city": "Niestetal",
        "codes": [
            "34266"
        ]
    },
    {
        "city": "Schauenburg",
        "codes": [
            "34270"
        ]
    },
    {
        "city": "Fuldabrück",
        "codes": [
            "34277"
        ]
    },
    {
        "city": "Gudensberg",
        "codes": [
            "34281"
        ]
    },
    {
        "city": "Spangenberg",
        "codes": [
            "34286"
        ]
    },
    {
        "city": "Zierenberg",
        "codes": [
            "34289"
        ]
    },
    {
        "city": "Ahnatal",
        "codes": [
            "34292"
        ]
    },
    {
        "city": "Edermünde",
        "codes": [
            "34295"
        ]
    },
    {
        "city": "Helsa",
        "codes": [
            "34298"
        ]
    },
    {
        "city": "Guxhagen",
        "codes": [
            "34302"
        ]
    },
    {
        "city": "Niedenstein",
        "codes": [
            "34305"
        ]
    },
    {
        "city": "Bad Emstal",
        "codes": [
            "34308"
        ]
    },
    {
        "city": "Naumburg",
        "codes": [
            "34311"
        ]
    },
    {
        "city": "Espenau",
        "codes": [
            "34314"
        ]
    },
    {
        "city": "Habichtswald",
        "codes": [
            "34317"
        ]
    },
    {
        "city": "Söhrewald",
        "codes": [
            "34320"
        ]
    },
    {
        "city": "Malsfeld",
        "codes": [
            "34323"
        ]
    },
    {
        "city": "Morschen",
        "codes": [
            "34326"
        ]
    },
    {
        "city": "Körle",
        "codes": [
            "34327"
        ]
    },
    {
        "city": "Nieste",
        "codes": [
            "34329"
        ]
    },
    {
        "city": "Hannoversch Münden",
        "codes": [
            "34346"
        ]
    },
    {
        "city": "Staufenberg",
        "codes": [
            "34355",
            "35460"
        ]
    },
    {
        "city": "Reinhardshagen",
        "codes": [
            "34359"
        ]
    },
    {
        "city": "Hofgeismar",
        "codes": [
            "34369"
        ]
    },
    {
        "city": "Immenhausen",
        "codes": [
            "34376"
        ]
    },
    {
        "city": "Calden",
        "codes": [
            "34379"
        ]
    },
    {
        "city": "Bad Karlshafen",
        "codes": [
            "34385"
        ]
    },
    {
        "city": "Trendelburg",
        "codes": [
            "34388"
        ]
    },
    {
        "city": "Grebenstein",
        "codes": [
            "34393"
        ]
    },
    {
        "city": "Oberweser",
        "codes": [
            "34399"
        ]
    },
    {
        "city": "Warburg",
        "codes": [
            "34414"
        ]
    },
    {
        "city": "Marsberg",
        "codes": [
            "34431"
        ]
    },
    {
        "city": "Borgentreich",
        "codes": [
            "34434"
        ]
    },
    {
        "city": "Willebadessen",
        "codes": [
            "34439"
        ]
    },
    {
        "city": "Arolsen",
        "codes": [
            "34454"
        ]
    },
    {
        "city": "Wolfhagen",
        "codes": [
            "34466"
        ]
    },
    {
        "city": "Volkmarsen",
        "codes": [
            "34471"
        ]
    },
    {
        "city": "Diemelstadt",
        "codes": [
            "34474"
        ]
    },
    {
        "city": "Twistetal",
        "codes": [
            "34477"
        ]
    },
    {
        "city": "Breuna",
        "codes": [
            "34479"
        ]
    },
    {
        "city": "Korbach",
        "codes": [
            "34497"
        ]
    },
    {
        "city": "Willingen (Upland)",
        "codes": [
            "34508"
        ]
    },
    {
        "city": "Waldeck",
        "codes": [
            "34513"
        ]
    },
    {
        "city": "Vöhl",
        "codes": [
            "34516"
        ]
    },
    {
        "city": "Diemelsee",
        "codes": [
            "34519"
        ]
    },
    {
        "city": "Bad Wildungen",
        "codes": [
            "34537"
        ]
    },
    {
        "city": "Edertal",
        "codes": [
            "34549"
        ]
    },
    {
        "city": "Fritzlar",
        "codes": [
            "34560"
        ]
    },
    {
        "city": "Homberg (Efze)",
        "codes": [
            "34576"
        ]
    },
    {
        "city": "Borken (Hessen)",
        "codes": [
            "34582"
        ]
    },
    {
        "city": "Felsberg",
        "codes": [
            "34587"
        ]
    },
    {
        "city": "Wabern",
        "codes": [
            "34590"
        ]
    },
    {
        "city": "Knüllwald",
        "codes": [
            "34593"
        ]
    },
    {
        "city": "Bad Zwesten",
        "codes": [
            "34596"
        ]
    },
    {
        "city": "Neuental",
        "codes": [
            "34599"
        ]
    },
    {
        "city": "Schwalmstadt",
        "codes": [
            "34613"
        ]
    },
    {
        "city": "Frielendorf",
        "codes": [
            "34621"
        ]
    },
    {
        "city": "Willingshausen",
        "codes": [
            "34628"
        ]
    },
    {
        "city": "Gilserberg",
        "codes": [
            "34630"
        ]
    },
    {
        "city": "Jesberg",
        "codes": [
            "34632"
        ]
    },
    {
        "city": "Ottrau",
        "codes": [
            "34633"
        ]
    },
    {
        "city": "Schrecksbach",
        "codes": [
            "34637"
        ]
    },
    {
        "city": "Schwarzenborn",
        "codes": [
            "34639"
        ]
    },
    {
        "city": "Marburg",
        "codes": [
            "35037",
            "35039",
            "35041",
            "35043"
        ]
    },
    {
        "city": "Frankenberg (Eder)",
        "codes": [
            "35066"
        ]
    },
    {
        "city": "Gladenbach",
        "codes": [
            "35075"
        ]
    },
    {
        "city": "Bad Endbach",
        "codes": [
            "35080"
        ]
    },
    {
        "city": "Wetter (Hessen)",
        "codes": [
            "35083"
        ]
    },
    {
        "city": "Ebsdorfergrund",
        "codes": [
            "35085"
        ]
    },
    {
        "city": "Battenberg (Eder)",
        "codes": [
            "35088"
        ]
    },
    {
        "city": "Cölbe",
        "codes": [
            "35091"
        ]
    },
    {
        "city": "Lahntal",
        "codes": [
            "35094"
        ]
    },
    {
        "city": "Weimar",
        "codes": [
            "35096",
            "99423",
            "99425",
            "99427",
            "99428",
            "99438",
            "99441"
        ]
    },
    {
        "city": "Burgwald",
        "codes": [
            "35099"
        ]
    },
    {
        "city": "Lohra",
        "codes": [
            "35102"
        ]
    },
    {
        "city": "Lichtenfels",
        "codes": [
            "35104",
            "96215"
        ]
    },
    {
        "city": "Allendorf (Eder)",
        "codes": [
            "35108"
        ]
    },
    {
        "city": "Frankenau",
        "codes": [
            "35110"
        ]
    },
    {
        "city": "Fronhausen",
        "codes": [
            "35112"
        ]
    },
    {
        "city": "Haina (Kloster)",
        "codes": [
            "35114"
        ]
    },
    {
        "city": "Hatzfeld (Eder)",
        "codes": [
            "35116"
        ]
    },
    {
        "city": "Münchhausen",
        "codes": [
            "35117"
        ]
    },
    {
        "city": "Rosenthal",
        "codes": [
            "35119"
        ]
    },
    {
        "city": "Biedenkopf",
        "codes": [
            "35216"
        ]
    },
    {
        "city": "Dautphetal",
        "codes": [
            "35232"
        ]
    },
    {
        "city": "Breidenbach",
        "codes": [
            "35236"
        ]
    },
    {
        "city": "Steffenberg",
        "codes": [
            "35239"
        ]
    },
    {
        "city": "Stadtallendorf",
        "codes": [
            "35260"
        ]
    },
    {
        "city": "Kirchhain",
        "codes": [
            "35274"
        ]
    },
    {
        "city": "Neustadt (Hessen)",
        "codes": [
            "35279"
        ]
    },
    {
        "city": "Rauschenberg",
        "codes": [
            "35282"
        ]
    },
    {
        "city": "Gemünden (Wohra)",
        "codes": [
            "35285"
        ]
    },
    {
        "city": "Amöneburg",
        "codes": [
            "35287"
        ]
    },
    {
        "city": "Wohratal",
        "codes": [
            "35288"
        ]
    },
    {
        "city": "Grünberg",
        "codes": [
            "35305"
        ]
    },
    {
        "city": "Homberg (Ohm)",
        "codes": [
            "35315"
        ]
    },
    {
        "city": "Laubach",
        "codes": [
            "35321"
        ]
    },
    {
        "city": "Mücke",
        "codes": [
            "35325"
        ]
    },
    {
        "city": "Ulrichstein",
        "codes": [
            "35327"
        ]
    },
    {
        "city": "Gemünden (Felda)",
        "codes": [
            "35329"
        ]
    },
    {
        "city": "Gießen",
        "codes": [
            "35390",
            "35392",
            "35394",
            "35396",
            "35398"
        ]
    },
    {
        "city": "Hungen",
        "codes": [
            "35410"
        ]
    },
    {
        "city": "Pohlheim",
        "codes": [
            "35415"
        ]
    },
    {
        "city": "Buseck",
        "codes": [
            "35418"
        ]
    },
    {
        "city": "Lich",
        "codes": [
            "35423"
        ]
    },
    {
        "city": "Langgöns",
        "codes": [
            "35428"
        ]
    },
    {
        "city": "Wettenberg",
        "codes": [
            "35435"
        ]
    },
    {
        "city": "Biebertal",
        "codes": [
            "35444"
        ]
    },
    {
        "city": "Reiskirchen",
        "codes": [
            "35447"
        ]
    },
    {
        "city": "Heuchelheim",
        "codes": [
            "35452"
        ]
    },
    {
        "city": "Lollar",
        "codes": [
            "35457"
        ]
    },
    {
        "city": "Fernwald",
        "codes": [
            "35463"
        ]
    },
    {
        "city": "Allendorf (Lumda)",
        "codes": [
            "35469"
        ]
    },
    {
        "city": "Butzbach",
        "codes": [
            "35510"
        ]
    },
    {
        "city": "Münzenberg",
        "codes": [
            "35516"
        ]
    },
    {
        "city": "Rockenberg",
        "codes": [
            "35519"
        ]
    },
    {
        "city": "Wetzlar",
        "codes": [
            "35576",
            "35578",
            "35579",
            "35580",
            "35581",
            "35582",
            "35583",
            "35584",
            "35585",
            "35586"
        ]
    },
    {
        "city": "Solms",
        "codes": [
            "35606"
        ]
    },
    {
        "city": "Aßlar",
        "codes": [
            "35614"
        ]
    },
    {
        "city": "Braunfels",
        "codes": [
            "35619"
        ]
    },
    {
        "city": "Hüttenberg",
        "codes": [
            "35625"
        ]
    },
    {
        "city": "Ehringshausen",
        "codes": [
            "35630"
        ]
    },
    {
        "city": "Lahnau",
        "codes": [
            "35633"
        ]
    },
    {
        "city": "Leun",
        "codes": [
            "35638"
        ]
    },
    {
        "city": "Schöffengrund",
        "codes": [
            "35641"
        ]
    },
    {
        "city": "Hohenahr",
        "codes": [
            "35644"
        ]
    },
    {
        "city": "Waldsolms",
        "codes": [
            "35647"
        ]
    },
    {
        "city": "Bischoffen",
        "codes": [
            "35649"
        ]
    },
    {
        "city": "Dillenburg",
        "codes": [
            "35683",
            "35684",
            "35685",
            "35686",
            "35687",
            "35688",
            "35689",
            "35690"
        ]
    },
    {
        "city": "Haiger",
        "codes": [
            "35708"
        ]
    },
    {
        "city": "Eschenburg",
        "codes": [
            "35713"
        ]
    },
    {
        "city": "Dietzhölztal",
        "codes": [
            "35716"
        ]
    },
    {
        "city": "Angelburg",
        "codes": [
            "35719"
        ]
    },
    {
        "city": "Herborn",
        "codes": [
            "35745"
        ]
    },
    {
        "city": "Greifenstein",
        "codes": [
            "35753"
        ]
    },
    {
        "city": "Mittenaar",
        "codes": [
            "35756"
        ]
    },
    {
        "city": "Driedorf",
        "codes": [
            "35759"
        ]
    },
    {
        "city": "Sinn",
        "codes": [
            "35764"
        ]
    },
    {
        "city": "Breitscheid",
        "codes": [
            "35767",
            "53547"
        ]
    },
    {
        "city": "Siegbach",
        "codes": [
            "35768"
        ]
    },
    {
        "city": "Weilburg",
        "codes": [
            "35781"
        ]
    },
    {
        "city": "Weilmünster",
        "codes": [
            "35789"
        ]
    },
    {
        "city": "Löhnberg",
        "codes": [
            "35792"
        ]
    },
    {
        "city": "Mengerskirchen",
        "codes": [
            "35794"
        ]
    },
    {
        "city": "Weinbach",
        "codes": [
            "35796"
        ]
    },
    {
        "city": "Merenberg",
        "codes": [
            "35799"
        ]
    },
    {
        "city": "Fulda",
        "codes": [
            "36037",
            "36039",
            "36041",
            "36043"
        ]
    },
    {
        "city": "Hünfeld",
        "codes": [
            "36088"
        ]
    },
    {
        "city": "Künzell",
        "codes": [
            "36093"
        ]
    },
    {
        "city": "Petersberg",
        "codes": [
            "36100"
        ]
    },
    {
        "city": "Flieden",
        "codes": [
            "36103"
        ]
    },
    {
        "city": "Schlitz",
        "codes": [
            "36110"
        ]
    },
    {
        "city": "Hilders",
        "codes": [
            "36115"
        ]
    },
    {
        "city": "Neuhof",
        "codes": [
            "36119"
        ]
    },
    {
        "city": "Eichenzell",
        "codes": [
            "36124"
        ]
    },
    {
        "city": "Gersfeld (Rhön)",
        "codes": [
            "36129"
        ]
    },
    {
        "city": "Eiterfeld",
        "codes": [
            "36132"
        ]
    },
    {
        "city": "Großenlüder",
        "codes": [
            "36137"
        ]
    },
    {
        "city": "Tann (Rhön)",
        "codes": [
            "36142"
        ]
    },
    {
        "city": "Hofbieber",
        "codes": [
            "36145"
        ]
    },
    {
        "city": "Kalbach",
        "codes": [
            "36148"
        ]
    },
    {
        "city": "Burghaun",
        "codes": [
            "36151"
        ]
    },
    {
        "city": "Hosenfeld",
        "codes": [
            "36154"
        ]
    },
    {
        "city": "Ebersburg",
        "codes": [
            "36157"
        ]
    },
    {
        "city": "Dipperz",
        "codes": [
            "36160"
        ]
    },
    {
        "city": "Poppenhausen (Wasserkuppe)",
        "codes": [
            "36163"
        ]
    },
    {
        "city": "Haunetal",
        "codes": [
            "36166"
        ]
    },
    {
        "city": "Nüsttal",
        "codes": [
            "36167"
        ]
    },
    {
        "city": "Rasdorf",
        "codes": [
            "36169"
        ]
    },
    {
        "city": "Bebra",
        "codes": [
            "36179"
        ]
    },
    {
        "city": "Rotenburg an der Fulda",
        "codes": [
            "36199"
        ]
    },
    {
        "city": "Sontra",
        "codes": [
            "36205"
        ]
    },
    {
        "city": "Wildeck",
        "codes": [
            "36208"
        ]
    },
    {
        "city": "Alheim",
        "codes": [
            "36211"
        ]
    },
    {
        "city": "Nentershausen",
        "codes": [
            "36214",
            "56412"
        ]
    },
    {
        "city": "Ronshausen",
        "codes": [
            "36217"
        ]
    },
    {
        "city": "Cornberg",
        "codes": [
            "36219"
        ]
    },
    {
        "city": "Bad Hersfeld",
        "codes": [
            "36251"
        ]
    },
    {
        "city": "Heringen (Werra)",
        "codes": [
            "36266"
        ]
    },
    {
        "city": "Philippsthal (Werra)",
        "codes": [
            "36269"
        ]
    },
    {
        "city": "Niederaula",
        "codes": [
            "36272"
        ]
    },
    {
        "city": "Kirchheim",
        "codes": [
            "36275",
            "97268"
        ]
    },
    {
        "city": "Schenklengsfeld",
        "codes": [
            "36277"
        ]
    },
    {
        "city": "Oberaula",
        "codes": [
            "36280"
        ]
    },
    {
        "city": "Hauneck",
        "codes": [
            "36282"
        ]
    },
    {
        "city": "Hohenroda",
        "codes": [
            "36284"
        ]
    },
    {
        "city": "Neuenstein",
        "codes": [
            "36286",
            "74632"
        ]
    },
    {
        "city": "Breitenbach am Herzberg",
        "codes": [
            "36287"
        ]
    },
    {
        "city": "Friedewald",
        "codes": [
            "36289"
        ]
    },
    {
        "city": "Alsfeld",
        "codes": [
            "36304"
        ]
    },
    {
        "city": "Schwalmtal",
        "codes": [
            "36318",
            "41366"
        ]
    },
    {
        "city": "Kirtorf",
        "codes": [
            "36320"
        ]
    },
    {
        "city": "Grebenau",
        "codes": [
            "36323"
        ]
    },
    {
        "city": "Feldatal",
        "codes": [
            "36325"
        ]
    },
    {
        "city": "Antrifttal",
        "codes": [
            "36326"
        ]
    },
    {
        "city": "Romrod",
        "codes": [
            "36329"
        ]
    },
    {
        "city": "Lauterbach (Hessen)",
        "codes": [
            "36341"
        ]
    },
    {
        "city": "Grebenhain",
        "codes": [
            "36355"
        ]
    },
    {
        "city": "Herbstein",
        "codes": [
            "36358"
        ]
    },
    {
        "city": "Bad Salzschlirf",
        "codes": [
            "36364"
        ]
    },
    {
        "city": "Wartenberg",
        "codes": [
            "36367",
            "85456"
        ]
    },
    {
        "city": "Lautertal (Vogelsberg)",
        "codes": [
            "36369"
        ]
    },
    {
        "city": "Schlüchtern",
        "codes": [
            "36381"
        ]
    },
    {
        "city": "Sinntal",
        "codes": [
            "36391"
        ]
    },
    {
        "city": "Steinau an der Straße",
        "codes": [
            "36396"
        ]
    },
    {
        "city": "Freiensteinau",
        "codes": [
            "36399"
        ]
    },
    {
        "city": "Vacha",
        "codes": [
            "36404"
        ]
    },
    {
        "city": "Unterbreizbach",
        "codes": [
            "36414"
        ]
    },
    {
        "city": "Geisa",
        "codes": [
            "36419"
        ]
    },
    {
        "city": "Bad Salzungen",
        "codes": [
            "36433"
        ]
    },
    {
        "city": "Bad Liebenstein",
        "codes": [
            "36448"
        ]
    },
    {
        "city": "Kaltennordheim",
        "codes": [
            "36452"
        ]
    },
    {
        "city": "Barchfeld",
        "codes": [
            "36456"
        ]
    },
    {
        "city": "Stadtlengsfeld",
        "codes": [
            "36457"
        ]
    },
    {
        "city": "Merkers-Kieselbach",
        "codes": [
            "36460"
        ]
    },
    {
        "city": "Dermbach",
        "codes": [
            "36466"
        ]
    },
    {
        "city": "Tiefenort",
        "codes": [
            "36469"
        ]
    },
    {
        "city": "Göttingen",
        "codes": [
            "37073",
            "37075",
            "37077",
            "37079",
            "37081",
            "37083",
            "37085"
        ]
    },
    {
        "city": "Duderstadt",
        "codes": [
            "37115"
        ]
    },
    {
        "city": "Bovenden",
        "codes": [
            "37120"
        ]
    },
    {
        "city": "Rosdorf",
        "codes": [
            "37124",
            "37127"
        ]
    },
    {
        "city": "Gleichen",
        "codes": [
            "37130"
        ]
    },
    {
        "city": "Ebergötzen",
        "codes": [
            "37136"
        ]
    },
    {
        "city": "Adelebsen",
        "codes": [
            "37139"
        ]
    },
    {
        "city": "Northeim",
        "codes": [
            "37154"
        ]
    },
    {
        "city": "Uslar",
        "codes": [
            "37170"
        ]
    },
    {
        "city": "Nörten-Hardenberg",
        "codes": [
            "37176"
        ]
    },
    {
        "city": "Hardegsen",
        "codes": [
            "37181"
        ]
    },
    {
        "city": "Moringen",
        "codes": [
            "37186"
        ]
    },
    {
        "city": "Katlenburg-Lindau",
        "codes": [
            "37191"
        ]
    },
    {
        "city": "Bodenfelde",
        "codes": [
            "37194"
        ]
    },
    {
        "city": "Hattorf am Harz",
        "codes": [
            "37197"
        ]
    },
    {
        "city": "Wulften",
        "codes": [
            "37199"
        ]
    },
    {
        "city": "Witzenhausen",
        "codes": [
            "37213",
            "37214",
            "37215",
            "37216",
            "37217",
            "37218"
        ]
    },
    {
        "city": "Hessisch Lichtenau",
        "codes": [
            "37235"
        ]
    },
    {
        "city": "Bad Sooden-Allendorf",
        "codes": [
            "37242"
        ]
    },
    {
        "city": "Großalmerode",
        "codes": [
            "37247"
        ]
    },
    {
        "city": "Neu-Eichenberg",
        "codes": [
            "37249"
        ]
    },
    {
        "city": "Eschwege",
        "codes": [
            "37269"
        ]
    },
    {
        "city": "Meinhard",
        "codes": [
            "37276"
        ]
    },
    {
        "city": "Wanfried",
        "codes": [
            "37281"
        ]
    },
    {
        "city": "Waldkappel",
        "codes": [
            "37284"
        ]
    },
    {
        "city": "Wehretal",
        "codes": [
            "37287"
        ]
    },
    {
        "city": "Meißner",
        "codes": [
            "37290"
        ]
    },
    {
        "city": "Herleshausen",
        "codes": [
            "37293"
        ]
    },
    {
        "city": "Ringgau",
        "codes": [
            "37296"
        ]
    },
    {
        "city": "Berkatal",
        "codes": [
            "37297"
        ]
    },
    {
        "city": "Weißenborn",
        "codes": [
            "37299"
        ]
    },
    {
        "city": "Heiligenstadt",
        "codes": [
            "37308"
        ]
    },
    {
        "city": "Uder",
        "codes": [
            "37318"
        ]
    },
    {
        "city": "Leinefelde",
        "codes": [
            "37327"
        ]
    },
    {
        "city": "Worbis",
        "codes": [
            "37339"
        ]
    },
    {
        "city": "Bischofferode",
        "codes": [
            "37345"
        ]
    },
    {
        "city": "Dingelstädt",
        "codes": [
            "37351"
        ]
    },
    {
        "city": "Niederorschel",
        "codes": [
            "37355"
        ]
    },
    {
        "city": "Küllstedt",
        "codes": [
            "37359"
        ]
    },
    {
        "city": "Herzberg am Harz",
        "codes": [
            "37412"
        ]
    },
    {
        "city": "Bad Lauterberg im Harz",
        "codes": [
            "37431"
        ]
    },
    {
        "city": "Gieboldehausen",
        "codes": [
            "37434"
        ]
    },
    {
        "city": "Bad Sachsa",
        "codes": [
            "37441"
        ]
    },
    {
        "city": "Sankt Andreasberg",
        "codes": [
            "37444"
        ]
    },
    {
        "city": "Walkenried",
        "codes": [
            "37445"
        ]
    },
    {
        "city": "Wieda",
        "codes": [
            "37447"
        ]
    },
    {
        "city": "Zorge",
        "codes": [
            "37449"
        ]
    },
    {
        "city": "Osterode am Harz",
        "codes": [
            "37520"
        ]
    },
    {
        "city": "Gittelde",
        "codes": [
            "37534"
        ]
    },
    {
        "city": "Bad Grund (Harz)",
        "codes": [
            "37539"
        ]
    },
    {
        "city": "Kreiensen",
        "codes": [
            "37547"
        ]
    },
    {
        "city": "Einbeck",
        "codes": [
            "37574"
        ]
    },
    {
        "city": "Bad Gandersheim",
        "codes": [
            "37581"
        ]
    },
    {
        "city": "Dassel",
        "codes": [
            "37586"
        ]
    },
    {
        "city": "Kalefeld",
        "codes": [
            "37589"
        ]
    },
    {
        "city": "Holzminden",
        "codes": [
            "37603"
        ]
    },
    {
        "city": "Bodenwerder",
        "codes": [
            "37619"
        ]
    },
    {
        "city": "Halle",
        "codes": [
            "37620"
        ]
    },
    {
        "city": "Stadtoldendorf",
        "codes": [
            "37627"
        ]
    },
    {
        "city": "Eschershausen",
        "codes": [
            "37632"
        ]
    },
    {
        "city": "Dielmissen",
        "codes": [
            "37633"
        ]
    },
    {
        "city": "Lüerdissen",
        "codes": [
            "37635"
        ]
    },
    {
        "city": "Bevern",
        "codes": [
            "37639"
        ]
    },
    {
        "city": "Golmbach",
        "codes": [
            "37640"
        ]
    },
    {
        "city": "Holenberg",
        "codes": [
            "37642"
        ]
    },
    {
        "city": "Negenborn",
        "codes": [
            "37643"
        ]
    },
    {
        "city": "Polle",
        "codes": [
            "37647"
        ]
    },
    {
        "city": "Heinsen",
        "codes": [
            "37649"
        ]
    },
    {
        "city": "Höxter",
        "codes": [
            "37671"
        ]
    },
    {
        "city": "Beverungen",
        "codes": [
            "37688"
        ]
    },
    {
        "city": "Boffzen",
        "codes": [
            "37691"
        ]
    },
    {
        "city": "Marienmünster",
        "codes": [
            "37696"
        ]
    },
    {
        "city": "Lauenförde",
        "codes": [
            "37697"
        ]
    },
    {
        "city": "Braunschweig",
        "codes": [
            "38100",
            "38102",
            "38104",
            "38106",
            "38108",
            "38110",
            "38112",
            "38114",
            "38116",
            "38118",
            "38120",
            "38122",
            "38124",
            "38126"
        ]
    },
    {
        "city": "Königslutter am Elm",
        "codes": [
            "38154"
        ]
    },
    {
        "city": "Vechelde",
        "codes": [
            "38159"
        ]
    },
    {
        "city": "Cremlingen",
        "codes": [
            "38162"
        ]
    },
    {
        "city": "Lehre",
        "codes": [
            "38165"
        ]
    },
    {
        "city": "Schöppenstedt",
        "codes": [
            "38170"
        ]
    },
    {
        "city": "Sickte",
        "codes": [
            "38173"
        ]
    },
    {
        "city": "Wendeburg",
        "codes": [
            "38176"
        ]
    },
    {
        "city": "Schwülper",
        "codes": [
            "38179"
        ]
    },
    {
        "city": "Salzgitter",
        "codes": [
            "38226",
            "38228",
            "38229",
            "38239",
            "38259"
        ]
    },
    {
        "city": "Lengede",
        "codes": [
            "38268"
        ]
    },
    {
        "city": "Baddeckenstedt",
        "codes": [
            "38271"
        ]
    },
    {
        "city": "Elbe",
        "codes": [
            "38274"
        ]
    },
    {
        "city": "Haverlah",
        "codes": [
            "38275"
        ]
    },
    {
        "city": "Heere",
        "codes": [
            "38277"
        ]
    },
    {
        "city": "Sehlde",
        "codes": [
            "38279"
        ]
    },
    {
        "city": "Wolfenbüttel",
        "codes": [
            "38300",
            "38302",
            "38304"
        ]
    },
    {
        "city": "Börßum",
        "codes": [
            "38312"
        ]
    },
    {
        "city": "Schladen",
        "codes": [
            "38315"
        ]
    },
    {
        "city": "Remlingen",
        "codes": [
            "38319",
            "97280"
        ]
    },
    {
        "city": "Denkte",
        "codes": [
            "38321"
        ]
    },
    {
        "city": "Hedeper",
        "codes": [
            "38322"
        ]
    },
    {
        "city": "Kissenbrück",
        "codes": [
            "38324"
        ]
    },
    {
        "city": "Roklum",
        "codes": [
            "38325"
        ]
    },
    {
        "city": "Semmenstedt",
        "codes": [
            "38327"
        ]
    },
    {
        "city": "Wittmar",
        "codes": [
            "38329"
        ]
    },
    {
        "city": "Helmstedt",
        "codes": [
            "38350"
        ]
    },
    {
        "city": "Schöningen",
        "codes": [
            "38364"
        ]
    },
    {
        "city": "Grasleben",
        "codes": [
            "38368"
        ]
    },
    {
        "city": "Büddenstedt",
        "codes": [
            "38372"
        ]
    },
    {
        "city": "Süpplingen",
        "codes": [
            "38373"
        ]
    },
    {
        "city": "Räbke",
        "codes": [
            "38375"
        ]
    },
    {
        "city": "Süpplingenburg",
        "codes": [
            "38376"
        ]
    },
    {
        "city": "Warberg",
        "codes": [
            "38378"
        ]
    },
    {
        "city": "Wolsdorf",
        "codes": [
            "38379"
        ]
    },
    {
        "city": "Jerxheim",
        "codes": [
            "38381"
        ]
    },
    {
        "city": "Beierstedt",
        "codes": [
            "38382"
        ]
    },
    {
        "city": "Gevensleben",
        "codes": [
            "38384"
        ]
    },
    {
        "city": "Ingeleben",
        "codes": [
            "38385"
        ]
    },
    {
        "city": "Söllingen",
        "codes": [
            "38387"
        ]
    },
    {
        "city": "Twieflingen",
        "codes": [
            "38388"
        ]
    },
    {
        "city": "Wolfsburg",
        "codes": [
            "38440",
            "38442",
            "38444",
            "38446",
            "38448"
        ]
    },
    {
        "city": "Velpke",
        "codes": [
            "38458"
        ]
    },
    {
        "city": "Bahrdorf",
        "codes": [
            "38459"
        ]
    },
    {
        "city": "Danndorf",
        "codes": [
            "38461"
        ]
    },
    {
        "city": "Grafhorst",
        "codes": [
            "38462"
        ]
    },
    {
        "city": "Groß Twülpstedt",
        "codes": [
            "38464"
        ]
    },
    {
        "city": "Brome",
        "codes": [
            "38465"
        ]
    },
    {
        "city": "Bergfeld",
        "codes": [
            "38467"
        ]
    },
    {
        "city": "Ehra-Lessien",
        "codes": [
            "38468"
        ]
    },
    {
        "city": "Parsau",
        "codes": [
            "38470"
        ]
    },
    {
        "city": "Rühen",
        "codes": [
            "38471"
        ]
    },
    {
        "city": "Tiddische",
        "codes": [
            "38473"
        ]
    },
    {
        "city": "Tülau",
        "codes": [
            "38474"
        ]
    },
    {
        "city": "Barwedel",
        "codes": [
            "38476"
        ]
    },
    {
        "city": "Jembke",
        "codes": [
            "38477"
        ]
    },
    {
        "city": "Tappenbeck",
        "codes": [
            "38479"
        ]
    },
    {
        "city": "Klötze / Altmark",
        "codes": [
            "38486"
        ]
    },
    {
        "city": "Beetzendorf",
        "codes": [
            "38489"
        ]
    },
    {
        "city": "Gifhorn",
        "codes": [
            "38518"
        ]
    },
    {
        "city": "Sassenburg",
        "codes": [
            "38524"
        ]
    },
    {
        "city": "Meine",
        "codes": [
            "38527"
        ]
    },
    {
        "city": "Adenbüttel",
        "codes": [
            "38528"
        ]
    },
    {
        "city": "Didderse",
        "codes": [
            "38530"
        ]
    },
    {
        "city": "Rötgesbüttel",
        "codes": [
            "38531"
        ]
    },
    {
        "city": "Vordorf",
        "codes": [
            "38533"
        ]
    },
    {
        "city": "Meinersen",
        "codes": [
            "38536"
        ]
    },
    {
        "city": "Müden (Aller)",
        "codes": [
            "38539"
        ]
    },
    {
        "city": "Leiferde",
        "codes": [
            "38542"
        ]
    },
    {
        "city": "Hillerse",
        "codes": [
            "38543"
        ]
    },
    {
        "city": "Calberlah",
        "codes": [
            "38547"
        ]
    },
    {
        "city": "Isenbüttel",
        "codes": [
            "38550"
        ]
    },
    {
        "city": "Ribbesbüttel",
        "codes": [
            "38551"
        ]
    },
    {
        "city": "Wasbüttel",
        "codes": [
            "38553"
        ]
    },
    {
        "city": "Weyhausen",
        "codes": [
            "38554"
        ]
    },
    {
        "city": "Bokensdorf",
        "codes": [
            "38556"
        ]
    },
    {
        "city": "Osloß",
        "codes": [
            "38557"
        ]
    },
    {
        "city": "Wagenhoff",
        "codes": [
            "38559"
        ]
    },
    {
        "city": "Goslar",
        "codes": [
            "38640",
            "38642",
            "38644"
        ]
    },
    {
        "city": "Bad Harzburg",
        "codes": [
            "38667"
        ]
    },
    {
        "city": "Clausthal-Zellerfeld",
        "codes": [
            "38678"
        ]
    },
    {
        "city": "Langelsheim",
        "codes": [
            "38685"
        ]
    },
    {
        "city": "Vienenburg",
        "codes": [
            "38690"
        ]
    },
    {
        "city": "Braunlage",
        "codes": [
            "38700"
        ]
    },
    {
        "city": "Liebenburg",
        "codes": [
            "38704"
        ]
    },
    {
        "city": "Altenau",
        "codes": [
            "38707"
        ]
    },
    {
        "city": "Wildemann",
        "codes": [
            "38709"
        ]
    },
    {
        "city": "Seesen",
        "codes": [
            "38723"
        ]
    },
    {
        "city": "Lutter am Barenberge",
        "codes": [
            "38729"
        ]
    },
    {
        "city": "Halberstadt",
        "codes": [
            "38820",
            "38822"
        ]
    },
    {
        "city": "Wegeleben",
        "codes": [
            "38828"
        ]
    },
    {
        "city": "Harsleben",
        "codes": [
            "38829"
        ]
    },
    {
        "city": "Osterwieck",
        "codes": [
            "38835"
        ]
    },
    {
        "city": "Badersleben",
        "codes": [
            "38836"
        ]
    },
    {
        "city": "Dingelstedt am Huy",
        "codes": [
            "38838"
        ]
    },
    {
        "city": "Wernigerode",
        "codes": [
            "38855"
        ]
    },
    {
        "city": "Ilsenburg / Harz",
        "codes": [
            "38871"
        ]
    },
    {
        "city": "Elbingerode / Harz",
        "codes": [
            "38875"
        ]
    },
    {
        "city": "Benneckenstein / Harz",
        "codes": [
            "38877"
        ]
    },
    {
        "city": "Schierke",
        "codes": [
            "38879"
        ]
    },
    {
        "city": "Blankenburg / Harz",
        "codes": [
            "38889"
        ]
    },
    {
        "city": "Derenburg",
        "codes": [
            "38895"
        ]
    },
    {
        "city": "Hasselfelde",
        "codes": [
            "38899"
        ]
    },
    {
        "city": "Magdeburg",
        "codes": [
            "39104",
            "39106",
            "39108",
            "39110",
            "39112",
            "39114",
            "39116",
            "39118",
            "39120",
            "39122",
            "39124",
            "39126",
            "39128",
            "39130",
            "39221"
        ]
    },
    {
        "city": "Wanzleben",
        "codes": [
            "39164"
        ]
    },
    {
        "city": "Niederndodeleben",
        "codes": [
            "39167"
        ]
    },
    {
        "city": "Langenweddingen",
        "codes": [
            "39171"
        ]
    },
    {
        "city": "Biederitz",
        "codes": [
            "39175"
        ]
    },
    {
        "city": "Barleben",
        "codes": [
            "39179"
        ]
    },
    {
        "city": "Schönebeck / Elbe",
        "codes": [
            "39217",
            "39218"
        ]
    },
    {
        "city": "Calbe / Saale",
        "codes": [
            "39240"
        ]
    },
    {
        "city": "Gommern",
        "codes": [
            "39245"
        ]
    },
    {
        "city": "Barby / Elbe",
        "codes": [
            "39249"
        ]
    },
    {
        "city": "Zerbst",
        "codes": [
            "39261"
        ]
    },
    {
        "city": "Lindau",
        "codes": [
            "39264"
        ]
    },
    {
        "city": "Loburg",
        "codes": [
            "39279"
        ]
    },
    {
        "city": "Burg bei Magdeburg",
        "codes": [
            "39288"
        ]
    },
    {
        "city": "Möckern",
        "codes": [
            "39291"
        ]
    },
    {
        "city": "Genthin",
        "codes": [
            "39307"
        ]
    },
    {
        "city": "Parey",
        "codes": [
            "39317"
        ]
    },
    {
        "city": "Jerichow",
        "codes": [
            "39319"
        ]
    },
    {
        "city": "Wolmirstedt",
        "codes": [
            "39326"
        ]
    },
    {
        "city": "Haldensleben",
        "codes": [
            "39340",
            "39343",
            "39345"
        ]
    },
    {
        "city": "Weferlingen",
        "codes": [
            "39356"
        ]
    },
    {
        "city": "Calvörde",
        "codes": [
            "39359"
        ]
    },
    {
        "city": "Wefensleben",
        "codes": [
            "39365"
        ]
    },
    {
        "city": "Oschersleben / Bode",
        "codes": [
            "39387"
        ]
    },
    {
        "city": "Hötensleben",
        "codes": [
            "39393"
        ]
    },
    {
        "city": "Gröningen",
        "codes": [
            "39397"
        ]
    },
    {
        "city": "Hadmersleben",
        "codes": [
            "39398"
        ]
    },
    {
        "city": "Staßfurt",
        "codes": [
            "39418"
        ]
    },
    {
        "city": "Egeln",
        "codes": [
            "39435"
        ]
    },
    {
        "city": "Güsten",
        "codes": [
            "39439"
        ]
    },
    {
        "city": "Förderstedt",
        "codes": [
            "39443"
        ]
    },
    {
        "city": "Hecklingen",
        "codes": [
            "39444"
        ]
    },
    {
        "city": "Löderburg",
        "codes": [
            "39446"
        ]
    },
    {
        "city": "Westeregeln",
        "codes": [
            "39448"
        ]
    },
    {
        "city": "Tangerhütte",
        "codes": [
            "39517"
        ]
    },
    {
        "city": "Schönhausen / Elbe",
        "codes": [
            "39524"
        ]
    },
    {
        "city": "Havelberg",
        "codes": [
            "39539"
        ]
    },
    {
        "city": "Stendal",
        "codes": [
            "39576"
        ]
    },
    {
        "city": "Kläden",
        "codes": [
            "39579"
        ]
    },
    {
        "city": "Tangermünde",
        "codes": [
            "39590"
        ]
    },
    {
        "city": "Arneburg",
        "codes": [
            "39596"
        ]
    },
    {
        "city": "Uchtspringe",
        "codes": [
            "39599"
        ]
    },
    {
        "city": "Osterburg / Altmark",
        "codes": [
            "39606"
        ]
    },
    {
        "city": "Seehausen / Altmark",
        "codes": [
            "39615"
        ]
    },
    {
        "city": "Arendsee / Altmark",
        "codes": [
            "39619"
        ]
    },
    {
        "city": "Kalbe / Milde",
        "codes": [
            "39624"
        ]
    },
    {
        "city": "Bismark / Altmark",
        "codes": [
            "39629"
        ]
    },
    {
        "city": "Gardelegen",
        "codes": [
            "39638"
        ]
    },
    {
        "city": "Oebisfelde",
        "codes": [
            "39646"
        ]
    },
    {
        "city": "Mieste",
        "codes": [
            "39649"
        ]
    },
    {
        "city": "Düsseldorf",
        "codes": [
            "40210",
            "40211",
            "40212",
            "40213",
            "40215",
            "40217",
            "40219",
            "40221",
            "40223",
            "40225",
            "40227",
            "40229",
            "40231",
            "40233",
            "40235",
            "40237",
            "40239",
            "40468",
            "40470",
            "40472",
            "40474",
            "40476",
            "40477",
            "40479",
            "40489",
            "40545",
            "40547",
            "40549",
            "40589",
            "40591",
            "40593",
            "40595",
            "40597",
            "40599",
            "40625",
            "40627",
            "40629"
        ]
    },
    {
        "city": "Meerbusch",
        "codes": [
            "40667",
            "40668",
            "40670"
        ]
    },
    {
        "city": "Erkrath",
        "codes": [
            "40699"
        ]
    },
    {
        "city": "Hilden",
        "codes": [
            "40721",
            "40723",
            "40724"
        ]
    },
    {
        "city": "Langenfeld (Rheinland)",
        "codes": [
            "40764"
        ]
    },
    {
        "city": "Monheim am Rhein",
        "codes": [
            "40789"
        ]
    },
    {
        "city": "Mettmann",
        "codes": [
            "40822"
        ]
    },
    {
        "city": "Ratingen",
        "codes": [
            "40878",
            "40880",
            "40882",
            "40883",
            "40885"
        ]
    },
    {
        "city": "Mönchengladbach",
        "codes": [
            "41061",
            "41063",
            "41065",
            "41066",
            "41068",
            "41069",
            "41169",
            "41179",
            "41189",
            "41199",
            "41236",
            "41238",
            "41239"
        ]
    },
    {
        "city": "Nettetal",
        "codes": [
            "41334"
        ]
    },
    {
        "city": "Korschenbroich",
        "codes": [
            "41352"
        ]
    },
    {
        "city": "Jüchen",
        "codes": [
            "41363"
        ]
    },
    {
        "city": "Niederkrüchten",
        "codes": [
            "41372"
        ]
    },
    {
        "city": "Neuss",
        "codes": [
            "41460",
            "41462",
            "41464",
            "41466",
            "41468",
            "41469",
            "41470",
            "41472"
        ]
    },
    {
        "city": "Grevenbroich",
        "codes": [
            "41515",
            "41516",
            "41517"
        ]
    },
    {
        "city": "Dormagen",
        "codes": [
            "41539",
            "41540",
            "41541",
            "41542"
        ]
    },
    {
        "city": "Kaarst",
        "codes": [
            "41564"
        ]
    },
    {
        "city": "Rommerskirchen",
        "codes": [
            "41569"
        ]
    },
    {
        "city": "Viersen",
        "codes": [
            "41747",
            "41748",
            "41749",
            "41751"
        ]
    },
    {
        "city": "Erkelenz",
        "codes": [
            "41812"
        ]
    },
    {
        "city": "Hückelhoven",
        "codes": [
            "41836"
        ]
    },
    {
        "city": "Wegberg",
        "codes": [
            "41844"
        ]
    },
    {
        "city": "Wassenberg",
        "codes": [
            "41849"
        ]
    },
    {
        "city": "Wuppertal",
        "codes": [
            "42103",
            "42105",
            "42107",
            "42109",
            "42111",
            "42113",
            "42115",
            "42117",
            "42119",
            "42275",
            "42277",
            "42279",
            "42281",
            "42283",
            "42285",
            "42287",
            "42289",
            "42327",
            "42329",
            "42349",
            "42369",
            "42389",
            "42399"
        ]
    },
    {
        "city": "Radevormwald",
        "codes": [
            "42477"
        ]
    },
    {
        "city": "Wülfrath",
        "codes": [
            "42489"
        ]
    },
    {
        "city": "Hückeswagen",
        "codes": [
            "42499"
        ]
    },
    {
        "city": "Velbert",
        "codes": [
            "42549",
            "42551",
            "42553",
            "42555"
        ]
    },
    {
        "city": "Heiligenhaus",
        "codes": [
            "42579"
        ]
    },
    {
        "city": "Solingen",
        "codes": [
            "42651",
            "42653",
            "42655",
            "42657",
            "42659",
            "42697",
            "42699",
            "42719"
        ]
    },
    {
        "city": "Haan",
        "codes": [
            "42781"
        ]
    },
    {
        "city": "Leichlingen (Rheinland)",
        "codes": [
            "42799"
        ]
    },
    {
        "city": "Remscheid",
        "codes": [
            "42853",
            "42855",
            "42857",
            "42859",
            "42897",
            "42899"
        ]
    },
    {
        "city": "Wermelskirchen",
        "codes": [
            "42929"
        ]
    },
    {
        "city": "Dortmund",
        "codes": [
            "44135",
            "44137",
            "44139",
            "44141",
            "44143",
            "44145",
            "44147",
            "44149",
            "44225",
            "44227",
            "44229",
            "44263",
            "44265",
            "44267",
            "44269",
            "44287",
            "44289",
            "44309",
            "44319",
            "44328",
            "44329",
            "44339",
            "44357",
            "44359",
            "44369",
            "44379",
            "44388"
        ]
    },
    {
        "city": "Lünen",
        "codes": [
            "44532",
            "44534",
            "44536"
        ]
    },
    {
        "city": "Castrop-Rauxel",
        "codes": [
            "44575",
            "44577",
            "44579",
            "44581"
        ]
    },
    {
        "city": "Herne",
        "codes": [
            "44623",
            "44625",
            "44627",
            "44628",
            "44629",
            "44649",
            "44651",
            "44652",
            "44653"
        ]
    },
    {
        "city": "Bochum",
        "codes": [
            "44787",
            "44789",
            "44791",
            "44793",
            "44795",
            "44797",
            "44799",
            "44801",
            "44803",
            "44805",
            "44807",
            "44809",
            "44866",
            "44867",
            "44869",
            "44879",
            "44892",
            "44894"
        ]
    },
    {
        "city": "Essen",
        "codes": [
            "45127",
            "45128",
            "45130",
            "45131",
            "45133",
            "45134",
            "45136",
            "45138",
            "45139",
            "45141",
            "45143",
            "45144",
            "45145",
            "45147",
            "45149",
            "45219",
            "45239",
            "45257",
            "45259",
            "45276",
            "45277",
            "45279",
            "45289",
            "45307",
            "45309",
            "45326",
            "45327",
            "45329",
            "45355",
            "45356",
            "45357",
            "45359"
        ]
    },
    {
        "city": "Mülheim an der Ruhr",
        "codes": [
            "45468",
            "45470",
            "45472",
            "45473",
            "45475",
            "45476",
            "45478",
            "45479",
            "45481"
        ]
    },
    {
        "city": "Hattingen",
        "codes": [
            "45525",
            "45527",
            "45529"
        ]
    },
    {
        "city": "Sprockhövel",
        "codes": [
            "45549"
        ]
    },
    {
        "city": "Recklinghausen",
        "codes": [
            "45657",
            "45659",
            "45661",
            "45663",
            "45665"
        ]
    },
    {
        "city": "Herten",
        "codes": [
            "45699",
            "45701"
        ]
    },
    {
        "city": "Datteln",
        "codes": [
            "45711"
        ]
    },
    {
        "city": "Haltern",
        "codes": [
            "45721"
        ]
    },
    {
        "city": "Waltrop",
        "codes": [
            "45731"
        ]
    },
    {
        "city": "Oer-Erkenschwick",
        "codes": [
            "45739"
        ]
    },
    {
        "city": "Marl",
        "codes": [
            "45768",
            "45770",
            "45772"
        ]
    },
    {
        "city": "Gelsenkirchen",
        "codes": [
            "45879",
            "45881",
            "45883",
            "45884",
            "45886",
            "45888",
            "45889",
            "45891",
            "45892",
            "45894",
            "45896",
            "45897",
            "45899"
        ]
    },
    {
        "city": "Gladbeck",
        "codes": [
            "45964",
            "45966",
            "45968"
        ]
    },
    {
        "city": "Oberhausen",
        "codes": [
            "46045",
            "46047",
            "46049",
            "46117",
            "46119",
            "46145",
            "46147",
            "46149",
            "86697"
        ]
    },
    {
        "city": "Bottrop",
        "codes": [
            "46236",
            "46238",
            "46240",
            "46242",
            "46244"
        ]
    },
    {
        "city": "Dorsten",
        "codes": [
            "46282",
            "46284",
            "46286"
        ]
    },
    {
        "city": "Borken",
        "codes": [
            "46325"
        ]
    },
    {
        "city": "Velen",
        "codes": [
            "46342"
        ]
    },
    {
        "city": "Raesfeld",
        "codes": [
            "46348"
        ]
    },
    {
        "city": "Südlohn",
        "codes": [
            "46354"
        ]
    },
    {
        "city": "Heiden",
        "codes": [
            "46359"
        ]
    },
    {
        "city": "Bocholt",
        "codes": [
            "46395",
            "46397",
            "46399"
        ]
    },
    {
        "city": "Rhede",
        "codes": [
            "46414"
        ]
    },
    {
        "city": "Isselburg",
        "codes": [
            "46419"
        ]
    },
    {
        "city": "Emmerich",
        "codes": [
            "46446"
        ]
    },
    {
        "city": "Rees",
        "codes": [
            "46459"
        ]
    },
    {
        "city": "Wesel",
        "codes": [
            "46483",
            "46485",
            "46487"
        ]
    },
    {
        "city": "Hamminkeln",
        "codes": [
            "46499"
        ]
    },
    {
        "city": "Xanten",
        "codes": [
            "46509"
        ]
    },
    {
        "city": "Schermbeck",
        "codes": [
            "46514"
        ]
    },
    {
        "city": "Alpen",
        "codes": [
            "46519"
        ]
    },
    {
        "city": "Dinslaken",
        "codes": [
            "46535",
            "46537",
            "46539"
        ]
    },
    {
        "city": "Voerde (Niederrhein)",
        "codes": [
            "46562"
        ]
    },
    {
        "city": "Hünxe",
        "codes": [
            "46569"
        ]
    },
    {
        "city": "Duisburg",
        "codes": [
            "47051",
            "47053",
            "47055",
            "47057",
            "47058",
            "47059",
            "47119",
            "47137",
            "47138",
            "47139",
            "47166",
            "47167",
            "47169",
            "47178",
            "47179",
            "47198",
            "47199",
            "47226",
            "47228",
            "47229",
            "47239",
            "47249",
            "47259",
            "47269",
            "47279"
        ]
    },
    {
        "city": "Moers",
        "codes": [
            "47441",
            "47443",
            "47445",
            "47447"
        ]
    },
    {
        "city": "Kamp-Lintfort",
        "codes": [
            "47475"
        ]
    },
    {
        "city": "Rheinberg",
        "codes": [
            "47495"
        ]
    },
    {
        "city": "Neukirchen-Vluyn",
        "codes": [
            "47506"
        ]
    },
    {
        "city": "Rheurdt",
        "codes": [
            "47509"
        ]
    },
    {
        "city": "Kleve",
        "codes": [
            "47533"
        ]
    },
    {
        "city": "Kalkar",
        "codes": [
            "47546"
        ]
    },
    {
        "city": "Bedburg-Hau",
        "codes": [
            "47551"
        ]
    },
    {
        "city": "Kranenburg",
        "codes": [
            "47559"
        ]
    },
    {
        "city": "Goch",
        "codes": [
            "47574"
        ]
    },
    {
        "city": "Uedem",
        "codes": [
            "47589"
        ]
    },
    {
        "city": "Geldern",
        "codes": [
            "47608"
        ]
    },
    {
        "city": "Kevelaer",
        "codes": [
            "47623",
            "47624",
            "47625",
            "47626",
            "47627"
        ]
    },
    {
        "city": "Straelen",
        "codes": [
            "47638"
        ]
    },
    {
        "city": "Kerken",
        "codes": [
            "47647"
        ]
    },
    {
        "city": "Weeze",
        "codes": [
            "47652"
        ]
    },
    {
        "city": "Issum",
        "codes": [
            "47661"
        ]
    },
    {
        "city": "Sonsbeck",
        "codes": [
            "47665"
        ]
    },
    {
        "city": "Wachtendonk",
        "codes": [
            "47669"
        ]
    },
    {
        "city": "Krefeld",
        "codes": [
            "47798",
            "47799",
            "47800",
            "47802",
            "47803",
            "47804",
            "47805",
            "47807",
            "47809",
            "47829",
            "47839"
        ]
    },
    {
        "city": "Willich",
        "codes": [
            "47877"
        ]
    },
    {
        "city": "Kempen",
        "codes": [
            "47906"
        ]
    },
    {
        "city": "Tönisvorst",
        "codes": [
            "47918"
        ]
    },
    {
        "city": "Grefrath",
        "codes": [
            "47929"
        ]
    },
    {
        "city": "Münster",
        "codes": [
            "48143",
            "48145",
            "48147",
            "48149",
            "48151",
            "48153",
            "48155",
            "48157",
            "48159",
            "48161",
            "48163",
            "48165",
            "48167",
            "64839",
            "86692"
        ]
    },
    {
        "city": "Warendorf",
        "codes": [
            "48231"
        ]
    },
    {
        "city": "Dülmen",
        "codes": [
            "48249"
        ]
    },
    {
        "city": "Greven",
        "codes": [
            "48268"
        ]
    },
    {
        "city": "Emsdetten",
        "codes": [
            "48282"
        ]
    },
    {
        "city": "Telgte",
        "codes": [
            "48291"
        ]
    },
    {
        "city": "Nottuln",
        "codes": [
            "48301"
        ]
    },
    {
        "city": "Senden",
        "codes": [
            "48308",
            "89250"
        ]
    },
    {
        "city": "Drensteinfurt",
        "codes": [
            "48317"
        ]
    },
    {
        "city": "Sendenhorst",
        "codes": [
            "48324"
        ]
    },
    {
        "city": "Havixbeck",
        "codes": [
            "48329"
        ]
    },
    {
        "city": "Sassenberg",
        "codes": [
            "48336"
        ]
    },
    {
        "city": "Altenberge",
        "codes": [
            "48341"
        ]
    },
    {
        "city": "Ostbevern",
        "codes": [
            "48346"
        ]
    },
    {
        "city": "Everswinkel",
        "codes": [
            "48351"
        ]
    },
    {
        "city": "Nordwalde",
        "codes": [
            "48356"
        ]
    },
    {
        "city": "Beelen",
        "codes": [
            "48361"
        ]
    },
    {
        "city": "Laer",
        "codes": [
            "48366"
        ]
    },
    {
        "city": "Saerbeck",
        "codes": [
            "48369"
        ]
    },
    {
        "city": "Rheine",
        "codes": [
            "48429",
            "48431",
            "48432"
        ]
    },
    {
        "city": "Bad Bentheim",
        "codes": [
            "48455"
        ]
    },
    {
        "city": "Schüttorf",
        "codes": [
            "48465"
        ]
    },
    {
        "city": "Hörstel",
        "codes": [
            "48477"
        ]
    },
    {
        "city": "Spelle",
        "codes": [
            "48480"
        ]
    },
    {
        "city": "Emsbüren",
        "codes": [
            "48488"
        ]
    },
    {
        "city": "Wettringen",
        "codes": [
            "48493",
            "91631"
        ]
    },
    {
        "city": "Hopsten",
        "codes": [
            "48496"
        ]
    },
    {
        "city": "Salzbergen",
        "codes": [
            "48499"
        ]
    },
    {
        "city": "Nordhorn",
        "codes": [
            "48527",
            "48529",
            "48531"
        ]
    },
    {
        "city": "Steinfurt",
        "codes": [
            "48565"
        ]
    },
    {
        "city": "Gronau (Westfalen)",
        "codes": [
            "48599"
        ]
    },
    {
        "city": "Ochtrup",
        "codes": [
            "48607"
        ]
    },
    {
        "city": "Horstmar",
        "codes": [
            "48612"
        ]
    },
    {
        "city": "Heek",
        "codes": [
            "48619"
        ]
    },
    {
        "city": "Schöppingen",
        "codes": [
            "48624"
        ]
    },
    {
        "city": "Metelen",
        "codes": [
            "48629"
        ]
    },
    {
        "city": "Coesfeld",
        "codes": [
            "48653"
        ]
    },
    {
        "city": "Ahaus",
        "codes": [
            "48683"
        ]
    },
    {
        "city": "Vreden",
        "codes": [
            "48691"
        ]
    },
    {
        "city": "Stadtlohn",
        "codes": [
            "48703"
        ]
    },
    {
        "city": "Gescher",
        "codes": [
            "48712"
        ]
    },
    {
        "city": "Rosendahl",
        "codes": [
            "48720"
        ]
    },
    {
        "city": "Billerbeck",
        "codes": [
            "48727"
        ]
    },
    {
        "city": "Reken",
        "codes": [
            "48734"
        ]
    },
    {
        "city": "Legden",
        "codes": [
            "48739"
        ]
    },
    {
        "city": "Osnabrück",
        "codes": [
            "49074",
            "49076",
            "49078",
            "49080",
            "49082",
            "49084",
            "49086",
            "49088",
            "49090"
        ]
    },
    {
        "city": "Georgsmarienhütte",
        "codes": [
            "49124"
        ]
    },
    {
        "city": "Wallenhorst",
        "codes": [
            "49134"
        ]
    },
    {
        "city": "Bissendorf",
        "codes": [
            "49143"
        ]
    },
    {
        "city": "Bad Essen",
        "codes": [
            "49152"
        ]
    },
    {
        "city": "Bohmte",
        "codes": [
            "49163"
        ]
    },
    {
        "city": "Hagen am Teutoburger Wald",
        "codes": [
            "49170"
        ]
    },
    {
        "city": "Hilter am Teutoburger Wald",
        "codes": [
            "49176"
        ]
    },
    {
        "city": "Ostercappeln",
        "codes": [
            "49179"
        ]
    },
    {
        "city": "Bad Iburg",
        "codes": [
            "49186"
        ]
    },
    {
        "city": "Belm",
        "codes": [
            "49191"
        ]
    },
    {
        "city": "Bad Laer",
        "codes": [
            "49196"
        ]
    },
    {
        "city": "Dissen am Teutoburger Wald",
        "codes": [
            "49201"
        ]
    },
    {
        "city": "Hasbergen",
        "codes": [
            "49205"
        ]
    },
    {
        "city": "Bad Rothenfelde",
        "codes": [
            "49214"
        ]
    },
    {
        "city": "Glandorf",
        "codes": [
            "49219"
        ]
    },
    {
        "city": "Melle",
        "codes": [
            "49324",
            "49326",
            "49328"
        ]
    },
    {
        "city": "Diepholz",
        "codes": [
            "49356"
        ]
    },
    {
        "city": "Vechta",
        "codes": [
            "49377"
        ]
    },
    {
        "city": "Lohne (Oldenburg)",
        "codes": [
            "49393"
        ]
    },
    {
        "city": "Damme",
        "codes": [
            "49401"
        ]
    },
    {
        "city": "Barnstorf",
        "codes": [
            "49406"
        ]
    },
    {
        "city": "Dinklage",
        "codes": [
            "49413"
        ]
    },
    {
        "city": "Wagenfeld",
        "codes": [
            "49419"
        ]
    },
    {
        "city": "Goldenstedt",
        "codes": [
            "49424"
        ]
    },
    {
        "city": "Visbek",
        "codes": [
            "49429"
        ]
    },
    {
        "city": "Neuenkirchen-Vörden",
        "codes": [
            "49434"
        ]
    },
    {
        "city": "Steinfeld (Oldenburg)",
        "codes": [
            "49439"
        ]
    },
    {
        "city": "Lemförde",
        "codes": [
            "49448"
        ]
    },
    {
        "city": "Holdorf",
        "codes": [
            "49451"
        ]
    },
    {
        "city": "Rehden",
        "codes": [
            "49453"
        ]
    },
    {
        "city": "Bakum",
        "codes": [
            "49456"
        ]
    },
    {
        "city": "Drebber",
        "codes": [
            "49457"
        ]
    },
    {
        "city": "Lembruch",
        "codes": [
            "49459"
        ]
    },
    {
        "city": "Ibbenbüren",
        "codes": [
            "49477",
            "49479"
        ]
    },
    {
        "city": "Westerkappeln",
        "codes": [
            "49492"
        ]
    },
    {
        "city": "Mettingen",
        "codes": [
            "49497"
        ]
    },
    {
        "city": "Lotte",
        "codes": [
            "49504"
        ]
    },
    {
        "city": "Recke",
        "codes": [
            "49509"
        ]
    },
    {
        "city": "Lengerich",
        "codes": [
            "49525",
            "49838"
        ]
    },
    {
        "city": "Lienen",
        "codes": [
            "49536"
        ]
    },
    {
        "city": "Tecklenburg",
        "codes": [
            "49545"
        ]
    },
    {
        "city": "Ladbergen",
        "codes": [
            "49549"
        ]
    },
    {
        "city": "Bramsche",
        "codes": [
            "49565"
        ]
    },
    {
        "city": "Ankum",
        "codes": [
            "49577"
        ]
    },
    {
        "city": "Fürstenau",
        "codes": [
            "49584"
        ]
    },
    {
        "city": "Bersenbrück",
        "codes": [
            "49593"
        ]
    },
    {
        "city": "Alfhausen",
        "codes": [
            "49594"
        ]
    },
    {
        "city": "Gehrde",
        "codes": [
            "49596"
        ]
    },
    {
        "city": "Rieste",
        "codes": [
            "49597"
        ]
    },
    {
        "city": "Voltlage",
        "codes": [
            "49599"
        ]
    },
    {
        "city": "Quakenbrück",
        "codes": [
            "49610"
        ]
    },
    {
        "city": "Löningen",
        "codes": [
            "49624"
        ]
    },
    {
        "city": "Berge",
        "codes": [
            "49626"
        ]
    },
    {
        "city": "Essen (Oldenburg)",
        "codes": [
            "49632"
        ]
    },
    {
        "city": "Badbergen",
        "codes": [
            "49635"
        ]
    },
    {
        "city": "Menslage",
        "codes": [
            "49637"
        ]
    },
    {
        "city": "Nortrup",
        "codes": [
            "49638"
        ]
    },
    {
        "city": "Cloppenburg",
        "codes": [
            "49661"
        ]
    },
    {
        "city": "Garrel",
        "codes": [
            "49681"
        ]
    },
    {
        "city": "Emstek",
        "codes": [
            "49685"
        ]
    },
    {
        "city": "Lastrup",
        "codes": [
            "49688"
        ]
    },
    {
        "city": "Cappeln (Oldenburg)",
        "codes": [
            "49692"
        ]
    },
    {
        "city": "Molbergen",
        "codes": [
            "49696"
        ]
    },
    {
        "city": "Lindern (Oldenburg)",
        "codes": [
            "49699"
        ]
    },
    {
        "city": "Meppen",
        "codes": [
            "49716"
        ]
    },
    {
        "city": "Haren (Ems)",
        "codes": [
            "49733"
        ]
    },
    {
        "city": "Haselünne",
        "codes": [
            "49740"
        ]
    },
    {
        "city": "Geeste",
        "codes": [
            "49744"
        ]
    },
    {
        "city": "Sögel",
        "codes": [
            "49751"
        ]
    },
    {
        "city": "Werlte",
        "codes": [
            "49757"
        ]
    },
    {
        "city": "Lathen",
        "codes": [
            "49762"
        ]
    },
    {
        "city": "Twist",
        "codes": [
            "49767"
        ]
    },
    {
        "city": "Herzlake",
        "codes": [
            "49770"
        ]
    },
    {
        "city": "Lähden",
        "codes": [
            "49774"
        ]
    },
    {
        "city": "Stavern",
        "codes": [
            "49777"
        ]
    },
    {
        "city": "Niederlangen",
        "codes": [
            "49779"
        ]
    },
    {
        "city": "Lingen (Ems)",
        "codes": [
            "49808",
            "49809",
            "49811"
        ]
    },
    {
        "city": "Emlichheim",
        "codes": [
            "49824"
        ]
    },
    {
        "city": "Neuenhaus",
        "codes": [
            "49828"
        ]
    },
    {
        "city": "Freren",
        "codes": [
            "49832"
        ]
    },
    {
        "city": "Wietmarschen",
        "codes": [
            "49835"
        ]
    },
    {
        "city": "Uelsen",
        "codes": [
            "49843"
        ]
    },
    {
        "city": "Bawinkel",
        "codes": [
            "49844"
        ]
    },
    {
        "city": "Hoogstede",
        "codes": [
            "49846"
        ]
    },
    {
        "city": "Itterbeck",
        "codes": [
            "49847"
        ]
    },
    {
        "city": "Wilsum",
        "codes": [
            "49849"
        ]
    },
    {
        "city": "Bergheim",
        "codes": [
            "50126",
            "50127",
            "50129",
            "86673"
        ]
    },
    {
        "city": "Kerpen",
        "codes": [
            "50169",
            "50170",
            "50171"
        ]
    },
    {
        "city": "Bedburg",
        "codes": [
            "50181"
        ]
    },
    {
        "city": "Elsdorf",
        "codes": [
            "50189"
        ]
    },
    {
        "city": "Frechen",
        "codes": [
            "50226"
        ]
    },
    {
        "city": "Pulheim",
        "codes": [
            "50259"
        ]
    },
    {
        "city": "Brühl",
        "codes": [
            "50321",
            "68782"
        ]
    },
    {
        "city": "Hürth",
        "codes": [
            "50354"
        ]
    },
    {
        "city": "Erftstadt",
        "codes": [
            "50374"
        ]
    },
    {
        "city": "Wesseling",
        "codes": [
            "50389"
        ]
    },
    {
        "city": "Köln",
        "codes": [
            "50667",
            "50668",
            "50670",
            "50672",
            "50674",
            "50676",
            "50677",
            "50678",
            "50679",
            "50733",
            "50735",
            "50737",
            "50739",
            "50765",
            "50767",
            "50769",
            "50823",
            "50825",
            "50827",
            "50829",
            "50858",
            "50859",
            "50931",
            "50933",
            "50935",
            "50937",
            "50939",
            "50968",
            "50969",
            "50996",
            "50997",
            "50999",
            "51061",
            "51063",
            "51065",
            "51067",
            "51069",
            "51103",
            "51105",
            "51107",
            "51109",
            "51143",
            "51145",
            "51147",
            "51149"
        ]
    },
    {
        "city": "Leverkusen",
        "codes": [
            "51371",
            "51373",
            "51375",
            "51377",
            "51379",
            "51381"
        ]
    },
    {
        "city": "Burscheid",
        "codes": [
            "51399"
        ]
    },
    {
        "city": "Bergisch Gladbach",
        "codes": [
            "51427",
            "51429",
            "51465",
            "51467",
            "51469"
        ]
    },
    {
        "city": "Overath",
        "codes": [
            "51491"
        ]
    },
    {
        "city": "Rösrath",
        "codes": [
            "51503"
        ]
    },
    {
        "city": "Kürten",
        "codes": [
            "51515"
        ]
    },
    {
        "city": "Odenthal",
        "codes": [
            "51519"
        ]
    },
    {
        "city": "Waldbröl",
        "codes": [
            "51545"
        ]
    },
    {
        "city": "Windeck",
        "codes": [
            "51570"
        ]
    },
    {
        "city": "Reichshof",
        "codes": [
            "51580"
        ]
    },
    {
        "city": "Nümbrecht",
        "codes": [
            "51588"
        ]
    },
    {
        "city": "Morsbach",
        "codes": [
            "51597"
        ]
    },
    {
        "city": "Friesenhagen",
        "codes": [
            "51598"
        ]
    },
    {
        "city": "Gummersbach",
        "codes": [
            "51643",
            "51645",
            "51647"
        ]
    },
    {
        "city": "Wiehl",
        "codes": [
            "51674"
        ]
    },
    {
        "city": "Wipperfürth",
        "codes": [
            "51688"
        ]
    },
    {
        "city": "Bergneustadt",
        "codes": [
            "51702"
        ]
    },
    {
        "city": "Marienheide",
        "codes": [
            "51709"
        ]
    },
    {
        "city": "Engelskirchen",
        "codes": [
            "51766"
        ]
    },
    {
        "city": "Lindlar",
        "codes": [
            "51789"
        ]
    },
    {
        "city": "Aachen",
        "codes": [
            "52062",
            "52064",
            "52066",
            "52068",
            "52070",
            "52072",
            "52074",
            "52076",
            "52078",
            "52080"
        ]
    },
    {
        "city": "Herzogenrath",
        "codes": [
            "52134"
        ]
    },
    {
        "city": "Würselen",
        "codes": [
            "52146"
        ]
    },
    {
        "city": "Simmerath",
        "codes": [
            "52152"
        ]
    },
    {
        "city": "Monschau",
        "codes": [
            "52156"
        ]
    },
    {
        "city": "Roetgen",
        "codes": [
            "52159"
        ]
    },
    {
        "city": "Stolberg (Rheinland)",
        "codes": [
            "52222",
            "52223",
            "52224"
        ]
    },
    {
        "city": "Eschweiler",
        "codes": [
            "52249"
        ]
    },
    {
        "city": "Düren",
        "codes": [
            "52349",
            "52351",
            "52353",
            "52355"
        ]
    },
    {
        "city": "Kreuzau",
        "codes": [
            "52372"
        ]
    },
    {
        "city": "Langerwehe",
        "codes": [
            "52379"
        ]
    },
    {
        "city": "Niederzier",
        "codes": [
            "52382"
        ]
    },
    {
        "city": "Nideggen",
        "codes": [
            "52385"
        ]
    },
    {
        "city": "Nörvenich",
        "codes": [
            "52388"
        ]
    },
    {
        "city": "Vettweiß",
        "codes": [
            "52391"
        ]
    },
    {
        "city": "Hürtgenwald",
        "codes": [
            "52393"
        ]
    },
    {
        "city": "Heimbach",
        "codes": [
            "52396",
            "55779"
        ]
    },
    {
        "city": "Merzenich",
        "codes": [
            "52399"
        ]
    },
    {
        "city": "Jülich",
        "codes": [
            "52428"
        ]
    },
    {
        "city": "Linnich",
        "codes": [
            "52441"
        ]
    },
    {
        "city": "Titz",
        "codes": [
            "52445"
        ]
    },
    {
        "city": "Aldenhoven",
        "codes": [
            "52457"
        ]
    },
    {
        "city": "Inden",
        "codes": [
            "52459"
        ]
    },
    {
        "city": "Alsdorf",
        "codes": [
            "52477"
        ]
    },
    {
        "city": "Baesweiler",
        "codes": [
            "52499"
        ]
    },
    {
        "city": "Geilenkirchen",
        "codes": [
            "52511"
        ]
    },
    {
        "city": "Heinsberg",
        "codes": [
            "52525"
        ]
    },
    {
        "city": "Übach-Palenberg",
        "codes": [
            "52531"
        ]
    },
    {
        "city": "Gangelt",
        "codes": [
            "52538"
        ]
    },
    {
        "city": "Bonn",
        "codes": [
            "53111",
            "53113",
            "53115",
            "53117",
            "53119",
            "53121",
            "53123",
            "53125",
            "53127",
            "53129",
            "53173",
            "53175",
            "53177",
            "53179",
            "53225",
            "53227",
            "53229"
        ]
    },
    {
        "city": "Bornheim",
        "codes": [
            "53332"
        ]
    },
    {
        "city": "Meckenheim",
        "codes": [
            "53340",
            "67149"
        ]
    },
    {
        "city": "Wachtberg",
        "codes": [
            "53343"
        ]
    },
    {
        "city": "Alfter",
        "codes": [
            "53347"
        ]
    },
    {
        "city": "Rheinbach",
        "codes": [
            "53359"
        ]
    },
    {
        "city": "Remagen",
        "codes": [
            "53424"
        ]
    },
    {
        "city": "Schalkenbach",
        "codes": [
            "53426"
        ]
    },
    {
        "city": "Bad Neuenahr-Ahrweiler",
        "codes": [
            "53474"
        ]
    },
    {
        "city": "Sinzig",
        "codes": [
            "53489"
        ]
    },
    {
        "city": "Bad Breisig",
        "codes": [
            "53498"
        ]
    },
    {
        "city": "Grafschaft",
        "codes": [
            "53501"
        ]
    },
    {
        "city": "Altenahr",
        "codes": [
            "53505"
        ]
    },
    {
        "city": "Ahrbrück",
        "codes": [
            "53506"
        ]
    },
    {
        "city": "Dernau",
        "codes": [
            "53507"
        ]
    },
    {
        "city": "Mayschoß",
        "codes": [
            "53508"
        ]
    },
    {
        "city": "Adenau",
        "codes": [
            "53518"
        ]
    },
    {
        "city": "Schuld",
        "codes": [
            "53520"
        ]
    },
    {
        "city": "Antweiler",
        "codes": [
            "53533"
        ]
    },
    {
        "city": "Barweiler",
        "codes": [
            "53534"
        ]
    },
    {
        "city": "Kelberg",
        "codes": [
            "53539"
        ]
    },
    {
        "city": "Linz am Rhein",
        "codes": [
            "53545"
        ]
    },
    {
        "city": "Bad Hönningen",
        "codes": [
            "53557"
        ]
    },
    {
        "city": "Vettelschoß",
        "codes": [
            "53560"
        ]
    },
    {
        "city": "Sankt Katharinen (Landkreis",
        "codes": [
            "53562"
        ]
    },
    {
        "city": "Asbach",
        "codes": [
            "53567"
        ]
    },
    {
        "city": "Unkel",
        "codes": [
            "53572"
        ]
    },
    {
        "city": "Neustadt (Wied)",
        "codes": [
            "53577"
        ]
    },
    {
        "city": "Windhagen",
        "codes": [
            "53578"
        ]
    },
    {
        "city": "Erpel",
        "codes": [
            "53579"
        ]
    },
    {
        "city": "Bad Honnef",
        "codes": [
            "53604"
        ]
    },
    {
        "city": "Rheinbreitbach",
        "codes": [
            "53619"
        ]
    },
    {
        "city": "Königswinter",
        "codes": [
            "53639"
        ]
    },
    {
        "city": "Siegburg",
        "codes": [
            "53721"
        ]
    },
    {
        "city": "Sankt Augustin",
        "codes": [
            "53757"
        ]
    },
    {
        "city": "Hennef (Sieg)",
        "codes": [
            "53773"
        ]
    },
    {
        "city": "Eitorf",
        "codes": [
            "53783"
        ]
    },
    {
        "city": "Lohmar",
        "codes": [
            "53797"
        ]
    },
    {
        "city": "Much",
        "codes": [
            "53804"
        ]
    },
    {
        "city": "Ruppichteroth",
        "codes": [
            "53809"
        ]
    },
    {
        "city": "Neunkirchen-Seelscheid",
        "codes": [
            "53819"
        ]
    },
    {
        "city": "Troisdorf",
        "codes": [
            "53840",
            "53842",
            "53844"
        ]
    },
    {
        "city": "Niederkassel",
        "codes": [
            "53859"
        ]
    },
    {
        "city": "Euskirchen",
        "codes": [
            "53879",
            "53881"
        ]
    },
    {
        "city": "Mechernich",
        "codes": [
            "53894"
        ]
    },
    {
        "city": "Bad Münstereifel",
        "codes": [
            "53902"
        ]
    },
    {
        "city": "Zülpich",
        "codes": [
            "53909"
        ]
    },
    {
        "city": "Swisttal",
        "codes": [
            "53913"
        ]
    },
    {
        "city": "Weilerswist",
        "codes": [
            "53919"
        ]
    },
    {
        "city": "Kall",
        "codes": [
            "53925"
        ]
    },
    {
        "city": "Schleiden",
        "codes": [
            "53937"
        ]
    },
    {
        "city": "Hellenthal",
        "codes": [
            "53940"
        ]
    },
    {
        "city": "Blankenheim",
        "codes": [
            "53945"
        ]
    },
    {
        "city": "Nettersheim",
        "codes": [
            "53947"
        ]
    },
    {
        "city": "Dahlem",
        "codes": [
            "53949"
        ]
    },
    {
        "city": "Trier",
        "codes": [
            "54290",
            "54292",
            "54293",
            "54294",
            "54295",
            "54296"
        ]
    },
    {
        "city": "Welschbillig",
        "codes": [
            "54298"
        ]
    },
    {
        "city": "Kordel",
        "codes": [
            "54306"
        ]
    },
    {
        "city": "Langsur",
        "codes": [
            "54308"
        ]
    },
    {
        "city": "Newel",
        "codes": [
            "54309"
        ]
    },
    {
        "city": "Ralingen",
        "codes": [
            "54310"
        ]
    },
    {
        "city": "Trierweiler",
        "codes": [
            "54311"
        ]
    },
    {
        "city": "Zemmer",
        "codes": [
            "54313"
        ]
    },
    {
        "city": "Zerf",
        "codes": [
            "54314"
        ]
    },
    {
        "city": "Pluwig",
        "codes": [
            "54316"
        ]
    },
    {
        "city": "Gusterath",
        "codes": [
            "54317"
        ]
    },
    {
        "city": "Mertesdorf",
        "codes": [
            "54318"
        ]
    },
    {
        "city": "Waldrach",
        "codes": [
            "54320"
        ]
    },
    {
        "city": "Konz",
        "codes": [
            "54329"
        ]
    },
    {
        "city": "Pellingen",
        "codes": [
            "54331"
        ]
    },
    {
        "city": "Wasserliesch",
        "codes": [
            "54332"
        ]
    },
    {
        "city": "Schweich",
        "codes": [
            "54338"
        ]
    },
    {
        "city": "Leiwen",
        "codes": [
            "54340"
        ]
    },
    {
        "city": "Fell",
        "codes": [
            "54341"
        ]
    },
    {
        "city": "Föhren",
        "codes": [
            "54343"
        ]
    },
    {
        "city": "Kenn",
        "codes": [
            "54344"
        ]
    },
    {
        "city": "Mehring",
        "codes": [
            "54346",
            "84561"
        ]
    },
    {
        "city": "Neumagen-Dhron",
        "codes": [
            "54347"
        ]
    },
    {
        "city": "Trittenheim",
        "codes": [
            "54349"
        ]
    },
    {
        "city": "Hermeskeil",
        "codes": [
            "54411"
        ]
    },
    {
        "city": "Gusenburg",
        "codes": [
            "54413"
        ]
    },
    {
        "city": "Reinsfeld",
        "codes": [
            "54421"
        ]
    },
    {
        "city": "Neuhütten",
        "codes": [
            "54422",
            "97843"
        ]
    },
    {
        "city": "Thalfang",
        "codes": [
            "54424"
        ]
    },
    {
        "city": "Malborn",
        "codes": [
            "54426"
        ]
    },
    {
        "city": "Kell am See",
        "codes": [
            "54427"
        ]
    },
    {
        "city": "Schillingen",
        "codes": [
            "54429"
        ]
    },
    {
        "city": "Saarburg",
        "codes": [
            "54439"
        ]
    },
    {
        "city": "Ayl",
        "codes": [
            "54441"
        ]
    },
    {
        "city": "Freudenburg",
        "codes": [
            "54450"
        ]
    },
    {
        "city": "Irsch",
        "codes": [
            "54451"
        ]
    },
    {
        "city": "Nittel",
        "codes": [
            "54453"
        ]
    },
    {
        "city": "Serrig",
        "codes": [
            "54455"
        ]
    },
    {
        "city": "Tawern",
        "codes": [
            "54456"
        ]
    },
    {
        "city": "Wincheringen",
        "codes": [
            "54457"
        ]
    },
    {
        "city": "Wiltingen",
        "codes": [
            "54459"
        ]
    },
    {
        "city": "Bernkastel-Kues",
        "codes": [
            "54470"
        ]
    },
    {
        "city": "Monzelfeld",
        "codes": [
            "54472"
        ]
    },
    {
        "city": "Kleinich",
        "codes": [
            "54483"
        ]
    },
    {
        "city": "Maring-Noviand",
        "codes": [
            "54484"
        ]
    },
    {
        "city": "Mülheim (Mosel)",
        "codes": [
            "54486"
        ]
    },
    {
        "city": "Wintrich",
        "codes": [
            "54487"
        ]
    },
    {
        "city": "Zeltingen-Rachtig",
        "codes": [
            "54492"
        ]
    },
    {
        "city": "Morbach",
        "codes": [
            "54497"
        ]
    },
    {
        "city": "Piesport",
        "codes": [
            "54498"
        ]
    },
    {
        "city": "Wittlich",
        "codes": [
            "54516"
        ]
    },
    {
        "city": "Osann-Monzel",
        "codes": [
            "54518"
        ]
    },
    {
        "city": "Hetzerath",
        "codes": [
            "54523"
        ]
    },
    {
        "city": "Klausen",
        "codes": [
            "54524"
        ]
    },
    {
        "city": "Landscheid",
        "codes": [
            "54526"
        ]
    },
    {
        "city": "Salmtal",
        "codes": [
            "54528"
        ]
    },
    {
        "city": "Spangdahlem",
        "codes": [
            "54529"
        ]
    },
    {
        "city": "Manderscheid",
        "codes": [
            "54531"
        ]
    },
    {
        "city": "Bettenfeld",
        "codes": [
            "54533"
        ]
    },
    {
        "city": "Großlittgen",
        "codes": [
            "54534"
        ]
    },
    {
        "city": "Kröv",
        "codes": [
            "54536"
        ]
    },
    {
        "city": "Bausendorf",
        "codes": [
            "54538"
        ]
    },
    {
        "city": "Ürzig",
        "codes": [
            "54539"
        ]
    },
    {
        "city": "Daun",
        "codes": [
            "54550"
        ]
    },
    {
        "city": "Mehren",
        "codes": [
            "54552"
        ]
    },
    {
        "city": "Gillenfeld",
        "codes": [
            "54558"
        ]
    },
    {
        "city": "Gerolstein",
        "codes": [
            "54568"
        ]
    },
    {
        "city": "Pelm",
        "codes": [
            "54570"
        ]
    },
    {
        "city": "Birresborn",
        "codes": [
            "54574"
        ]
    },
    {
        "city": "Hillesheim",
        "codes": [
            "54576",
            "67586"
        ]
    },
    {
        "city": "Walsdorf",
        "codes": [
            "54578",
            "96194"
        ]
    },
    {
        "city": "Üxheim",
        "codes": [
            "54579"
        ]
    },
    {
        "city": "Jünkerath",
        "codes": [
            "54584"
        ]
    },
    {
        "city": "Esch",
        "codes": [
            "54585"
        ]
    },
    {
        "city": "Schüller",
        "codes": [
            "54586"
        ]
    },
    {
        "city": "Lissendorf",
        "codes": [
            "54587"
        ]
    },
    {
        "city": "Stadtkyll",
        "codes": [
            "54589"
        ]
    },
    {
        "city": "Prüm",
        "codes": [
            "54595"
        ]
    },
    {
        "city": "Pronsfeld",
        "codes": [
            "54597"
        ]
    },
    {
        "city": "Bleialf",
        "codes": [
            "54608"
        ]
    },
    {
        "city": "Büdesheim",
        "codes": [
            "54610"
        ]
    },
    {
        "city": "Hallschlag",
        "codes": [
            "54611"
        ]
    },
    {
        "city": "Lasel",
        "codes": [
            "54612"
        ]
    },
    {
        "city": "Schönecken",
        "codes": [
            "54614"
        ]
    },
    {
        "city": "Winterspelt",
        "codes": [
            "54616"
        ]
    },
    {
        "city": "Lützkampen",
        "codes": [
            "54617"
        ]
    },
    {
        "city": "Üttfeld",
        "codes": [
            "54619"
        ]
    },
    {
        "city": "Bitburg",
        "codes": [
            "54634"
        ]
    },
    {
        "city": "Rittersdorf",
        "codes": [
            "54636"
        ]
    },
    {
        "city": "Bettingen",
        "codes": [
            "54646"
        ]
    },
    {
        "city": "Dudeldorf",
        "codes": [
            "54647"
        ]
    },
    {
        "city": "Waxweiler",
        "codes": [
            "54649"
        ]
    },
    {
        "city": "Kyllburg",
        "codes": [
            "54655"
        ]
    },
    {
        "city": "Badem",
        "codes": [
            "54657"
        ]
    },
    {
        "city": "Speicher",
        "codes": [
            "54662"
        ]
    },
    {
        "city": "Preist",
        "codes": [
            "54664"
        ]
    },
    {
        "city": "Irrel",
        "codes": [
            "54666"
        ]
    },
    {
        "city": "Ferschweiler",
        "codes": [
            "54668"
        ]
    },
    {
        "city": "Bollendorf",
        "codes": [
            "54669"
        ]
    },
    {
        "city": "Neuerburg",
        "codes": [
            "54673"
        ]
    },
    {
        "city": "Körperich",
        "codes": [
            "54675"
        ]
    },
    {
        "city": "Arzfeld",
        "codes": [
            "54687"
        ]
    },
    {
        "city": "Daleiden",
        "codes": [
            "54689"
        ]
    },
    {
        "city": "Mainz",
        "codes": [
            "55116",
            "55118",
            "55120",
            "55122",
            "55124",
            "55126",
            "55127",
            "55128",
            "55129",
            "55130",
            "55131"
        ]
    },
    {
        "city": "Ingelheim am Rhein",
        "codes": [
            "55218"
        ]
    },
    {
        "city": "Alzey",
        "codes": [
            "55232"
        ]
    },
    {
        "city": "Albig",
        "codes": [
            "55234"
        ]
    },
    {
        "city": "Flonheim",
        "codes": [
            "55237"
        ]
    },
    {
        "city": "Gau-Odernheim",
        "codes": [
            "55239"
        ]
    },
    {
        "city": "Wiesbaden",
        "codes": [
            "55246",
            "55252",
            "65183",
            "65185",
            "65187",
            "65189",
            "65191",
            "65193",
            "65195",
            "65197",
            "65199",
            "65201",
            "65203",
            "65205",
            "65207"
        ]
    },
    {
        "city": "Budenheim",
        "codes": [
            "55257"
        ]
    },
    {
        "city": "Heidesheim am Rhein",
        "codes": [
            "55262"
        ]
    },
    {
        "city": "Wackernheim",
        "codes": [
            "55263"
        ]
    },
    {
        "city": "Nieder-Olm",
        "codes": [
            "55268"
        ]
    },
    {
        "city": "Ober-Olm",
        "codes": [
            "55270"
        ]
    },
    {
        "city": "Stadecken-Elsheim",
        "codes": [
            "55271"
        ]
    },
    {
        "city": "Oppenheim",
        "codes": [
            "55276"
        ]
    },
    {
        "city": "Mommenheim",
        "codes": [
            "55278"
        ]
    },
    {
        "city": "Nierstein",
        "codes": [
            "55283"
        ]
    },
    {
        "city": "Wörrstadt",
        "codes": [
            "55286"
        ]
    },
    {
        "city": "Armsheim",
        "codes": [
            "55288"
        ]
    },
    {
        "city": "Saulheim",
        "codes": [
            "55291"
        ]
    },
    {
        "city": "Bodenheim",
        "codes": [
            "55294"
        ]
    },
    {
        "city": "Harxheim",
        "codes": [
            "55296"
        ]
    },
    {
        "city": "Nackenheim",
        "codes": [
            "55299"
        ]
    },
    {
        "city": "Bingen am Rhein",
        "codes": [
            "55411"
        ]
    },
    {
        "city": "Weiler bei Bingen",
        "codes": [
            "55413"
        ]
    },
    {
        "city": "Bacharach",
        "codes": [
            "55422"
        ]
    },
    {
        "city": "Münster-Sarmsheim",
        "codes": [
            "55424"
        ]
    },
    {
        "city": "Waldalgesheim",
        "codes": [
            "55425"
        ]
    },
    {
        "city": "Oberwesel",
        "codes": [
            "55430"
        ]
    },
    {
        "city": "Niederburg",
        "codes": [
            "55432"
        ]
    },
    {
        "city": "Gau-Algesheim",
        "codes": [
            "55435"
        ]
    },
    {
        "city": "Ockenheim",
        "codes": [
            "55437"
        ]
    },
    {
        "city": "Stromberg",
        "codes": [
            "55442"
        ]
    },
    {
        "city": "Seibersbach",
        "codes": [
            "55444"
        ]
    },
    {
        "city": "Langenlonsheim",
        "codes": [
            "55450"
        ]
    },
    {
        "city": "Guldental",
        "codes": [
            "55452"
        ]
    },
    {
        "city": "Gensingen",
        "codes": [
            "55457"
        ]
    },
    {
        "city": "Aspisheim",
        "codes": [
            "55459"
        ]
    },
    {
        "city": "Simmern / Hunsrück",
        "codes": [
            "55469"
        ]
    },
    {
        "city": "Tiefenbach",
        "codes": [
            "55471",
            "84184",
            "93464",
            "94113"
        ]
    },
    {
        "city": "Kirchberg (Hunsrück)",
        "codes": [
            "55481"
        ]
    },
    {
        "city": "Dickenschied",
        "codes": [
            "55483"
        ]
    },
    {
        "city": "Sohren",
        "codes": [
            "55487"
        ]
    },
    {
        "city": "Gemünden",
        "codes": [
            "55490"
        ]
    },
    {
        "city": "Büchenbeuren",
        "codes": [
            "55491"
        ]
    },
    {
        "city": "Rheinböllen",
        "codes": [
            "55494"
        ]
    },
    {
        "city": "Argenthal",
        "codes": [
            "55496"
        ]
    },
    {
        "city": "Ellern (Hunsrück)",
        "codes": [
            "55497"
        ]
    },
    {
        "city": "Riesweiler",
        "codes": [
            "55499"
        ]
    },
    {
        "city": "Bad Kreuznach",
        "codes": [
            "55543",
            "55545"
        ]
    },
    {
        "city": "Hackenheim",
        "codes": [
            "55546"
        ]
    },
    {
        "city": "Bretzenheim",
        "codes": [
            "55559"
        ]
    },
    {
        "city": "Sobernheim",
        "codes": [
            "55566"
        ]
    },
    {
        "city": "Staudernheim",
        "codes": [
            "55568"
        ]
    },
    {
        "city": "Monzingen",
        "codes": [
            "55569"
        ]
    },
    {
        "city": "Odernheim am Glan",
        "codes": [
            "55571"
        ]
    },
    {
        "city": "Sprendlingen",
        "codes": [
            "55576"
        ]
    },
    {
        "city": "Wallertheim",
        "codes": [
            "55578"
        ]
    },
    {
        "city": "Bad Münster am Stein-Ebernbu",
        "codes": [
            "55583"
        ]
    },
    {
        "city": "Norheim",
        "codes": [
            "55585"
        ]
    },
    {
        "city": "Meisenheim",
        "codes": [
            "55590"
        ]
    },
    {
        "city": "Rehborn",
        "codes": [
            "55592"
        ]
    },
    {
        "city": "Rüdesheim",
        "codes": [
            "55593"
        ]
    },
    {
        "city": "Hargesheim",
        "codes": [
            "55595"
        ]
    },
    {
        "city": "Waldböckelheim",
        "codes": [
            "55596"
        ]
    },
    {
        "city": "Wöllstein",
        "codes": [
            "55597"
        ]
    },
    {
        "city": "Gau-Bickelheim",
        "codes": [
            "55599"
        ]
    },
    {
        "city": "Kirn",
        "codes": [
            "55606"
        ]
    },
    {
        "city": "Simmertal",
        "codes": [
            "55618"
        ]
    },
    {
        "city": "Hennweiler",
        "codes": [
            "55619"
        ]
    },
    {
        "city": "Hundsbach",
        "codes": [
            "55621"
        ]
    },
    {
        "city": "Rhaunen",
        "codes": [
            "55624"
        ]
    },
    {
        "city": "Bundenbach",
        "codes": [
            "55626"
        ]
    },
    {
        "city": "Merxheim",
        "codes": [
            "55627"
        ]
    },
    {
        "city": "Seesbach",
        "codes": [
            "55629"
        ]
    },
    {
        "city": "Idar-Oberstein",
        "codes": [
            "55743"
        ]
    },
    {
        "city": "Herrstein",
        "codes": [
            "55756"
        ]
    },
    {
        "city": "Niederwörresbach",
        "codes": [
            "55758"
        ]
    },
    {
        "city": "Birkenfeld",
        "codes": [
            "55765",
            "75217",
            "97834"
        ]
    },
    {
        "city": "Brücken",
        "codes": [
            "55767"
        ]
    },
    {
        "city": "Hoppstädten-Weiersbach",
        "codes": [
            "55768"
        ]
    },
    {
        "city": "Baumholder",
        "codes": [
            "55774"
        ]
    },
    {
        "city": "Ruschberg",
        "codes": [
            "55776"
        ]
    },
    {
        "city": "Berschweiler bei Baumholder",
        "codes": [
            "55777"
        ]
    },
    {
        "city": "Koblenz",
        "codes": [
            "56068",
            "56070",
            "56072",
            "56073",
            "56075",
            "56076",
            "56077"
        ]
    },
    {
        "city": "Lahnstein",
        "codes": [
            "56112"
        ]
    },
    {
        "city": "Bad Ems",
        "codes": [
            "56130"
        ]
    },
    {
        "city": "Dausenau",
        "codes": [
            "56132"
        ]
    },
    {
        "city": "Fachbach",
        "codes": [
            "56133"
        ]
    },
    {
        "city": "Boppard",
        "codes": [
            "56154"
        ]
    },
    {
        "city": "Bendorf",
        "codes": [
            "56170"
        ]
    },
    {
        "city": "Vallendar",
        "codes": [
            "56179"
        ]
    },
    {
        "city": "Urbar",
        "codes": [
            "56182"
        ]
    },
    {
        "city": "Weitersburg",
        "codes": [
            "56191"
        ]
    },
    {
        "city": "Höhr-Grenzhausen",
        "codes": [
            "56203"
        ]
    },
    {
        "city": "Hillscheid",
        "codes": [
            "56204"
        ]
    },
    {
        "city": "Hilgert",
        "codes": [
            "56206"
        ]
    },
    {
        "city": "Mülheim-Kärlich",
        "codes": [
            "56218"
        ]
    },
    {
        "city": "Urmitz",
        "codes": [
            "56220"
        ]
    },
    {
        "city": "Ransbach-Baumbach",
        "codes": [
            "56235"
        ]
    },
    {
        "city": "Nauort",
        "codes": [
            "56237"
        ]
    },
    {
        "city": "Selters (Westerwald)",
        "codes": [
            "56242"
        ]
    },
    {
        "city": "Ötzingen",
        "codes": [
            "56244"
        ]
    },
    {
        "city": "Herschbach",
        "codes": [
            "56249"
        ]
    },
    {
        "city": "Treis-Karden",
        "codes": [
            "56253"
        ]
    },
    {
        "city": "Müden (Mosel)",
        "codes": [
            "56254"
        ]
    },
    {
        "city": "Dierdorf",
        "codes": [
            "56269"
        ]
    },
    {
        "city": "Kleinmaischeid",
        "codes": [
            "56271"
        ]
    },
    {
        "city": "Großmaischeid",
        "codes": [
            "56276"
        ]
    },
    {
        "city": "Emmelshausen",
        "codes": [
            "56281"
        ]
    },
    {
        "city": "Gondershausen",
        "codes": [
            "56283"
        ]
    },
    {
        "city": "Kastellaun",
        "codes": [
            "56288"
        ]
    },
    {
        "city": "Beltheim",
        "codes": [
            "56290"
        ]
    },
    {
        "city": "Leiningen",
        "codes": [
            "56291"
        ]
    },
    {
        "city": "Münstermaifeld",
        "codes": [
            "56294"
        ]
    },
    {
        "city": "Lonnig",
        "codes": [
            "56295"
        ]
    },
    {
        "city": "Ochtendung",
        "codes": [
            "56299"
        ]
    },
    {
        "city": "Puderbach",
        "codes": [
            "56305"
        ]
    },
    {
        "city": "Dürrholz",
        "codes": [
            "56307"
        ]
    },
    {
        "city": "Raubach",
        "codes": [
            "56316"
        ]
    },
    {
        "city": "Urbach",
        "codes": [
            "56317",
            "73660"
        ]
    },
    {
        "city": "Rhens",
        "codes": [
            "56321"
        ]
    },
    {
        "city": "Spay",
        "codes": [
            "56322"
        ]
    },
    {
        "city": "Waldesch",
        "codes": [
            "56323"
        ]
    },
    {
        "city": "Sankt Goar",
        "codes": [
            "56329"
        ]
    },
    {
        "city": "Kobern-Gondorf",
        "codes": [
            "56330"
        ]
    },
    {
        "city": "Dieblich",
        "codes": [
            "56332"
        ]
    },
    {
        "city": "Winningen",
        "codes": [
            "56333"
        ]
    },
    {
        "city": "Neuhäusel",
        "codes": [
            "56335"
        ]
    },
    {
        "city": "Eitelborn",
        "codes": [
            "56337"
        ]
    },
    {
        "city": "Braubach",
        "codes": [
            "56338"
        ]
    },
    {
        "city": "Osterspai",
        "codes": [
            "56340"
        ]
    },
    {
        "city": "Kamp-Bornhofen",
        "codes": [
            "56341"
        ]
    },
    {
        "city": "Sankt Goarshausen",
        "codes": [
            "56346"
        ]
    },
    {
        "city": "Bornich",
        "codes": [
            "56348"
        ]
    },
    {
        "city": "Kaub",
        "codes": [
            "56349"
        ]
    },
    {
        "city": "Nastätten",
        "codes": [
            "56355"
        ]
    },
    {
        "city": "Miehlen",
        "codes": [
            "56357"
        ]
    },
    {
        "city": "Katzenelnbogen",
        "codes": [
            "56368"
        ]
    },
    {
        "city": "Schönborn",
        "codes": [
            "56370"
        ]
    },
    {
        "city": "Nassau",
        "codes": [
            "56377"
        ]
    },
    {
        "city": "Singhofen",
        "codes": [
            "56379"
        ]
    },
    {
        "city": "Montabaur",
        "codes": [
            "56410"
        ]
    },
    {
        "city": "Hundsangen",
        "codes": [
            "56414"
        ]
    },
    {
        "city": "Wirges",
        "codes": [
            "56422"
        ]
    },
    {
        "city": "Ebernhahn",
        "codes": [
            "56424"
        ]
    },
    {
        "city": "Siershahn",
        "codes": [
            "56427"
        ]
    },
    {
        "city": "Dernbach (Westerwald)",
        "codes": [
            "56428"
        ]
    },
    {
        "city": "Westerburg",
        "codes": [
            "56457"
        ]
    },
    {
        "city": "Langenhahn",
        "codes": [
            "56459"
        ]
    },
    {
        "city": "Höhn",
        "codes": [
            "56462"
        ]
    },
    {
        "city": "Bad Marienberg (Westerwald)",
        "codes": [
            "56470"
        ]
    },
    {
        "city": "Hof",
        "codes": [
            "56472",
            "95028",
            "95030",
            "95032"
        ]
    },
    {
        "city": "Rennerod",
        "codes": [
            "56477"
        ]
    },
    {
        "city": "Seck",
        "codes": [
            "56479"
        ]
    },
    {
        "city": "Neuwied",
        "codes": [
            "56564",
            "56566",
            "56567"
        ]
    },
    {
        "city": "Weißenthurm",
        "codes": [
            "56575"
        ]
    },
    {
        "city": "Rengsdorf",
        "codes": [
            "56579"
        ]
    },
    {
        "city": "Melsbach",
        "codes": [
            "56581"
        ]
    },
    {
        "city": "Anhausen",
        "codes": [
            "56584"
        ]
    },
    {
        "city": "Straßenhaus",
        "codes": [
            "56587"
        ]
    },
    {
        "city": "Waldbreitbach",
        "codes": [
            "56588"
        ]
    },
    {
        "city": "Niederbreitbach",
        "codes": [
            "56589"
        ]
    },
    {
        "city": "Horhausen (Westerwald)",
        "codes": [
            "56593"
        ]
    },
    {
        "city": "Willroth",
        "codes": [
            "56594"
        ]
    },
    {
        "city": "Rheinbrohl",
        "codes": [
            "56598"
        ]
    },
    {
        "city": "Leutesdorf",
        "codes": [
            "56599"
        ]
    },
    {
        "city": "Andernach",
        "codes": [
            "56626"
        ]
    },
    {
        "city": "Kretz",
        "codes": [
            "56630"
        ]
    },
    {
        "city": "Plaidt",
        "codes": [
            "56637"
        ]
    },
    {
        "city": "Kruft",
        "codes": [
            "56642"
        ]
    },
    {
        "city": "Nickenich",
        "codes": [
            "56645"
        ]
    },
    {
        "city": "Saffig",
        "codes": [
            "56648"
        ]
    },
    {
        "city": "Niederzissen",
        "codes": [
            "56651"
        ]
    },
    {
        "city": "Wehr",
        "codes": [
            "56653",
            "79664"
        ]
    },
    {
        "city": "Brohl-Lützing",
        "codes": [
            "56656"
        ]
    },
    {
        "city": "Burgbrohl",
        "codes": [
            "56659"
        ]
    },
    {
        "city": "Mayen",
        "codes": [
            "56727"
        ]
    },
    {
        "city": "Ettringen",
        "codes": [
            "56729",
            "86833"
        ]
    },
    {
        "city": "Kottenheim",
        "codes": [
            "56736"
        ]
    },
    {
        "city": "Mendig",
        "codes": [
            "56743"
        ]
    },
    {
        "city": "Bell",
        "codes": [
            "56745"
        ]
    },
    {
        "city": "Kempenich",
        "codes": [
            "56746"
        ]
    },
    {
        "city": "Polch",
        "codes": [
            "56751"
        ]
    },
    {
        "city": "Mertloch",
        "codes": [
            "56753"
        ]
    },
    {
        "city": "Binningen",
        "codes": [
            "56754"
        ]
    },
    {
        "city": "Kaisersesch",
        "codes": [
            "56759"
        ]
    },
    {
        "city": "Düngenheim",
        "codes": [
            "56761"
        ]
    },
    {
        "city": "Ulmen",
        "codes": [
            "56766"
        ]
    },
    {
        "city": "Uersfeld",
        "codes": [
            "56767"
        ]
    },
    {
        "city": "Retterath",
        "codes": [
            "56769"
        ]
    },
    {
        "city": "Cochem",
        "codes": [
            "56812"
        ]
    },
    {
        "city": "Ediger-Eller",
        "codes": [
            "56814"
        ]
    },
    {
        "city": "Klotten",
        "codes": [
            "56818"
        ]
    },
    {
        "city": "Senheim",
        "codes": [
            "56820"
        ]
    },
    {
        "city": "Ellenz-Poltersdorf",
        "codes": [
            "56821"
        ]
    },
    {
        "city": "Büchel",
        "codes": [
            "56823"
        ]
    },
    {
        "city": "Gevenich",
        "codes": [
            "56825"
        ]
    },
    {
        "city": "Lutzerath",
        "codes": [
            "56826"
        ]
    },
    {
        "city": "Alflen",
        "codes": [
            "56828"
        ]
    },
    {
        "city": "Pommern",
        "codes": [
            "56829"
        ]
    },
    {
        "city": "Traben-Trarbach",
        "codes": [
            "56841"
        ]
    },
    {
        "city": "Irmenach",
        "codes": [
            "56843"
        ]
    },
    {
        "city": "Enkirch",
        "codes": [
            "56850"
        ]
    },
    {
        "city": "Zell (Mosel)",
        "codes": [
            "56856"
        ]
    },
    {
        "city": "Peterswald-Löffelscheid",
        "codes": [
            "56858"
        ]
    },
    {
        "city": "Bullay",
        "codes": [
            "56859"
        ]
    },
    {
        "city": "Reil",
        "codes": [
            "56861"
        ]
    },
    {
        "city": "Pünderich",
        "codes": [
            "56862"
        ]
    },
    {
        "city": "Bad Bertrich",
        "codes": [
            "56864"
        ]
    },
    {
        "city": "Blankenrath",
        "codes": [
            "56865"
        ]
    },
    {
        "city": "Briedel",
        "codes": [
            "56867"
        ]
    },
    {
        "city": "Mastershausen",
        "codes": [
            "56869"
        ]
    },
    {
        "city": "Siegen",
        "codes": [
            "57072",
            "57074",
            "57076",
            "57078",
            "57080"
        ]
    },
    {
        "city": "Kreuztal",
        "codes": [
            "57223"
        ]
    },
    {
        "city": "Wilnsdorf",
        "codes": [
            "57234"
        ]
    },
    {
        "city": "Netphen",
        "codes": [
            "57250"
        ]
    },
    {
        "city": "Freudenberg",
        "codes": [
            "57258",
            "92272",
            "97896"
        ]
    },
    {
        "city": "Hilchenbach",
        "codes": [
            "57271"
        ]
    },
    {
        "city": "Neunkirchen",
        "codes": [
            "57290",
            "63930",
            "66538",
            "66539",
            "66540",
            "74867"
        ]
    },
    {
        "city": "Burbach",
        "codes": [
            "57299"
        ]
    },
    {
        "city": "Bad Berleburg",
        "codes": [
            "57319"
        ]
    },
    {
        "city": "Bad Laasphe",
        "codes": [
            "57334"
        ]
    },
    {
        "city": "Erndtebrück",
        "codes": [
            "57339"
        ]
    },
    {
        "city": "Lennestadt",
        "codes": [
            "57368"
        ]
    },
    {
        "city": "Schmallenberg",
        "codes": [
            "57392"
        ]
    },
    {
        "city": "Kirchhundem",
        "codes": [
            "57399"
        ]
    },
    {
        "city": "Finnentrop",
        "codes": [
            "57413"
        ]
    },
    {
        "city": "Attendorn",
        "codes": [
            "57439"
        ]
    },
    {
        "city": "Olpe",
        "codes": [
            "57462"
        ]
    },
    {
        "city": "Wenden",
        "codes": [
            "57482"
        ]
    },
    {
        "city": "Drolshagen",
        "codes": [
            "57489"
        ]
    },
    {
        "city": "Betzdorf",
        "codes": [
            "57518"
        ]
    },
    {
        "city": "Steinebach / Sieg",
        "codes": [
            "57520"
        ]
    },
    {
        "city": "Wissen",
        "codes": [
            "57537"
        ]
    },
    {
        "city": "Fürthen",
        "codes": [
            "57539"
        ]
    },
    {
        "city": "Kirchen (Sieg)",
        "codes": [
            "57548"
        ]
    },
    {
        "city": "Mudersbach",
        "codes": [
            "57555"
        ]
    },
    {
        "city": "Herdorf",
        "codes": [
            "57562"
        ]
    },
    {
        "city": "Daaden",
        "codes": [
            "57567"
        ]
    },
    {
        "city": "Niederfischbach",
        "codes": [
            "57572"
        ]
    },
    {
        "city": "Hamm (Sieg)",
        "codes": [
            "57577"
        ]
    },
    {
        "city": "Elkenroth",
        "codes": [
            "57578"
        ]
    },
    {
        "city": "Gebhardshain",
        "codes": [
            "57580"
        ]
    },
    {
        "city": "Katzwinkel (Sieg)",
        "codes": [
            "57581"
        ]
    },
    {
        "city": "Nauroth",
        "codes": [
            "57583"
        ]
    },
    {
        "city": "Scheuerfeld",
        "codes": [
            "57584"
        ]
    },
    {
        "city": "Weitefeld",
        "codes": [
            "57586"
        ]
    },
    {
        "city": "Birken-Honigsessen",
        "codes": [
            "57587"
        ]
    },
    {
        "city": "Pracht",
        "codes": [
            "57589"
        ]
    },
    {
        "city": "Altenkirchen (Westerwald)",
        "codes": [
            "57610"
        ]
    },
    {
        "city": "Birnbach",
        "codes": [
            "57612"
        ]
    },
    {
        "city": "Steimel",
        "codes": [
            "57614"
        ]
    },
    {
        "city": "Hachenburg",
        "codes": [
            "57627"
        ]
    },
    {
        "city": "Malberg",
        "codes": [
            "57629"
        ]
    },
    {
        "city": "Flammersfeld",
        "codes": [
            "57632"
        ]
    },
    {
        "city": "Weyerbusch",
        "codes": [
            "57635"
        ]
    },
    {
        "city": "Mammelzen",
        "codes": [
            "57636"
        ]
    },
    {
        "city": "Neitersen",
        "codes": [
            "57638"
        ]
    },
    {
        "city": "Oberdreis",
        "codes": [
            "57639"
        ]
    },
    {
        "city": "Oberlahr",
        "codes": [
            "57641"
        ]
    },
    {
        "city": "Alpenrod",
        "codes": [
            "57642"
        ]
    },
    {
        "city": "Hattert",
        "codes": [
            "57644"
        ]
    },
    {
        "city": "Nister",
        "codes": [
            "57645"
        ]
    },
    {
        "city": "Nistertal",
        "codes": [
            "57647"
        ]
    },
    {
        "city": "Unnau",
        "codes": [
            "57648"
        ]
    },
    {
        "city": "Hagen",
        "codes": [
            "58089",
            "58091",
            "58093",
            "58095",
            "58097",
            "58099",
            "58119",
            "58135"
        ]
    },
    {
        "city": "Schwerte",
        "codes": [
            "58239"
        ]
    },
    {
        "city": "Ennepetal",
        "codes": [
            "58256"
        ]
    },
    {
        "city": "Gevelsberg",
        "codes": [
            "58285"
        ]
    },
    {
        "city": "Wetter (Ruhr)",
        "codes": [
            "58300"
        ]
    },
    {
        "city": "Herdecke",
        "codes": [
            "58313"
        ]
    },
    {
        "city": "Schwelm",
        "codes": [
            "58332"
        ]
    },
    {
        "city": "Breckerfeld",
        "codes": [
            "58339"
        ]
    },
    {
        "city": "Witten",
        "codes": [
            "58452",
            "58453",
            "58454",
            "58455",
            "58456"
        ]
    },
    {
        "city": "Lüdenscheid",
        "codes": [
            "58507",
            "58509",
            "58511",
            "58513",
            "58515"
        ]
    },
    {
        "city": "Meinerzhagen",
        "codes": [
            "58540"
        ]
    },
    {
        "city": "Halver",
        "codes": [
            "58553"
        ]
    },
    {
        "city": "Kierspe",
        "codes": [
            "58566"
        ]
    },
    {
        "city": "Schalksmühle",
        "codes": [
            "58579"
        ]
    },
    {
        "city": "Iserlohn",
        "codes": [
            "58636",
            "58638",
            "58640",
            "58642",
            "58644"
        ]
    },
    {
        "city": "Hemer",
        "codes": [
            "58675"
        ]
    },
    {
        "city": "Menden (Sauerland)",
        "codes": [
            "58706",
            "58708",
            "58710"
        ]
    },
    {
        "city": "Fröndenberg",
        "codes": [
            "58730"
        ]
    },
    {
        "city": "Wickede (Ruhr)",
        "codes": [
            "58739"
        ]
    },
    {
        "city": "Altena",
        "codes": [
            "58762"
        ]
    },
    {
        "city": "Nachrodt-Wiblingwerde",
        "codes": [
            "58769"
        ]
    },
    {
        "city": "Werdohl",
        "codes": [
            "58791"
        ]
    },
    {
        "city": "Balve",
        "codes": [
            "58802"
        ]
    },
    {
        "city": "Neuenrade",
        "codes": [
            "58809"
        ]
    },
    {
        "city": "Plettenberg",
        "codes": [
            "58840"
        ]
    },
    {
        "city": "Herscheid",
        "codes": [
            "58849"
        ]
    },
    {
        "city": "Hamm",
        "codes": [
            "59063",
            "59065",
            "59067",
            "59069",
            "59071",
            "59073",
            "59075",
            "59077",
            "67580"
        ]
    },
    {
        "city": "Kamen",
        "codes": [
            "59174"
        ]
    },
    {
        "city": "Bergkamen",
        "codes": [
            "59192"
        ]
    },
    {
        "city": "Bönen",
        "codes": [
            "59199"
        ]
    },
    {
        "city": "Ahlen",
        "codes": [
            "59227",
            "59229"
        ]
    },
    {
        "city": "Beckum",
        "codes": [
            "59269"
        ]
    },
    {
        "city": "Oelde",
        "codes": [
            "59302"
        ]
    },
    {
        "city": "Ennigerloh",
        "codes": [
            "59320"
        ]
    },
    {
        "city": "Wadersloh",
        "codes": [
            "59329"
        ]
    },
    {
        "city": "Lüdinghausen",
        "codes": [
            "59348"
        ]
    },
    {
        "city": "Werne",
        "codes": [
            "59368"
        ]
    },
    {
        "city": "Selm",
        "codes": [
            "59379"
        ]
    },
    {
        "city": "Ascheberg",
        "codes": [
            "59387"
        ]
    },
    {
        "city": "Nordkirchen",
        "codes": [
            "59394"
        ]
    },
    {
        "city": "Olfen",
        "codes": [
            "59399"
        ]
    },
    {
        "city": "Unna",
        "codes": [
            "59423",
            "59425",
            "59427"
        ]
    },
    {
        "city": "Holzwickede",
        "codes": [
            "59439"
        ]
    },
    {
        "city": "Werl",
        "codes": [
            "59457"
        ]
    },
    {
        "city": "Ense",
        "codes": [
            "59469"
        ]
    },
    {
        "city": "Soest",
        "codes": [
            "59494"
        ]
    },
    {
        "city": "Bad Sassendorf",
        "codes": [
            "59505"
        ]
    },
    {
        "city": "Lippetal",
        "codes": [
            "59510"
        ]
    },
    {
        "city": "Welver",
        "codes": [
            "59514"
        ]
    },
    {
        "city": "Möhnesee",
        "codes": [
            "59519"
        ]
    },
    {
        "city": "Lippstadt",
        "codes": [
            "59555",
            "59556",
            "59557",
            "59558"
        ]
    },
    {
        "city": "Warstein",
        "codes": [
            "59581"
        ]
    },
    {
        "city": "Geseke",
        "codes": [
            "59590"
        ]
    },
    {
        "city": "Erwitte",
        "codes": [
            "59597"
        ]
    },
    {
        "city": "Rüthen",
        "codes": [
            "59602"
        ]
    },
    {
        "city": "Anröchte",
        "codes": [
            "59609"
        ]
    },
    {
        "city": "Arnsberg",
        "codes": [
            "59755",
            "59757",
            "59759",
            "59821",
            "59823"
        ]
    },
    {
        "city": "Sundern (Sauerland)",
        "codes": [
            "59846"
        ]
    },
    {
        "city": "Meschede",
        "codes": [
            "59872"
        ]
    },
    {
        "city": "Eslohe (Sauerland)",
        "codes": [
            "59889"
        ]
    },
    {
        "city": "Bestwig",
        "codes": [
            "59909"
        ]
    },
    {
        "city": "Brilon",
        "codes": [
            "59929"
        ]
    },
    {
        "city": "Olsberg",
        "codes": [
            "59939"
        ]
    },
    {
        "city": "Winterberg",
        "codes": [
            "59955"
        ]
    },
    {
        "city": "Medebach",
        "codes": [
            "59964"
        ]
    },
    {
        "city": "Hallenberg",
        "codes": [
            "59969"
        ]
    },
    {
        "city": "Frankfurt am Main",
        "codes": [
            "60306",
            "60308",
            "60310",
            "60311",
            "60313",
            "60314",
            "60316",
            "60318",
            "60320",
            "60322",
            "60323",
            "60325",
            "60326",
            "60327",
            "60329",
            "60385",
            "60386",
            "60388",
            "60389",
            "60431",
            "60433",
            "60435",
            "60437",
            "60438",
            "60439",
            "60486",
            "60487",
            "60488",
            "60489",
            "60528",
            "60529",
            "60549",
            "60594",
            "60596",
            "60598",
            "60599",
            "65929",
            "65931",
            "65933",
            "65934",
            "65936"
        ]
    },
    {
        "city": "Bad Vilbel",
        "codes": [
            "61118"
        ]
    },
    {
        "city": "Nidderau",
        "codes": [
            "61130"
        ]
    },
    {
        "city": "Schöneck",
        "codes": [
            "61137"
        ]
    },
    {
        "city": "Niederdorfelden",
        "codes": [
            "61138"
        ]
    },
    {
        "city": "Friedberg (Hessen)",
        "codes": [
            "61169"
        ]
    },
    {
        "city": "Karben",
        "codes": [
            "61184"
        ]
    },
    {
        "city": "Rosbach vor der Höhe",
        "codes": [
            "61191"
        ]
    },
    {
        "city": "Niddatal",
        "codes": [
            "61194"
        ]
    },
    {
        "city": "Florstadt",
        "codes": [
            "61197"
        ]
    },
    {
        "city": "Wölfersheim",
        "codes": [
            "61200"
        ]
    },
    {
        "city": "Reichelsheim (Wetterau)",
        "codes": [
            "61203"
        ]
    },
    {
        "city": "Wöllstadt",
        "codes": [
            "61206"
        ]
    },
    {
        "city": "Echzell",
        "codes": [
            "61209"
        ]
    },
    {
        "city": "Bad Nauheim",
        "codes": [
            "61231"
        ]
    },
    {
        "city": "Ober-Mörlen",
        "codes": [
            "61239"
        ]
    },
    {
        "city": "Usingen",
        "codes": [
            "61250"
        ]
    },
    {
        "city": "Neu-Anspach",
        "codes": [
            "61267"
        ]
    },
    {
        "city": "Wehrheim",
        "codes": [
            "61273"
        ]
    },
    {
        "city": "Weilrod",
        "codes": [
            "61276"
        ]
    },
    {
        "city": "Grävenwiesbach",
        "codes": [
            "61279"
        ]
    },
    {
        "city": "Bad Homburg vor der Höhe",
        "codes": [
            "61348",
            "61350",
            "61352"
        ]
    },
    {
        "city": "Friedrichsdorf",
        "codes": [
            "61381"
        ]
    },
    {
        "city": "Schmitten",
        "codes": [
            "61389"
        ]
    },
    {
        "city": "Oberursel (Taunus)",
        "codes": [
            "61440"
        ]
    },
    {
        "city": "Steinbach (Taunus)",
        "codes": [
            "61449"
        ]
    },
    {
        "city": "Königstein im Taunus",
        "codes": [
            "61462"
        ]
    },
    {
        "city": "Kronberg im Taunus",
        "codes": [
            "61476"
        ]
    },
    {
        "city": "Glashütten",
        "codes": [
            "61479",
            "95496"
        ]
    },
    {
        "city": "Offenbach am Main",
        "codes": [
            "63065",
            "63067",
            "63069",
            "63071",
            "63073",
            "63075"
        ]
    },
    {
        "city": "Rodgau",
        "codes": [
            "63110"
        ]
    },
    {
        "city": "Dietzenbach",
        "codes": [
            "63128"
        ]
    },
    {
        "city": "Heusenstamm",
        "codes": [
            "63150"
        ]
    },
    {
        "city": "Mühlheim am Main",
        "codes": [
            "63165"
        ]
    },
    {
        "city": "Obertshausen",
        "codes": [
            "63179"
        ]
    },
    {
        "city": "Neu-Isenburg",
        "codes": [
            "63263"
        ]
    },
    {
        "city": "Dreieich",
        "codes": [
            "63303"
        ]
    },
    {
        "city": "Rödermark",
        "codes": [
            "63322"
        ]
    },
    {
        "city": "Egelsbach",
        "codes": [
            "63329"
        ]
    },
    {
        "city": "Hanau",
        "codes": [
            "63450",
            "63452",
            "63454",
            "63456",
            "63457"
        ]
    },
    {
        "city": "Maintal",
        "codes": [
            "63477"
        ]
    },
    {
        "city": "Bruchköbel",
        "codes": [
            "63486"
        ]
    },
    {
        "city": "Seligenstadt",
        "codes": [
            "63500"
        ]
    },
    {
        "city": "Langenselbold",
        "codes": [
            "63505"
        ]
    },
    {
        "city": "Hainburg",
        "codes": [
            "63512"
        ]
    },
    {
        "city": "Rodenbach",
        "codes": [
            "63517",
            "67688"
        ]
    },
    {
        "city": "Erlensee",
        "codes": [
            "63526"
        ]
    },
    {
        "city": "Mainhausen",
        "codes": [
            "63533"
        ]
    },
    {
        "city": "Großkrotzenburg",
        "codes": [
            "63538"
        ]
    },
    {
        "city": "Neuberg",
        "codes": [
            "63543"
        ]
    },
    {
        "city": "Hammersbach",
        "codes": [
            "63546"
        ]
    },
    {
        "city": "Gelnhausen",
        "codes": [
            "63571"
        ]
    },
    {
        "city": "Freigericht",
        "codes": [
            "63579"
        ]
    },
    {
        "city": "Gründau",
        "codes": [
            "63584"
        ]
    },
    {
        "city": "Linsengericht",
        "codes": [
            "63589"
        ]
    },
    {
        "city": "Hasselroth",
        "codes": [
            "63594"
        ]
    },
    {
        "city": "Biebergemünd",
        "codes": [
            "63599"
        ]
    },
    {
        "city": "Wächtersbach",
        "codes": [
            "63607"
        ]
    },
    {
        "city": "Bad Orb",
        "codes": [
            "63619"
        ]
    },
    {
        "city": "Bad Soden-Salmünster",
        "codes": [
            "63628"
        ]
    },
    {
        "city": "Birstein",
        "codes": [
            "63633"
        ]
    },
    {
        "city": "Brachttal",
        "codes": [
            "63636"
        ]
    },
    {
        "city": "Jossgrund",
        "codes": [
            "63637"
        ]
    },
    {
        "city": "Flörsbachtal",
        "codes": [
            "63639"
        ]
    },
    {
        "city": "Büdingen",
        "codes": [
            "63654"
        ]
    },
    {
        "city": "Nidda",
        "codes": [
            "63667"
        ]
    },
    {
        "city": "Altenstadt",
        "codes": [
            "63674",
            "86972",
            "89281"
        ]
    },
    {
        "city": "Schotten",
        "codes": [
            "63679"
        ]
    },
    {
        "city": "Ortenberg",
        "codes": [
            "63683",
            "77799"
        ]
    },
    {
        "city": "Gedern",
        "codes": [
            "63688"
        ]
    },
    {
        "city": "Ranstadt",
        "codes": [
            "63691"
        ]
    },
    {
        "city": "Limeshain",
        "codes": [
            "63694"
        ]
    },
    {
        "city": "Glauburg",
        "codes": [
            "63695"
        ]
    },
    {
        "city": "Hirzenhain",
        "codes": [
            "63697"
        ]
    },
    {
        "city": "Kefenrod",
        "codes": [
            "63699"
        ]
    },
    {
        "city": "Aschaffenburg",
        "codes": [
            "63739",
            "63741",
            "63743"
        ]
    },
    {
        "city": "Alzenau in Unterfranken",
        "codes": [
            "63755"
        ]
    },
    {
        "city": "Großostheim",
        "codes": [
            "63762"
        ]
    },
    {
        "city": "Hösbach",
        "codes": [
            "63768"
        ]
    },
    {
        "city": "Goldbach",
        "codes": [
            "63773"
        ]
    },
    {
        "city": "Mömbris",
        "codes": [
            "63776"
        ]
    },
    {
        "city": "Obernburg am Main",
        "codes": [
            "63785"
        ]
    },
    {
        "city": "Karlstein am Main",
        "codes": [
            "63791"
        ]
    },
    {
        "city": "Kahl am Main",
        "codes": [
            "63796"
        ]
    },
    {
        "city": "Kleinostheim",
        "codes": [
            "63801"
        ]
    },
    {
        "city": "Haibach",
        "codes": [
            "63808",
            "94353"
        ]
    },
    {
        "city": "Stockstadt am Main",
        "codes": [
            "63811"
        ]
    },
    {
        "city": "Mainaschaff",
        "codes": [
            "63814"
        ]
    },
    {
        "city": "Elsenfeld",
        "codes": [
            "63820"
        ]
    },
    {
        "city": "Schöllkrippen",
        "codes": [
            "63825"
        ]
    },
    {
        "city": "Geiselbach",
        "codes": [
            "63826"
        ]
    },
    {
        "city": "Kleinkahl",
        "codes": [
            "63828"
        ]
    },
    {
        "city": "Krombach",
        "codes": [
            "63829"
        ]
    },
    {
        "city": "Wiesen",
        "codes": [
            "63831"
        ]
    },
    {
        "city": "Sulzbach am Main",
        "codes": [
            "63834"
        ]
    },
    {
        "city": "Kleinwallstadt",
        "codes": [
            "63839"
        ]
    },
    {
        "city": "Hausen",
        "codes": [
            "63840",
            "91353",
            "93345"
        ]
    },
    {
        "city": "Niedernberg",
        "codes": [
            "63843"
        ]
    },
    {
        "city": "Laufach",
        "codes": [
            "63846"
        ]
    },
    {
        "city": "Leidersbach",
        "codes": [
            "63849"
        ]
    },
    {
        "city": "Mömlingen",
        "codes": [
            "63853"
        ]
    },
    {
        "city": "Bessenbach",
        "codes": [
            "63856"
        ]
    },
    {
        "city": "Waldaschaff",
        "codes": [
            "63857"
        ]
    },
    {
        "city": "Rothenbuch",
        "codes": [
            "63860"
        ]
    },
    {
        "city": "Eschau",
        "codes": [
            "63863"
        ]
    },
    {
        "city": "Glattbach",
        "codes": [
            "63864"
        ]
    },
    {
        "city": "Johannesberg",
        "codes": [
            "63867"
        ]
    },
    {
        "city": "Großwallstadt",
        "codes": [
            "63868"
        ]
    },
    {
        "city": "Heigenbrücken",
        "codes": [
            "63869"
        ]
    },
    {
        "city": "Heinrichsthal",
        "codes": [
            "63871"
        ]
    },
    {
        "city": "Heimbuchenthal",
        "codes": [
            "63872"
        ]
    },
    {
        "city": "Dammbach",
        "codes": [
            "63874"
        ]
    },
    {
        "city": "Mespelbrunn",
        "codes": [
            "63875"
        ]
    },
    {
        "city": "Sailauf",
        "codes": [
            "63877"
        ]
    },
    {
        "city": "Weibersbrunn",
        "codes": [
            "63879"
        ]
    },
    {
        "city": "Miltenberg",
        "codes": [
            "63897"
        ]
    },
    {
        "city": "Erlenbach am Main",
        "codes": [
            "63906"
        ]
    },
    {
        "city": "Klingenberg am Main",
        "codes": [
            "63911"
        ]
    },
    {
        "city": "Amorbach",
        "codes": [
            "63916"
        ]
    },
    {
        "city": "Großheubach",
        "codes": [
            "63920"
        ]
    },
    {
        "city": "Kleinheubach",
        "codes": [
            "63924"
        ]
    },
    {
        "city": "Laudenbach",
        "codes": [
            "63925",
            "69514"
        ]
    },
    {
        "city": "Bürgstadt",
        "codes": [
            "63927"
        ]
    },
    {
        "city": "Eichenbühl",
        "codes": [
            "63928"
        ]
    },
    {
        "city": "Kirchzell",
        "codes": [
            "63931"
        ]
    },
    {
        "city": "Mönchberg",
        "codes": [
            "63933"
        ]
    },
    {
        "city": "Röllbach",
        "codes": [
            "63934"
        ]
    },
    {
        "city": "Weilbach",
        "codes": [
            "63937"
        ]
    },
    {
        "city": "Wörth am Main",
        "codes": [
            "63939"
        ]
    },
    {
        "city": "Darmstadt",
        "codes": [
            "64283",
            "64285",
            "64287",
            "64289",
            "64291",
            "64293",
            "64295",
            "64297"
        ]
    },
    {
        "city": "Pfungstadt",
        "codes": [
            "64319"
        ]
    },
    {
        "city": "Weiterstadt",
        "codes": [
            "64331"
        ]
    },
    {
        "city": "Seeheim-Jugenheim",
        "codes": [
            "64342"
        ]
    },
    {
        "city": "Griesheim",
        "codes": [
            "64347"
        ]
    },
    {
        "city": "Reinheim",
        "codes": [
            "64354"
        ]
    },
    {
        "city": "Mühltal",
        "codes": [
            "64367"
        ]
    },
    {
        "city": "Ober-Ramstadt",
        "codes": [
            "64372"
        ]
    },
    {
        "city": "Roßdorf",
        "codes": [
            "64380"
        ]
    },
    {
        "city": "Reichelsheim (Odenwald)",
        "codes": [
            "64385"
        ]
    },
    {
        "city": "Erzhausen",
        "codes": [
            "64390"
        ]
    },
    {
        "city": "Brensbach",
        "codes": [
            "64395"
        ]
    },
    {
        "city": "Modautal",
        "codes": [
            "64397"
        ]
    },
    {
        "city": "Groß-Bieberau",
        "codes": [
            "64401"
        ]
    },
    {
        "city": "Bickenbach",
        "codes": [
            "64404"
        ]
    },
    {
        "city": "Fischbachtal",
        "codes": [
            "64405"
        ]
    },
    {
        "city": "Fränkisch-Crumbach",
        "codes": [
            "64407"
        ]
    },
    {
        "city": "Messel",
        "codes": [
            "64409"
        ]
    },
    {
        "city": "Groß-Gerau",
        "codes": [
            "64521"
        ]
    },
    {
        "city": "Mörfelden-Walldorf",
        "codes": [
            "64546"
        ]
    },
    {
        "city": "Riedstadt",
        "codes": [
            "64560"
        ]
    },
    {
        "city": "Nauheim",
        "codes": [
            "64569"
        ]
    },
    {
        "city": "Büttelborn",
        "codes": [
            "64572"
        ]
    },
    {
        "city": "Gernsheim",
        "codes": [
            "64579"
        ]
    },
    {
        "city": "Biebesheim am Rhein",
        "codes": [
            "64584"
        ]
    },
    {
        "city": "Stockstadt am Rhein",
        "codes": [
            "64589"
        ]
    },
    {
        "city": "Bensheim",
        "codes": [
            "64625"
        ]
    },
    {
        "city": "Heppenheim (Bergstraße)",
        "codes": [
            "64646"
        ]
    },
    {
        "city": "Lorsch",
        "codes": [
            "64653"
        ]
    },
    {
        "city": "Fürth",
        "codes": [
            "64658",
            "90762",
            "90763",
            "90765",
            "90766",
            "90768"
        ]
    },
    {
        "city": "Alsbach-Hähnlein",
        "codes": [
            "64665"
        ]
    },
    {
        "city": "Rimbach",
        "codes": [
            "64668",
            "93485"
        ]
    },
    {
        "city": "Zwingenberg",
        "codes": [
            "64673",
            "69439"
        ]
    },
    {
        "city": "Lindenfels",
        "codes": [
            "64678"
        ]
    },
    {
        "city": "Einhausen",
        "codes": [
            "64683"
        ]
    },
    {
        "city": "Lautertal (Odenwald)",
        "codes": [
            "64686"
        ]
    },
    {
        "city": "Grasellenbach",
        "codes": [
            "64689"
        ]
    },
    {
        "city": "Erbach",
        "codes": [
            "64711",
            "89155"
        ]
    },
    {
        "city": "Michelstadt",
        "codes": [
            "64720"
        ]
    },
    {
        "city": "Bad König",
        "codes": [
            "64732"
        ]
    },
    {
        "city": "Höchst im Odenwald",
        "codes": [
            "64739"
        ]
    },
    {
        "city": "Beerfelden",
        "codes": [
            "64743"
        ]
    },
    {
        "city": "Breuberg",
        "codes": [
            "64747"
        ]
    },
    {
        "city": "Lützelbach",
        "codes": [
            "64750"
        ]
    },
    {
        "city": "Brombachtal",
        "codes": [
            "64753"
        ]
    },
    {
        "city": "Hesseneck",
        "codes": [
            "64754"
        ]
    },
    {
        "city": "Mossautal",
        "codes": [
            "64756"
        ]
    },
    {
        "city": "Rothenberg",
        "codes": [
            "64757"
        ]
    },
    {
        "city": "Sensbachtal",
        "codes": [
            "64759"
        ]
    },
    {
        "city": "Dieburg",
        "codes": [
            "64807"
        ]
    },
    {
        "city": "Groß-Umstadt",
        "codes": [
            "64823"
        ]
    },
    {
        "city": "Babenhausen",
        "codes": [
            "64832",
            "87727"
        ]
    },
    {
        "city": "Groß-Zimmern",
        "codes": [
            "64846"
        ]
    },
    {
        "city": "Schaafheim",
        "codes": [
            "64850"
        ]
    },
    {
        "city": "Otzberg",
        "codes": [
            "64853"
        ]
    },
    {
        "city": "Eppertshausen",
        "codes": [
            "64859"
        ]
    },
    {
        "city": "Taunusstein",
        "codes": [
            "65232"
        ]
    },
    {
        "city": "Hochheim am Main",
        "codes": [
            "65239"
        ]
    },
    {
        "city": "Bad Schwalbach",
        "codes": [
            "65307"
        ]
    },
    {
        "city": "Heidenrod",
        "codes": [
            "65321"
        ]
    },
    {
        "city": "Aarbergen",
        "codes": [
            "65326"
        ]
    },
    {
        "city": "Hohenstein",
        "codes": [
            "65329",
            "72531"
        ]
    },
    {
        "city": "Eltville am Rhein",
        "codes": [
            "65343",
            "65344",
            "65345",
            "65346",
            "65347"
        ]
    },
    {
        "city": "Geisenheim",
        "codes": [
            "65366"
        ]
    },
    {
        "city": "Oestrich-Winkel",
        "codes": [
            "65375"
        ]
    },
    {
        "city": "Rüdesheim am Rhein",
        "codes": [
            "65385"
        ]
    },
    {
        "city": "Schlangenbad",
        "codes": [
            "65388"
        ]
    },
    {
        "city": "Lorch",
        "codes": [
            "65391",
            "73547"
        ]
    },
    {
        "city": "Walluf",
        "codes": [
            "65396"
        ]
    },
    {
        "city": "Kiedrich",
        "codes": [
            "65399"
        ]
    },
    {
        "city": "Rüsselsheim",
        "codes": [
            "65428"
        ]
    },
    {
        "city": "Flörsheim am Main",
        "codes": [
            "65439"
        ]
    },
    {
        "city": "Kelsterbach",
        "codes": [
            "65451"
        ]
    },
    {
        "city": "Ginsheim-Gustavsburg",
        "codes": [
            "65462"
        ]
    },
    {
        "city": "Trebur",
        "codes": [
            "65468"
        ]
    },
    {
        "city": "Bischofsheim",
        "codes": [
            "65474"
        ]
    },
    {
        "city": "Raunheim",
        "codes": [
            "65479"
        ]
    },
    {
        "city": "Idstein",
        "codes": [
            "65510"
        ]
    },
    {
        "city": "Bad Camberg",
        "codes": [
            "65520"
        ]
    },
    {
        "city": "Niedernhausen",
        "codes": [
            "65527"
        ]
    },
    {
        "city": "Waldems",
        "codes": [
            "65529"
        ]
    },
    {
        "city": "Limburg an der Lahn",
        "codes": [
            "65549",
            "65550",
            "65551",
            "65552",
            "65553",
            "65554",
            "65555",
            "65556"
        ]
    },
    {
        "city": "Burgschwalbach",
        "codes": [
            "65558"
        ]
    },
    {
        "city": "Diez",
        "codes": [
            "65582"
        ]
    },
    {
        "city": "Hadamar",
        "codes": [
            "65589"
        ]
    },
    {
        "city": "Runkel",
        "codes": [
            "65594"
        ]
    },
    {
        "city": "Hünfelden",
        "codes": [
            "65597"
        ]
    },
    {
        "city": "Dornburg",
        "codes": [
            "65599"
        ]
    },
    {
        "city": "Elz",
        "codes": [
            "65604"
        ]
    },
    {
        "city": "Villmar",
        "codes": [
            "65606"
        ]
    },
    {
        "city": "Brechen",
        "codes": [
            "65611"
        ]
    },
    {
        "city": "Beselich",
        "codes": [
            "65614"
        ]
    },
    {
        "city": "Selters (Taunus)",
        "codes": [
            "65618"
        ]
    },
    {
        "city": "Waldbrunn (Westerwald)",
        "codes": [
            "65620"
        ]
    },
    {
        "city": "Hahnstätten",
        "codes": [
            "65623"
        ]
    },
    {
        "city": "Altendiez",
        "codes": [
            "65624"
        ]
    },
    {
        "city": "Birlenbach",
        "codes": [
            "65626"
        ]
    },
    {
        "city": "Elbtal",
        "codes": [
            "65627"
        ]
    },
    {
        "city": "Niederneisen",
        "codes": [
            "65629"
        ]
    },
    {
        "city": "Hofheim am Taunus",
        "codes": [
            "65719"
        ]
    },
    {
        "city": "Eschborn",
        "codes": [
            "65760"
        ]
    },
    {
        "city": "Kelkheim (Taunus)",
        "codes": [
            "65779"
        ]
    },
    {
        "city": "Hattersheim am Main",
        "codes": [
            "65795"
        ]
    },
    {
        "city": "Bad Soden am Taunus",
        "codes": [
            "65812"
        ]
    },
    {
        "city": "Eppstein",
        "codes": [
            "65817"
        ]
    },
    {
        "city": "Schwalbach am Taunus",
        "codes": [
            "65824"
        ]
    },
    {
        "city": "Kriftel",
        "codes": [
            "65830"
        ]
    },
    {
        "city": "Liederbach am Taunus",
        "codes": [
            "65835"
        ]
    },
    {
        "city": "Sulzbach (Taunus)",
        "codes": [
            "65843"
        ]
    },
    {
        "city": "Saarbrücken",
        "codes": [
            "66111",
            "66113",
            "66115",
            "66117",
            "66119",
            "66121",
            "66123",
            "66125",
            "66126",
            "66127",
            "66128",
            "66129",
            "66130",
            "66131",
            "66132",
            "66133"
        ]
    },
    {
        "city": "Heusweiler",
        "codes": [
            "66265"
        ]
    },
    {
        "city": "Kleinblittersdorf",
        "codes": [
            "66271"
        ]
    },
    {
        "city": "Sulzbach / Saar",
        "codes": [
            "66280"
        ]
    },
    {
        "city": "Quierschied",
        "codes": [
            "66287"
        ]
    },
    {
        "city": "Riegelsberg",
        "codes": [
            "66292"
        ]
    },
    {
        "city": "Friedrichsthal",
        "codes": [
            "66299"
        ]
    },
    {
        "city": "Völklingen",
        "codes": [
            "66333"
        ]
    },
    {
        "city": "Püttlingen",
        "codes": [
            "66346"
        ]
    },
    {
        "city": "Großrosseln",
        "codes": [
            "66352"
        ]
    },
    {
        "city": "Bous",
        "codes": [
            "66359"
        ]
    },
    {
        "city": "Sankt Ingbert",
        "codes": [
            "66386"
        ]
    },
    {
        "city": "Mandelbachtal",
        "codes": [
            "66399"
        ]
    },
    {
        "city": "Homburg",
        "codes": [
            "66424"
        ]
    },
    {
        "city": "Blieskastel",
        "codes": [
            "66440"
        ]
    },
    {
        "city": "Bexbach",
        "codes": [
            "66450"
        ]
    },
    {
        "city": "Gersheim",
        "codes": [
            "66453"
        ]
    },
    {
        "city": "Kirkel",
        "codes": [
            "66459"
        ]
    },
    {
        "city": "Zweibrücken",
        "codes": [
            "66482"
        ]
    },
    {
        "city": "Battweiler",
        "codes": [
            "66484"
        ]
    },
    {
        "city": "Contwig",
        "codes": [
            "66497"
        ]
    },
    {
        "city": "Hornbach",
        "codes": [
            "66500"
        ]
    },
    {
        "city": "Kleinbundenbach",
        "codes": [
            "66501"
        ]
    },
    {
        "city": "Dellfeld",
        "codes": [
            "66503"
        ]
    },
    {
        "city": "Bottenbach",
        "codes": [
            "66504"
        ]
    },
    {
        "city": "Maßweiler",
        "codes": [
            "66506"
        ]
    },
    {
        "city": "Reifenberg",
        "codes": [
            "66507"
        ]
    },
    {
        "city": "Rieschweiler-Mühlbach",
        "codes": [
            "66509"
        ]
    },
    {
        "city": "Illingen",
        "codes": [
            "66557",
            "75428"
        ]
    },
    {
        "city": "Ottweiler",
        "codes": [
            "66564"
        ]
    },
    {
        "city": "Eppelborn",
        "codes": [
            "66571"
        ]
    },
    {
        "city": "Schiffweiler",
        "codes": [
            "66578"
        ]
    },
    {
        "city": "Spiesen-Elversberg",
        "codes": [
            "66583"
        ]
    },
    {
        "city": "Merchweiler",
        "codes": [
            "66589"
        ]
    },
    {
        "city": "Sankt Wendel",
        "codes": [
            "66606"
        ]
    },
    {
        "city": "Nonnweiler",
        "codes": [
            "66620"
        ]
    },
    {
        "city": "Nohfelden",
        "codes": [
            "66625"
        ]
    },
    {
        "city": "Freisen",
        "codes": [
            "66629"
        ]
    },
    {
        "city": "Tholey",
        "codes": [
            "66636"
        ]
    },
    {
        "city": "Namborn",
        "codes": [
            "66640"
        ]
    },
    {
        "city": "Marpingen",
        "codes": [
            "66646"
        ]
    },
    {
        "city": "Oberthal",
        "codes": [
            "66649"
        ]
    },
    {
        "city": "Merzig",
        "codes": [
            "66663"
        ]
    },
    {
        "city": "Losheim",
        "codes": [
            "66679"
        ]
    },
    {
        "city": "Wadern",
        "codes": [
            "66687"
        ]
    },
    {
        "city": "Mettlach",
        "codes": [
            "66693"
        ]
    },
    {
        "city": "Beckingen",
        "codes": [
            "66701"
        ]
    },
    {
        "city": "Perl",
        "codes": [
            "66706"
        ]
    },
    {
        "city": "Weiskirchen",
        "codes": [
            "66709"
        ]
    },
    {
        "city": "Saarlouis",
        "codes": [
            "66740"
        ]
    },
    {
        "city": "Dillingen / Saar",
        "codes": [
            "66763"
        ]
    },
    {
        "city": "Schwalbach",
        "codes": [
            "66773"
        ]
    },
    {
        "city": "Rehlingen-Siersburg",
        "codes": [
            "66780"
        ]
    },
    {
        "city": "Wadgassen",
        "codes": [
            "66787"
        ]
    },
    {
        "city": "Saarwellingen",
        "codes": [
            "66793"
        ]
    },
    {
        "city": "Wallerfangen",
        "codes": [
            "66798"
        ]
    },
    {
        "city": "Überherrn",
        "codes": [
            "66802"
        ]
    },
    {
        "city": "Ensdorf",
        "codes": [
            "66806",
            "92266"
        ]
    },
    {
        "city": "Nalbach",
        "codes": [
            "66809"
        ]
    },
    {
        "city": "Lebach",
        "codes": [
            "66822"
        ]
    },
    {
        "city": "Schmelz",
        "codes": [
            "66839"
        ]
    },
    {
        "city": "Landstuhl",
        "codes": [
            "66849"
        ]
    },
    {
        "city": "Queidersbach",
        "codes": [
            "66851"
        ]
    },
    {
        "city": "Kindsbach",
        "codes": [
            "66862"
        ]
    },
    {
        "city": "Kusel",
        "codes": [
            "66869"
        ]
    },
    {
        "city": "Pfeffelbach",
        "codes": [
            "66871"
        ]
    },
    {
        "city": "Ramstein-Miesenbach",
        "codes": [
            "66877"
        ]
    },
    {
        "city": "Steinwenden",
        "codes": [
            "66879"
        ]
    },
    {
        "city": "Hütschenhausen",
        "codes": [
            "66882"
        ]
    },
    {
        "city": "Altenglan",
        "codes": [
            "66885"
        ]
    },
    {
        "city": "Rammelsbach",
        "codes": [
            "66887"
        ]
    },
    {
        "city": "Bruchmühlbach-Miesau",
        "codes": [
            "66892"
        ]
    },
    {
        "city": "Bechhofen",
        "codes": [
            "66894",
            "91572"
        ]
    },
    {
        "city": "Schönenberg-Kübelberg",
        "codes": [
            "66901"
        ]
    },
    {
        "city": "Altenkirchen",
        "codes": [
            "66903"
        ]
    },
    {
        "city": "Brücken (Pfalz)",
        "codes": [
            "66904"
        ]
    },
    {
        "city": "Glan-Münchweiler",
        "codes": [
            "66907"
        ]
    },
    {
        "city": "Herschweiler-Pettersheim",
        "codes": [
            "66909"
        ]
    },
    {
        "city": "Waldmohr",
        "codes": [
            "66914"
        ]
    },
    {
        "city": "Breitenbach",
        "codes": [
            "66916"
        ]
    },
    {
        "city": "Wallhalben",
        "codes": [
            "66917"
        ]
    },
    {
        "city": "Obernheim-Kirchenarnbach",
        "codes": [
            "66919"
        ]
    },
    {
        "city": "Pirmasens",
        "codes": [
            "66953",
            "66954",
            "66955"
        ]
    },
    {
        "city": "Vinningen",
        "codes": [
            "66957"
        ]
    },
    {
        "city": "Lemberg",
        "codes": [
            "66969"
        ]
    },
    {
        "city": "Rodalben",
        "codes": [
            "66976"
        ]
    },
    {
        "city": "Clausen",
        "codes": [
            "66978"
        ]
    },
    {
        "city": "Münchweiler an der Rodalb",
        "codes": [
            "66981"
        ]
    },
    {
        "city": "Thaleischweiler-Fröschen",
        "codes": [
            "66987"
        ]
    },
    {
        "city": "Höheinöd",
        "codes": [
            "66989"
        ]
    },
    {
        "city": "Dahn",
        "codes": [
            "66994"
        ]
    },
    {
        "city": "Fischbach bei Dahn",
        "codes": [
            "66996"
        ]
    },
    {
        "city": "Hinterweidenthal",
        "codes": [
            "66999"
        ]
    },
    {
        "city": "Ludwigshafen am Rhein",
        "codes": [
            "67059",
            "67061",
            "67063",
            "67065",
            "67067",
            "67069",
            "67071"
        ]
    },
    {
        "city": "Bad Dürkheim",
        "codes": [
            "67098"
        ]
    },
    {
        "city": "Schifferstadt",
        "codes": [
            "67105"
        ]
    },
    {
        "city": "Mutterstadt",
        "codes": [
            "67112"
        ]
    },
    {
        "city": "Limburgerhof",
        "codes": [
            "67117"
        ]
    },
    {
        "city": "Altrip",
        "codes": [
            "67122"
        ]
    },
    {
        "city": "Dannstadt-Schauernheim",
        "codes": [
            "67125"
        ]
    },
    {
        "city": "Hochdorf-Assenheim",
        "codes": [
            "67126"
        ]
    },
    {
        "city": "Rödersheim-Gronau",
        "codes": [
            "67127"
        ]
    },
    {
        "city": "Maxdorf",
        "codes": [
            "67133"
        ]
    },
    {
        "city": "Birkenheide",
        "codes": [
            "67134"
        ]
    },
    {
        "city": "Fußgönheim",
        "codes": [
            "67136"
        ]
    },
    {
        "city": "Neuhofen",
        "codes": [
            "67141"
        ]
    },
    {
        "city": "Deidesheim",
        "codes": [
            "67146"
        ]
    },
    {
        "city": "Forst an der Weinstraße",
        "codes": [
            "67147"
        ]
    },
    {
        "city": "Niederkirchen bei Deidesheim",
        "codes": [
            "67150"
        ]
    },
    {
        "city": "Ruppertsberg",
        "codes": [
            "67152"
        ]
    },
    {
        "city": "Wachenheim an der Weinstraße",
        "codes": [
            "67157"
        ]
    },
    {
        "city": "Ellerstadt",
        "codes": [
            "67158"
        ]
    },
    {
        "city": "Friedelsheim",
        "codes": [
            "67159"
        ]
    },
    {
        "city": "Gönnheim",
        "codes": [
            "67161"
        ]
    },
    {
        "city": "Waldsee",
        "codes": [
            "67165"
        ]
    },
    {
        "city": "Otterstadt",
        "codes": [
            "67166"
        ]
    },
    {
        "city": "Erpolzheim",
        "codes": [
            "67167"
        ]
    },
    {
        "city": "Kallstadt",
        "codes": [
            "67169"
        ]
    },
    {
        "city": "Frankenthal (Pfalz)",
        "codes": [
            "67227"
        ]
    },
    {
        "city": "Gerolsheim",
        "codes": [
            "67229"
        ]
    },
    {
        "city": "Bobenheim-Roxheim",
        "codes": [
            "67240"
        ]
    },
    {
        "city": "Lambsheim",
        "codes": [
            "67245"
        ]
    },
    {
        "city": "Dirmstein",
        "codes": [
            "67246"
        ]
    },
    {
        "city": "Freinsheim",
        "codes": [
            "67251"
        ]
    },
    {
        "city": "Weisenheim am Sand",
        "codes": [
            "67256"
        ]
    },
    {
        "city": "Heßheim",
        "codes": [
            "67258"
        ]
    },
    {
        "city": "Beindersheim",
        "codes": [
            "67259"
        ]
    },
    {
        "city": "Grünstadt",
        "codes": [
            "67269"
        ]
    },
    {
        "city": "Kindenheim",
        "codes": [
            "67271"
        ]
    },
    {
        "city": "Weisenheim am Berg",
        "codes": [
            "67273"
        ]
    },
    {
        "city": "Bockenheim an der Weinstraße",
        "codes": [
            "67278"
        ]
    },
    {
        "city": "Ebertsheim",
        "codes": [
            "67280"
        ]
    },
    {
        "city": "Kirchheim an der Weinstraße",
        "codes": [
            "67281"
        ]
    },
    {
        "city": "Obrigheim (Pfalz)",
        "codes": [
            "67283"
        ]
    },
    {
        "city": "Orbis",
        "codes": [
            "67292"
        ]
    },
    {
        "city": "Kirchheimbolanden",
        "codes": [
            "67294"
        ]
    },
    {
        "city": "Bolanden",
        "codes": [
            "67295"
        ]
    },
    {
        "city": "Marnheim",
        "codes": [
            "67297"
        ]
    },
    {
        "city": "Eisenberg (Pfalz)",
        "codes": [
            "67304"
        ]
    },
    {
        "city": "Ramsen",
        "codes": [
            "67305"
        ]
    },
    {
        "city": "Göllheim",
        "codes": [
            "67307"
        ]
    },
    {
        "city": "Albisheim (Pfrimm)",
        "codes": [
            "67308"
        ]
    },
    {
        "city": "Hettenleidelheim",
        "codes": [
            "67310"
        ]
    },
    {
        "city": "Tiefenthal",
        "codes": [
            "67311"
        ]
    },
    {
        "city": "Carlsberg",
        "codes": [
            "67316"
        ]
    },
    {
        "city": "Altleiningen",
        "codes": [
            "67317"
        ]
    },
    {
        "city": "Wattenheim",
        "codes": [
            "67319"
        ]
    },
    {
        "city": "Speyer",
        "codes": [
            "67346"
        ]
    },
    {
        "city": "Römerberg",
        "codes": [
            "67354"
        ]
    },
    {
        "city": "Lingenfeld",
        "codes": [
            "67360"
        ]
    },
    {
        "city": "Freisbach",
        "codes": [
            "67361"
        ]
    },
    {
        "city": "Lustadt",
        "codes": [
            "67363"
        ]
    },
    {
        "city": "Schwegenheim",
        "codes": [
            "67365"
        ]
    },
    {
        "city": "Weingarten (Pfalz)",
        "codes": [
            "67366"
        ]
    },
    {
        "city": "Westheim (Pfalz)",
        "codes": [
            "67368"
        ]
    },
    {
        "city": "Dudenhofen",
        "codes": [
            "67373"
        ]
    },
    {
        "city": "Hanhofen",
        "codes": [
            "67374"
        ]
    },
    {
        "city": "Harthausen",
        "codes": [
            "67376"
        ]
    },
    {
        "city": "Gommersheim",
        "codes": [
            "67377"
        ]
    },
    {
        "city": "Zeiskam",
        "codes": [
            "67378"
        ]
    },
    {
        "city": "Neustadt an der Weinstraße",
        "codes": [
            "67433",
            "67434",
            "67435"
        ]
    },
    {
        "city": "Haßloch",
        "codes": [
            "67454"
        ]
    },
    {
        "city": "Böhl-Iggelheim",
        "codes": [
            "67459"
        ]
    },
    {
        "city": "Lambrecht (Pfalz)",
        "codes": [
            "67466"
        ]
    },
    {
        "city": "Neidenfels",
        "codes": [
            "67468"
        ]
    },
    {
        "city": "Elmstein",
        "codes": [
            "67471"
        ]
    },
    {
        "city": "Esthal",
        "codes": [
            "67472"
        ]
    },
    {
        "city": "Lindenberg",
        "codes": [
            "67473"
        ]
    },
    {
        "city": "Weidenthal",
        "codes": [
            "67475"
        ]
    },
    {
        "city": "Edenkoben",
        "codes": [
            "67480"
        ]
    },
    {
        "city": "Venningen",
        "codes": [
            "67482"
        ]
    },
    {
        "city": "Edesheim",
        "codes": [
            "67483"
        ]
    },
    {
        "city": "Maikammer",
        "codes": [
            "67487"
        ]
    },
    {
        "city": "Kirrweiler (Pfalz)",
        "codes": [
            "67489"
        ]
    },
    {
        "city": "Worms",
        "codes": [
            "67547",
            "67549",
            "67550",
            "67551"
        ]
    },
    {
        "city": "Osthofen",
        "codes": [
            "67574"
        ]
    },
    {
        "city": "Eich",
        "codes": [
            "67575"
        ]
    },
    {
        "city": "Alsheim",
        "codes": [
            "67577"
        ]
    },
    {
        "city": "Gimbsheim",
        "codes": [
            "67578"
        ]
    },
    {
        "city": "Mettenheim",
        "codes": [
            "67582",
            "84562"
        ]
    },
    {
        "city": "Guntersblum",
        "codes": [
            "67583"
        ]
    },
    {
        "city": "Dorn-Dürkheim",
        "codes": [
            "67585"
        ]
    },
    {
        "city": "Wintersheim",
        "codes": [
            "67587"
        ]
    },
    {
        "city": "Monsheim",
        "codes": [
            "67590"
        ]
    },
    {
        "city": "Offstein",
        "codes": [
            "67591"
        ]
    },
    {
        "city": "Flörsheim-Dalsheim",
        "codes": [
            "67592"
        ]
    },
    {
        "city": "Westhofen",
        "codes": [
            "67593"
        ]
    },
    {
        "city": "Bechtheim",
        "codes": [
            "67595"
        ]
    },
    {
        "city": "Dittelsheim-Heßloch",
        "codes": [
            "67596"
        ]
    },
    {
        "city": "Gundersheim",
        "codes": [
            "67598"
        ]
    },
    {
        "city": "Gundheim",
        "codes": [
            "67599"
        ]
    },
    {
        "city": "Kaiserslautern",
        "codes": [
            "67655",
            "67657",
            "67659",
            "67661",
            "67663"
        ]
    },
    {
        "city": "Enkenbach-Alsenborn",
        "codes": [
            "67677"
        ]
    },
    {
        "city": "Mehlingen",
        "codes": [
            "67678"
        ]
    },
    {
        "city": "Neuhemsbach",
        "codes": [
            "67680"
        ]
    },
    {
        "city": "Sembach",
        "codes": [
            "67681"
        ]
    },
    {
        "city": "Weilerbach",
        "codes": [
            "67685"
        ]
    },
    {
        "city": "Mackenbach",
        "codes": [
            "67686"
        ]
    },
    {
        "city": "Hochspeyer",
        "codes": [
            "67691"
        ]
    },
    {
        "city": "Fischbach",
        "codes": [
            "67693"
        ]
    },
    {
        "city": "Otterberg",
        "codes": [
            "67697"
        ]
    },
    {
        "city": "Schneckenhausen",
        "codes": [
            "67699"
        ]
    },
    {
        "city": "Niederkirchen",
        "codes": [
            "67700"
        ]
    },
    {
        "city": "Schallodenbach",
        "codes": [
            "67701"
        ]
    },
    {
        "city": "Trippstadt",
        "codes": [
            "67705"
        ]
    },
    {
        "city": "Krickenbach",
        "codes": [
            "67706"
        ]
    },
    {
        "city": "Schopp",
        "codes": [
            "67707"
        ]
    },
    {
        "city": "Waldfischbach-Burgalben",
        "codes": [
            "67714"
        ]
    },
    {
        "city": "Geiselberg",
        "codes": [
            "67715"
        ]
    },
    {
        "city": "Heltersberg",
        "codes": [
            "67716"
        ]
    },
    {
        "city": "Schmalenberg",
        "codes": [
            "67718"
        ]
    },
    {
        "city": "Winnweiler",
        "codes": [
            "67722"
        ]
    },
    {
        "city": "Höringen",
        "codes": [
            "67724"
        ]
    },
    {
        "city": "Börrstadt",
        "codes": [
            "67725"
        ]
    },
    {
        "city": "Lohnsfeld",
        "codes": [
            "67727"
        ]
    },
    {
        "city": "Münchweiler an der Alsenz",
        "codes": [
            "67728"
        ]
    },
    {
        "city": "Sippersfeld",
        "codes": [
            "67729"
        ]
    },
    {
        "city": "Otterbach",
        "codes": [
            "67731"
        ]
    },
    {
        "city": "Hirschhorn / Pfalz",
        "codes": [
            "67732"
        ]
    },
    {
        "city": "Katzweiler",
        "codes": [
            "67734"
        ]
    },
    {
        "city": "Mehlbach",
        "codes": [
            "67735"
        ]
    },
    {
        "city": "Olsbrücken",
        "codes": [
            "67737"
        ]
    },
    {
        "city": "Lauterecken",
        "codes": [
            "67742"
        ]
    },
    {
        "city": "Medard",
        "codes": [
            "67744"
        ]
    },
    {
        "city": "Grumbach",
        "codes": [
            "67745"
        ]
    },
    {
        "city": "Langweiler",
        "codes": [
            "67746"
        ]
    },
    {
        "city": "Odenbach",
        "codes": [
            "67748"
        ]
    },
    {
        "city": "Offenbach-Hundheim",
        "codes": [
            "67749"
        ]
    },
    {
        "city": "Wolfstein",
        "codes": [
            "67752"
        ]
    },
    {
        "city": "Rothselberg",
        "codes": [
            "67753"
        ]
    },
    {
        "city": "Eßweiler",
        "codes": [
            "67754"
        ]
    },
    {
        "city": "Hinzweiler",
        "codes": [
            "67756"
        ]
    },
    {
        "city": "Kreimbach-Kaulbach",
        "codes": [
            "67757"
        ]
    },
    {
        "city": "Nußbach",
        "codes": [
            "67759"
        ]
    },
    {
        "city": "Rockenhausen",
        "codes": [
            "67806"
        ]
    },
    {
        "city": "Steinbach am Donnersberg",
        "codes": [
            "67808"
        ]
    },
    {
        "city": "Dielkirchen",
        "codes": [
            "67811"
        ]
    },
    {
        "city": "Gerbach",
        "codes": [
            "67813"
        ]
    },
    {
        "city": "Dannenfels",
        "codes": [
            "67814"
        ]
    },
    {
        "city": "Dreisen",
        "codes": [
            "67816"
        ]
    },
    {
        "city": "Imsbach",
        "codes": [
            "67817"
        ]
    },
    {
        "city": "Kriegsfeld",
        "codes": [
            "67819"
        ]
    },
    {
        "city": "Alsenz",
        "codes": [
            "67821"
        ]
    },
    {
        "city": "Münsterappel",
        "codes": [
            "67822"
        ]
    },
    {
        "city": "Obermoschel",
        "codes": [
            "67823"
        ]
    },
    {
        "city": "Feilbingert",
        "codes": [
            "67824"
        ]
    },
    {
        "city": "Hallgarten",
        "codes": [
            "67826"
        ]
    },
    {
        "city": "Becherbach",
        "codes": [
            "67827"
        ]
    },
    {
        "city": "Callbach",
        "codes": [
            "67829"
        ]
    },
    {
        "city": "Mannheim",
        "codes": [
            "68159",
            "68161",
            "68163",
            "68165",
            "68167",
            "68169",
            "68199",
            "68219",
            "68229",
            "68239",
            "68259",
            "68305",
            "68307",
            "68309"
        ]
    },
    {
        "city": "Viernheim",
        "codes": [
            "68519"
        ]
    },
    {
        "city": "Ladenburg",
        "codes": [
            "68526"
        ]
    },
    {
        "city": "Edingen-Neckarhausen",
        "codes": [
            "68535"
        ]
    },
    {
        "city": "Heddesheim",
        "codes": [
            "68542"
        ]
    },
    {
        "city": "Ilvesheim",
        "codes": [
            "68549"
        ]
    },
    {
        "city": "Lampertheim",
        "codes": [
            "68623"
        ]
    },
    {
        "city": "Bürstadt",
        "codes": [
            "68642"
        ]
    },
    {
        "city": "Biblis",
        "codes": [
            "68647"
        ]
    },
    {
        "city": "Groß-Rohrheim",
        "codes": [
            "68649"
        ]
    },
    {
        "city": "Schwetzingen",
        "codes": [
            "68723"
        ]
    },
    {
        "city": "Waghäusel",
        "codes": [
            "68753"
        ]
    },
    {
        "city": "Hockenheim",
        "codes": [
            "68766"
        ]
    },
    {
        "city": "Ketsch",
        "codes": [
            "68775"
        ]
    },
    {
        "city": "Sankt Leon-Rot",
        "codes": [
            "68789"
        ]
    },
    {
        "city": "Oberhausen-Rheinhausen",
        "codes": [
            "68794"
        ]
    },
    {
        "city": "Reilingen",
        "codes": [
            "68799"
        ]
    },
    {
        "city": "Altlußheim",
        "codes": [
            "68804"
        ]
    },
    {
        "city": "Neulußheim",
        "codes": [
            "68809"
        ]
    },
    {
        "city": "Heidelberg",
        "codes": [
            "69115",
            "69117",
            "69118",
            "69120",
            "69121",
            "69123",
            "69124",
            "69126"
        ]
    },
    {
        "city": "Neckargemünd",
        "codes": [
            "69151"
        ]
    },
    {
        "city": "Wiesloch",
        "codes": [
            "69168"
        ]
    },
    {
        "city": "Leimen",
        "codes": [
            "69181"
        ]
    },
    {
        "city": "Walldorf",
        "codes": [
            "69190",
            "98639"
        ]
    },
    {
        "city": "Schriesheim",
        "codes": [
            "69198"
        ]
    },
    {
        "city": "Sandhausen",
        "codes": [
            "69207"
        ]
    },
    {
        "city": "Eppelheim",
        "codes": [
            "69214"
        ]
    },
    {
        "city": "Dossenheim",
        "codes": [
            "69221"
        ]
    },
    {
        "city": "Nußloch",
        "codes": [
            "69226"
        ]
    },
    {
        "city": "Rauenberg",
        "codes": [
            "69231"
        ]
    },
    {
        "city": "Dielheim",
        "codes": [
            "69234"
        ]
    },
    {
        "city": "Neckarsteinach",
        "codes": [
            "69239"
        ]
    },
    {
        "city": "Mühlhausen",
        "codes": [
            "69242",
            "92360",
            "96172",
            "99974",
            "99996"
        ]
    },
    {
        "city": "Bammental",
        "codes": [
            "69245"
        ]
    },
    {
        "city": "Schönau",
        "codes": [
            "69250",
            "84337"
        ]
    },
    {
        "city": "Gaiberg",
        "codes": [
            "69251"
        ]
    },
    {
        "city": "Heiligkreuzsteinach",
        "codes": [
            "69253"
        ]
    },
    {
        "city": "Malsch",
        "codes": [
            "69254",
            "76316"
        ]
    },
    {
        "city": "Mauer",
        "codes": [
            "69256"
        ]
    },
    {
        "city": "Wiesenbach",
        "codes": [
            "69257",
            "86519"
        ]
    },
    {
        "city": "Wilhelmsfeld",
        "codes": [
            "69259"
        ]
    },
    {
        "city": "Eberbach",
        "codes": [
            "69412",
            "69434"
        ]
    },
    {
        "city": "Mudau",
        "codes": [
            "69427"
        ]
    },
    {
        "city": "Waldbrunn",
        "codes": [
            "69429",
            "97295"
        ]
    },
    {
        "city": "Schönbrunn",
        "codes": [
            "69436"
        ]
    },
    {
        "city": "Neckargerach",
        "codes": [
            "69437"
        ]
    },
    {
        "city": "Weinheim",
        "codes": [
            "69469"
        ]
    },
    {
        "city": "Wald-Michelbach",
        "codes": [
            "69483"
        ]
    },
    {
        "city": "Birkenau",
        "codes": [
            "69488"
        ]
    },
    {
        "city": "Hirschberg an der Bergstraße",
        "codes": [
            "69493"
        ]
    },
    {
        "city": "Hemsbach",
        "codes": [
            "69502"
        ]
    },
    {
        "city": "Mörlenbach",
        "codes": [
            "69509"
        ]
    },
    {
        "city": "Gorxheimertal",
        "codes": [
            "69517"
        ]
    },
    {
        "city": "Abtsteinach",
        "codes": [
            "69518"
        ]
    },
    {
        "city": "Stuttgart",
        "codes": [
            "70173",
            "70174",
            "70176",
            "70178",
            "70180",
            "70182",
            "70184",
            "70186",
            "70188",
            "70190",
            "70191",
            "70192",
            "70193",
            "70195",
            "70197",
            "70199",
            "70327",
            "70329",
            "70372",
            "70374",
            "70376",
            "70378",
            "70435",
            "70437",
            "70439",
            "70469",
            "70499",
            "70563",
            "70565",
            "70567",
            "70569",
            "70597",
            "70599",
            "70619",
            "70629"
        ]
    },
    {
        "city": "Fellbach",
        "codes": [
            "70734",
            "70736"
        ]
    },
    {
        "city": "Leinfelden-Echterdingen",
        "codes": [
            "70771"
        ]
    },
    {
        "city": "Filderstadt",
        "codes": [
            "70794"
        ]
    },
    {
        "city": "Kornwestheim",
        "codes": [
            "70806"
        ]
    },
    {
        "city": "Korntal-Münchingen",
        "codes": [
            "70825"
        ]
    },
    {
        "city": "Gerlingen",
        "codes": [
            "70839"
        ]
    },
    {
        "city": "Böblingen",
        "codes": [
            "71032",
            "71034"
        ]
    },
    {
        "city": "Sindelfingen",
        "codes": [
            "71063",
            "71065",
            "71067",
            "71069"
        ]
    },
    {
        "city": "Herrenberg",
        "codes": [
            "71083"
        ]
    },
    {
        "city": "Holzgerlingen",
        "codes": [
            "71088"
        ]
    },
    {
        "city": "Weil im Schönbuch",
        "codes": [
            "71093"
        ]
    },
    {
        "city": "Schönaich",
        "codes": [
            "71101"
        ]
    },
    {
        "city": "Magstadt",
        "codes": [
            "71106"
        ]
    },
    {
        "city": "Waldenbuch",
        "codes": [
            "71111"
        ]
    },
    {
        "city": "Gärtringen",
        "codes": [
            "71116"
        ]
    },
    {
        "city": "Grafenau",
        "codes": [
            "71120",
            "94481"
        ]
    },
    {
        "city": "Gäufelden",
        "codes": [
            "71126"
        ]
    },
    {
        "city": "Jettingen",
        "codes": [
            "71131"
        ]
    },
    {
        "city": "Aidlingen",
        "codes": [
            "71134"
        ]
    },
    {
        "city": "Ehningen",
        "codes": [
            "71139"
        ]
    },
    {
        "city": "Steinenbronn",
        "codes": [
            "71144"
        ]
    },
    {
        "city": "Bondorf",
        "codes": [
            "71149"
        ]
    },
    {
        "city": "Nufringen",
        "codes": [
            "71154"
        ]
    },
    {
        "city": "Altdorf",
        "codes": [
            "71155",
            "72655",
            "84032"
        ]
    },
    {
        "city": "Hildrizhausen",
        "codes": [
            "71157"
        ]
    },
    {
        "city": "Mötzingen",
        "codes": [
            "71159"
        ]
    },
    {
        "city": "Leonberg",
        "codes": [
            "71229"
        ]
    },
    {
        "city": "Ditzingen",
        "codes": [
            "71254"
        ]
    },
    {
        "city": "Weil der Stadt",
        "codes": [
            "71263"
        ]
    },
    {
        "city": "Renningen",
        "codes": [
            "71272"
        ]
    },
    {
        "city": "Rutesheim",
        "codes": [
            "71277"
        ]
    },
    {
        "city": "Weissach",
        "codes": [
            "71287"
        ]
    },
    {
        "city": "Friolzheim",
        "codes": [
            "71292"
        ]
    },
    {
        "city": "Heimsheim",
        "codes": [
            "71296"
        ]
    },
    {
        "city": "Mönsheim",
        "codes": [
            "71297"
        ]
    },
    {
        "city": "Wimsheim",
        "codes": [
            "71299"
        ]
    },
    {
        "city": "Waiblingen",
        "codes": [
            "71332",
            "71334",
            "71336"
        ]
    },
    {
        "city": "Winnenden",
        "codes": [
            "71364"
        ]
    },
    {
        "city": "Weinstadt",
        "codes": [
            "71384"
        ]
    },
    {
        "city": "Kernen im Remstal",
        "codes": [
            "71394"
        ]
    },
    {
        "city": "Leutenbach",
        "codes": [
            "71397",
            "91359"
        ]
    },
    {
        "city": "Korb",
        "codes": [
            "71404"
        ]
    },
    {
        "city": "Schwaikheim",
        "codes": [
            "71409"
        ]
    },
    {
        "city": "Backnang",
        "codes": [
            "71522"
        ]
    },
    {
        "city": "Murrhardt",
        "codes": [
            "71540"
        ]
    },
    {
        "city": "Wüstenrot",
        "codes": [
            "71543"
        ]
    },
    {
        "city": "Aspach",
        "codes": [
            "71546"
        ]
    },
    {
        "city": "Auenwald",
        "codes": [
            "71549"
        ]
    },
    {
        "city": "Weissach im Tal",
        "codes": [
            "71554"
        ]
    },
    {
        "city": "Sulzbach an der Murr",
        "codes": [
            "71560"
        ]
    },
    {
        "city": "Affalterbach",
        "codes": [
            "71563"
        ]
    },
    {
        "city": "Althütte",
        "codes": [
            "71566"
        ]
    },
    {
        "city": "Oppenweiler",
        "codes": [
            "71570"
        ]
    },
    {
        "city": "Allmersbach im Tal",
        "codes": [
            "71573"
        ]
    },
    {
        "city": "Burgstetten",
        "codes": [
            "71576"
        ]
    },
    {
        "city": "Großerlach",
        "codes": [
            "71577"
        ]
    },
    {
        "city": "Spiegelberg",
        "codes": [
            "71579"
        ]
    },
    {
        "city": "Ludwigsburg",
        "codes": [
            "71634",
            "71636",
            "71638",
            "71640",
            "71642"
        ]
    },
    {
        "city": "Vaihingen an der Enz",
        "codes": [
            "71665"
        ]
    },
    {
        "city": "Marbach am Neckar",
        "codes": [
            "71672"
        ]
    },
    {
        "city": "Asperg",
        "codes": [
            "71679"
        ]
    },
    {
        "city": "Remseck am Neckar",
        "codes": [
            "71686"
        ]
    },
    {
        "city": "Freiberg am Neckar",
        "codes": [
            "71691"
        ]
    },
    {
        "city": "Möglingen",
        "codes": [
            "71696"
        ]
    },
    {
        "city": "Schwieberdingen",
        "codes": [
            "71701"
        ]
    },
    {
        "city": "Markgröningen",
        "codes": [
            "71706"
        ]
    },
    {
        "city": "Steinheim an der Murr",
        "codes": [
            "71711"
        ]
    },
    {
        "city": "Beilstein",
        "codes": [
            "71717"
        ]
    },
    {
        "city": "Oberstenfeld",
        "codes": [
            "71720"
        ]
    },
    {
        "city": "Großbottwar",
        "codes": [
            "71723"
        ]
    },
    {
        "city": "Benningen am Neckar",
        "codes": [
            "71726"
        ]
    },
    {
        "city": "Erdmannhausen",
        "codes": [
            "71729"
        ]
    },
    {
        "city": "Tamm",
        "codes": [
            "71732"
        ]
    },
    {
        "city": "Eberdingen",
        "codes": [
            "71735"
        ]
    },
    {
        "city": "Kirchberg an der Murr",
        "codes": [
            "71737"
        ]
    },
    {
        "city": "Oberriexingen",
        "codes": [
            "71739"
        ]
    },
    {
        "city": "Tübingen",
        "codes": [
            "72070",
            "72072",
            "72074",
            "72076"
        ]
    },
    {
        "city": "Rottenburg am Neckar",
        "codes": [
            "72108"
        ]
    },
    {
        "city": "Mössingen",
        "codes": [
            "72116"
        ]
    },
    {
        "city": "Ammerbuch",
        "codes": [
            "72119"
        ]
    },
    {
        "city": "Pliezhausen",
        "codes": [
            "72124"
        ]
    },
    {
        "city": "Kusterdingen",
        "codes": [
            "72127"
        ]
    },
    {
        "city": "Ofterdingen",
        "codes": [
            "72131"
        ]
    },
    {
        "city": "Dettenhausen",
        "codes": [
            "72135"
        ]
    },
    {
        "city": "Kirchentellinsfurt",
        "codes": [
            "72138"
        ]
    },
    {
        "city": "Walddorfhäslach",
        "codes": [
            "72141"
        ]
    },
    {
        "city": "Dußlingen",
        "codes": [
            "72144"
        ]
    },
    {
        "city": "Hirrlingen",
        "codes": [
            "72145"
        ]
    },
    {
        "city": "Nehren",
        "codes": [
            "72147"
        ]
    },
    {
        "city": "Neustetten",
        "codes": [
            "72149"
        ]
    },
    {
        "city": "Horb am Neckar",
        "codes": [
            "72160"
        ]
    },
    {
        "city": "Sulz am Neckar",
        "codes": [
            "72172"
        ]
    },
    {
        "city": "Dornhan",
        "codes": [
            "72175"
        ]
    },
    {
        "city": "Waldachtal",
        "codes": [
            "72178"
        ]
    },
    {
        "city": "Starzach",
        "codes": [
            "72181"
        ]
    },
    {
        "city": "Eutingen im Gäu",
        "codes": [
            "72184"
        ]
    },
    {
        "city": "Empfingen",
        "codes": [
            "72186"
        ]
    },
    {
        "city": "Vöhringen",
        "codes": [
            "72189",
            "89269"
        ]
    },
    {
        "city": "Nagold",
        "codes": [
            "72202"
        ]
    },
    {
        "city": "Altensteig",
        "codes": [
            "72213"
        ]
    },
    {
        "city": "Wildberg",
        "codes": [
            "72218"
        ]
    },
    {
        "city": "Haiterbach",
        "codes": [
            "72221"
        ]
    },
    {
        "city": "Ebhausen",
        "codes": [
            "72224"
        ]
    },
    {
        "city": "Simmersfeld",
        "codes": [
            "72226"
        ]
    },
    {
        "city": "Egenhausen",
        "codes": [
            "72227"
        ]
    },
    {
        "city": "Rohrdorf",
        "codes": [
            "72229",
            "83101"
        ]
    },
    {
        "city": "Freudenstadt",
        "codes": [
            "72250"
        ]
    },
    {
        "city": "Baiersbronn",
        "codes": [
            "72270"
        ]
    },
    {
        "city": "Alpirsbach",
        "codes": [
            "72275"
        ]
    },
    {
        "city": "Dornstetten",
        "codes": [
            "72280"
        ]
    },
    {
        "city": "Pfalzgrafenweiler",
        "codes": [
            "72285"
        ]
    },
    {
        "city": "Loßburg",
        "codes": [
            "72290"
        ]
    },
    {
        "city": "Betzweiler-Wälde",
        "codes": [
            "72291"
        ]
    },
    {
        "city": "Glatten",
        "codes": [
            "72293"
        ]
    },
    {
        "city": "Grömbach",
        "codes": [
            "72294"
        ]
    },
    {
        "city": "Schopfloch",
        "codes": [
            "72296",
            "91626"
        ]
    },
    {
        "city": "Seewald",
        "codes": [
            "72297"
        ]
    },
    {
        "city": "Wörnersberg",
        "codes": [
            "72299"
        ]
    },
    {
        "city": "Balingen",
        "codes": [
            "72336"
        ]
    },
    {
        "city": "Rosenfeld",
        "codes": [
            "72348"
        ]
    },
    {
        "city": "Geislingen",
        "codes": [
            "72351"
        ]
    },
    {
        "city": "Schömberg",
        "codes": [
            "72355",
            "75328"
        ]
    },
    {
        "city": "Dautmergen",
        "codes": [
            "72356"
        ]
    },
    {
        "city": "Dormettingen",
        "codes": [
            "72358"
        ]
    },
    {
        "city": "Dotternhausen",
        "codes": [
            "72359"
        ]
    },
    {
        "city": "Hausen am Tann",
        "codes": [
            "72361"
        ]
    },
    {
        "city": "Nusplingen",
        "codes": [
            "72362"
        ]
    },
    {
        "city": "Obernheim",
        "codes": [
            "72364"
        ]
    },
    {
        "city": "Ratshausen",
        "codes": [
            "72365"
        ]
    },
    {
        "city": "Weilen unter den Rinnen",
        "codes": [
            "72367"
        ]
    },
    {
        "city": "Zimmern unter der Burg",
        "codes": [
            "72369"
        ]
    },
    {
        "city": "Hechingen",
        "codes": [
            "72379"
        ]
    },
    {
        "city": "Burladingen",
        "codes": [
            "72393"
        ]
    },
    {
        "city": "Haigerloch",
        "codes": [
            "72401"
        ]
    },
    {
        "city": "Bisingen",
        "codes": [
            "72406"
        ]
    },
    {
        "city": "Bodelshausen",
        "codes": [
            "72411"
        ]
    },
    {
        "city": "Rangendingen",
        "codes": [
            "72414"
        ]
    },
    {
        "city": "Grosselfingen",
        "codes": [
            "72415"
        ]
    },
    {
        "city": "Jungingen",
        "codes": [
            "72417"
        ]
    },
    {
        "city": "Neufra",
        "codes": [
            "72419"
        ]
    },
    {
        "city": "Albstadt",
        "codes": [
            "72458",
            "72459",
            "72461"
        ]
    },
    {
        "city": "Meßstetten",
        "codes": [
            "72469"
        ]
    },
    {
        "city": "Winterlingen",
        "codes": [
            "72474"
        ]
    },
    {
        "city": "Bitz",
        "codes": [
            "72475"
        ]
    },
    {
        "city": "Schwenningen",
        "codes": [
            "72477",
            "89443"
        ]
    },
    {
        "city": "Straßberg",
        "codes": [
            "72479"
        ]
    },
    {
        "city": "Sigmaringen",
        "codes": [
            "72488"
        ]
    },
    {
        "city": "Gammertingen",
        "codes": [
            "72501"
        ]
    },
    {
        "city": "Krauchenwies",
        "codes": [
            "72505"
        ]
    },
    {
        "city": "Stetten am kalten Markt",
        "codes": [
            "72510"
        ]
    },
    {
        "city": "Bingen",
        "codes": [
            "72511"
        ]
    },
    {
        "city": "Hettingen",
        "codes": [
            "72513"
        ]
    },
    {
        "city": "Inzigkofen",
        "codes": [
            "72514"
        ]
    },
    {
        "city": "Scheer",
        "codes": [
            "72516"
        ]
    },
    {
        "city": "Sigmaringendorf",
        "codes": [
            "72517"
        ]
    },
    {
        "city": "Veringenstadt",
        "codes": [
            "72519"
        ]
    },
    {
        "city": "Münsingen",
        "codes": [
            "72525"
        ]
    },
    {
        "city": "Gomadingen",
        "codes": [
            "72532"
        ]
    },
    {
        "city": "Hayingen",
        "codes": [
            "72534"
        ]
    },
    {
        "city": "Heroldstatt",
        "codes": [
            "72535"
        ]
    },
    {
        "city": "Mehrstetten",
        "codes": [
            "72537"
        ]
    },
    {
        "city": "Pfronstetten",
        "codes": [
            "72539"
        ]
    },
    {
        "city": "Metzingen",
        "codes": [
            "72555"
        ]
    },
    {
        "city": "Bad Urach",
        "codes": [
            "72574"
        ]
    },
    {
        "city": "Dettingen an der Erms",
        "codes": [
            "72581"
        ]
    },
    {
        "city": "Grabenstetten",
        "codes": [
            "72582"
        ]
    },
    {
        "city": "Hülben",
        "codes": [
            "72584"
        ]
    },
    {
        "city": "Riederich",
        "codes": [
            "72585"
        ]
    },
    {
        "city": "Römerstein",
        "codes": [
            "72587"
        ]
    },
    {
        "city": "Westerheim",
        "codes": [
            "72589",
            "87784"
        ]
    },
    {
        "city": "Nürtingen",
        "codes": [
            "72622"
        ]
    },
    {
        "city": "Aichtal",
        "codes": [
            "72631"
        ]
    },
    {
        "city": "Frickenhausen",
        "codes": [
            "72636"
        ]
    },
    {
        "city": "Neuffen",
        "codes": [
            "72639"
        ]
    },
    {
        "city": "Oberboihingen",
        "codes": [
            "72644"
        ]
    },
    {
        "city": "Wolfschlugen",
        "codes": [
            "72649"
        ]
    },
    {
        "city": "Neckartenzlingen",
        "codes": [
            "72654"
        ]
    },
    {
        "city": "Altenriet",
        "codes": [
            "72657"
        ]
    },
    {
        "city": "Bempflingen",
        "codes": [
            "72658"
        ]
    },
    {
        "city": "Beuren",
        "codes": [
            "72660"
        ]
    },
    {
        "city": "Grafenberg",
        "codes": [
            "72661"
        ]
    },
    {
        "city": "Großbettlingen",
        "codes": [
            "72663"
        ]
    },
    {
        "city": "Kohlberg",
        "codes": [
            "72664",
            "92702"
        ]
    },
    {
        "city": "Neckartailfingen",
        "codes": [
            "72666"
        ]
    },
    {
        "city": "Schlaitdorf",
        "codes": [
            "72667"
        ]
    },
    {
        "city": "Unterensingen",
        "codes": [
            "72669"
        ]
    },
    {
        "city": "Reutlingen",
        "codes": [
            "72760",
            "72762",
            "72764",
            "72766",
            "72768",
            "72770"
        ]
    },
    {
        "city": "Pfullingen",
        "codes": [
            "72793"
        ]
    },
    {
        "city": "Eningen unter Achalm",
        "codes": [
            "72800"
        ]
    },
    {
        "city": "Lichtenstein",
        "codes": [
            "72805"
        ]
    },
    {
        "city": "Gomaringen",
        "codes": [
            "72810"
        ]
    },
    {
        "city": "Sankt Johann",
        "codes": [
            "72813"
        ]
    },
    {
        "city": "Trochtelfingen",
        "codes": [
            "72818"
        ]
    },
    {
        "city": "Sonnenbühl",
        "codes": [
            "72820"
        ]
    },
    {
        "city": "Wannweil",
        "codes": [
            "72827"
        ]
    },
    {
        "city": "Engstingen",
        "codes": [
            "72829"
        ]
    },
    {
        "city": "Göppingen",
        "codes": [
            "73033",
            "73035",
            "73037"
        ]
    },
    {
        "city": "Eislingen / Fils",
        "codes": [
            "73054"
        ]
    },
    {
        "city": "Ebersbach an der Fils",
        "codes": [
            "73061"
        ]
    },
    {
        "city": "Uhingen",
        "codes": [
            "73066"
        ]
    },
    {
        "city": "Donzdorf",
        "codes": [
            "73072"
        ]
    },
    {
        "city": "Süßen",
        "codes": [
            "73079"
        ]
    },
    {
        "city": "Salach",
        "codes": [
            "73084"
        ]
    },
    {
        "city": "Boll",
        "codes": [
            "73087"
        ]
    },
    {
        "city": "Heiningen",
        "codes": [
            "73092"
        ]
    },
    {
        "city": "Albershausen",
        "codes": [
            "73095"
        ]
    },
    {
        "city": "Rechberghausen",
        "codes": [
            "73098"
        ]
    },
    {
        "city": "Adelberg",
        "codes": [
            "73099"
        ]
    },
    {
        "city": "Aichelberg",
        "codes": [
            "73101"
        ]
    },
    {
        "city": "Birenbach",
        "codes": [
            "73102"
        ]
    },
    {
        "city": "Börtlingen",
        "codes": [
            "73104"
        ]
    },
    {
        "city": "Dürnau",
        "codes": [
            "73105"
        ]
    },
    {
        "city": "Eschenbach",
        "codes": [
            "73107"
        ]
    },
    {
        "city": "Gammelshausen",
        "codes": [
            "73108"
        ]
    },
    {
        "city": "Hattenhofen",
        "codes": [
            "73110",
            "82285"
        ]
    },
    {
        "city": "Lauterstein",
        "codes": [
            "73111"
        ]
    },
    {
        "city": "Ottenbach",
        "codes": [
            "73113"
        ]
    },
    {
        "city": "Schlat",
        "codes": [
            "73114"
        ]
    },
    {
        "city": "Wäschenbeuren",
        "codes": [
            "73116"
        ]
    },
    {
        "city": "Wangen",
        "codes": [
            "73117"
        ]
    },
    {
        "city": "Zell unter Aichelberg",
        "codes": [
            "73119"
        ]
    },
    {
        "city": "Plochingen",
        "codes": [
            "73207"
        ]
    },
    {
        "city": "Kirchheim unter Teck",
        "codes": [
            "73230"
        ]
    },
    {
        "city": "Weilheim an der Teck",
        "codes": [
            "73235"
        ]
    },
    {
        "city": "Wendlingen am Neckar",
        "codes": [
            "73240"
        ]
    },
    {
        "city": "Wernau (Neckar)",
        "codes": [
            "73249"
        ]
    },
    {
        "city": "Lenningen",
        "codes": [
            "73252"
        ]
    },
    {
        "city": "Köngen",
        "codes": [
            "73257"
        ]
    },
    {
        "city": "Reichenbach an der Fils",
        "codes": [
            "73262"
        ]
    },
    {
        "city": "Dettingen unter Teck",
        "codes": [
            "73265"
        ]
    },
    {
        "city": "Bissingen an der Teck",
        "codes": [
            "73266"
        ]
    },
    {
        "city": "Erkenbrechtsweiler",
        "codes": [
            "73268"
        ]
    },
    {
        "city": "Hochdorf",
        "codes": [
            "73269",
            "88454"
        ]
    },
    {
        "city": "Holzmaden",
        "codes": [
            "73271"
        ]
    },
    {
        "city": "Neidlingen",
        "codes": [
            "73272"
        ]
    },
    {
        "city": "Notzingen",
        "codes": [
            "73274"
        ]
    },
    {
        "city": "Ohmden",
        "codes": [
            "73275"
        ]
    },
    {
        "city": "Owen",
        "codes": [
            "73277"
        ]
    },
    {
        "city": "Schlierbach",
        "codes": [
            "73278"
        ]
    },
    {
        "city": "Geislingen an der Steige",
        "codes": [
            "73312"
        ]
    },
    {
        "city": "Deggingen",
        "codes": [
            "73326"
        ]
    },
    {
        "city": "Kuchen",
        "codes": [
            "73329"
        ]
    },
    {
        "city": "Gingen an der Fils",
        "codes": [
            "73333"
        ]
    },
    {
        "city": "Bad Überkingen",
        "codes": [
            "73337"
        ]
    },
    {
        "city": "Amstetten",
        "codes": [
            "73340"
        ]
    },
    {
        "city": "Bad Ditzenbach",
        "codes": [
            "73342"
        ]
    },
    {
        "city": "Gruibingen",
        "codes": [
            "73344"
        ]
    },
    {
        "city": "Hohenstadt",
        "codes": [
            "73345"
        ]
    },
    {
        "city": "Mühlhausen im Täle",
        "codes": [
            "73347"
        ]
    },
    {
        "city": "Wiesensteig",
        "codes": [
            "73349"
        ]
    },
    {
        "city": "Aalen",
        "codes": [
            "73430",
            "73431",
            "73432",
            "73433",
            "73434"
        ]
    },
    {
        "city": "Bopfingen",
        "codes": [
            "73441"
        ]
    },
    {
        "city": "Oberkochen",
        "codes": [
            "73447"
        ]
    },
    {
        "city": "Neresheim",
        "codes": [
            "73450"
        ]
    },
    {
        "city": "Abtsgmünd",
        "codes": [
            "73453"
        ]
    },
    {
        "city": "Essingen",
        "codes": [
            "73457"
        ]
    },
    {
        "city": "Hüttlingen",
        "codes": [
            "73460"
        ]
    },
    {
        "city": "Westhausen",
        "codes": [
            "73463"
        ]
    },
    {
        "city": "Lauchheim",
        "codes": [
            "73466"
        ]
    },
    {
        "city": "Kirchheim am Ries",
        "codes": [
            "73467"
        ]
    },
    {
        "city": "Riesbürg",
        "codes": [
            "73469"
        ]
    },
    {
        "city": "Ellwangen (Jagst)",
        "codes": [
            "73479"
        ]
    },
    {
        "city": "Unterschneidheim",
        "codes": [
            "73485"
        ]
    },
    {
        "city": "Adelmannsfelden",
        "codes": [
            "73486"
        ]
    },
    {
        "city": "Ellenberg",
        "codes": [
            "73488"
        ]
    },
    {
        "city": "Jagstzell",
        "codes": [
            "73489"
        ]
    },
    {
        "city": "Neuler",
        "codes": [
            "73491"
        ]
    },
    {
        "city": "Rainau",
        "codes": [
            "73492"
        ]
    },
    {
        "city": "Rosenberg",
        "codes": [
            "73494",
            "74749"
        ]
    },
    {
        "city": "Stödtlen",
        "codes": [
            "73495"
        ]
    },
    {
        "city": "Tannhausen",
        "codes": [
            "73497"
        ]
    },
    {
        "city": "Wört",
        "codes": [
            "73499"
        ]
    },
    {
        "city": "Schwäbisch Gmünd",
        "codes": [
            "73525",
            "73527",
            "73529"
        ]
    },
    {
        "city": "Heubach",
        "codes": [
            "73540",
            "98666"
        ]
    },
    {
        "city": "Waldstetten",
        "codes": [
            "73550",
            "89367"
        ]
    },
    {
        "city": "Alfdorf",
        "codes": [
            "73553"
        ]
    },
    {
        "city": "Mutlangen",
        "codes": [
            "73557"
        ]
    },
    {
        "city": "Böbingen an der Rems",
        "codes": [
            "73560"
        ]
    },
    {
        "city": "Mögglingen",
        "codes": [
            "73563"
        ]
    },
    {
        "city": "Spraitbach",
        "codes": [
            "73565"
        ]
    },
    {
        "city": "Bartholomä",
        "codes": [
            "73566"
        ]
    },
    {
        "city": "Durlangen",
        "codes": [
            "73568"
        ]
    },
    {
        "city": "Eschach",
        "codes": [
            "73569"
        ]
    },
    {
        "city": "Göggingen",
        "codes": [
            "73571"
        ]
    },
    {
        "city": "Heuchlingen",
        "codes": [
            "73572"
        ]
    },
    {
        "city": "Iggingen",
        "codes": [
            "73574"
        ]
    },
    {
        "city": "Leinzell",
        "codes": [
            "73575"
        ]
    },
    {
        "city": "Ruppertshofen",
        "codes": [
            "73577"
        ]
    },
    {
        "city": "Schechingen",
        "codes": [
            "73579"
        ]
    },
    {
        "city": "Schorndorf",
        "codes": [
            "73614",
            "93489"
        ]
    },
    {
        "city": "Remshalden",
        "codes": [
            "73630"
        ]
    },
    {
        "city": "Rudersberg",
        "codes": [
            "73635"
        ]
    },
    {
        "city": "Welzheim",
        "codes": [
            "73642"
        ]
    },
    {
        "city": "Winterbach",
        "codes": [
            "73650",
            "89368"
        ]
    },
    {
        "city": "Plüderhausen",
        "codes": [
            "73655"
        ]
    },
    {
        "city": "Berglen",
        "codes": [
            "73663"
        ]
    },
    {
        "city": "Baltmannsweiler",
        "codes": [
            "73666"
        ]
    },
    {
        "city": "Kaisersbach",
        "codes": [
            "73667"
        ]
    },
    {
        "city": "Lichtenwald",
        "codes": [
            "73669"
        ]
    },
    {
        "city": "Esslingen am Neckar",
        "codes": [
            "73728",
            "73730",
            "73732",
            "73733",
            "73734"
        ]
    },
    {
        "city": "Ostfildern",
        "codes": [
            "73760"
        ]
    },
    {
        "city": "Neuhausen auf den Fildern",
        "codes": [
            "73765"
        ]
    },
    {
        "city": "Denkendorf",
        "codes": [
            "73770",
            "85095"
        ]
    },
    {
        "city": "Aichwald",
        "codes": [
            "73773"
        ]
    },
    {
        "city": "Altbach",
        "codes": [
            "73776"
        ]
    },
    {
        "city": "Deizisau",
        "codes": [
            "73779"
        ]
    },
    {
        "city": "Heilbronn",
        "codes": [
            "74072",
            "74074",
            "74076",
            "74078",
            "74080",
            "74081"
        ]
    },
    {
        "city": "Neckarsulm",
        "codes": [
            "74172"
        ]
    },
    {
        "city": "Bad Friedrichshall",
        "codes": [
            "74177"
        ]
    },
    {
        "city": "Obersulm",
        "codes": [
            "74182"
        ]
    },
    {
        "city": "Weinsberg",
        "codes": [
            "74189"
        ]
    },
    {
        "city": "Schwaigern",
        "codes": [
            "74193"
        ]
    },
    {
        "city": "Neuenstadt am Kocher",
        "codes": [
            "74196"
        ]
    },
    {
        "city": "Untergruppenbach",
        "codes": [
            "74199"
        ]
    },
    {
        "city": "Bad Wimpfen",
        "codes": [
            "74206"
        ]
    },
    {
        "city": "Leingarten",
        "codes": [
            "74211"
        ]
    },
    {
        "city": "Schöntal",
        "codes": [
            "74214"
        ]
    },
    {
        "city": "Möckmühl",
        "codes": [
            "74219"
        ]
    },
    {
        "city": "Flein",
        "codes": [
            "74223"
        ]
    },
    {
        "city": "Nordheim",
        "codes": [
            "74226"
        ]
    },
    {
        "city": "Oedheim",
        "codes": [
            "74229"
        ]
    },
    {
        "city": "Abstatt",
        "codes": [
            "74232"
        ]
    },
    {
        "city": "Erlenbach",
        "codes": [
            "74235"
        ]
    },
    {
        "city": "Krautheim",
        "codes": [
            "74238"
        ]
    },
    {
        "city": "Hardthausen am Kocher",
        "codes": [
            "74239"
        ]
    },
    {
        "city": "Langenbrettach",
        "codes": [
            "74243"
        ]
    },
    {
        "city": "Löwenstein",
        "codes": [
            "74245"
        ]
    },
    {
        "city": "Eberstadt",
        "codes": [
            "74246"
        ]
    },
    {
        "city": "Ellhofen",
        "codes": [
            "74248"
        ]
    },
    {
        "city": "Jagsthausen",
        "codes": [
            "74249"
        ]
    },
    {
        "city": "Lehrensteinsfeld",
        "codes": [
            "74251"
        ]
    },
    {
        "city": "Massenbachhausen",
        "codes": [
            "74252"
        ]
    },
    {
        "city": "Offenau",
        "codes": [
            "74254"
        ]
    },
    {
        "city": "Roigheim",
        "codes": [
            "74255"
        ]
    },
    {
        "city": "Untereisesheim",
        "codes": [
            "74257"
        ]
    },
    {
        "city": "Widdern",
        "codes": [
            "74259"
        ]
    },
    {
        "city": "Bietigheim-Bissingen",
        "codes": [
            "74321"
        ]
    },
    {
        "city": "Brackenheim",
        "codes": [
            "74336"
        ]
    },
    {
        "city": "Sachsenheim",
        "codes": [
            "74343"
        ]
    },
    {
        "city": "Lauffen am Neckar",
        "codes": [
            "74348"
        ]
    },
    {
        "city": "Besigheim",
        "codes": [
            "74354"
        ]
    },
    {
        "city": "Bönnigheim",
        "codes": [
            "74357"
        ]
    },
    {
        "city": "Ilsfeld",
        "codes": [
            "74360"
        ]
    },
    {
        "city": "Güglingen",
        "codes": [
            "74363"
        ]
    },
    {
        "city": "Kirchheim am Neckar",
        "codes": [
            "74366"
        ]
    },
    {
        "city": "Löchgau",
        "codes": [
            "74369"
        ]
    },
    {
        "city": "Sersheim",
        "codes": [
            "74372"
        ]
    },
    {
        "city": "Zaberfeld",
        "codes": [
            "74374"
        ]
    },
    {
        "city": "Gemmrigheim",
        "codes": [
            "74376"
        ]
    },
    {
        "city": "Ingersheim",
        "codes": [
            "74379"
        ]
    },
    {
        "city": "Neckarwestheim",
        "codes": [
            "74382"
        ]
    },
    {
        "city": "Pleidelsheim",
        "codes": [
            "74385"
        ]
    },
    {
        "city": "Talheim",
        "codes": [
            "74388",
            "78607"
        ]
    },
    {
        "city": "Cleebronn",
        "codes": [
            "74389"
        ]
    },
    {
        "city": "Erligheim",
        "codes": [
            "74391"
        ]
    },
    {
        "city": "Freudental",
        "codes": [
            "74392"
        ]
    },
    {
        "city": "Hessigheim",
        "codes": [
            "74394"
        ]
    },
    {
        "city": "Mundelsheim",
        "codes": [
            "74395"
        ]
    },
    {
        "city": "Pfaffenhofen",
        "codes": [
            "74397"
        ]
    },
    {
        "city": "Walheim",
        "codes": [
            "74399"
        ]
    },
    {
        "city": "Gaildorf",
        "codes": [
            "74405"
        ]
    },
    {
        "city": "Gschwend",
        "codes": [
            "74417"
        ]
    },
    {
        "city": "Oberrot",
        "codes": [
            "74420"
        ]
    },
    {
        "city": "Obersontheim",
        "codes": [
            "74423"
        ]
    },
    {
        "city": "Bühlertann",
        "codes": [
            "74424"
        ]
    },
    {
        "city": "Bühlerzell",
        "codes": [
            "74426"
        ]
    },
    {
        "city": "Fichtenberg",
        "codes": [
            "74427"
        ]
    },
    {
        "city": "Sulzbach-Laufen",
        "codes": [
            "74429"
        ]
    },
    {
        "city": "Schwäbisch Hall",
        "codes": [
            "74523"
        ]
    },
    {
        "city": "Ilshofen",
        "codes": [
            "74532"
        ]
    },
    {
        "city": "Mainhardt",
        "codes": [
            "74535"
        ]
    },
    {
        "city": "Vellberg",
        "codes": [
            "74541"
        ]
    },
    {
        "city": "Braunsbach",
        "codes": [
            "74542"
        ]
    },
    {
        "city": "Michelbach an der Bilz",
        "codes": [
            "74544"
        ]
    },
    {
        "city": "Michelfeld",
        "codes": [
            "74545"
        ]
    },
    {
        "city": "Untermünkheim",
        "codes": [
            "74547"
        ]
    },
    {
        "city": "Wolpertshausen",
        "codes": [
            "74549"
        ]
    },
    {
        "city": "Crailsheim",
        "codes": [
            "74564"
        ]
    },
    {
        "city": "Blaufelden",
        "codes": [
            "74572"
        ]
    },
    {
        "city": "Schrozberg",
        "codes": [
            "74575"
        ]
    },
    {
        "city": "Fichtenau",
        "codes": [
            "74579"
        ]
    },
    {
        "city": "Gerabronn",
        "codes": [
            "74582"
        ]
    },
    {
        "city": "Rot am See",
        "codes": [
            "74585"
        ]
    },
    {
        "city": "Frankenhardt",
        "codes": [
            "74586"
        ]
    },
    {
        "city": "Satteldorf",
        "codes": [
            "74589"
        ]
    },
    {
        "city": "Kirchberg an der Jagst",
        "codes": [
            "74592"
        ]
    },
    {
        "city": "Kreßberg",
        "codes": [
            "74594"
        ]
    },
    {
        "city": "Langenburg",
        "codes": [
            "74595"
        ]
    },
    {
        "city": "Stimpfach",
        "codes": [
            "74597"
        ]
    },
    {
        "city": "Öhringen",
        "codes": [
            "74613"
        ]
    },
    {
        "city": "Bretzfeld",
        "codes": [
            "74626"
        ]
    },
    {
        "city": "Pfedelbach",
        "codes": [
            "74629"
        ]
    },
    {
        "city": "Kupferzell",
        "codes": [
            "74635"
        ]
    },
    {
        "city": "Zweiflingen",
        "codes": [
            "74639"
        ]
    },
    {
        "city": "Künzelsau",
        "codes": [
            "74653"
        ]
    },
    {
        "city": "Forchtenberg",
        "codes": [
            "74670"
        ]
    },
    {
        "city": "Mulfingen",
        "codes": [
            "74673"
        ]
    },
    {
        "city": "Niedernhall",
        "codes": [
            "74676"
        ]
    },
    {
        "city": "Dörzbach",
        "codes": [
            "74677"
        ]
    },
    {
        "city": "Weißbach",
        "codes": [
            "74679"
        ]
    },
    {
        "city": "Osterburken",
        "codes": [
            "74706"
        ]
    },
    {
        "city": "Buchen (Odenwald)",
        "codes": [
            "74722"
        ]
    },
    {
        "city": "Walldürn",
        "codes": [
            "74731"
        ]
    },
    {
        "city": "Hardheim",
        "codes": [
            "74736"
        ]
    },
    {
        "city": "Adelsheim",
        "codes": [
            "74740"
        ]
    },
    {
        "city": "Seckach",
        "codes": [
            "74743"
        ]
    },
    {
        "city": "Ahorn",
        "codes": [
            "74744",
            "96482"
        ]
    },
    {
        "city": "Höpfingen",
        "codes": [
            "74746"
        ]
    },
    {
        "city": "Ravenstein",
        "codes": [
            "74747"
        ]
    },
    {
        "city": "Mosbach",
        "codes": [
            "74821"
        ]
    },
    {
        "city": "Gundelsheim",
        "codes": [
            "74831",
            "96163"
        ]
    },
    {
        "city": "Elztal",
        "codes": [
            "74834"
        ]
    },
    {
        "city": "Limbach",
        "codes": [
            "74838"
        ]
    },
    {
        "city": "Billigheim",
        "codes": [
            "74842"
        ]
    },
    {
        "city": "Obrigheim",
        "codes": [
            "74847"
        ]
    },
    {
        "city": "Schefflenz",
        "codes": [
            "74850"
        ]
    },
    {
        "city": "Haßmersheim",
        "codes": [
            "74855"
        ]
    },
    {
        "city": "Aglasterhausen",
        "codes": [
            "74858"
        ]
    },
    {
        "city": "Neudenau",
        "codes": [
            "74861"
        ]
    },
    {
        "city": "Binau",
        "codes": [
            "74862"
        ]
    },
    {
        "city": "Fahrenbach",
        "codes": [
            "74864"
        ]
    },
    {
        "city": "Neckarzimmern",
        "codes": [
            "74865"
        ]
    },
    {
        "city": "Schwarzach",
        "codes": [
            "74869",
            "94374"
        ]
    },
    {
        "city": "Sinsheim",
        "codes": [
            "74889"
        ]
    },
    {
        "city": "Bad Rappenau",
        "codes": [
            "74906"
        ]
    },
    {
        "city": "Meckesheim",
        "codes": [
            "74909"
        ]
    },
    {
        "city": "Kirchardt",
        "codes": [
            "74912"
        ]
    },
    {
        "city": "Waibstadt",
        "codes": [
            "74915"
        ]
    },
    {
        "city": "Angelbachtal",
        "codes": [
            "74918"
        ]
    },
    {
        "city": "Helmstadt-Bargen",
        "codes": [
            "74921"
        ]
    },
    {
        "city": "Neckarbischofsheim",
        "codes": [
            "74924"
        ]
    },
    {
        "city": "Epfenbach",
        "codes": [
            "74925"
        ]
    },
    {
        "city": "Eschelbronn",
        "codes": [
            "74927"
        ]
    },
    {
        "city": "Hüffenhardt",
        "codes": [
            "74928"
        ]
    },
    {
        "city": "Ittlingen",
        "codes": [
            "74930"
        ]
    },
    {
        "city": "Lobbach",
        "codes": [
            "74931"
        ]
    },
    {
        "city": "Neidenstein",
        "codes": [
            "74933"
        ]
    },
    {
        "city": "Reichartshausen",
        "codes": [
            "74934"
        ]
    },
    {
        "city": "Siegelsbach",
        "codes": [
            "74936"
        ]
    },
    {
        "city": "Spechbach",
        "codes": [
            "74937"
        ]
    },
    {
        "city": "Zuzenhausen",
        "codes": [
            "74939"
        ]
    },
    {
        "city": "Bretten",
        "codes": [
            "75015"
        ]
    },
    {
        "city": "Eppingen",
        "codes": [
            "75031"
        ]
    },
    {
        "city": "Oberderdingen",
        "codes": [
            "75038"
        ]
    },
    {
        "city": "Walzbachtal",
        "codes": [
            "75045"
        ]
    },
    {
        "city": "Gemmingen",
        "codes": [
            "75050"
        ]
    },
    {
        "city": "Gondelsheim",
        "codes": [
            "75053"
        ]
    },
    {
        "city": "Sulzfeld",
        "codes": [
            "75056",
            "97633"
        ]
    },
    {
        "city": "Kürnbach",
        "codes": [
            "75057"
        ]
    },
    {
        "city": "Zaisenhausen",
        "codes": [
            "75059"
        ]
    },
    {
        "city": "Pforzheim",
        "codes": [
            "75172",
            "75173",
            "75175",
            "75177",
            "75179",
            "75180",
            "75181"
        ]
    },
    {
        "city": "Remchingen",
        "codes": [
            "75196"
        ]
    },
    {
        "city": "Königsbach-Stein",
        "codes": [
            "75203"
        ]
    },
    {
        "city": "Keltern",
        "codes": [
            "75210"
        ]
    },
    {
        "city": "Niefern-+schelbronn",
        "codes": [
            "75223"
        ]
    },
    {
        "city": "Ispringen",
        "codes": [
            "75228"
        ]
    },
    {
        "city": "Tiefenbronn",
        "codes": [
            "75233"
        ]
    },
    {
        "city": "Kämpfelbach",
        "codes": [
            "75236"
        ]
    },
    {
        "city": "Eisingen",
        "codes": [
            "75239",
            "97249"
        ]
    },
    {
        "city": "Neuhausen",
        "codes": [
            "75242"
        ]
    },
    {
        "city": "Neulingen",
        "codes": [
            "75245"
        ]
    },
    {
        "city": "Ölbronn-Dürrn",
        "codes": [
            "75248"
        ]
    },
    {
        "city": "Kieselbronn",
        "codes": [
            "75249"
        ]
    },
    {
        "city": "Neuenbürg",
        "codes": [
            "75305"
        ]
    },
    {
        "city": "Bad Wildbad im Schwarzwald",
        "codes": [
            "75323"
        ]
    },
    {
        "city": "Engelsbrand",
        "codes": [
            "75331"
        ]
    },
    {
        "city": "Straubenhardt",
        "codes": [
            "75334"
        ]
    },
    {
        "city": "Dobel",
        "codes": [
            "75335"
        ]
    },
    {
        "city": "Enzklösterle",
        "codes": [
            "75337"
        ]
    },
    {
        "city": "Höfen an der Enz",
        "codes": [
            "75339"
        ]
    },
    {
        "city": "Calw",
        "codes": [
            "75365"
        ]
    },
    {
        "city": "Bad Liebenzell",
        "codes": [
            "75378"
        ]
    },
    {
        "city": "Althengstett",
        "codes": [
            "75382"
        ]
    },
    {
        "city": "Bad Teinach-Zavelstein",
        "codes": [
            "75385"
        ]
    },
    {
        "city": "Neubulach",
        "codes": [
            "75387"
        ]
    },
    {
        "city": "Neuweiler",
        "codes": [
            "75389"
        ]
    },
    {
        "city": "Gechingen",
        "codes": [
            "75391"
        ]
    },
    {
        "city": "Deckenpfronn",
        "codes": [
            "75392"
        ]
    },
    {
        "city": "Oberreichenbach",
        "codes": [
            "75394",
            "91097"
        ]
    },
    {
        "city": "Ostelsheim",
        "codes": [
            "75395"
        ]
    },
    {
        "city": "Simmozheim",
        "codes": [
            "75397"
        ]
    },
    {
        "city": "Unterreichenbach",
        "codes": [
            "75399"
        ]
    },
    {
        "city": "Mühlacker",
        "codes": [
            "75417"
        ]
    },
    {
        "city": "Maulbronn",
        "codes": [
            "75433"
        ]
    },
    {
        "city": "Knittlingen",
        "codes": [
            "75438"
        ]
    },
    {
        "city": "Ötisheim",
        "codes": [
            "75443"
        ]
    },
    {
        "city": "Wiernsheim",
        "codes": [
            "75446"
        ]
    },
    {
        "city": "Sternenfels",
        "codes": [
            "75447"
        ]
    },
    {
        "city": "Wurmberg",
        "codes": [
            "75449"
        ]
    },
    {
        "city": "Karlsruhe",
        "codes": [
            "76131",
            "76133",
            "76135",
            "76137",
            "76139",
            "76149",
            "76185",
            "76187",
            "76189",
            "76199",
            "76227",
            "76228",
            "76229"
        ]
    },
    {
        "city": "Ettlingen",
        "codes": [
            "76275"
        ]
    },
    {
        "city": "Rheinstetten",
        "codes": [
            "76287"
        ]
    },
    {
        "city": "Stutensee",
        "codes": [
            "76297"
        ]
    },
    {
        "city": "Karlsbad",
        "codes": [
            "76307"
        ]
    },
    {
        "city": "Pfinztal",
        "codes": [
            "76327"
        ]
    },
    {
        "city": "Bad Herrenalb",
        "codes": [
            "76332"
        ]
    },
    {
        "city": "Waldbronn",
        "codes": [
            "76337"
        ]
    },
    {
        "city": "Eggenstein-Leopoldshafen",
        "codes": [
            "76344"
        ]
    },
    {
        "city": "Linkenheim-Hochstetten",
        "codes": [
            "76351"
        ]
    },
    {
        "city": "Weingarten (Baden)",
        "codes": [
            "76356"
        ]
    },
    {
        "city": "Marxzell",
        "codes": [
            "76359"
        ]
    },
    {
        "city": "Rastatt",
        "codes": [
            "76437"
        ]
    },
    {
        "city": "Durmersheim",
        "codes": [
            "76448"
        ]
    },
    {
        "city": "Kuppenheim",
        "codes": [
            "76456"
        ]
    },
    {
        "city": "Muggensturm",
        "codes": [
            "76461"
        ]
    },
    {
        "city": "Bietigheim",
        "codes": [
            "76467"
        ]
    },
    {
        "city": "Ötigheim",
        "codes": [
            "76470"
        ]
    },
    {
        "city": "Iffezheim",
        "codes": [
            "76473"
        ]
    },
    {
        "city": "Au am Rhein",
        "codes": [
            "76474"
        ]
    },
    {
        "city": "Bischweier",
        "codes": [
            "76476"
        ]
    },
    {
        "city": "Elchesheim-Illingen",
        "codes": [
            "76477"
        ]
    },
    {
        "city": "Steinmauern",
        "codes": [
            "76479"
        ]
    },
    {
        "city": "Baden-Baden",
        "codes": [
            "76530",
            "76532",
            "76534"
        ]
    },
    {
        "city": "Sinzheim",
        "codes": [
            "76547"
        ]
    },
    {
        "city": "Hügelsheim",
        "codes": [
            "76549"
        ]
    },
    {
        "city": "Gaggenau",
        "codes": [
            "76571"
        ]
    },
    {
        "city": "Gernsbach",
        "codes": [
            "76593"
        ]
    },
    {
        "city": "Forbach",
        "codes": [
            "76596"
        ]
    },
    {
        "city": "Loffenau",
        "codes": [
            "76597"
        ]
    },
    {
        "city": "Weisenbach",
        "codes": [
            "76599"
        ]
    },
    {
        "city": "Bruchsal",
        "codes": [
            "76646"
        ]
    },
    {
        "city": "Philippsburg",
        "codes": [
            "76661"
        ]
    },
    {
        "city": "Bad Schönborn",
        "codes": [
            "76669"
        ]
    },
    {
        "city": "Graben-Neudorf",
        "codes": [
            "76676"
        ]
    },
    {
        "city": "Östringen",
        "codes": [
            "76684"
        ]
    },
    {
        "city": "Karlsdorf-Neuthard",
        "codes": [
            "76689"
        ]
    },
    {
        "city": "Forst",
        "codes": [
            "76694"
        ]
    },
    {
        "city": "Ubstadt-Weiher",
        "codes": [
            "76698"
        ]
    },
    {
        "city": "Kraichtal",
        "codes": [
            "76703"
        ]
    },
    {
        "city": "Dettenheim",
        "codes": [
            "76706"
        ]
    },
    {
        "city": "Hambrücken",
        "codes": [
            "76707"
        ]
    },
    {
        "city": "Kronau",
        "codes": [
            "76709"
        ]
    },
    {
        "city": "Germersheim",
        "codes": [
            "76726"
        ]
    },
    {
        "city": "Wörth am Rhein",
        "codes": [
            "76744"
        ]
    },
    {
        "city": "Jockgrim",
        "codes": [
            "76751"
        ]
    },
    {
        "city": "Bellheim",
        "codes": [
            "76756"
        ]
    },
    {
        "city": "Rülzheim",
        "codes": [
            "76761"
        ]
    },
    {
        "city": "Rheinzabern",
        "codes": [
            "76764"
        ]
    },
    {
        "city": "Hagenbach",
        "codes": [
            "76767"
        ]
    },
    {
        "city": "Berg (Pfalz)",
        "codes": [
            "76768"
        ]
    },
    {
        "city": "Hatzenbühl",
        "codes": [
            "76770"
        ]
    },
    {
        "city": "Hördt",
        "codes": [
            "76771"
        ]
    },
    {
        "city": "Kuhardt",
        "codes": [
            "76773"
        ]
    },
    {
        "city": "Leimersheim",
        "codes": [
            "76774"
        ]
    },
    {
        "city": "Neuburg am Rhein",
        "codes": [
            "76776"
        ]
    },
    {
        "city": "Neupotz",
        "codes": [
            "76777"
        ]
    },
    {
        "city": "Scheibenhardt",
        "codes": [
            "76779"
        ]
    },
    {
        "city": "Landau in der Pfalz",
        "codes": [
            "76829"
        ]
    },
    {
        "city": "Billigheim-Ingenheim",
        "codes": [
            "76831"
        ]
    },
    {
        "city": "Frankweiler",
        "codes": [
            "76833"
        ]
    },
    {
        "city": "Rhodt unter Rietburg",
        "codes": [
            "76835"
        ]
    },
    {
        "city": "Hauenstein",
        "codes": [
            "76846"
        ]
    },
    {
        "city": "Wilgartswiesen",
        "codes": [
            "76848"
        ]
    },
    {
        "city": "Annweiler am Trifels",
        "codes": [
            "76855"
        ]
    },
    {
        "city": "Albersweiler",
        "codes": [
            "76857"
        ]
    },
    {
        "city": "Herxheim bei Landau / Pfalz",
        "codes": [
            "76863"
        ]
    },
    {
        "city": "Insheim",
        "codes": [
            "76865"
        ]
    },
    {
        "city": "Kandel",
        "codes": [
            "76870"
        ]
    },
    {
        "city": "Steinweiler",
        "codes": [
            "76872"
        ]
    },
    {
        "city": "Offenbach an der Queich",
        "codes": [
            "76877"
        ]
    },
    {
        "city": "Hochstadt (Pfalz)",
        "codes": [
            "76879"
        ]
    },
    {
        "city": "Bad Bergzabern",
        "codes": [
            "76887"
        ]
    },
    {
        "city": "Klingenmünster",
        "codes": [
            "76889"
        ]
    },
    {
        "city": "Bruchweiler-Bärenbach",
        "codes": [
            "76891"
        ]
    },
    {
        "city": "Offenburg",
        "codes": [
            "77652",
            "77654",
            "77656"
        ]
    },
    {
        "city": "Kehl",
        "codes": [
            "77694"
        ]
    },
    {
        "city": "Oberkirch",
        "codes": [
            "77704"
        ]
    },
    {
        "city": "Wolfach",
        "codes": [
            "77709"
        ]
    },
    {
        "city": "Haslach im Kinzigtal",
        "codes": [
            "77716"
        ]
    },
    {
        "city": "Gengenbach",
        "codes": [
            "77723"
        ]
    },
    {
        "city": "Oppenau",
        "codes": [
            "77728"
        ]
    },
    {
        "city": "Willstätt",
        "codes": [
            "77731"
        ]
    },
    {
        "city": "Zell am Harmersbach",
        "codes": [
            "77736"
        ]
    },
    {
        "city": "Bad Peterstal-Griesbach",
        "codes": [
            "77740"
        ]
    },
    {
        "city": "Neuried",
        "codes": [
            "77743",
            "82061"
        ]
    },
    {
        "city": "Schutterwald",
        "codes": [
            "77746"
        ]
    },
    {
        "city": "Hohberg",
        "codes": [
            "77749"
        ]
    },
    {
        "city": "Hausach",
        "codes": [
            "77756"
        ]
    },
    {
        "city": "Schiltach",
        "codes": [
            "77761"
        ]
    },
    {
        "city": "Appenweier",
        "codes": [
            "77767"
        ]
    },
    {
        "city": "Durbach",
        "codes": [
            "77770"
        ]
    },
    {
        "city": "Schenkenzell",
        "codes": [
            "77773"
        ]
    },
    {
        "city": "Bad Rippoldsau-Schapbach",
        "codes": [
            "77776"
        ]
    },
    {
        "city": "Biberach",
        "codes": [
            "77781"
        ]
    },
    {
        "city": "Oberharmersbach",
        "codes": [
            "77784"
        ]
    },
    {
        "city": "Nordrach",
        "codes": [
            "77787"
        ]
    },
    {
        "city": "Steinach",
        "codes": [
            "77790",
            "94377",
            "96523"
        ]
    },
    {
        "city": "Berghaupten",
        "codes": [
            "77791"
        ]
    },
    {
        "city": "Gutach (Schwarzwaldbahn)",
        "codes": [
            "77793"
        ]
    },
    {
        "city": "Lautenbach",
        "codes": [
            "77794"
        ]
    },
    {
        "city": "Mühlenbach",
        "codes": [
            "77796"
        ]
    },
    {
        "city": "Ohlsbach",
        "codes": [
            "77797"
        ]
    },
    {
        "city": "Bühl",
        "codes": [
            "77815"
        ]
    },
    {
        "city": "Bühlertal",
        "codes": [
            "77830"
        ]
    },
    {
        "city": "Ottersweier",
        "codes": [
            "77833"
        ]
    },
    {
        "city": "Rheinmünster",
        "codes": [
            "77836"
        ]
    },
    {
        "city": "Achern",
        "codes": [
            "77855"
        ]
    },
    {
        "city": "Rheinau",
        "codes": [
            "77866"
        ]
    },
    {
        "city": "Renchen",
        "codes": [
            "77871"
        ]
    },
    {
        "city": "Kappelrodeck",
        "codes": [
            "77876"
        ]
    },
    {
        "city": "Sasbach",
        "codes": [
            "77880",
            "79361"
        ]
    },
    {
        "city": "Ottenhöfen im Schwarzwald",
        "codes": [
            "77883"
        ]
    },
    {
        "city": "Lauf",
        "codes": [
            "77886"
        ]
    },
    {
        "city": "Sasbachwalden",
        "codes": [
            "77887"
        ]
    },
    {
        "city": "Seebach",
        "codes": [
            "77889",
            "99846"
        ]
    },
    {
        "city": "Lahr / Schwarzwald",
        "codes": [
            "77933"
        ]
    },
    {
        "city": "Friesenheim",
        "codes": [
            "77948"
        ]
    },
    {
        "city": "Ettenheim",
        "codes": [
            "77955"
        ]
    },
    {
        "city": "Seelbach",
        "codes": [
            "77960"
        ]
    },
    {
        "city": "Schwanau",
        "codes": [
            "77963"
        ]
    },
    {
        "city": "Kappel-Grafenhausen",
        "codes": [
            "77966"
        ]
    },
    {
        "city": "Kippenheim",
        "codes": [
            "77971"
        ]
    },
    {
        "city": "Mahlberg",
        "codes": [
            "77972"
        ]
    },
    {
        "city": "Meißenheim",
        "codes": [
            "77974"
        ]
    },
    {
        "city": "Ringsheim",
        "codes": [
            "77975"
        ]
    },
    {
        "city": "Rust",
        "codes": [
            "77977"
        ]
    },
    {
        "city": "Schuttertal",
        "codes": [
            "77978"
        ]
    },
    {
        "city": "Villingen-Schwenningen",
        "codes": [
            "78048",
            "78050",
            "78052",
            "78054",
            "78056"
        ]
    },
    {
        "city": "Bad Dürrheim",
        "codes": [
            "78073"
        ]
    },
    {
        "city": "Niedereschach",
        "codes": [
            "78078"
        ]
    },
    {
        "city": "Dauchingen",
        "codes": [
            "78083"
        ]
    },
    {
        "city": "Brigachtal",
        "codes": [
            "78086"
        ]
    },
    {
        "city": "Mönchweiler",
        "codes": [
            "78087"
        ]
    },
    {
        "city": "Unterkirnach",
        "codes": [
            "78089"
        ]
    },
    {
        "city": "Triberg im Schwarzwald",
        "codes": [
            "78098"
        ]
    },
    {
        "city": "Sankt Georgen im Schwarzwald",
        "codes": [
            "78112"
        ]
    },
    {
        "city": "Furtwangen im Schwarzwald",
        "codes": [
            "78120"
        ]
    },
    {
        "city": "Königsfeld im Schwarzwald",
        "codes": [
            "78126"
        ]
    },
    {
        "city": "Hornberg",
        "codes": [
            "78132"
        ]
    },
    {
        "city": "Schonach im Schwarzwald",
        "codes": [
            "78136"
        ]
    },
    {
        "city": "Schönwald im Schwarzwald",
        "codes": [
            "78141"
        ]
    },
    {
        "city": "Tennenbronn",
        "codes": [
            "78144"
        ]
    },
    {
        "city": "Vöhrenbach",
        "codes": [
            "78147"
        ]
    },
    {
        "city": "Gütenbach",
        "codes": [
            "78148"
        ]
    },
    {
        "city": "Donaueschingen",
        "codes": [
            "78166"
        ]
    },
    {
        "city": "Blumberg",
        "codes": [
            "78176"
        ]
    },
    {
        "city": "Hüfingen",
        "codes": [
            "78183"
        ]
    },
    {
        "city": "Geisingen",
        "codes": [
            "78187"
        ]
    },
    {
        "city": "Immendingen",
        "codes": [
            "78194"
        ]
    },
    {
        "city": "Bräunlingen",
        "codes": [
            "78199"
        ]
    },
    {
        "city": "Singen (Hohentwiel)",
        "codes": [
            "78224"
        ]
    },
    {
        "city": "Engen",
        "codes": [
            "78234"
        ]
    },
    {
        "city": "Rielasingen-Worblingen",
        "codes": [
            "78239"
        ]
    },
    {
        "city": "Gottmadingen",
        "codes": [
            "78244"
        ]
    },
    {
        "city": "Hilzingen",
        "codes": [
            "78247"
        ]
    },
    {
        "city": "Tengen",
        "codes": [
            "78250"
        ]
    },
    {
        "city": "Eigeltingen",
        "codes": [
            "78253"
        ]
    },
    {
        "city": "Steißlingen",
        "codes": [
            "78256"
        ]
    },
    {
        "city": "Mühlhausen-Ehingen",
        "codes": [
            "78259"
        ]
    },
    {
        "city": "Gailingen am Hochrhein",
        "codes": [
            "78262"
        ]
    },
    {
        "city": "Büsingen am Hochrhein",
        "codes": [
            "78266"
        ]
    },
    {
        "city": "Aach",
        "codes": [
            "78267"
        ]
    },
    {
        "city": "Volkertshausen",
        "codes": [
            "78269"
        ]
    },
    {
        "city": "Radolfzell am Bodensee",
        "codes": [
            "78315"
        ]
    },
    {
        "city": "Stockach",
        "codes": [
            "78333"
        ]
    },
    {
        "city": "Öhningen",
        "codes": [
            "78337"
        ]
    },
    {
        "city": "Gaienhofen",
        "codes": [
            "78343"
        ]
    },
    {
        "city": "Moos",
        "codes": [
            "78345",
            "94554"
        ]
    },
    {
        "city": "Bodman-Ludwigshafen",
        "codes": [
            "78351"
        ]
    },
    {
        "city": "Sipplingen",
        "codes": [
            "78354"
        ]
    },
    {
        "city": "Hohenfels",
        "codes": [
            "78355",
            "92366"
        ]
    },
    {
        "city": "Mühlingen",
        "codes": [
            "78357"
        ]
    },
    {
        "city": "Orsingen-Nenzingen",
        "codes": [
            "78359"
        ]
    },
    {
        "city": "Konstanz",
        "codes": [
            "78462",
            "78464",
            "78465",
            "78467"
        ]
    },
    {
        "city": "Allensbach",
        "codes": [
            "78476"
        ]
    },
    {
        "city": "Reichenau",
        "codes": [
            "78479"
        ]
    },
    {
        "city": "Tuttlingen",
        "codes": [
            "78532"
        ]
    },
    {
        "city": "Spaichingen",
        "codes": [
            "78549"
        ]
    },
    {
        "city": "Aldingen",
        "codes": [
            "78554"
        ]
    },
    {
        "city": "Gosheim",
        "codes": [
            "78559"
        ]
    },
    {
        "city": "Wehingen",
        "codes": [
            "78564"
        ]
    },
    {
        "city": "Fridingen an der Donau",
        "codes": [
            "78567"
        ]
    },
    {
        "city": "Mühlheim an der Donau",
        "codes": [
            "78570"
        ]
    },
    {
        "city": "Wurmlingen",
        "codes": [
            "78573"
        ]
    },
    {
        "city": "Emmingen-Liptingen",
        "codes": [
            "78576"
        ]
    },
    {
        "city": "Neuhausen ob Eck",
        "codes": [
            "78579"
        ]
    },
    {
        "city": "Bärenthal",
        "codes": [
            "78580"
        ]
    },
    {
        "city": "Balgheim",
        "codes": [
            "78582"
        ]
    },
    {
        "city": "Böttingen",
        "codes": [
            "78583"
        ]
    },
    {
        "city": "Bubsheim",
        "codes": [
            "78585"
        ]
    },
    {
        "city": "Deilingen",
        "codes": [
            "78586"
        ]
    },
    {
        "city": "Denkingen",
        "codes": [
            "78588"
        ]
    },
    {
        "city": "Dürbheim",
        "codes": [
            "78589"
        ]
    },
    {
        "city": "Durchhausen",
        "codes": [
            "78591"
        ]
    },
    {
        "city": "Egesheim",
        "codes": [
            "78592"
        ]
    },
    {
        "city": "Gunningen",
        "codes": [
            "78594"
        ]
    },
    {
        "city": "Hausen ob Verena",
        "codes": [
            "78595"
        ]
    },
    {
        "city": "Irndorf",
        "codes": [
            "78597"
        ]
    },
    {
        "city": "Königsheim",
        "codes": [
            "78598"
        ]
    },
    {
        "city": "Kolbingen",
        "codes": [
            "78600"
        ]
    },
    {
        "city": "Mahlstetten",
        "codes": [
            "78601"
        ]
    },
    {
        "city": "Renquishausen",
        "codes": [
            "78603"
        ]
    },
    {
        "city": "Rietheim-Weilheim",
        "codes": [
            "78604"
        ]
    },
    {
        "city": "Seitingen-Oberflacht",
        "codes": [
            "78606"
        ]
    },
    {
        "city": "Tuningen",
        "codes": [
            "78609"
        ]
    },
    {
        "city": "Rottweil",
        "codes": [
            "78628"
        ]
    },
    {
        "city": "Trossingen",
        "codes": [
            "78647"
        ]
    },
    {
        "city": "Deißlingen",
        "codes": [
            "78652"
        ]
    },
    {
        "city": "Dunningen",
        "codes": [
            "78655"
        ]
    },
    {
        "city": "Zimmern ob Rottweil",
        "codes": [
            "78658"
        ]
    },
    {
        "city": "Dietingen",
        "codes": [
            "78661"
        ]
    },
    {
        "city": "Bösingen",
        "codes": [
            "78662"
        ]
    },
    {
        "city": "Eschbronn",
        "codes": [
            "78664"
        ]
    },
    {
        "city": "Frittlingen",
        "codes": [
            "78665"
        ]
    },
    {
        "city": "Villingendorf",
        "codes": [
            "78667"
        ]
    },
    {
        "city": "Wellendingen",
        "codes": [
            "78669"
        ]
    },
    {
        "city": "Schramberg",
        "codes": [
            "78713"
        ]
    },
    {
        "city": "Oberndorf am Neckar",
        "codes": [
            "78727"
        ]
    },
    {
        "city": "Lauterbach",
        "codes": [
            "78730"
        ]
    },
    {
        "city": "Aichhalden",
        "codes": [
            "78733"
        ]
    },
    {
        "city": "Epfendorf",
        "codes": [
            "78736"
        ]
    },
    {
        "city": "Fluorn-Winzeln",
        "codes": [
            "78737"
        ]
    },
    {
        "city": "Hardt",
        "codes": [
            "78739"
        ]
    },
    {
        "city": "Freiburg im Breisgau",
        "codes": [
            "79098",
            "79100",
            "79102",
            "79104",
            "79106",
            "79108",
            "79110",
            "79111",
            "79112",
            "79114",
            "79115",
            "79117"
        ]
    },
    {
        "city": "Waldkirch",
        "codes": [
            "79183"
        ]
    },
    {
        "city": "Bad Krozingen",
        "codes": [
            "79189"
        ]
    },
    {
        "city": "Gundelfingen",
        "codes": [
            "79194"
        ]
    },
    {
        "city": "Kirchzarten",
        "codes": [
            "79199"
        ]
    },
    {
        "city": "Breisach am Rhein",
        "codes": [
            "79206"
        ]
    },
    {
        "city": "Denzlingen",
        "codes": [
            "79211"
        ]
    },
    {
        "city": "Elzach",
        "codes": [
            "79215"
        ]
    },
    {
        "city": "Staufen im Breisgau",
        "codes": [
            "79219"
        ]
    },
    {
        "city": "Umkirch",
        "codes": [
            "79224"
        ]
    },
    {
        "city": "Schallstadt",
        "codes": [
            "79227"
        ]
    },
    {
        "city": "March",
        "codes": [
            "79232"
        ]
    },
    {
        "city": "Vogtsburg im Kaiserstuhl",
        "codes": [
            "79235"
        ]
    },
    {
        "city": "Ehrenkirchen",
        "codes": [
            "79238"
        ]
    },
    {
        "city": "Ihringen",
        "codes": [
            "79241"
        ]
    },
    {
        "city": "Münstertal / Schwarzwald",
        "codes": [
            "79244"
        ]
    },
    {
        "city": "Merzhausen",
        "codes": [
            "79249"
        ]
    },
    {
        "city": "Stegen",
        "codes": [
            "79252"
        ]
    },
    {
        "city": "Oberried",
        "codes": [
            "79254"
        ]
    },
    {
        "city": "Buchenbach",
        "codes": [
            "79256"
        ]
    },
    {
        "city": "Hartheim",
        "codes": [
            "79258"
        ]
    },
    {
        "city": "Gutach im Breisgau",
        "codes": [
            "79261"
        ]
    },
    {
        "city": "Simonswald",
        "codes": [
            "79263"
        ]
    },
    {
        "city": "Bötzingen",
        "codes": [
            "79268"
        ]
    },
    {
        "city": "Sankt Peter",
        "codes": [
            "79271"
        ]
    },
    {
        "city": "Sankt Märgen",
        "codes": [
            "79274"
        ]
    },
    {
        "city": "Reute",
        "codes": [
            "79276"
        ]
    },
    {
        "city": "Vörstetten",
        "codes": [
            "79279"
        ]
    },
    {
        "city": "Au",
        "codes": [
            "79280"
        ]
    },
    {
        "city": "Ballrechten-Dottingen",
        "codes": [
            "79282"
        ]
    },
    {
        "city": "Bollschweil",
        "codes": [
            "79283"
        ]
    },
    {
        "city": "Ebringen",
        "codes": [
            "79285"
        ]
    },
    {
        "city": "Glottertal",
        "codes": [
            "79286"
        ]
    },
    {
        "city": "Gottenheim",
        "codes": [
            "79288"
        ]
    },
    {
        "city": "Horben",
        "codes": [
            "79289"
        ]
    },
    {
        "city": "Merdingen",
        "codes": [
            "79291"
        ]
    },
    {
        "city": "Pfaffenweiler",
        "codes": [
            "79292"
        ]
    },
    {
        "city": "Sölden",
        "codes": [
            "79294"
        ]
    },
    {
        "city": "Sulzburg",
        "codes": [
            "79295"
        ]
    },
    {
        "city": "Winden im Elztal",
        "codes": [
            "79297"
        ]
    },
    {
        "city": "Wittnau",
        "codes": [
            "79299"
        ]
    },
    {
        "city": "Emmendingen",
        "codes": [
            "79312"
        ]
    },
    {
        "city": "Teningen",
        "codes": [
            "79331"
        ]
    },
    {
        "city": "Herbolzheim",
        "codes": [
            "79336"
        ]
    },
    {
        "city": "Kenzingen",
        "codes": [
            "79341"
        ]
    },
    {
        "city": "Endingen am Kaiserstuhl",
        "codes": [
            "79346"
        ]
    },
    {
        "city": "Freiamt",
        "codes": [
            "79348"
        ]
    },
    {
        "city": "Sexau",
        "codes": [
            "79350"
        ]
    },
    {
        "city": "Bahlingen",
        "codes": [
            "79353"
        ]
    },
    {
        "city": "Eichstetten",
        "codes": [
            "79356"
        ]
    },
    {
        "city": "Riegel",
        "codes": [
            "79359"
        ]
    },
    {
        "city": "Forchheim",
        "codes": [
            "79362",
            "91301"
        ]
    },
    {
        "city": "Malterdingen",
        "codes": [
            "79364"
        ]
    },
    {
        "city": "Rheinhausen",
        "codes": [
            "79365"
        ]
    },
    {
        "city": "Weisweil",
        "codes": [
            "79367"
        ]
    },
    {
        "city": "Wyhl",
        "codes": [
            "79369"
        ]
    },
    {
        "city": "Müllheim",
        "codes": [
            "79379"
        ]
    },
    {
        "city": "Neuenburg am Rhein",
        "codes": [
            "79395"
        ]
    },
    {
        "city": "Kandern",
        "codes": [
            "79400"
        ]
    },
    {
        "city": "Badenweiler",
        "codes": [
            "79410"
        ]
    },
    {
        "city": "Bad Bellingen",
        "codes": [
            "79415"
        ]
    },
    {
        "city": "Schliengen",
        "codes": [
            "79418"
        ]
    },
    {
        "city": "Heitersheim",
        "codes": [
            "79423"
        ]
    },
    {
        "city": "Auggen",
        "codes": [
            "79424"
        ]
    },
    {
        "city": "Buggingen",
        "codes": [
            "79426"
        ]
    },
    {
        "city": "Eschbach",
        "codes": [
            "79427"
        ]
    },
    {
        "city": "Malsburg-Marzell",
        "codes": [
            "79429"
        ]
    },
    {
        "city": "Lörrach",
        "codes": [
            "79539",
            "79540",
            "79541"
        ]
    },
    {
        "city": "Weil am Rhein",
        "codes": [
            "79576"
        ]
    },
    {
        "city": "Steinen",
        "codes": [
            "79585"
        ]
    },
    {
        "city": "Efringen-Kirchen",
        "codes": [
            "79588"
        ]
    },
    {
        "city": "Binzen",
        "codes": [
            "79589"
        ]
    },
    {
        "city": "Eimeldingen",
        "codes": [
            "79591"
        ]
    },
    {
        "city": "Fischingen",
        "codes": [
            "79592"
        ]
    },
    {
        "city": "Inzlingen",
        "codes": [
            "79594"
        ]
    },
    {
        "city": "Rümmingen",
        "codes": [
            "79595"
        ]
    },
    {
        "city": "Schallbach",
        "codes": [
            "79597"
        ]
    },
    {
        "city": "Wittlingen",
        "codes": [
            "79599"
        ]
    },
    {
        "city": "Rheinfelden (Baden)",
        "codes": [
            "79618"
        ]
    },
    {
        "city": "Grenzach-Wyhlen",
        "codes": [
            "79639"
        ]
    },
    {
        "city": "Schopfheim",
        "codes": [
            "79650"
        ]
    },
    {
        "city": "Zell im Wiesental",
        "codes": [
            "79669"
        ]
    },
    {
        "city": "Todtnau",
        "codes": [
            "79674"
        ]
    },
    {
        "city": "Schönau im Schwarzwald",
        "codes": [
            "79677"
        ]
    },
    {
        "city": "Todtmoos",
        "codes": [
            "79682"
        ]
    },
    {
        "city": "Bürchau",
        "codes": [
            "79683"
        ]
    },
    {
        "city": "Häg-Ehrsberg",
        "codes": [
            "79685"
        ]
    },
    {
        "city": "Hasel",
        "codes": [
            "79686"
        ]
    },
    {
        "city": "Hausen im Wiesental",
        "codes": [
            "79688"
        ]
    },
    {
        "city": "Maulburg",
        "codes": [
            "79689"
        ]
    },
    {
        "city": "Neuenweg",
        "codes": [
            "79691"
        ]
    },
    {
        "city": "Tegernau",
        "codes": [
            "79692"
        ]
    },
    {
        "city": "Utzenfeld",
        "codes": [
            "79694"
        ]
    },
    {
        "city": "Wieden",
        "codes": [
            "79695"
        ]
    },
    {
        "city": "Wies",
        "codes": [
            "79697"
        ]
    },
    {
        "city": "Wieslet",
        "codes": [
            "79699"
        ]
    },
    {
        "city": "Bad Säckingen",
        "codes": [
            "79713"
        ]
    },
    {
        "city": "Laufenburg (Baden)",
        "codes": [
            "79725"
        ]
    },
    {
        "city": "Murg",
        "codes": [
            "79730"
        ]
    },
    {
        "city": "Görwihl",
        "codes": [
            "79733"
        ]
    },
    {
        "city": "Rickenbach",
        "codes": [
            "79736"
        ]
    },
    {
        "city": "Herrischried",
        "codes": [
            "79737"
        ]
    },
    {
        "city": "Schwörstadt",
        "codes": [
            "79739"
        ]
    },
    {
        "city": "Waldshut-Tiengen",
        "codes": [
            "79761"
        ]
    },
    {
        "city": "Klettgau",
        "codes": [
            "79771"
        ]
    },
    {
        "city": "Albbruck",
        "codes": [
            "79774"
        ]
    },
    {
        "city": "Ühlingen-Birkendorf",
        "codes": [
            "79777"
        ]
    },
    {
        "city": "Stühlingen",
        "codes": [
            "79780"
        ]
    },
    {
        "city": "Lauchringen",
        "codes": [
            "79787"
        ]
    },
    {
        "city": "Küssaberg",
        "codes": [
            "79790"
        ]
    },
    {
        "city": "Wutöschingen",
        "codes": [
            "79793"
        ]
    },
    {
        "city": "Jestetten",
        "codes": [
            "79798"
        ]
    },
    {
        "city": "Hohentengen am Hochrhein",
        "codes": [
            "79801"
        ]
    },
    {
        "city": "Dettighofen",
        "codes": [
            "79802"
        ]
    },
    {
        "city": "Dogern",
        "codes": [
            "79804"
        ]
    },
    {
        "city": "Eggingen",
        "codes": [
            "79805"
        ]
    },
    {
        "city": "Lottstetten",
        "codes": [
            "79807"
        ]
    },
    {
        "city": "Weilheim",
        "codes": [
            "79809"
        ]
    },
    {
        "city": "Titisee-Neustadt",
        "codes": [
            "79822"
        ]
    },
    {
        "city": "Sankt Blasien",
        "codes": [
            "79837"
        ]
    },
    {
        "city": "Löffingen",
        "codes": [
            "79843"
        ]
    },
    {
        "city": "Bonndorf im Schwarzwald",
        "codes": [
            "79848"
        ]
    },
    {
        "city": "Lenzkirch",
        "codes": [
            "79853"
        ]
    },
    {
        "city": "Hinterzarten",
        "codes": [
            "79856"
        ]
    },
    {
        "city": "Schluchsee",
        "codes": [
            "79859"
        ]
    },
    {
        "city": "Höchenschwand",
        "codes": [
            "79862"
        ]
    },
    {
        "city": "Grafenhausen",
        "codes": [
            "79865"
        ]
    },
    {
        "city": "Feldberg (Schwarzwald)",
        "codes": [
            "79868"
        ]
    },
    {
        "city": "Eisenbach (Hochschwarzwald)",
        "codes": [
            "79871"
        ]
    },
    {
        "city": "Bernau",
        "codes": [
            "79872"
        ]
    },
    {
        "city": "Breitnau",
        "codes": [
            "79874"
        ]
    },
    {
        "city": "Dachsberg (Südschwarzwald)",
        "codes": [
            "79875"
        ]
    },
    {
        "city": "Friedenweiler",
        "codes": [
            "79877"
        ]
    },
    {
        "city": "Wutach",
        "codes": [
            "79879"
        ]
    },
    {
        "city": "München",
        "codes": [
            "80331",
            "80333",
            "80335",
            "80336",
            "80337",
            "80339",
            "80469",
            "80538",
            "80539",
            "80634",
            "80636",
            "80637",
            "80638",
            "80639",
            "80686",
            "80687",
            "80689",
            "80796",
            "80797",
            "80798",
            "80799",
            "80801",
            "80802",
            "80803",
            "80804",
            "80805",
            "80807",
            "80809",
            "80933",
            "80935",
            "80937",
            "80939",
            "80992",
            "80993",
            "80995",
            "80997",
            "80999",
            "81241",
            "81243",
            "81245",
            "81247",
            "81249",
            "81369",
            "81371",
            "81373",
            "81375",
            "81377",
            "81379",
            "81475",
            "81476",
            "81477",
            "81479",
            "81539",
            "81541",
            "81543",
            "81545",
            "81547",
            "81549",
            "81667",
            "81669",
            "81671",
            "81673",
            "81675",
            "81677",
            "81679",
            "81735",
            "81737",
            "81739",
            "81825",
            "81827",
            "81829",
            "81925",
            "81927",
            "81929"
        ]
    },
    {
        "city": "Unterhaching",
        "codes": [
            "82008"
        ]
    },
    {
        "city": "Taufkirchen",
        "codes": [
            "82024",
            "84574"
        ]
    },
    {
        "city": "Grünwald",
        "codes": [
            "82031"
        ]
    },
    {
        "city": "Oberhaching",
        "codes": [
            "82041"
        ]
    },
    {
        "city": "Pullach im Isartal",
        "codes": [
            "82049"
        ]
    },
    {
        "city": "Sauerlach",
        "codes": [
            "82054"
        ]
    },
    {
        "city": "Icking",
        "codes": [
            "82057"
        ]
    },
    {
        "city": "Straßlach-Dingharting",
        "codes": [
            "82064"
        ]
    },
    {
        "city": "Baierbrunn",
        "codes": [
            "82065"
        ]
    },
    {
        "city": "Schäftlarn",
        "codes": [
            "82067",
            "82069"
        ]
    },
    {
        "city": "Germering",
        "codes": [
            "82110"
        ]
    },
    {
        "city": "Gauting",
        "codes": [
            "82131"
        ]
    },
    {
        "city": "Olching",
        "codes": [
            "82140"
        ]
    },
    {
        "city": "Planegg",
        "codes": [
            "82152"
        ]
    },
    {
        "city": "Gräfelfing",
        "codes": [
            "82166"
        ]
    },
    {
        "city": "Puchheim",
        "codes": [
            "82178"
        ]
    },
    {
        "city": "Gröbenzell",
        "codes": [
            "82194"
        ]
    },
    {
        "city": "Gilching",
        "codes": [
            "82205"
        ]
    },
    {
        "city": "Herrsching am Ammersee",
        "codes": [
            "82211"
        ]
    },
    {
        "city": "Maisach",
        "codes": [
            "82216"
        ]
    },
    {
        "city": "Eichenau",
        "codes": [
            "82223"
        ]
    },
    {
        "city": "Seefeld",
        "codes": [
            "82229"
        ]
    },
    {
        "city": "Weßling",
        "codes": [
            "82234"
        ]
    },
    {
        "city": "Wörthsee",
        "codes": [
            "82237"
        ]
    },
    {
        "city": "Alling",
        "codes": [
            "82239"
        ]
    },
    {
        "city": "Fürstenfeldbruck",
        "codes": [
            "82256"
        ]
    },
    {
        "city": "Inning am Ammersee",
        "codes": [
            "82266"
        ]
    },
    {
        "city": "Geltendorf",
        "codes": [
            "82269"
        ]
    },
    {
        "city": "Moorenweis",
        "codes": [
            "82272"
        ]
    },
    {
        "city": "Emmering",
        "codes": [
            "82275",
            "83550"
        ]
    },
    {
        "city": "Adelshofen",
        "codes": [
            "82276",
            "91587"
        ]
    },
    {
        "city": "Althegnenberg",
        "codes": [
            "82278"
        ]
    },
    {
        "city": "Eching am Ammersee",
        "codes": [
            "82279"
        ]
    },
    {
        "city": "Egenhofen",
        "codes": [
            "82281",
            "82282"
        ]
    },
    {
        "city": "Grafrath",
        "codes": [
            "82284"
        ]
    },
    {
        "city": "Jesenwang",
        "codes": [
            "82287"
        ]
    },
    {
        "city": "Kottgeisering",
        "codes": [
            "82288"
        ]
    },
    {
        "city": "Landsberied",
        "codes": [
            "82290"
        ]
    },
    {
        "city": "Mammendorf",
        "codes": [
            "82291"
        ]
    },
    {
        "city": "Mittelstetten",
        "codes": [
            "82293"
        ]
    },
    {
        "city": "Oberschweinbach",
        "codes": [
            "82294"
        ]
    },
    {
        "city": "Schöngeising",
        "codes": [
            "82296"
        ]
    },
    {
        "city": "Steindorf",
        "codes": [
            "82297"
        ]
    },
    {
        "city": "Türkenfeld",
        "codes": [
            "82299"
        ]
    },
    {
        "city": "Starnberg",
        "codes": [
            "82319"
        ]
    },
    {
        "city": "Tutzing",
        "codes": [
            "82327"
        ]
    },
    {
        "city": "Berg",
        "codes": [
            "82335",
            "88276",
            "95180"
        ]
    },
    {
        "city": "Feldafing",
        "codes": [
            "82340"
        ]
    },
    {
        "city": "Pöcking",
        "codes": [
            "82343"
        ]
    },
    {
        "city": "Andechs",
        "codes": [
            "82346"
        ]
    },
    {
        "city": "Bernried",
        "codes": [
            "82347",
            "94505"
        ]
    },
    {
        "city": "Krailling",
        "codes": [
            "82349"
        ]
    },
    {
        "city": "Weilheim in Oberbayern",
        "codes": [
            "82362"
        ]
    },
    {
        "city": "Penzberg",
        "codes": [
            "82377"
        ]
    },
    {
        "city": "Peißenberg",
        "codes": [
            "82380"
        ]
    },
    {
        "city": "Hohenpeißenberg",
        "codes": [
            "82383"
        ]
    },
    {
        "city": "Huglfing",
        "codes": [
            "82386"
        ]
    },
    {
        "city": "Antdorf",
        "codes": [
            "82387"
        ]
    },
    {
        "city": "Böbing",
        "codes": [
            "82389"
        ]
    },
    {
        "city": "Eberfing",
        "codes": [
            "82390"
        ]
    },
    {
        "city": "Habach",
        "codes": [
            "82392"
        ]
    },
    {
        "city": "Iffeldorf",
        "codes": [
            "82393"
        ]
    },
    {
        "city": "Obersöchering",
        "codes": [
            "82395"
        ]
    },
    {
        "city": "Pähl",
        "codes": [
            "82396"
        ]
    },
    {
        "city": "Polling",
        "codes": [
            "82398",
            "84570"
        ]
    },
    {
        "city": "Raisting",
        "codes": [
            "82399"
        ]
    },
    {
        "city": "Rottenbuch",
        "codes": [
            "82401"
        ]
    },
    {
        "city": "Seeshaupt",
        "codes": [
            "82402"
        ]
    },
    {
        "city": "Sindelsdorf",
        "codes": [
            "82404"
        ]
    },
    {
        "city": "Wessobrunn",
        "codes": [
            "82405"
        ]
    },
    {
        "city": "Wielenbach",
        "codes": [
            "82407"
        ]
    },
    {
        "city": "Wildsteig",
        "codes": [
            "82409"
        ]
    },
    {
        "city": "Murnau am Staffelsee",
        "codes": [
            "82418"
        ]
    },
    {
        "city": "Kochel am See",
        "codes": [
            "82431",
            "82432"
        ]
    },
    {
        "city": "Bad Kohlgrub",
        "codes": [
            "82433"
        ]
    },
    {
        "city": "Bayersoien",
        "codes": [
            "82435"
        ]
    },
    {
        "city": "Eglfing",
        "codes": [
            "82436"
        ]
    },
    {
        "city": "Eschenlohe",
        "codes": [
            "82438"
        ]
    },
    {
        "city": "Großweil",
        "codes": [
            "82439"
        ]
    },
    {
        "city": "Ohlstadt",
        "codes": [
            "82441"
        ]
    },
    {
        "city": "Saulgrub",
        "codes": [
            "82442"
        ]
    },
    {
        "city": "Schlehdorf",
        "codes": [
            "82444"
        ]
    },
    {
        "city": "Schwaigen",
        "codes": [
            "82445"
        ]
    },
    {
        "city": "Spatzenhausen",
        "codes": [
            "82447"
        ]
    },
    {
        "city": "Uffing am Staffelsee",
        "codes": [
            "82449"
        ]
    },
    {
        "city": "Garmisch-Partenkirchen",
        "codes": [
            "82467",
            "82475"
        ]
    },
    {
        "city": "Mittenwald",
        "codes": [
            "82481"
        ]
    },
    {
        "city": "Oberammergau",
        "codes": [
            "82487"
        ]
    },
    {
        "city": "Ettal",
        "codes": [
            "82488"
        ]
    },
    {
        "city": "Farchant",
        "codes": [
            "82490"
        ]
    },
    {
        "city": "Grainau",
        "codes": [
            "82491"
        ]
    },
    {
        "city": "Krün",
        "codes": [
            "82493",
            "82494"
        ]
    },
    {
        "city": "Oberau",
        "codes": [
            "82496"
        ]
    },
    {
        "city": "Unterammergau",
        "codes": [
            "82497"
        ]
    },
    {
        "city": "Wallgau",
        "codes": [
            "82499"
        ]
    },
    {
        "city": "Wolfratshausen",
        "codes": [
            "82515"
        ]
    },
    {
        "city": "Geretsried",
        "codes": [
            "82538"
        ]
    },
    {
        "city": "Münsing",
        "codes": [
            "82541"
        ]
    },
    {
        "city": "Egling",
        "codes": [
            "82544"
        ]
    },
    {
        "city": "Eurasburg",
        "codes": [
            "82547",
            "86495"
        ]
    },
    {
        "city": "Königsdorf",
        "codes": [
            "82549"
        ]
    },
    {
        "city": "Rosenheim",
        "codes": [
            "83022",
            "83024",
            "83026"
        ]
    },
    {
        "city": "Bad Aibling",
        "codes": [
            "83043"
        ]
    },
    {
        "city": "Bruckmühl",
        "codes": [
            "83052"
        ]
    },
    {
        "city": "Kolbermoor",
        "codes": [
            "83059"
        ]
    },
    {
        "city": "Raubling",
        "codes": [
            "83064"
        ]
    },
    {
        "city": "Stephanskirchen",
        "codes": [
            "83071"
        ]
    },
    {
        "city": "Bad Feilnbach",
        "codes": [
            "83075"
        ]
    },
    {
        "city": "Oberaudorf",
        "codes": [
            "83080"
        ]
    },
    {
        "city": "Riedering",
        "codes": [
            "83083"
        ]
    },
    {
        "city": "Kiefersfelden",
        "codes": [
            "83088"
        ]
    },
    {
        "city": "Bad Endorf",
        "codes": [
            "83093"
        ]
    },
    {
        "city": "Brannenburg",
        "codes": [
            "83098"
        ]
    },
    {
        "city": "Tuntenhausen",
        "codes": [
            "83104"
        ]
    },
    {
        "city": "Großkarolinenfeld",
        "codes": [
            "83109"
        ]
    },
    {
        "city": "Frasdorf",
        "codes": [
            "83112"
        ]
    },
    {
        "city": "Neubeuern",
        "codes": [
            "83115"
        ]
    },
    {
        "city": "Obing",
        "codes": [
            "83119"
        ]
    },
    {
        "city": "Samerberg",
        "codes": [
            "83122"
        ]
    },
    {
        "city": "Amerang",
        "codes": [
            "83123",
            "83552"
        ]
    },
    {
        "city": "Eggstätt",
        "codes": [
            "83125"
        ]
    },
    {
        "city": "Flintsbach am Inn",
        "codes": [
            "83126"
        ]
    },
    {
        "city": "Halfing",
        "codes": [
            "83128"
        ]
    },
    {
        "city": "Höslwang",
        "codes": [
            "83129"
        ]
    },
    {
        "city": "Nußdorf am Inn",
        "codes": [
            "83131"
        ]
    },
    {
        "city": "Pittenhart",
        "codes": [
            "83132"
        ]
    },
    {
        "city": "Prutting",
        "codes": [
            "83134"
        ]
    },
    {
        "city": "Schechen",
        "codes": [
            "83135"
        ]
    },
    {
        "city": "Schonstett",
        "codes": [
            "83137"
        ]
    },
    {
        "city": "Söchtenau",
        "codes": [
            "83139"
        ]
    },
    {
        "city": "Prien am Chiemsee",
        "codes": [
            "83209"
        ]
    },
    {
        "city": "Grassau",
        "codes": [
            "83224"
        ]
    },
    {
        "city": "Aschau im Chiemgau",
        "codes": [
            "83229"
        ]
    },
    {
        "city": "Bernau am Chiemsee",
        "codes": [
            "83233"
        ]
    },
    {
        "city": "Übersee",
        "codes": [
            "83236"
        ]
    },
    {
        "city": "Reit im Winkl",
        "codes": [
            "83242"
        ]
    },
    {
        "city": "Unterwössen",
        "codes": [
            "83246"
        ]
    },
    {
        "city": "Marquartstein",
        "codes": [
            "83250"
        ]
    },
    {
        "city": "Rimsting",
        "codes": [
            "83253"
        ]
    },
    {
        "city": "Breitbrunn am Chiemsee",
        "codes": [
            "83254"
        ]
    },
    {
        "city": "Chiemsee",
        "codes": [
            "83256"
        ]
    },
    {
        "city": "Gstadt am Chiemsee",
        "codes": [
            "83257"
        ]
    },
    {
        "city": "Schleching",
        "codes": [
            "83259"
        ]
    },
    {
        "city": "Traunstein",
        "codes": [
            "83278"
        ]
    },
    {
        "city": "Traunreut",
        "codes": [
            "83301",
            "83368",
            "83371",
            "83374"
        ]
    },
    {
        "city": "Trostberg",
        "codes": [
            "83308"
        ]
    },
    {
        "city": "Siegsdorf",
        "codes": [
            "83313"
        ]
    },
    {
        "city": "Teisendorf",
        "codes": [
            "83317",
            "83364"
        ]
    },
    {
        "city": "Ruhpolding",
        "codes": [
            "83324"
        ]
    },
    {
        "city": "Waging am See",
        "codes": [
            "83329"
        ]
    },
    {
        "city": "Inzell",
        "codes": [
            "83334"
        ]
    },
    {
        "city": "Chieming",
        "codes": [
            "83339"
        ]
    },
    {
        "city": "Tacherting",
        "codes": [
            "83342"
        ]
    },
    {
        "city": "Palling",
        "codes": [
            "83349"
        ]
    },
    {
        "city": "Altenmarkt an der Alz",
        "codes": [
            "83352"
        ]
    },
    {
        "city": "Grabenstätt",
        "codes": [
            "83355"
        ]
    },
    {
        "city": "Seeon-Seebruck",
        "codes": [
            "83358",
            "83370",
            "83376"
        ]
    },
    {
        "city": "Surberg",
        "codes": [
            "83359",
            "83362"
        ]
    },
    {
        "city": "Kienberg",
        "codes": [
            "83361"
        ]
    },
    {
        "city": "Nußdorf",
        "codes": [
            "83365"
        ]
    },
    {
        "city": "Petting",
        "codes": [
            "83367"
        ]
    },
    {
        "city": "Taching am See",
        "codes": [
            "83373"
        ]
    },
    {
        "city": "Vachendorf",
        "codes": [
            "83377"
        ]
    },
    {
        "city": "Wonneberg",
        "codes": [
            "83379"
        ]
    },
    {
        "city": "Freilassing",
        "codes": [
            "83395"
        ]
    },
    {
        "city": "Ainring",
        "codes": [
            "83404"
        ]
    },
    {
        "city": "Laufen",
        "codes": [
            "83410"
        ]
    },
    {
        "city": "Fridolfing",
        "codes": [
            "83413"
        ]
    },
    {
        "city": "Saaldorf",
        "codes": [
            "83416"
        ]
    },
    {
        "city": "Kirchanschöring",
        "codes": [
            "83417"
        ]
    },
    {
        "city": "Bad Reichenhall",
        "codes": [
            "83435"
        ]
    },
    {
        "city": "Piding",
        "codes": [
            "83451"
        ]
    },
    {
        "city": "Anger",
        "codes": [
            "83454"
        ]
    },
    {
        "city": "Bayerisch Gmain",
        "codes": [
            "83457"
        ]
    },
    {
        "city": "Schneizlreuth",
        "codes": [
            "83458"
        ]
    },
    {
        "city": "Berchtesgaden",
        "codes": [
            "83471"
        ]
    },
    {
        "city": "Bischofswiesen",
        "codes": [
            "83483",
            "83489"
        ]
    },
    {
        "city": "Ramsau bei Berchtesgaden",
        "codes": [
            "83486"
        ]
    },
    {
        "city": "Marktschellenberg",
        "codes": [
            "83487"
        ]
    },
    {
        "city": "Wasserburg am Inn",
        "codes": [
            "83512"
        ]
    },
    {
        "city": "Haag in Oberbayern",
        "codes": [
            "83527"
        ]
    },
    {
        "city": "Schnaitsee",
        "codes": [
            "83530"
        ]
    },
    {
        "city": "Edling",
        "codes": [
            "83533"
        ]
    },
    {
        "city": "Gars am Inn",
        "codes": [
            "83536",
            "83546",
            "83555",
            "83559"
        ]
    },
    {
        "city": "Pfaffing",
        "codes": [
            "83539"
        ]
    },
    {
        "city": "Rott am Inn",
        "codes": [
            "83543"
        ]
    },
    {
        "city": "Albaching",
        "codes": [
            "83544"
        ]
    },
    {
        "city": "Babensham",
        "codes": [
            "83547"
        ]
    },
    {
        "city": "Eiselfing",
        "codes": [
            "83549"
        ]
    },
    {
        "city": "Frauenneuharting",
        "codes": [
            "83553",
            "83565"
        ]
    },
    {
        "city": "Griesstätt",
        "codes": [
            "83556"
        ]
    },
    {
        "city": "Maitenbeth",
        "codes": [
            "83558"
        ]
    },
    {
        "city": "Ramerberg",
        "codes": [
            "83561"
        ]
    },
    {
        "city": "Rechtmehring",
        "codes": [
            "83562"
        ]
    },
    {
        "city": "Soyen",
        "codes": [
            "83564"
        ]
    },
    {
        "city": "Unterreit",
        "codes": [
            "83567"
        ]
    },
    {
        "city": "Vogtareuth",
        "codes": [
            "83569"
        ]
    },
    {
        "city": "Holzkirchen",
        "codes": [
            "83607"
        ]
    },
    {
        "city": "Feldkirchen-Westerham",
        "codes": [
            "83620"
        ]
    },
    {
        "city": "Dietramszell",
        "codes": [
            "83623"
        ]
    },
    {
        "city": "Otterfing",
        "codes": [
            "83624"
        ]
    },
    {
        "city": "Valley",
        "codes": [
            "83626"
        ]
    },
    {
        "city": "Warngau",
        "codes": [
            "83627"
        ]
    },
    {
        "city": "Weyarn",
        "codes": [
            "83629"
        ]
    },
    {
        "city": "Bad Tölz",
        "codes": [
            "83646"
        ]
    },
    {
        "city": "Lenggries",
        "codes": [
            "83661"
        ]
    },
    {
        "city": "Waakirchen",
        "codes": [
            "83666"
        ]
    },
    {
        "city": "Bad Heilbrunn",
        "codes": [
            "83670"
        ]
    },
    {
        "city": "Benediktbeuern",
        "codes": [
            "83671"
        ]
    },
    {
        "city": "Bichl",
        "codes": [
            "83673"
        ]
    },
    {
        "city": "Gaißach",
        "codes": [
            "83674"
        ]
    },
    {
        "city": "Jachenau",
        "codes": [
            "83676"
        ]
    },
    {
        "city": "Reichersbeuern",
        "codes": [
            "83677"
        ]
    },
    {
        "city": "Sachsenkam",
        "codes": [
            "83679"
        ]
    },
    {
        "city": "Tegernsee",
        "codes": [
            "83684"
        ]
    },
    {
        "city": "Rottach-Egern",
        "codes": [
            "83700"
        ]
    },
    {
        "city": "Gmund am Tegernsee",
        "codes": [
            "83703"
        ]
    },
    {
        "city": "Bad Wiessee",
        "codes": [
            "83707"
        ]
    },
    {
        "city": "Kreuth",
        "codes": [
            "83708"
        ]
    },
    {
        "city": "Miesbach",
        "codes": [
            "83714"
        ]
    },
    {
        "city": "Schliersee",
        "codes": [
            "83727"
        ]
    },
    {
        "city": "Fischbachau",
        "codes": [
            "83730"
        ]
    },
    {
        "city": "Hausham",
        "codes": [
            "83734"
        ]
    },
    {
        "city": "Bayrischzell",
        "codes": [
            "83735"
        ]
    },
    {
        "city": "Irschenberg",
        "codes": [
            "83737",
            "83739"
        ]
    },
    {
        "city": "Landshut",
        "codes": [
            "84028",
            "84034",
            "84036"
        ]
    },
    {
        "city": "Ergolding",
        "codes": [
            "84030"
        ]
    },
    {
        "city": "Mainburg",
        "codes": [
            "84048"
        ]
    },
    {
        "city": "Essenbach",
        "codes": [
            "84051"
        ]
    },
    {
        "city": "Rottenburg an der Laaber",
        "codes": [
            "84056"
        ]
    },
    {
        "city": "Ergoldsbach",
        "codes": [
            "84061"
        ]
    },
    {
        "city": "Mallersdorf-Pfaffenberg",
        "codes": [
            "84066"
        ]
    },
    {
        "city": "Schierling",
        "codes": [
            "84069"
        ]
    },
    {
        "city": "Au in der Hallertau",
        "codes": [
            "84072"
        ]
    },
    {
        "city": "Pfeffenhausen",
        "codes": [
            "84076"
        ]
    },
    {
        "city": "Bruckberg",
        "codes": [
            "84079",
            "91590"
        ]
    },
    {
        "city": "Laberweinting",
        "codes": [
            "84082"
        ]
    },
    {
        "city": "Langquaid",
        "codes": [
            "84085"
        ]
    },
    {
        "city": "Neufahrn in Niederbayern",
        "codes": [
            "84088"
        ]
    },
    {
        "city": "Aiglsbach",
        "codes": [
            "84089"
        ]
    },
    {
        "city": "Attenhofen",
        "codes": [
            "84091"
        ]
    },
    {
        "city": "Bayerbach bei Ergoldsbach",
        "codes": [
            "84092"
        ]
    },
    {
        "city": "Elsendorf",
        "codes": [
            "84094"
        ]
    },
    {
        "city": "Furth",
        "codes": [
            "84095"
        ]
    },
    {
        "city": "Herrngiersdorf",
        "codes": [
            "84097"
        ]
    },
    {
        "city": "Hohenthann",
        "codes": [
            "84098"
        ]
    },
    {
        "city": "Niederaichbach",
        "codes": [
            "84100"
        ]
    },
    {
        "city": "Obersüßbach",
        "codes": [
            "84101"
        ]
    },
    {
        "city": "Postau",
        "codes": [
            "84103"
        ]
    },
    {
        "city": "Rudelzhausen",
        "codes": [
            "84104"
        ]
    },
    {
        "city": "Volkenschwand",
        "codes": [
            "84106"
        ]
    },
    {
        "city": "Weihmichl",
        "codes": [
            "84107"
        ]
    },
    {
        "city": "Wörth an der Isar",
        "codes": [
            "84109"
        ]
    },
    {
        "city": "Dingolfing",
        "codes": [
            "84130"
        ]
    },
    {
        "city": "Vilsbiburg",
        "codes": [
            "84137"
        ]
    },
    {
        "city": "Gangkofen",
        "codes": [
            "84140"
        ]
    },
    {
        "city": "Geisenhausen",
        "codes": [
            "84144"
        ]
    },
    {
        "city": "Velden",
        "codes": [
            "84149",
            "91235"
        ]
    },
    {
        "city": "Mengkofen",
        "codes": [
            "84152"
        ]
    },
    {
        "city": "Bodenkirchen",
        "codes": [
            "84155"
        ]
    },
    {
        "city": "Frontenhausen",
        "codes": [
            "84160"
        ]
    },
    {
        "city": "Marklkofen",
        "codes": [
            "84163"
        ]
    },
    {
        "city": "Moosthenning",
        "codes": [
            "84164"
        ]
    },
    {
        "city": "Adlkofen",
        "codes": [
            "84166"
        ]
    },
    {
        "city": "Aham",
        "codes": [
            "84168"
        ]
    },
    {
        "city": "Altfraunhofen",
        "codes": [
            "84169"
        ]
    },
    {
        "city": "Baierbach",
        "codes": [
            "84171"
        ]
    },
    {
        "city": "Buch am Erlbach",
        "codes": [
            "84172"
        ]
    },
    {
        "city": "Eching",
        "codes": [
            "84174",
            "85386"
        ]
    },
    {
        "city": "Gerzen",
        "codes": [
            "84175"
        ]
    },
    {
        "city": "Gottfrieding",
        "codes": [
            "84177"
        ]
    },
    {
        "city": "Kröning",
        "codes": [
            "84178"
        ]
    },
    {
        "city": "Loiching",
        "codes": [
            "84180"
        ]
    },
    {
        "city": "Neufraunhofen",
        "codes": [
            "84181"
        ]
    },
    {
        "city": "Niederviehbach",
        "codes": [
            "84183"
        ]
    },
    {
        "city": "Vilsheim",
        "codes": [
            "84186"
        ]
    },
    {
        "city": "Weng",
        "codes": [
            "84187"
        ]
    },
    {
        "city": "Wurmsham",
        "codes": [
            "84189"
        ]
    },
    {
        "city": "Eggenfelden",
        "codes": [
            "84307"
        ]
    },
    {
        "city": "Massing",
        "codes": [
            "84323"
        ]
    },
    {
        "city": "Falkenberg",
        "codes": [
            "84326",
            "95685"
        ]
    },
    {
        "city": "Wurmannsquick",
        "codes": [
            "84329"
        ]
    },
    {
        "city": "Hebertsfelden",
        "codes": [
            "84332"
        ]
    },
    {
        "city": "Malgersdorf",
        "codes": [
            "84333"
        ]
    },
    {
        "city": "Mitterskirchen",
        "codes": [
            "84335"
        ]
    },
    {
        "city": "Unterdietfurt",
        "codes": [
            "84339"
        ]
    },
    {
        "city": "Pfarrkirchen",
        "codes": [
            "84347"
        ]
    },
    {
        "city": "Simbach am Inn",
        "codes": [
            "84359"
        ]
    },
    {
        "city": "Bad Birnbach",
        "codes": [
            "84364"
        ]
    },
    {
        "city": "Tann",
        "codes": [
            "84367"
        ]
    },
    {
        "city": "Triftern",
        "codes": [
            "84371"
        ]
    },
    {
        "city": "Kirchdorf am Inn",
        "codes": [
            "84375"
        ]
    },
    {
        "city": "Dietersburg",
        "codes": [
            "84378"
        ]
    },
    {
        "city": "Johanniskirchen",
        "codes": [
            "84381"
        ]
    },
    {
        "city": "Wittibreut",
        "codes": [
            "84384"
        ]
    },
    {
        "city": "Egglham",
        "codes": [
            "84385"
        ]
    },
    {
        "city": "Julbach",
        "codes": [
            "84387"
        ]
    },
    {
        "city": "Postmünster",
        "codes": [
            "84389"
        ]
    },
    {
        "city": "Dorfen",
        "codes": [
            "84405"
        ]
    },
    {
        "city": "Taufkirchen (Vils)",
        "codes": [
            "84416"
        ]
    },
    {
        "city": "Schwindegg",
        "codes": [
            "84419"
        ]
    },
    {
        "city": "Isen",
        "codes": [
            "84424"
        ]
    },
    {
        "city": "Sankt Wolfgang",
        "codes": [
            "84427"
        ]
    },
    {
        "city": "Buchbach",
        "codes": [
            "84428"
        ]
    },
    {
        "city": "Heldenstein",
        "codes": [
            "84431"
        ]
    },
    {
        "city": "Hohenpolding",
        "codes": [
            "84432"
        ]
    },
    {
        "city": "Lengdorf",
        "codes": [
            "84435"
        ]
    },
    {
        "city": "Reichertsheim",
        "codes": [
            "84437"
        ]
    },
    {
        "city": "Steinkirchen",
        "codes": [
            "84439"
        ]
    },
    {
        "city": "Mühldorf am Inn",
        "codes": [
            "84453"
        ]
    },
    {
        "city": "Waldkraiburg",
        "codes": [
            "84478"
        ]
    },
    {
        "city": "Burghausen",
        "codes": [
            "84489"
        ]
    },
    {
        "city": "Neumarkt-Sankt Veit",
        "codes": [
            "84494"
        ]
    },
    {
        "city": "Altötting",
        "codes": [
            "84503"
        ]
    },
    {
        "city": "Burgkirchen an der Alz",
        "codes": [
            "84508"
        ]
    },
    {
        "city": "Töging am Inn",
        "codes": [
            "84513"
        ]
    },
    {
        "city": "Garching an der Alz",
        "codes": [
            "84518"
        ]
    },
    {
        "city": "Neuötting",
        "codes": [
            "84524"
        ]
    },
    {
        "city": "Tittmoning",
        "codes": [
            "84529"
        ]
    },
    {
        "city": "Marktl",
        "codes": [
            "84533"
        ]
    },
    {
        "city": "Ampfing",
        "codes": [
            "84539"
        ]
    },
    {
        "city": "Winhöring",
        "codes": [
            "84543"
        ]
    },
    {
        "city": "Aschau am Inn",
        "codes": [
            "84544"
        ]
    },
    {
        "city": "Egglkofen",
        "codes": [
            "84546"
        ]
    },
    {
        "city": "Emmerting",
        "codes": [
            "84547"
        ]
    },
    {
        "city": "Engelsberg",
        "codes": [
            "84549"
        ]
    },
    {
        "city": "Feichten an der Alz",
        "codes": [
            "84550"
        ]
    },
    {
        "city": "Geratskirchen",
        "codes": [
            "84552"
        ]
    },
    {
        "city": "Halsbach",
        "codes": [
            "84553"
        ]
    },
    {
        "city": "Jettenbach",
        "codes": [
            "84555"
        ]
    },
    {
        "city": "Kastl",
        "codes": [
            "84556",
            "92280",
            "95506"
        ]
    },
    {
        "city": "Kirchweidach",
        "codes": [
            "84558"
        ]
    },
    {
        "city": "Kraiburg am Inn",
        "codes": [
            "84559"
        ]
    },
    {
        "city": "Oberbergkirchen",
        "codes": [
            "84564"
        ]
    },
    {
        "city": "Oberneukirchen",
        "codes": [
            "84565"
        ]
    },
    {
        "city": "Pleiskirchen",
        "codes": [
            "84568"
        ]
    },
    {
        "city": "Reischach",
        "codes": [
            "84571"
        ]
    },
    {
        "city": "Teising",
        "codes": [
            "84576"
        ]
    },
    {
        "city": "Tüßling",
        "codes": [
            "84577"
        ]
    },
    {
        "city": "Unterneukirchen",
        "codes": [
            "84579"
        ]
    },
    {
        "city": "Ingolstadt",
        "codes": [
            "85049",
            "85051",
            "85053",
            "85055",
            "85057"
        ]
    },
    {
        "city": "Eichstätt",
        "codes": [
            "85072"
        ]
    },
    {
        "city": "Manching",
        "codes": [
            "85077"
        ]
    },
    {
        "city": "Gaimersheim",
        "codes": [
            "85080"
        ]
    },
    {
        "city": "Reichertshofen",
        "codes": [
            "85084"
        ]
    },
    {
        "city": "Vohburg an der Donau",
        "codes": [
            "85088"
        ]
    },
    {
        "city": "Kösching",
        "codes": [
            "85092"
        ]
    },
    {
        "city": "Großmehring",
        "codes": [
            "85098"
        ]
    },
    {
        "city": "Lenting",
        "codes": [
            "85101"
        ]
    },
    {
        "city": "Pförring",
        "codes": [
            "85104"
        ]
    },
    {
        "city": "Baar-Ebenhausen",
        "codes": [
            "85107"
        ]
    },
    {
        "city": "Kipfenberg",
        "codes": [
            "85110"
        ]
    },
    {
        "city": "Adelschlag",
        "codes": [
            "85111"
        ]
    },
    {
        "city": "Böhmfeld",
        "codes": [
            "85113"
        ]
    },
    {
        "city": "Buxheim",
        "codes": [
            "85114",
            "87740"
        ]
    },
    {
        "city": "Egweil",
        "codes": [
            "85116"
        ]
    },
    {
        "city": "Eitensheim",
        "codes": [
            "85117"
        ]
    },
    {
        "city": "Ernsgaden",
        "codes": [
            "85119"
        ]
    },
    {
        "city": "Hepberg",
        "codes": [
            "85120"
        ]
    },
    {
        "city": "Hitzhofen",
        "codes": [
            "85122"
        ]
    },
    {
        "city": "Karlskron",
        "codes": [
            "85123"
        ]
    },
    {
        "city": "Kinding",
        "codes": [
            "85125"
        ]
    },
    {
        "city": "Münchsmünster",
        "codes": [
            "85126"
        ]
    },
    {
        "city": "Nassenfels",
        "codes": [
            "85128"
        ]
    },
    {
        "city": "Oberdolling",
        "codes": [
            "85129"
        ]
    },
    {
        "city": "Pollenfeld",
        "codes": [
            "85131"
        ]
    },
    {
        "city": "Schernfeld",
        "codes": [
            "85132"
        ]
    },
    {
        "city": "Stammham",
        "codes": [
            "85134"
        ]
    },
    {
        "city": "Titting",
        "codes": [
            "85135"
        ]
    },
    {
        "city": "Walting",
        "codes": [
            "85137"
        ]
    },
    {
        "city": "Wettstetten",
        "codes": [
            "85139"
        ]
    },
    {
        "city": "Dachau",
        "codes": [
            "85221"
        ]
    },
    {
        "city": "Markt Indersdorf",
        "codes": [
            "85229"
        ]
    },
    {
        "city": "Bergkirchen",
        "codes": [
            "85232"
        ]
    },
    {
        "city": "Odelzhausen",
        "codes": [
            "85235"
        ]
    },
    {
        "city": "Petershausen",
        "codes": [
            "85238"
        ]
    },
    {
        "city": "Hebertshausen",
        "codes": [
            "85241"
        ]
    },
    {
        "city": "Röhrmoos",
        "codes": [
            "85244"
        ]
    },
    {
        "city": "Schwabhausen",
        "codes": [
            "85247"
        ]
    },
    {
        "city": "Altomünster",
        "codes": [
            "85250"
        ]
    },
    {
        "city": "Erdweg",
        "codes": [
            "85253"
        ]
    },
    {
        "city": "Sulzemoos",
        "codes": [
            "85254",
            "85259"
        ]
    },
    {
        "city": "Vierkirchen",
        "codes": [
            "85256"
        ]
    },
    {
        "city": "Weichs",
        "codes": [
            "85258"
        ]
    },
    {
        "city": "Pfaffenhofen an der Ilm",
        "codes": [
            "85276"
        ]
    },
    {
        "city": "Wolnzach",
        "codes": [
            "85283"
        ]
    },
    {
        "city": "Geisenfeld",
        "codes": [
            "85290"
        ]
    },
    {
        "city": "Reichertshausen",
        "codes": [
            "85293"
        ]
    },
    {
        "city": "Rohrbach",
        "codes": [
            "85296"
        ]
    },
    {
        "city": "Scheyern",
        "codes": [
            "85298"
        ]
    },
    {
        "city": "Schweitenkirchen",
        "codes": [
            "85301"
        ]
    },
    {
        "city": "Gerolsbach",
        "codes": [
            "85302"
        ]
    },
    {
        "city": "Ilmmünster",
        "codes": [
            "85304"
        ]
    },
    {
        "city": "Jetzendorf",
        "codes": [
            "85305"
        ]
    },
    {
        "city": "Paunzhausen",
        "codes": [
            "85307"
        ]
    },
    {
        "city": "Pörnbach",
        "codes": [
            "85309"
        ]
    },
    {
        "city": "Freising",
        "codes": [
            "85354",
            "85356"
        ]
    },
    {
        "city": "Moosburg an der Isar",
        "codes": [
            "85368"
        ]
    },
    {
        "city": "Neufahrn bei Freising",
        "codes": [
            "85375",
            "85376"
        ]
    },
    {
        "city": "Allershausen",
        "codes": [
            "85391"
        ]
    },
    {
        "city": "Attenkirchen",
        "codes": [
            "85395"
        ]
    },
    {
        "city": "Hallbergmoos",
        "codes": [
            "85399"
        ]
    },
    {
        "city": "Kranzberg",
        "codes": [
            "85402"
        ]
    },
    {
        "city": "Nandlstadt",
        "codes": [
            "85405"
        ]
    },
    {
        "city": "Zolling",
        "codes": [
            "85406"
        ]
    },
    {
        "city": "Gammelsdorf",
        "codes": [
            "85408"
        ]
    },
    {
        "city": "Haag an der Amper",
        "codes": [
            "85410"
        ]
    },
    {
        "city": "Hohenkammer",
        "codes": [
            "85411"
        ]
    },
    {
        "city": "Hörgertshausen",
        "codes": [
            "85413"
        ]
    },
    {
        "city": "Kirchdorf an der Amper",
        "codes": [
            "85414"
        ]
    },
    {
        "city": "Marzling",
        "codes": [
            "85417"
        ]
    },
    {
        "city": "Mauern",
        "codes": [
            "85419"
        ]
    },
    {
        "city": "Erding",
        "codes": [
            "85435"
        ]
    },
    {
        "city": "Oberding",
        "codes": [
            "85445"
        ]
    },
    {
        "city": "Fraunberg",
        "codes": [
            "85447"
        ]
    },
    {
        "city": "Moosinning",
        "codes": [
            "85452"
        ]
    },
    {
        "city": "Wörth",
        "codes": [
            "85457"
        ]
    },
    {
        "city": "Berglern",
        "codes": [
            "85459"
        ]
    },
    {
        "city": "Eitting",
        "codes": [
            "85462"
        ]
    },
    {
        "city": "Finsing",
        "codes": [
            "85464"
        ]
    },
    {
        "city": "Langenpreising",
        "codes": [
            "85465"
        ]
    },
    {
        "city": "Neuching",
        "codes": [
            "85467"
        ]
    },
    {
        "city": "Walpertskirchen",
        "codes": [
            "85469"
        ]
    },
    {
        "city": "Ottobrunn",
        "codes": [
            "85521"
        ]
    },
    {
        "city": "Haar",
        "codes": [
            "85540"
        ]
    },
    {
        "city": "Kirchheim bei München",
        "codes": [
            "85551"
        ]
    },
    {
        "city": "Ebersberg",
        "codes": [
            "85560"
        ]
    },
    {
        "city": "Grafing bei München",
        "codes": [
            "85567"
        ]
    },
    {
        "city": "Markt Schwaben",
        "codes": [
            "85570"
        ]
    },
    {
        "city": "Neubiberg",
        "codes": [
            "85579"
        ]
    },
    {
        "city": "Poing",
        "codes": [
            "85586"
        ]
    },
    {
        "city": "Vaterstetten",
        "codes": [
            "85591",
            "85599"
        ]
    },
    {
        "city": "Zorneding",
        "codes": [
            "85598",
            "85604"
        ]
    },
    {
        "city": "Aschheim",
        "codes": [
            "85609"
        ]
    },
    {
        "city": "Kirchseeon",
        "codes": [
            "85614"
        ]
    },
    {
        "city": "Aßling",
        "codes": [
            "85617"
        ]
    },
    {
        "city": "Feldkirchen",
        "codes": [
            "85622",
            "94351"
        ]
    },
    {
        "city": "Glonn",
        "codes": [
            "85625"
        ]
    },
    {
        "city": "Grasbrunn",
        "codes": [
            "85630"
        ]
    },
    {
        "city": "Höhenkirchen-Siegertsbrunn",
        "codes": [
            "85635"
        ]
    },
    {
        "city": "Putzbrunn",
        "codes": [
            "85640"
        ]
    },
    {
        "city": "Steinhöring",
        "codes": [
            "85643"
        ]
    },
    {
        "city": "Anzing",
        "codes": [
            "85646"
        ]
    },
    {
        "city": "Brunnthal",
        "codes": [
            "85649"
        ]
    },
    {
        "city": "Pliening",
        "codes": [
            "85652"
        ]
    },
    {
        "city": "Aying",
        "codes": [
            "85653",
            "85655"
        ]
    },
    {
        "city": "Buch am Buchrain",
        "codes": [
            "85656"
        ]
    },
    {
        "city": "Egmating",
        "codes": [
            "85658"
        ]
    },
    {
        "city": "Forstern",
        "codes": [
            "85659"
        ]
    },
    {
        "city": "Forstinning",
        "codes": [
            "85661"
        ]
    },
    {
        "city": "Hohenbrunn",
        "codes": [
            "85662"
        ]
    },
    {
        "city": "Hohenlinden",
        "codes": [
            "85664"
        ]
    },
    {
        "city": "Moosach",
        "codes": [
            "85665"
        ]
    },
    {
        "city": "Oberpframmern",
        "codes": [
            "85667"
        ]
    },
    {
        "city": "Pastetten",
        "codes": [
            "85669"
        ]
    },
    {
        "city": "Unterschleißheim",
        "codes": [
            "85716"
        ]
    },
    {
        "city": "Ismaning",
        "codes": [
            "85737"
        ]
    },
    {
        "city": "Garching bei München",
        "codes": [
            "85748"
        ]
    },
    {
        "city": "Karlsfeld",
        "codes": [
            "85757"
        ]
    },
    {
        "city": "Oberschleißheim",
        "codes": [
            "85764"
        ]
    },
    {
        "city": "Unterföhring",
        "codes": [
            "85774"
        ]
    },
    {
        "city": "Fahrenzhausen",
        "codes": [
            "85777"
        ]
    },
    {
        "city": "Haimhausen",
        "codes": [
            "85778"
        ]
    },
    {
        "city": "Augsburg",
        "codes": [
            "86150",
            "86152",
            "86153",
            "86154",
            "86156",
            "86157",
            "86159",
            "86161",
            "86163",
            "86165",
            "86167",
            "86169",
            "86179",
            "86199"
        ]
    },
    {
        "city": "Friedberg",
        "codes": [
            "86316"
        ]
    },
    {
        "city": "Königsbrunn",
        "codes": [
            "86343"
        ]
    },
    {
        "city": "Neusäß",
        "codes": [
            "86356"
        ]
    },
    {
        "city": "Gersthofen",
        "codes": [
            "86368"
        ]
    },
    {
        "city": "Krumbach (Schwaben)",
        "codes": [
            "86381"
        ]
    },
    {
        "city": "Stadtbergen",
        "codes": [
            "86391"
        ]
    },
    {
        "city": "Bobingen",
        "codes": [
            "86399"
        ]
    },
    {
        "city": "Meitingen",
        "codes": [
            "86405"
        ]
    },
    {
        "city": "Mering",
        "codes": [
            "86415"
        ]
    },
    {
        "city": "Diedorf",
        "codes": [
            "86420"
        ]
    },
    {
        "city": "Dinkelscherben",
        "codes": [
            "86424"
        ]
    },
    {
        "city": "Kissing",
        "codes": [
            "86438"
        ]
    },
    {
        "city": "Zusmarshausen",
        "codes": [
            "86441"
        ]
    },
    {
        "city": "Affing",
        "codes": [
            "86444"
        ]
    },
    {
        "city": "Aindling",
        "codes": [
            "86447"
        ]
    },
    {
        "city": "Altenmünster",
        "codes": [
            "86450"
        ]
    },
    {
        "city": "Dasing",
        "codes": [
            "86453"
        ]
    },
    {
        "city": "Gablingen",
        "codes": [
            "86456"
        ]
    },
    {
        "city": "Gessertshausen",
        "codes": [
            "86459"
        ]
    },
    {
        "city": "Langweid am Lech",
        "codes": [
            "86462"
        ]
    },
    {
        "city": "Welden",
        "codes": [
            "86465"
        ]
    },
    {
        "city": "Thannhausen",
        "codes": [
            "86470"
        ]
    },
    {
        "city": "Ziemetshausen",
        "codes": [
            "86473"
        ]
    },
    {
        "city": "Neuburg an der Kammel",
        "codes": [
            "86476"
        ]
    },
    {
        "city": "Adelsried",
        "codes": [
            "86477"
        ]
    },
    {
        "city": "Aichen",
        "codes": [
            "86479"
        ]
    },
    {
        "city": "Aletshausen",
        "codes": [
            "86480"
        ]
    },
    {
        "city": "Aystetten",
        "codes": [
            "86482"
        ]
    },
    {
        "city": "Balzhausen",
        "codes": [
            "86483"
        ]
    },
    {
        "city": "Biberbach",
        "codes": [
            "86485"
        ]
    },
    {
        "city": "Bonstetten",
        "codes": [
            "86486"
        ]
    },
    {
        "city": "Breitenthal",
        "codes": [
            "86488"
        ]
    },
    {
        "city": "Deisenhausen",
        "codes": [
            "86489"
        ]
    },
    {
        "city": "Ebershausen",
        "codes": [
            "86491"
        ]
    },
    {
        "city": "Egling an der Paar",
        "codes": [
            "86492"
        ]
    },
    {
        "city": "Emersacker",
        "codes": [
            "86494"
        ]
    },
    {
        "city": "Horgau",
        "codes": [
            "86497"
        ]
    },
    {
        "city": "Kettershausen",
        "codes": [
            "86498"
        ]
    },
    {
        "city": "Kutzenhausen",
        "codes": [
            "86500"
        ]
    },
    {
        "city": "Laugna",
        "codes": [
            "86502"
        ]
    },
    {
        "city": "Merching",
        "codes": [
            "86504"
        ]
    },
    {
        "city": "Münsterhausen",
        "codes": [
            "86505"
        ]
    },
    {
        "city": "Oberottmarshausen",
        "codes": [
            "86507"
        ]
    },
    {
        "city": "Rehling",
        "codes": [
            "86508"
        ]
    },
    {
        "city": "Ried",
        "codes": [
            "86510"
        ]
    },
    {
        "city": "Schmiechen",
        "codes": [
            "86511"
        ]
    },
    {
        "city": "Ursberg",
        "codes": [
            "86513"
        ]
    },
    {
        "city": "Ustersbach",
        "codes": [
            "86514"
        ]
    },
    {
        "city": "Wehringen",
        "codes": [
            "86517"
        ]
    },
    {
        "city": "Schrobenhausen",
        "codes": [
            "86529"
        ]
    },
    {
        "city": "Aichach",
        "codes": [
            "86551"
        ]
    },
    {
        "city": "Pöttmes",
        "codes": [
            "86554"
        ]
    },
    {
        "city": "Kühbach",
        "codes": [
            "86556"
        ]
    },
    {
        "city": "Hohenwart",
        "codes": [
            "86558"
        ]
    },
    {
        "city": "Adelzhausen",
        "codes": [
            "86559"
        ]
    },
    {
        "city": "Aresing",
        "codes": [
            "86561"
        ]
    },
    {
        "city": "Berg im Gau",
        "codes": [
            "86562"
        ]
    },
    {
        "city": "Brunnen",
        "codes": [
            "86564"
        ]
    },
    {
        "city": "Gachenbach",
        "codes": [
            "86565"
        ]
    },
    {
        "city": "Hilgertshausen-Tandern",
        "codes": [
            "86567"
        ]
    },
    {
        "city": "Hollenbach",
        "codes": [
            "86568"
        ]
    },
    {
        "city": "Inchenhofen",
        "codes": [
            "86570"
        ]
    },
    {
        "city": "Langenmosen",
        "codes": [
            "86571"
        ]
    },
    {
        "city": "Obergriesbach",
        "codes": [
            "86573"
        ]
    },
    {
        "city": "Petersdorf",
        "codes": [
            "86574"
        ]
    },
    {
        "city": "Schiltberg",
        "codes": [
            "86576"
        ]
    },
    {
        "city": "Sielenbach",
        "codes": [
            "86577"
        ]
    },
    {
        "city": "Waidhofen",
        "codes": [
            "86579"
        ]
    },
    {
        "city": "Donauwörth",
        "codes": [
            "86609"
        ]
    },
    {
        "city": "Neuburg an der Donau",
        "codes": [
            "86633"
        ]
    },
    {
        "city": "Wertingen",
        "codes": [
            "86637"
        ]
    },
    {
        "city": "Rain",
        "codes": [
            "86641",
            "94369"
        ]
    },
    {
        "city": "Rennertshofen",
        "codes": [
            "86643"
        ]
    },
    {
        "city": "Buttenwiesen",
        "codes": [
            "86647"
        ]
    },
    {
        "city": "Wemding",
        "codes": [
            "86650"
        ]
    },
    {
        "city": "Monheim",
        "codes": [
            "86653"
        ]
    },
    {
        "city": "Harburg (Schwaben)",
        "codes": [
            "86655"
        ]
    },
    {
        "city": "Bissingen",
        "codes": [
            "86657"
        ]
    },
    {
        "city": "Tapfheim",
        "codes": [
            "86660"
        ]
    },
    {
        "city": "Asbach-Bäumenheim",
        "codes": [
            "86663"
        ]
    },
    {
        "city": "Burgheim",
        "codes": [
            "86666"
        ]
    },
    {
        "city": "Karlshuld",
        "codes": [
            "86668"
        ]
    },
    {
        "city": "Königsmoos",
        "codes": [
            "86669"
        ]
    },
    {
        "city": "Thierhaupten",
        "codes": [
            "86672"
        ]
    },
    {
        "city": "Baar",
        "codes": [
            "86674"
        ]
    },
    {
        "city": "Buchdorf",
        "codes": [
            "86675"
        ]
    },
    {
        "city": "Ehekirchen",
        "codes": [
            "86676"
        ]
    },
    {
        "city": "Ehingen",
        "codes": [
            "86678",
            "91725"
        ]
    },
    {
        "city": "Ellgau",
        "codes": [
            "86679"
        ]
    },
    {
        "city": "Fünfstetten",
        "codes": [
            "86681"
        ]
    },
    {
        "city": "Genderkingen",
        "codes": [
            "86682"
        ]
    },
    {
        "city": "Holzheim",
        "codes": [
            "86684",
            "89291",
            "89438"
        ]
    },
    {
        "city": "Huisheim",
        "codes": [
            "86685"
        ]
    },
    {
        "city": "Kaisheim",
        "codes": [
            "86687"
        ]
    },
    {
        "city": "Marxheim",
        "codes": [
            "86688"
        ]
    },
    {
        "city": "Mertingen",
        "codes": [
            "86690"
        ]
    },
    {
        "city": "Niederschönenfeld",
        "codes": [
            "86694"
        ]
    },
    {
        "city": "Nordendorf",
        "codes": [
            "86695"
        ]
    },
    {
        "city": "Oberndorf am Lech",
        "codes": [
            "86698"
        ]
    },
    {
        "city": "Otting",
        "codes": [
            "86700"
        ]
    },
    {
        "city": "Rohrenfels",
        "codes": [
            "86701"
        ]
    },
    {
        "city": "Rögling",
        "codes": [
            "86703"
        ]
    },
    {
        "city": "Tagmersheim",
        "codes": [
            "86704"
        ]
    },
    {
        "city": "Weichering",
        "codes": [
            "86706"
        ]
    },
    {
        "city": "Westendorf",
        "codes": [
            "86707",
            "87679"
        ]
    },
    {
        "city": "Wolferstadt",
        "codes": [
            "86709"
        ]
    },
    {
        "city": "Nördlingen",
        "codes": [
            "86720"
        ]
    },
    {
        "city": "Oettingen in Bayern",
        "codes": [
            "86732"
        ]
    },
    {
        "city": "Alerheim",
        "codes": [
            "86733"
        ]
    },
    {
        "city": "Amerdingen",
        "codes": [
            "86735"
        ]
    },
    {
        "city": "Auhausen",
        "codes": [
            "86736"
        ]
    },
    {
        "city": "Deiningen",
        "codes": [
            "86738"
        ]
    },
    {
        "city": "Ederheim",
        "codes": [
            "86739"
        ]
    },
    {
        "city": "Ehingen am Ries",
        "codes": [
            "86741"
        ]
    },
    {
        "city": "Fremdingen",
        "codes": [
            "86742"
        ]
    },
    {
        "city": "Hainsfarth",
        "codes": [
            "86744"
        ]
    },
    {
        "city": "Hohenaltheim",
        "codes": [
            "86745"
        ]
    },
    {
        "city": "Maihingen",
        "codes": [
            "86747"
        ]
    },
    {
        "city": "Marktoffingen",
        "codes": [
            "86748"
        ]
    },
    {
        "city": "Megesheim",
        "codes": [
            "86750"
        ]
    },
    {
        "city": "Mönchsdeggingen",
        "codes": [
            "86751"
        ]
    },
    {
        "city": "Möttingen",
        "codes": [
            "86753"
        ]
    },
    {
        "city": "Munningen",
        "codes": [
            "86754"
        ]
    },
    {
        "city": "Reimlingen",
        "codes": [
            "86756"
        ]
    },
    {
        "city": "Wallerstein",
        "codes": [
            "86757"
        ]
    },
    {
        "city": "Wechingen",
        "codes": [
            "86759"
        ]
    },
    {
        "city": "Buchloe",
        "codes": [
            "86807"
        ]
    },
    {
        "city": "Bad Wörishofen",
        "codes": [
            "86825"
        ]
    },
    {
        "city": "Schwabmünchen",
        "codes": [
            "86830"
        ]
    },
    {
        "city": "Untermeitingen",
        "codes": [
            "86836"
        ]
    },
    {
        "city": "Türkheim",
        "codes": [
            "86842"
        ]
    },
    {
        "city": "Großaitingen",
        "codes": [
            "86845"
        ]
    },
    {
        "city": "Fischach",
        "codes": [
            "86850"
        ]
    },
    {
        "city": "Langerringen",
        "codes": [
            "86853"
        ]
    },
    {
        "city": "Amberg",
        "codes": [
            "86854",
            "92224"
        ]
    },
    {
        "city": "Hiltenfingen",
        "codes": [
            "86856"
        ]
    },
    {
        "city": "Hurlach",
        "codes": [
            "86857"
        ]
    },
    {
        "city": "Igling",
        "codes": [
            "86859"
        ]
    },
    {
        "city": "Jengen",
        "codes": [
            "86860"
        ]
    },
    {
        "city": "Lamerdingen",
        "codes": [
            "86862"
        ]
    },
    {
        "city": "Langenneufnach",
        "codes": [
            "86863"
        ]
    },
    {
        "city": "Markt Wald",
        "codes": [
            "86865"
        ]
    },
    {
        "city": "Mickhausen",
        "codes": [
            "86866"
        ]
    },
    {
        "city": "Mittelneufnach",
        "codes": [
            "86868"
        ]
    },
    {
        "city": "Oberostendorf",
        "codes": [
            "86869"
        ]
    },
    {
        "city": "Rammingen",
        "codes": [
            "86871",
            "89192"
        ]
    },
    {
        "city": "Scherstetten",
        "codes": [
            "86872"
        ]
    },
    {
        "city": "Tussenhausen",
        "codes": [
            "86874"
        ]
    },
    {
        "city": "Waal",
        "codes": [
            "86875"
        ]
    },
    {
        "city": "Walkertshofen",
        "codes": [
            "86877"
        ]
    },
    {
        "city": "Wiedergeltingen",
        "codes": [
            "86879"
        ]
    },
    {
        "city": "Landsberg am Lech",
        "codes": [
            "86899"
        ]
    },
    {
        "city": "Dießen am Ammersee",
        "codes": [
            "86911"
        ]
    },
    {
        "city": "Kaufering",
        "codes": [
            "86916"
        ]
    },
    {
        "city": "Utting am Ammersee",
        "codes": [
            "86919"
        ]
    },
    {
        "city": "Denklingen",
        "codes": [
            "86920"
        ]
    },
    {
        "city": "Eresing",
        "codes": [
            "86922",
            "86941"
        ]
    },
    {
        "city": "Finning",
        "codes": [
            "86923"
        ]
    },
    {
        "city": "Fuchstal",
        "codes": [
            "86925"
        ]
    },
    {
        "city": "Greifenberg",
        "codes": [
            "86926"
        ]
    },
    {
        "city": "Hofstetten",
        "codes": [
            "86928"
        ]
    },
    {
        "city": "Penzing",
        "codes": [
            "86929"
        ]
    },
    {
        "city": "Prittriching",
        "codes": [
            "86931"
        ]
    },
    {
        "city": "Pürgen",
        "codes": [
            "86932"
        ]
    },
    {
        "city": "Reichling",
        "codes": [
            "86934"
        ]
    },
    {
        "city": "Rott",
        "codes": [
            "86935"
        ]
    },
    {
        "city": "Scheuring",
        "codes": [
            "86937"
        ]
    },
    {
        "city": "Schondorf am Ammersee",
        "codes": [
            "86938"
        ]
    },
    {
        "city": "Schwifting",
        "codes": [
            "86940"
        ]
    },
    {
        "city": "Thaining",
        "codes": [
            "86943"
        ]
    },
    {
        "city": "Unterdießen",
        "codes": [
            "86944"
        ]
    },
    {
        "city": "Vilgertshofen",
        "codes": [
            "86946"
        ]
    },
    {
        "city": "Weil",
        "codes": [
            "86947"
        ]
    },
    {
        "city": "Windach",
        "codes": [
            "86949"
        ]
    },
    {
        "city": "Schongau",
        "codes": [
            "86956"
        ]
    },
    {
        "city": "Peiting",
        "codes": [
            "86971"
        ]
    },
    {
        "city": "Apfeldorf",
        "codes": [
            "86974"
        ]
    },
    {
        "city": "Bernbeuren",
        "codes": [
            "86975"
        ]
    },
    {
        "city": "Burggen",
        "codes": [
            "86977"
        ]
    },
    {
        "city": "Hohenfurch",
        "codes": [
            "86978"
        ]
    },
    {
        "city": "Ingenried",
        "codes": [
            "86980"
        ]
    },
    {
        "city": "Kinsau",
        "codes": [
            "86981"
        ]
    },
    {
        "city": "Lechbruck",
        "codes": [
            "86983"
        ]
    },
    {
        "city": "Prem",
        "codes": [
            "86984"
        ]
    },
    {
        "city": "Schwabbruck",
        "codes": [
            "86986"
        ]
    },
    {
        "city": "Schwabsoien",
        "codes": [
            "86987"
        ]
    },
    {
        "city": "Steingaden",
        "codes": [
            "86989"
        ]
    },
    {
        "city": "Kempten (Allgäu)",
        "codes": [
            "87435",
            "87437",
            "87439"
        ]
    },
    {
        "city": "Waltenhofen",
        "codes": [
            "87448"
        ]
    },
    {
        "city": "Altusried",
        "codes": [
            "87452"
        ]
    },
    {
        "city": "Pfronten",
        "codes": [
            "87459"
        ]
    },
    {
        "city": "Dietmannsried",
        "codes": [
            "87463"
        ]
    },
    {
        "city": "Oy-Mittelberg",
        "codes": [
            "87466"
        ]
    },
    {
        "city": "Durach",
        "codes": [
            "87471"
        ]
    },
    {
        "city": "Buchenberg",
        "codes": [
            "87474"
        ]
    },
    {
        "city": "Sulzberg",
        "codes": [
            "87477"
        ]
    },
    {
        "city": "Weitnau",
        "codes": [
            "87480"
        ]
    },
    {
        "city": "Nesselwang",
        "codes": [
            "87484"
        ]
    },
    {
        "city": "Wiggensbach",
        "codes": [
            "87487"
        ]
    },
    {
        "city": "Betzigau",
        "codes": [
            "87488"
        ]
    },
    {
        "city": "Haldenwang",
        "codes": [
            "87490",
            "89356"
        ]
    },
    {
        "city": "Lauben",
        "codes": [
            "87493",
            "87761"
        ]
    },
    {
        "city": "Rückholz",
        "codes": [
            "87494"
        ]
    },
    {
        "city": "Untrasried",
        "codes": [
            "87496"
        ]
    },
    {
        "city": "Wertach",
        "codes": [
            "87497"
        ]
    },
    {
        "city": "Wildpoldsried",
        "codes": [
            "87499"
        ]
    },
    {
        "city": "Immenstadt im Allgäu",
        "codes": [
            "87509"
        ]
    },
    {
        "city": "Sonthofen",
        "codes": [
            "87527"
        ]
    },
    {
        "city": "Oberstaufen",
        "codes": [
            "87534"
        ]
    },
    {
        "city": "Fischen im Allgäu",
        "codes": [
            "87538"
        ]
    },
    {
        "city": "Hindelang",
        "codes": [
            "87541"
        ]
    },
    {
        "city": "Blaichach",
        "codes": [
            "87544"
        ]
    },
    {
        "city": "Burgberg im Allgäu",
        "codes": [
            "87545"
        ]
    },
    {
        "city": "Missen-Wilhams",
        "codes": [
            "87547"
        ]
    },
    {
        "city": "Rettenberg",
        "codes": [
            "87549"
        ]
    },
    {
        "city": "Oberstdorf",
        "codes": [
            "87561"
        ]
    },
    {
        "city": "Kaufbeuren",
        "codes": [
            "87600"
        ]
    },
    {
        "city": "Marktoberdorf",
        "codes": [
            "87616"
        ]
    },
    {
        "city": "Füssen",
        "codes": [
            "87629"
        ]
    },
    {
        "city": "Obergünzburg",
        "codes": [
            "87634"
        ]
    },
    {
        "city": "Seeg",
        "codes": [
            "87637"
        ]
    },
    {
        "city": "Biessenhofen",
        "codes": [
            "87640"
        ]
    },
    {
        "city": "Halblech",
        "codes": [
            "87642"
        ]
    },
    {
        "city": "Schwangau",
        "codes": [
            "87645"
        ]
    },
    {
        "city": "Unterthingau",
        "codes": [
            "87647"
        ]
    },
    {
        "city": "Aitrang",
        "codes": [
            "87648"
        ]
    },
    {
        "city": "Baisweil",
        "codes": [
            "87650"
        ]
    },
    {
        "city": "Bidingen",
        "codes": [
            "87651"
        ]
    },
    {
        "city": "Eggenthal",
        "codes": [
            "87653"
        ]
    },
    {
        "city": "Friesenried",
        "codes": [
            "87654"
        ]
    },
    {
        "city": "Germaringen",
        "codes": [
            "87656"
        ]
    },
    {
        "city": "Görisried",
        "codes": [
            "87657"
        ]
    },
    {
        "city": "Hopferau",
        "codes": [
            "87659"
        ]
    },
    {
        "city": "Irsee",
        "codes": [
            "87660"
        ]
    },
    {
        "city": "Kaltental",
        "codes": [
            "87662"
        ]
    },
    {
        "city": "Lengenwang",
        "codes": [
            "87663"
        ]
    },
    {
        "city": "Mauerstetten",
        "codes": [
            "87665"
        ]
    },
    {
        "city": "Pforzen",
        "codes": [
            "87666"
        ]
    },
    {
        "city": "Rieden",
        "codes": [
            "87668",
            "92286"
        ]
    },
    {
        "city": "Rieden am Forggensee",
        "codes": [
            "87669"
        ]
    },
    {
        "city": "Ronsberg",
        "codes": [
            "87671"
        ]
    },
    {
        "city": "Roßhaupten",
        "codes": [
            "87672"
        ]
    },
    {
        "city": "Ruderatshofen",
        "codes": [
            "87674"
        ]
    },
    {
        "city": "Stötten am Au",
        "codes": [
            "87675"
        ]
    },
    {
        "city": "Stöttwang",
        "codes": [
            "87677"
        ]
    },
    {
        "city": "Memmingen",
        "codes": [
            "87700"
        ]
    },
    {
        "city": "Mindelheim",
        "codes": [
            "87719"
        ]
    },
    {
        "city": "Ottobeuren",
        "codes": [
            "87724"
        ]
    },
    {
        "city": "Grönenbach",
        "codes": [
            "87730"
        ]
    },
    {
        "city": "Markt Rettenbach",
        "codes": [
            "87733"
        ]
    },
    {
        "city": "Benningen",
        "codes": [
            "87734"
        ]
    },
    {
        "city": "Böhen",
        "codes": [
            "87736"
        ]
    },
    {
        "city": "Boos",
        "codes": [
            "87737"
        ]
    },
    {
        "city": "Breitenbrunn",
        "codes": [
            "87739",
            "92363"
        ]
    },
    {
        "city": "Apfeltrach",
        "codes": [
            "87742"
        ]
    },
    {
        "city": "Egg an der Günz",
        "codes": [
            "87743"
        ]
    },
    {
        "city": "Eppishausen",
        "codes": [
            "87745"
        ]
    },
    {
        "city": "Erkheim",
        "codes": [
            "87746"
        ]
    },
    {
        "city": "Fellheim",
        "codes": [
            "87748"
        ]
    },
    {
        "city": "Hawangen",
        "codes": [
            "87749"
        ]
    },
    {
        "city": "Heimertingen",
        "codes": [
            "87751"
        ]
    },
    {
        "city": "Holzgünz",
        "codes": [
            "87752"
        ]
    },
    {
        "city": "Kammlach",
        "codes": [
            "87754"
        ]
    },
    {
        "city": "Kirchhaslach",
        "codes": [
            "87755"
        ]
    },
    {
        "city": "Kirchheim im Schwarzwald",
        "codes": [
            "87757"
        ]
    },
    {
        "city": "Kronburg",
        "codes": [
            "87758"
        ]
    },
    {
        "city": "Lachen",
        "codes": [
            "87760"
        ]
    },
    {
        "city": "Lautrach",
        "codes": [
            "87763"
        ]
    },
    {
        "city": "Legau",
        "codes": [
            "87764"
        ]
    },
    {
        "city": "Memmingerberg",
        "codes": [
            "87766"
        ]
    },
    {
        "city": "Niederrieden",
        "codes": [
            "87767"
        ]
    },
    {
        "city": "Oberrieden",
        "codes": [
            "87769"
        ]
    },
    {
        "city": "Oberschönegg",
        "codes": [
            "87770"
        ]
    },
    {
        "city": "Pfaffenhausen",
        "codes": [
            "87772"
        ]
    },
    {
        "city": "Pleß",
        "codes": [
            "87773"
        ]
    },
    {
        "city": "Salgen",
        "codes": [
            "87775"
        ]
    },
    {
        "city": "Sontheim",
        "codes": [
            "87776"
        ]
    },
    {
        "city": "Stetten",
        "codes": [
            "87778",
            "88719"
        ]
    },
    {
        "city": "Trunkelsberg",
        "codes": [
            "87779"
        ]
    },
    {
        "city": "Ungerhausen",
        "codes": [
            "87781"
        ]
    },
    {
        "city": "Dirlewang",
        "codes": [
            "87782"
        ]
    },
    {
        "city": "Winterrieden",
        "codes": [
            "87785"
        ]
    },
    {
        "city": "Wolfertschwenden",
        "codes": [
            "87787"
        ]
    },
    {
        "city": "Woringen",
        "codes": [
            "87789"
        ]
    },
    {
        "city": "Friedrichshafen",
        "codes": [
            "88045",
            "88046",
            "88048"
        ]
    },
    {
        "city": "Tettnang",
        "codes": [
            "88069"
        ]
    },
    {
        "city": "Meckenbeuren",
        "codes": [
            "88074"
        ]
    },
    {
        "city": "Kressbronn am Bodensee",
        "codes": [
            "88079"
        ]
    },
    {
        "city": "Langenargen",
        "codes": [
            "88085"
        ]
    },
    {
        "city": "Immenstaad am Bodensee",
        "codes": [
            "88090"
        ]
    },
    {
        "city": "Oberteuringen",
        "codes": [
            "88094"
        ]
    },
    {
        "city": "Eriskirch",
        "codes": [
            "88097"
        ]
    },
    {
        "city": "Neukirch",
        "codes": [
            "88099"
        ]
    },
    {
        "city": "Lindau (Bodensee)",
        "codes": [
            "88131"
        ]
    },
    {
        "city": "Sigmarszell",
        "codes": [
            "88138"
        ]
    },
    {
        "city": "Wasserburg (Bodensee)",
        "codes": [
            "88142"
        ]
    },
    {
        "city": "Hergatz",
        "codes": [
            "88145"
        ]
    },
    {
        "city": "Achberg",
        "codes": [
            "88147"
        ]
    },
    {
        "city": "Nonnenhorn",
        "codes": [
            "88149"
        ]
    },
    {
        "city": "Lindenberg im Allgäu",
        "codes": [
            "88161"
        ]
    },
    {
        "city": "Röthenbach (Allgäu)",
        "codes": [
            "88167"
        ]
    },
    {
        "city": "Weiler-Simmerberg",
        "codes": [
            "88171"
        ]
    },
    {
        "city": "Scheidegg",
        "codes": [
            "88175"
        ]
    },
    {
        "city": "Heimenkirch",
        "codes": [
            "88178"
        ]
    },
    {
        "city": "Oberreute",
        "codes": [
            "88179"
        ]
    },
    {
        "city": "Ravensburg",
        "codes": [
            "88212",
            "88213",
            "88214"
        ]
    },
    {
        "city": "Wangen im Allgäu",
        "codes": [
            "88239"
        ]
    },
    {
        "city": "Weingarten",
        "codes": [
            "88250"
        ]
    },
    {
        "city": "Baienfurt",
        "codes": [
            "88255"
        ]
    },
    {
        "city": "Argenbühl",
        "codes": [
            "88260"
        ]
    },
    {
        "city": "Horgenzell",
        "codes": [
            "88263"
        ]
    },
    {
        "city": "Vogt",
        "codes": [
            "88267"
        ]
    },
    {
        "city": "Wilhelmsdorf",
        "codes": [
            "88271",
            "91489"
        ]
    },
    {
        "city": "Fronreute",
        "codes": [
            "88273"
        ]
    },
    {
        "city": "Amtzell",
        "codes": [
            "88279"
        ]
    },
    {
        "city": "Schlier",
        "codes": [
            "88281"
        ]
    },
    {
        "city": "Wolpertswende",
        "codes": [
            "88284"
        ]
    },
    {
        "city": "Bodnegg",
        "codes": [
            "88285"
        ]
    },
    {
        "city": "Grünkraut",
        "codes": [
            "88287"
        ]
    },
    {
        "city": "Waldburg",
        "codes": [
            "88289"
        ]
    },
    {
        "city": "Leutkirch im Allgäu",
        "codes": [
            "88299"
        ]
    },
    {
        "city": "Isny im Allgäu",
        "codes": [
            "88316"
        ]
    },
    {
        "city": "Aichstetten",
        "codes": [
            "88317"
        ]
    },
    {
        "city": "Aitrach",
        "codes": [
            "88319"
        ]
    },
    {
        "city": "Aulendorf",
        "codes": [
            "88326"
        ]
    },
    {
        "city": "Bad Waldsee",
        "codes": [
            "88339"
        ]
    },
    {
        "city": "Saulgau",
        "codes": [
            "88348"
        ]
    },
    {
        "city": "Kißlegg",
        "codes": [
            "88353"
        ]
    },
    {
        "city": "Ostrach",
        "codes": [
            "88356"
        ]
    },
    {
        "city": "Altshausen",
        "codes": [
            "88361"
        ]
    },
    {
        "city": "Wolfegg",
        "codes": [
            "88364"
        ]
    },
    {
        "city": "Hohentengen",
        "codes": [
            "88367"
        ]
    },
    {
        "city": "Bergatreute",
        "codes": [
            "88368"
        ]
    },
    {
        "city": "Ebenweiler",
        "codes": [
            "88370"
        ]
    },
    {
        "city": "Ebersbach-Musbach",
        "codes": [
            "88371"
        ]
    },
    {
        "city": "Fleischwangen",
        "codes": [
            "88373"
        ]
    },
    {
        "city": "Hoßkirch",
        "codes": [
            "88374"
        ]
    },
    {
        "city": "Königseggwald",
        "codes": [
            "88376"
        ]
    },
    {
        "city": "Riedhausen",
        "codes": [
            "88377"
        ]
    },
    {
        "city": "Unterwaldhausen",
        "codes": [
            "88379"
        ]
    },
    {
        "city": "Biberach an der Riß",
        "codes": [
            "88400",
            "88441"
        ]
    },
    {
        "city": "Bad Wurzach",
        "codes": [
            "88410"
        ]
    },
    {
        "city": "Ochsenhausen",
        "codes": [
            "88416"
        ]
    },
    {
        "city": "Bad Buchau",
        "codes": [
            "88422"
        ]
    },
    {
        "city": "Bad Schussenried",
        "codes": [
            "88427"
        ]
    },
    {
        "city": "Rot an der Rot",
        "codes": [
            "88430"
        ]
    },
    {
        "city": "Schemmerhofen",
        "codes": [
            "88433"
        ]
    },
    {
        "city": "Eberhardzell",
        "codes": [
            "88436"
        ]
    },
    {
        "city": "Maselheim",
        "codes": [
            "88437"
        ]
    },
    {
        "city": "Ummendorf",
        "codes": [
            "88444"
        ]
    },
    {
        "city": "Warthausen",
        "codes": [
            "88447"
        ]
    },
    {
        "city": "Attenweiler",
        "codes": [
            "88448"
        ]
    },
    {
        "city": "Berkheim",
        "codes": [
            "88450"
        ]
    },
    {
        "city": "Dettingen an der Iller",
        "codes": [
            "88451"
        ]
    },
    {
        "city": "Erolzheim",
        "codes": [
            "88453"
        ]
    },
    {
        "city": "Ingoldingen",
        "codes": [
            "88456"
        ]
    },
    {
        "city": "Kirchdorf an der Iller",
        "codes": [
            "88457"
        ]
    },
    {
        "city": "Tannheim",
        "codes": [
            "88459"
        ]
    },
    {
        "city": "Laupheim",
        "codes": [
            "88471"
        ]
    },
    {
        "city": "Schwendi",
        "codes": [
            "88477"
        ]
    },
    {
        "city": "Achstetten",
        "codes": [
            "88480"
        ]
    },
    {
        "city": "Balzheim",
        "codes": [
            "88481"
        ]
    },
    {
        "city": "Burgrieden",
        "codes": [
            "88483"
        ]
    },
    {
        "city": "Gutenzell-Hürbel",
        "codes": [
            "88484"
        ]
    },
    {
        "city": "Kirchberg an der Iller",
        "codes": [
            "88486"
        ]
    },
    {
        "city": "Mietingen",
        "codes": [
            "88487"
        ]
    },
    {
        "city": "Wain",
        "codes": [
            "88489"
        ]
    },
    {
        "city": "Riedlingen",
        "codes": [
            "88499"
        ]
    },
    {
        "city": "Mengen",
        "codes": [
            "88512"
        ]
    },
    {
        "city": "Langenenslingen",
        "codes": [
            "88515"
        ]
    },
    {
        "city": "Herbertingen",
        "codes": [
            "88518"
        ]
    },
    {
        "city": "Ertingen",
        "codes": [
            "88521"
        ]
    },
    {
        "city": "Uttenweiler",
        "codes": [
            "88524"
        ]
    },
    {
        "city": "Dürmentingen",
        "codes": [
            "88525"
        ]
    },
    {
        "city": "Unlingen",
        "codes": [
            "88527"
        ]
    },
    {
        "city": "Zwiefalten",
        "codes": [
            "88529"
        ]
    },
    {
        "city": "Meßkirch",
        "codes": [
            "88605"
        ]
    },
    {
        "city": "Pfullendorf",
        "codes": [
            "88630"
        ]
    },
    {
        "city": "Beuron",
        "codes": [
            "88631"
        ]
    },
    {
        "city": "Heiligenberg",
        "codes": [
            "88633"
        ]
    },
    {
        "city": "Herdwangen-Schönach",
        "codes": [
            "88634"
        ]
    },
    {
        "city": "Illmensee",
        "codes": [
            "88636"
        ]
    },
    {
        "city": "Leibertingen",
        "codes": [
            "88637"
        ]
    },
    {
        "city": "Wald",
        "codes": [
            "88639",
            "93192"
        ]
    },
    {
        "city": "Überlingen",
        "codes": [
            "88662"
        ]
    },
    {
        "city": "Markdorf",
        "codes": [
            "88677"
        ]
    },
    {
        "city": "Salem",
        "codes": [
            "88682"
        ]
    },
    {
        "city": "Uhldingen-Mühlhofen",
        "codes": [
            "88690"
        ]
    },
    {
        "city": "Deggenhausertal",
        "codes": [
            "88693"
        ]
    },
    {
        "city": "Owingen",
        "codes": [
            "88696"
        ]
    },
    {
        "city": "Bermatingen",
        "codes": [
            "88697"
        ]
    },
    {
        "city": "Frickingen",
        "codes": [
            "88699"
        ]
    },
    {
        "city": "Meersburg",
        "codes": [
            "88709"
        ]
    },
    {
        "city": "Daisendorf",
        "codes": [
            "88718"
        ]
    },
    {
        "city": "Ulm",
        "codes": [
            "89073",
            "89075",
            "89077",
            "89079",
            "89081"
        ]
    },
    {
        "city": "Blaustein",
        "codes": [
            "89134"
        ]
    },
    {
        "city": "Blaubeuren",
        "codes": [
            "89143"
        ]
    },
    {
        "city": "Laichingen",
        "codes": [
            "89150"
        ]
    },
    {
        "city": "Dornstadt",
        "codes": [
            "89160"
        ]
    },
    {
        "city": "Dietenheim",
        "codes": [
            "89165"
        ]
    },
    {
        "city": "Niederstotzingen",
        "codes": [
            "89168"
        ]
    },
    {
        "city": "Illerkirchberg",
        "codes": [
            "89171"
        ]
    },
    {
        "city": "Lonsee",
        "codes": [
            "89173"
        ]
    },
    {
        "city": "Altheim (Alb)",
        "codes": [
            "89174"
        ]
    },
    {
        "city": "Asselfingen",
        "codes": [
            "89176"
        ]
    },
    {
        "city": "Ballendorf",
        "codes": [
            "89177"
        ]
    },
    {
        "city": "Beimerstetten",
        "codes": [
            "89179"
        ]
    },
    {
        "city": "Berghülen",
        "codes": [
            "89180"
        ]
    },
    {
        "city": "Breitingen",
        "codes": [
            "89183"
        ]
    },
    {
        "city": "Hüttisheim",
        "codes": [
            "89185"
        ]
    },
    {
        "city": "Illerrieden",
        "codes": [
            "89186"
        ]
    },
    {
        "city": "Merklingen",
        "codes": [
            "89188"
        ]
    },
    {
        "city": "Neenstetten",
        "codes": [
            "89189"
        ]
    },
    {
        "city": "Nellingen",
        "codes": [
            "89191"
        ]
    },
    {
        "city": "Schnürpflingen",
        "codes": [
            "89194"
        ]
    },
    {
        "city": "Staig",
        "codes": [
            "89195"
        ]
    },
    {
        "city": "Weidenstetten",
        "codes": [
            "89197"
        ]
    },
    {
        "city": "Westerstetten",
        "codes": [
            "89198"
        ]
    },
    {
        "city": "Neu-Ulm",
        "codes": [
            "89231",
            "89233"
        ]
    },
    {
        "city": "Illertissen",
        "codes": [
            "89257"
        ]
    },
    {
        "city": "Weißenhorn",
        "codes": [
            "89264"
        ]
    },
    {
        "city": "Elchingen",
        "codes": [
            "89275"
        ]
    },
    {
        "city": "Nersingen",
        "codes": [
            "89278"
        ]
    },
    {
        "city": "Pfaffenhofen an der Roth",
        "codes": [
            "89284"
        ]
    },
    {
        "city": "Bellenberg",
        "codes": [
            "89287"
        ]
    },
    {
        "city": "Buch",
        "codes": [
            "89290"
        ]
    },
    {
        "city": "Kellmünz an der Iller",
        "codes": [
            "89293"
        ]
    },
    {
        "city": "Oberroth",
        "codes": [
            "89294"
        ]
    },
    {
        "city": "Osterberg",
        "codes": [
            "89296"
        ]
    },
    {
        "city": "Roggenburg",
        "codes": [
            "89297"
        ]
    },
    {
        "city": "Unterroth",
        "codes": [
            "89299"
        ]
    },
    {
        "city": "Günzburg",
        "codes": [
            "89312"
        ]
    },
    {
        "city": "Burgau",
        "codes": [
            "89331"
        ]
    },
    {
        "city": "Ichenhausen",
        "codes": [
            "89335"
        ]
    },
    {
        "city": "Leipheim",
        "codes": [
            "89340"
        ]
    },
    {
        "city": "Jettingen-Scheppach",
        "codes": [
            "89343"
        ]
    },
    {
        "city": "Aislingen",
        "codes": [
            "89344"
        ]
    },
    {
        "city": "Bibertal",
        "codes": [
            "89346"
        ]
    },
    {
        "city": "Bubesheim",
        "codes": [
            "89347"
        ]
    },
    {
        "city": "Burtenbach",
        "codes": [
            "89349"
        ]
    },
    {
        "city": "Dürrlauingen",
        "codes": [
            "89350"
        ]
    },
    {
        "city": "Ellzee",
        "codes": [
            "89352"
        ]
    },
    {
        "city": "Glött",
        "codes": [
            "89353"
        ]
    },
    {
        "city": "Gundremmingen",
        "codes": [
            "89355"
        ]
    },
    {
        "city": "Kammeltal",
        "codes": [
            "89358"
        ]
    },
    {
        "city": "Kötz",
        "codes": [
            "89359"
        ]
    },
    {
        "city": "Landensberg",
        "codes": [
            "89361"
        ]
    },
    {
        "city": "Offingen",
        "codes": [
            "89362"
        ]
    },
    {
        "city": "Rettenbach",
        "codes": [
            "89364",
            "93191"
        ]
    },
    {
        "city": "Röfingen",
        "codes": [
            "89365"
        ]
    },
    {
        "city": "Dillingen an der Donau",
        "codes": [
            "89407"
        ]
    },
    {
        "city": "Lauingen (Donau)",
        "codes": [
            "89415"
        ]
    },
    {
        "city": "Höchstädt an der Donau",
        "codes": [
            "89420"
        ]
    },
    {
        "city": "Gundelfingen an der Donau",
        "codes": [
            "89423"
        ]
    },
    {
        "city": "Wittislingen",
        "codes": [
            "89426"
        ]
    },
    {
        "city": "Syrgenstein",
        "codes": [
            "89428"
        ]
    },
    {
        "city": "Bachhagel",
        "codes": [
            "89429"
        ]
    },
    {
        "city": "Bächingen an der Brenz",
        "codes": [
            "89431"
        ]
    },
    {
        "city": "Blindheim",
        "codes": [
            "89434"
        ]
    },
    {
        "city": "Finningen",
        "codes": [
            "89435"
        ]
    },
    {
        "city": "Haunsheim",
        "codes": [
            "89437"
        ]
    },
    {
        "city": "Lutzingen",
        "codes": [
            "89440"
        ]
    },
    {
        "city": "Medlingen",
        "codes": [
            "89441"
        ]
    },
    {
        "city": "Ziertheim",
        "codes": [
            "89446"
        ]
    },
    {
        "city": "Zöschingen",
        "codes": [
            "89447"
        ]
    },
    {
        "city": "Heidenheim an der Brenz",
        "codes": [
            "89518",
            "89520",
            "89522"
        ]
    },
    {
        "city": "Giengen an der Brenz",
        "codes": [
            "89537"
        ]
    },
    {
        "city": "Herbrechtingen",
        "codes": [
            "89542"
        ]
    },
    {
        "city": "Gerstetten",
        "codes": [
            "89547"
        ]
    },
    {
        "city": "Königsbronn",
        "codes": [
            "89551"
        ]
    },
    {
        "city": "Steinheim am Albuch",
        "codes": [
            "89555"
        ]
    },
    {
        "city": "Böhmenkirch",
        "codes": [
            "89558"
        ]
    },
    {
        "city": "Dischingen",
        "codes": [
            "89561"
        ]
    },
    {
        "city": "Nattheim",
        "codes": [
            "89564"
        ]
    },
    {
        "city": "Sontheim an der Brenz",
        "codes": [
            "89567"
        ]
    },
    {
        "city": "Hermaringen",
        "codes": [
            "89568"
        ]
    },
    {
        "city": "Ehingen (Donau)",
        "codes": [
            "89584"
        ]
    },
    {
        "city": "Munderkingen",
        "codes": [
            "89597"
        ]
    },
    {
        "city": "Schelklingen",
        "codes": [
            "89601"
        ]
    },
    {
        "city": "Allmendingen",
        "codes": [
            "89604"
        ]
    },
    {
        "city": "Altheim",
        "codes": [
            "89605"
        ]
    },
    {
        "city": "Emerkingen",
        "codes": [
            "89607"
        ]
    },
    {
        "city": "Griesingen",
        "codes": [
            "89608"
        ]
    },
    {
        "city": "Oberdischingen",
        "codes": [
            "89610"
        ]
    },
    {
        "city": "Obermarchtal",
        "codes": [
            "89611"
        ]
    },
    {
        "city": "Oberstadion",
        "codes": [
            "89613"
        ]
    },
    {
        "city": "Öpfingen",
        "codes": [
            "89614"
        ]
    },
    {
        "city": "Rottenacker",
        "codes": [
            "89616"
        ]
    },
    {
        "city": "Untermarchtal",
        "codes": [
            "89617"
        ]
    },
    {
        "city": "Unterstadion",
        "codes": [
            "89619"
        ]
    },
    {
        "city": "Nürnberg",
        "codes": [
            "90402",
            "90403",
            "90408",
            "90409",
            "90411",
            "90419",
            "90425",
            "90427",
            "90429",
            "90431",
            "90439",
            "90441",
            "90443",
            "90449",
            "90451",
            "90453",
            "90455",
            "90459",
            "90461",
            "90469",
            "90471",
            "90473",
            "90475",
            "90478",
            "90480",
            "90482",
            "90489",
            "90491"
        ]
    },
    {
        "city": "Zirndorf",
        "codes": [
            "90513"
        ]
    },
    {
        "city": "Altdorf bei Nürnberg",
        "codes": [
            "90518"
        ]
    },
    {
        "city": "Oberasbach",
        "codes": [
            "90522"
        ]
    },
    {
        "city": "Wendelstein",
        "codes": [
            "90530"
        ]
    },
    {
        "city": "Feucht",
        "codes": [
            "90537"
        ]
    },
    {
        "city": "Eckental",
        "codes": [
            "90542"
        ]
    },
    {
        "city": "Stein",
        "codes": [
            "90547"
        ]
    },
    {
        "city": "Röthenbach an der Pegnitz",
        "codes": [
            "90552"
        ]
    },
    {
        "city": "Cadolzburg",
        "codes": [
            "90556"
        ]
    },
    {
        "city": "Burgthann",
        "codes": [
            "90559"
        ]
    },
    {
        "city": "Heroldsberg",
        "codes": [
            "90562"
        ]
    },
    {
        "city": "Schwaig bei Nürnberg",
        "codes": [
            "90571"
        ]
    },
    {
        "city": "Roßtal",
        "codes": [
            "90574"
        ]
    },
    {
        "city": "Langenzenn",
        "codes": [
            "90579"
        ]
    },
    {
        "city": "Allersberg",
        "codes": [
            "90584"
        ]
    },
    {
        "city": "Veitsbronn",
        "codes": [
            "90587"
        ]
    },
    {
        "city": "Schwarzenbruck",
        "codes": [
            "90592"
        ]
    },
    {
        "city": "Schwanstetten",
        "codes": [
            "90596"
        ]
    },
    {
        "city": "Dietenhofen",
        "codes": [
            "90599"
        ]
    },
    {
        "city": "Pyrbaum",
        "codes": [
            "90602"
        ]
    },
    {
        "city": "Rückersdorf",
        "codes": [
            "90607"
        ]
    },
    {
        "city": "Winkelhaid",
        "codes": [
            "90610"
        ]
    },
    {
        "city": "Großhabersdorf",
        "codes": [
            "90613"
        ]
    },
    {
        "city": "Ammerndorf",
        "codes": [
            "90614"
        ]
    },
    {
        "city": "Neuhof an der Zenn",
        "codes": [
            "90616"
        ]
    },
    {
        "city": "Puschendorf",
        "codes": [
            "90617"
        ]
    },
    {
        "city": "Trautskirchen",
        "codes": [
            "90619"
        ]
    },
    {
        "city": "Erlangen",
        "codes": [
            "91052",
            "91054",
            "91056",
            "91058"
        ]
    },
    {
        "city": "Herzogenaurach",
        "codes": [
            "91074"
        ]
    },
    {
        "city": "Neunkirchen am Brand",
        "codes": [
            "91077"
        ]
    },
    {
        "city": "Uttenreuth",
        "codes": [
            "91080"
        ]
    },
    {
        "city": "Baiersdorf",
        "codes": [
            "91083"
        ]
    },
    {
        "city": "Weisendorf",
        "codes": [
            "91085"
        ]
    },
    {
        "city": "Aurachtal",
        "codes": [
            "91086"
        ]
    },
    {
        "city": "Bubenreuth",
        "codes": [
            "91088"
        ]
    },
    {
        "city": "Effeltrich",
        "codes": [
            "91090"
        ]
    },
    {
        "city": "Großenseebach",
        "codes": [
            "91091"
        ]
    },
    {
        "city": "Heßdorf",
        "codes": [
            "91093"
        ]
    },
    {
        "city": "Langensendelbach",
        "codes": [
            "91094"
        ]
    },
    {
        "city": "Möhrendorf",
        "codes": [
            "91096"
        ]
    },
    {
        "city": "Poxdorf",
        "codes": [
            "91099"
        ]
    },
    {
        "city": "Schwabach",
        "codes": [
            "91126"
        ]
    },
    {
        "city": "Roth",
        "codes": [
            "91154"
        ]
    },
    {
        "city": "Hilpoltstein",
        "codes": [
            "91161"
        ]
    },
    {
        "city": "Georgensgmünd",
        "codes": [
            "91166"
        ]
    },
    {
        "city": "Greding",
        "codes": [
            "91171"
        ]
    },
    {
        "city": "Spalt",
        "codes": [
            "91174"
        ]
    },
    {
        "city": "Thalmässing",
        "codes": [
            "91177"
        ]
    },
    {
        "city": "Heideck",
        "codes": [
            "91180"
        ]
    },
    {
        "city": "Abenberg",
        "codes": [
            "91183"
        ]
    },
    {
        "city": "Büchenbach",
        "codes": [
            "91186"
        ]
    },
    {
        "city": "Röttenbach",
        "codes": [
            "91187",
            "91341"
        ]
    },
    {
        "city": "Rohr",
        "codes": [
            "91189"
        ]
    },
    {
        "city": "Lauf an der Pegnitz",
        "codes": [
            "91207"
        ]
    },
    {
        "city": "Hersbruck",
        "codes": [
            "91217"
        ]
    },
    {
        "city": "Schnaittach",
        "codes": [
            "91220"
        ]
    },
    {
        "city": "Pommelsbrunn",
        "codes": [
            "91224"
        ]
    },
    {
        "city": "Leinburg",
        "codes": [
            "91227"
        ]
    },
    {
        "city": "Happurg",
        "codes": [
            "91230"
        ]
    },
    {
        "city": "Neunkirchen am Sand",
        "codes": [
            "91233"
        ]
    },
    {
        "city": "Alfeld",
        "codes": [
            "91236"
        ]
    },
    {
        "city": "Offenhausen",
        "codes": [
            "91238"
        ]
    },
    {
        "city": "Henfenfeld",
        "codes": [
            "91239"
        ]
    },
    {
        "city": "Kirchensittenbach",
        "codes": [
            "91241"
        ]
    },
    {
        "city": "Ottensoos",
        "codes": [
            "91242"
        ]
    },
    {
        "city": "Reichenschwand",
        "codes": [
            "91244"
        ]
    },
    {
        "city": "Simmelsdorf",
        "codes": [
            "91245"
        ]
    },
    {
        "city": "Vorra",
        "codes": [
            "91247"
        ]
    },
    {
        "city": "Weigendorf",
        "codes": [
            "91249"
        ]
    },
    {
        "city": "Pegnitz",
        "codes": [
            "91257"
        ]
    },
    {
        "city": "Auerbach in der Oberpfalz",
        "codes": [
            "91275"
        ]
    },
    {
        "city": "Pottenstein",
        "codes": [
            "91278"
        ]
    },
    {
        "city": "Kirchenthumbach",
        "codes": [
            "91281"
        ]
    },
    {
        "city": "Betzenstein",
        "codes": [
            "91282"
        ]
    },
    {
        "city": "Neuhaus an der Pegnitz",
        "codes": [
            "91284"
        ]
    },
    {
        "city": "Obertrubach",
        "codes": [
            "91286"
        ]
    },
    {
        "city": "Plech",
        "codes": [
            "91287"
        ]
    },
    {
        "city": "Schnabelwaid",
        "codes": [
            "91289"
        ]
    },
    {
        "city": "Höchstadt an der Aisch",
        "codes": [
            "91315"
        ]
    },
    {
        "city": "Ebermannstadt",
        "codes": [
            "91320"
        ]
    },
    {
        "city": "Gräfenberg",
        "codes": [
            "91322"
        ]
    },
    {
        "city": "Adelsdorf",
        "codes": [
            "91325"
        ]
    },
    {
        "city": "Gößweinstein",
        "codes": [
            "91327"
        ]
    },
    {
        "city": "Eggolsheim",
        "codes": [
            "91330"
        ]
    },
    {
        "city": "Heiligenstadt in Oberfranken",
        "codes": [
            "91332"
        ]
    },
    {
        "city": "Hemhofen",
        "codes": [
            "91334"
        ]
    },
    {
        "city": "Heroldsbach",
        "codes": [
            "91336"
        ]
    },
    {
        "city": "Igensdorf",
        "codes": [
            "91338"
        ]
    },
    {
        "city": "Waischenfeld",
        "codes": [
            "91344"
        ]
    },
    {
        "city": "Wiesenttal",
        "codes": [
            "91346"
        ]
    },
    {
        "city": "Aufseß",
        "codes": [
            "91347"
        ]
    },
    {
        "city": "Egloffstein",
        "codes": [
            "91349"
        ]
    },
    {
        "city": "Gremsdorf",
        "codes": [
            "91350"
        ]
    },
    {
        "city": "Hallerndorf",
        "codes": [
            "91352"
        ]
    },
    {
        "city": "Hiltpoltstein",
        "codes": [
            "91355"
        ]
    },
    {
        "city": "Kirchehrenbach",
        "codes": [
            "91356"
        ]
    },
    {
        "city": "Kunreuth",
        "codes": [
            "91358"
        ]
    },
    {
        "city": "Pinzberg",
        "codes": [
            "91361"
        ]
    },
    {
        "city": "Pretzfeld",
        "codes": [
            "91362"
        ]
    },
    {
        "city": "Unterleinleiter",
        "codes": [
            "91364"
        ]
    },
    {
        "city": "Weilersbach",
        "codes": [
            "91365"
        ]
    },
    {
        "city": "Weißenohe",
        "codes": [
            "91367"
        ]
    },
    {
        "city": "Wiesenthau",
        "codes": [
            "91369"
        ]
    },
    {
        "city": "Neustadt an der Aisch",
        "codes": [
            "91413"
        ]
    },
    {
        "city": "Bad Windsheim",
        "codes": [
            "91438"
        ]
    },
    {
        "city": "Scheinfeld",
        "codes": [
            "91443"
        ]
    },
    {
        "city": "Emskirchen",
        "codes": [
            "91448"
        ]
    },
    {
        "city": "Wilhermsdorf",
        "codes": [
            "91452"
        ]
    },
    {
        "city": "Diespeck",
        "codes": [
            "91456"
        ]
    },
    {
        "city": "Markt Erlbach",
        "codes": [
            "91459"
        ]
    },
    {
        "city": "Baudenbach",
        "codes": [
            "91460"
        ]
    },
    {
        "city": "Dachsbach",
        "codes": [
            "91462"
        ]
    },
    {
        "city": "Dietersheim",
        "codes": [
            "91463"
        ]
    },
    {
        "city": "Ergersheim",
        "codes": [
            "91465"
        ]
    },
    {
        "city": "Gerhardshofen",
        "codes": [
            "91466"
        ]
    },
    {
        "city": "Gutenstetten",
        "codes": [
            "91468"
        ]
    },
    {
        "city": "Hagenbüchach",
        "codes": [
            "91469"
        ]
    },
    {
        "city": "Illesheim",
        "codes": [
            "91471"
        ]
    },
    {
        "city": "Ipsheim",
        "codes": [
            "91472"
        ]
    },
    {
        "city": "Langenfeld",
        "codes": [
            "91474"
        ]
    },
    {
        "city": "Lonnerstadt",
        "codes": [
            "91475"
        ]
    },
    {
        "city": "Markt Bibart",
        "codes": [
            "91477"
        ]
    },
    {
        "city": "Markt Nordheim",
        "codes": [
            "91478"
        ]
    },
    {
        "city": "Markt Taschendorf",
        "codes": [
            "91480"
        ]
    },
    {
        "city": "Münchsteinach",
        "codes": [
            "91481"
        ]
    },
    {
        "city": "Oberscheinfeld",
        "codes": [
            "91483"
        ]
    },
    {
        "city": "Sugenheim",
        "codes": [
            "91484"
        ]
    },
    {
        "city": "Uehlfeld",
        "codes": [
            "91486"
        ]
    },
    {
        "city": "Vestenbergsgreuth",
        "codes": [
            "91487"
        ]
    },
    {
        "city": "Ansbach",
        "codes": [
            "91522"
        ]
    },
    {
        "city": "Rothenburg ob der Tauber",
        "codes": [
            "91541"
        ]
    },
    {
        "city": "Dinkelsbühl",
        "codes": [
            "91550"
        ]
    },
    {
        "city": "Feuchtwangen",
        "codes": [
            "91555"
        ]
    },
    {
        "city": "Heilsbronn",
        "codes": [
            "91560"
        ]
    },
    {
        "city": "Neuendettelsau",
        "codes": [
            "91564"
        ]
    },
    {
        "city": "Herrieden",
        "codes": [
            "91567"
        ]
    },
    {
        "city": "Windsbach",
        "codes": [
            "91575"
        ]
    },
    {
        "city": "Leutershausen",
        "codes": [
            "91578"
        ]
    },
    {
        "city": "Petersaurach",
        "codes": [
            "91580"
        ]
    },
    {
        "city": "Schillingsfürst",
        "codes": [
            "91583"
        ]
    },
    {
        "city": "Aurach",
        "codes": [
            "91589"
        ]
    },
    {
        "city": "Buch am Wald",
        "codes": [
            "91592"
        ]
    },
    {
        "city": "Burgbernheim",
        "codes": [
            "91593"
        ]
    },
    {
        "city": "Burgoberbach",
        "codes": [
            "91595"
        ]
    },
    {
        "city": "Burk",
        "codes": [
            "91596"
        ]
    },
    {
        "city": "Colmberg",
        "codes": [
            "91598"
        ]
    },
    {
        "city": "Dentlein am Forst",
        "codes": [
            "91599"
        ]
    },
    {
        "city": "Dombühl",
        "codes": [
            "91601"
        ]
    },
    {
        "city": "Dürrwangen",
        "codes": [
            "91602"
        ]
    },
    {
        "city": "Flachslanden",
        "codes": [
            "91604"
        ]
    },
    {
        "city": "Gallmersgarten",
        "codes": [
            "91605"
        ]
    },
    {
        "city": "Gebsattel",
        "codes": [
            "91607"
        ]
    },
    {
        "city": "Geslau",
        "codes": [
            "91608"
        ]
    },
    {
        "city": "Insingen",
        "codes": [
            "91610"
        ]
    },
    {
        "city": "Lehrberg",
        "codes": [
            "91611"
        ]
    },
    {
        "city": "Marktbergel",
        "codes": [
            "91613"
        ]
    },
    {
        "city": "Mönchsroth",
        "codes": [
            "91614"
        ]
    },
    {
        "city": "Neusitz",
        "codes": [
            "91616"
        ]
    },
    {
        "city": "Oberdachstetten",
        "codes": [
            "91617"
        ]
    },
    {
        "city": "Obernzenn",
        "codes": [
            "91619"
        ]
    },
    {
        "city": "Ohrenbach",
        "codes": [
            "91620"
        ]
    },
    {
        "city": "Rügland",
        "codes": [
            "91622"
        ]
    },
    {
        "city": "Sachsen bei Ansbach",
        "codes": [
            "91623"
        ]
    },
    {
        "city": "Schnelldorf",
        "codes": [
            "91625"
        ]
    },
    {
        "city": "Steinsfeld",
        "codes": [
            "91628"
        ]
    },
    {
        "city": "Weihenzell",
        "codes": [
            "91629"
        ]
    },
    {
        "city": "Wieseth",
        "codes": [
            "91632"
        ]
    },
    {
        "city": "Wilburgstetten",
        "codes": [
            "91634"
        ]
    },
    {
        "city": "Windelsbach",
        "codes": [
            "91635"
        ]
    },
    {
        "city": "Wörnitz",
        "codes": [
            "91637"
        ]
    },
    {
        "city": "Wolframs-Eschenbach",
        "codes": [
            "91639"
        ]
    },
    {
        "city": "Gunzenhausen",
        "codes": [
            "91710"
        ]
    },
    {
        "city": "Wassertrüdingen",
        "codes": [
            "91717"
        ]
    },
    {
        "city": "Heidenheim",
        "codes": [
            "91719"
        ]
    },
    {
        "city": "Absberg",
        "codes": [
            "91720"
        ]
    },
    {
        "city": "Arberg",
        "codes": [
            "91722"
        ]
    },
    {
        "city": "Dittenheim",
        "codes": [
            "91723"
        ]
    },
    {
        "city": "Gerolfingen",
        "codes": [
            "91726"
        ]
    },
    {
        "city": "Gnotzheim",
        "codes": [
            "91728"
        ]
    },
    {
        "city": "Haundorf",
        "codes": [
            "91729"
        ]
    },
    {
        "city": "Langfurth",
        "codes": [
            "91731"
        ]
    },
    {
        "city": "Merkendorf",
        "codes": [
            "91732"
        ]
    },
    {
        "city": "Mitteleschenbach",
        "codes": [
            "91734"
        ]
    },
    {
        "city": "Muhr am See",
        "codes": [
            "91735"
        ]
    },
    {
        "city": "Ornbau",
        "codes": [
            "91737"
        ]
    },
    {
        "city": "Pfofeld",
        "codes": [
            "91738"
        ]
    },
    {
        "city": "Röckingen",
        "codes": [
            "91740"
        ]
    },
    {
        "city": "Theilenhofen",
        "codes": [
            "91741"
        ]
    },
    {
        "city": "Unterschwaningen",
        "codes": [
            "91743"
        ]
    },
    {
        "city": "Weiltingen",
        "codes": [
            "91744"
        ]
    },
    {
        "city": "Weidenbach",
        "codes": [
            "91746"
        ]
    },
    {
        "city": "Westheim",
        "codes": [
            "91747"
        ]
    },
    {
        "city": "Wittelshofen",
        "codes": [
            "91749"
        ]
    },
    {
        "city": "Treuchtlingen",
        "codes": [
            "91757"
        ]
    },
    {
        "city": "Weißenburg in Bayern",
        "codes": [
            "91781"
        ]
    },
    {
        "city": "Pleinfeld",
        "codes": [
            "91785"
        ]
    },
    {
        "city": "Pappenheim",
        "codes": [
            "91788"
        ]
    },
    {
        "city": "Nennslingen",
        "codes": [
            "91790"
        ]
    },
    {
        "city": "Ellingen",
        "codes": [
            "91792"
        ]
    },
    {
        "city": "Alesheim",
        "codes": [
            "91793"
        ]
    },
    {
        "city": "Dollnstein",
        "codes": [
            "91795"
        ]
    },
    {
        "city": "Ettenstatt",
        "codes": [
            "91796"
        ]
    },
    {
        "city": "Höttingen",
        "codes": [
            "91798"
        ]
    },
    {
        "city": "Langenaltheim",
        "codes": [
            "91799"
        ]
    },
    {
        "city": "Markt Berolzheim",
        "codes": [
            "91801"
        ]
    },
    {
        "city": "Meinheim",
        "codes": [
            "91802"
        ]
    },
    {
        "city": "Mörnsheim",
        "codes": [
            "91804"
        ]
    },
    {
        "city": "Polsingen",
        "codes": [
            "91805"
        ]
    },
    {
        "city": "Solnhofen",
        "codes": [
            "91807"
        ]
    },
    {
        "city": "Wellheim",
        "codes": [
            "91809"
        ]
    },
    {
        "city": "Sulzbach-Rosenberg",
        "codes": [
            "92237"
        ]
    },
    {
        "city": "Hirschau",
        "codes": [
            "92242"
        ]
    },
    {
        "city": "Kümmersbruck",
        "codes": [
            "92245"
        ]
    },
    {
        "city": "Vilseck",
        "codes": [
            "92249"
        ]
    },
    {
        "city": "Schnaittenbach",
        "codes": [
            "92253"
        ]
    },
    {
        "city": "Hahnbach",
        "codes": [
            "92256"
        ]
    },
    {
        "city": "Neukirchen bei Sulzbach-Rosen",
        "codes": [
            "92259"
        ]
    },
    {
        "city": "Ammerthal",
        "codes": [
            "92260"
        ]
    },
    {
        "city": "Birgland",
        "codes": [
            "92262"
        ]
    },
    {
        "city": "Ebermannsdorf",
        "codes": [
            "92263"
        ]
    },
    {
        "city": "Edelsfeld",
        "codes": [
            "92265"
        ]
    },
    {
        "city": "Etzelwang",
        "codes": [
            "92268"
        ]
    },
    {
        "city": "Fensterbach",
        "codes": [
            "92269"
        ]
    },
    {
        "city": "Freihung",
        "codes": [
            "92271"
        ]
    },
    {
        "city": "Gebenbach",
        "codes": [
            "92274"
        ]
    },
    {
        "city": "Hirschbach",
        "codes": [
            "92275"
        ]
    },
    {
        "city": "Hohenburg",
        "codes": [
            "92277"
        ]
    },
    {
        "city": "Illschwang",
        "codes": [
            "92278"
        ]
    },
    {
        "city": "Königstein",
        "codes": [
            "92281"
        ]
    },
    {
        "city": "Lauterhofen",
        "codes": [
            "92283"
        ]
    },
    {
        "city": "Poppenricht",
        "codes": [
            "92284"
        ]
    },
    {
        "city": "Schmidmühlen",
        "codes": [
            "92287"
        ]
    },
    {
        "city": "Ursensollen",
        "codes": [
            "92289"
        ]
    },
    {
        "city": "Neumarkt in der Oberpfalz",
        "codes": [
            "92318"
        ]
    },
    {
        "city": "Parsberg",
        "codes": [
            "92331"
        ]
    },
    {
        "city": "Berching",
        "codes": [
            "92334"
        ]
    },
    {
        "city": "Beilngries",
        "codes": [
            "92339"
        ]
    },
    {
        "city": "Freystadt",
        "codes": [
            "92342"
        ]
    },
    {
        "city": "Dietfurt an der Altmühl",
        "codes": [
            "92345"
        ]
    },
    {
        "city": "Berg bei Neumarkt in der Oberpfalz",
        "codes": [
            "92348"
        ]
    },
    {
        "city": "Postbauer-Heng",
        "codes": [
            "92353"
        ]
    },
    {
        "city": "Velburg",
        "codes": [
            "92355"
        ]
    },
    {
        "city": "Seubersdorf in der Oberpfalz",
        "codes": [
            "92358"
        ]
    },
    {
        "city": "Berngau",
        "codes": [
            "92361"
        ]
    },
    {
        "city": "Deining",
        "codes": [
            "92364"
        ]
    },
    {
        "city": "Pilsach",
        "codes": [
            "92367"
        ]
    },
    {
        "city": "Sengenthal",
        "codes": [
            "92369"
        ]
    },
    {
        "city": "Schwandorf",
        "codes": [
            "92421"
        ]
    },
    {
        "city": "Neunburg vorm Wald",
        "codes": [
            "92431"
        ]
    },
    {
        "city": "Bruck in der Oberpfalz",
        "codes": [
            "92436"
        ]
    },
    {
        "city": "Bodenwöhr",
        "codes": [
            "92439"
        ]
    },
    {
        "city": "Wackersdorf",
        "codes": [
            "92442"
        ]
    },
    {
        "city": "Rötz",
        "codes": [
            "92444"
        ]
    },
    {
        "city": "Neukirchen-Balbini",
        "codes": [
            "92445"
        ]
    },
    {
        "city": "Schwarzhofen",
        "codes": [
            "92447"
        ]
    },
    {
        "city": "Nabburg",
        "codes": [
            "92507"
        ]
    },
    {
        "city": "Schwarzenfeld",
        "codes": [
            "92521"
        ]
    },
    {
        "city": "Oberviechtach",
        "codes": [
            "92526"
        ]
    },
    {
        "city": "Wernberg-Köblitz",
        "codes": [
            "92533"
        ]
    },
    {
        "city": "Pfreimd",
        "codes": [
            "92536"
        ]
    },
    {
        "city": "Schönsee",
        "codes": [
            "92539"
        ]
    },
    {
        "city": "Altendorf",
        "codes": [
            "92540",
            "96146"
        ]
    },
    {
        "city": "Dieterskirchen",
        "codes": [
            "92542"
        ]
    },
    {
        "city": "Guteneck",
        "codes": [
            "92543"
        ]
    },
    {
        "city": "Niedermurach",
        "codes": [
            "92545"
        ]
    },
    {
        "city": "Schmidgaden",
        "codes": [
            "92546"
        ]
    },
    {
        "city": "Schwarzach bei Nabburg",
        "codes": [
            "92548"
        ]
    },
    {
        "city": "Stadlern",
        "codes": [
            "92549"
        ]
    },
    {
        "city": "Stulln",
        "codes": [
            "92551"
        ]
    },
    {
        "city": "Teunz",
        "codes": [
            "92552"
        ]
    },
    {
        "city": "Thanstein",
        "codes": [
            "92554"
        ]
    },
    {
        "city": "Trausnitz",
        "codes": [
            "92555"
        ]
    },
    {
        "city": "Weiding",
        "codes": [
            "92557",
            "93495"
        ]
    },
    {
        "city": "Winklarn",
        "codes": [
            "92559"
        ]
    },
    {
        "city": "Weiden in der Oberpfalz",
        "codes": [
            "92637"
        ]
    },
    {
        "city": "Vohenstrauß",
        "codes": [
            "92648"
        ]
    },
    {
        "city": "Grafenwöhr",
        "codes": [
            "92655"
        ]
    },
    {
        "city": "Neustadt an der Waldnaab",
        "codes": [
            "92660"
        ]
    },
    {
        "city": "Altenstadt an der Waldnaab",
        "codes": [
            "92665"
        ]
    },
    {
        "city": "Windischeschenbach",
        "codes": [
            "92670"
        ]
    },
    {
        "city": "Eschenbach in der Oberpfalz",
        "codes": [
            "92676"
        ]
    },
    {
        "city": "Erbendorf",
        "codes": [
            "92681"
        ]
    },
    {
        "city": "Floß",
        "codes": [
            "92685"
        ]
    },
    {
        "city": "Pressath",
        "codes": [
            "92690"
        ]
    },
    {
        "city": "Eslarn",
        "codes": [
            "92693"
        ]
    },
    {
        "city": "Etzenricht",
        "codes": [
            "92694"
        ]
    },
    {
        "city": "Flossenbürg",
        "codes": [
            "92696"
        ]
    },
    {
        "city": "Georgenberg",
        "codes": [
            "92697"
        ]
    },
    {
        "city": "Bechtsried",
        "codes": [
            "92699"
        ]
    },
    {
        "city": "Weiherhammer",
        "codes": [
            "92700",
            "92729"
        ]
    },
    {
        "city": "Krummennaab",
        "codes": [
            "92703"
        ]
    },
    {
        "city": "Leuchtenberg",
        "codes": [
            "92705"
        ]
    },
    {
        "city": "Luhe-Wildenau",
        "codes": [
            "92706"
        ]
    },
    {
        "city": "Mantel",
        "codes": [
            "92708"
        ]
    },
    {
        "city": "Moosbach",
        "codes": [
            "92709"
        ]
    },
    {
        "city": "Parkstein",
        "codes": [
            "92711"
        ]
    },
    {
        "city": "Pirk",
        "codes": [
            "92712"
        ]
    },
    {
        "city": "Pleystein",
        "codes": [
            "92714"
        ]
    },
    {
        "city": "Püchersreuth",
        "codes": [
            "92715"
        ]
    },
    {
        "city": "Reuth bei Erbendorf",
        "codes": [
            "92717"
        ]
    },
    {
        "city": "Schirmitz",
        "codes": [
            "92718"
        ]
    },
    {
        "city": "Schwarzenbach",
        "codes": [
            "92720"
        ]
    },
    {
        "city": "Störnstein",
        "codes": [
            "92721"
        ]
    },
    {
        "city": "Tännesberg",
        "codes": [
            "92723"
        ]
    },
    {
        "city": "Trabitz",
        "codes": [
            "92724"
        ]
    },
    {
        "city": "Waidhaus",
        "codes": [
            "92726"
        ]
    },
    {
        "city": "Waldthurn",
        "codes": [
            "92727"
        ]
    },
    {
        "city": "Regensburg",
        "codes": [
            "93047",
            "93049",
            "93051",
            "93053",
            "93055",
            "93057",
            "93059"
        ]
    },
    {
        "city": "Neutraubling",
        "codes": [
            "93073"
        ]
    },
    {
        "city": "Bad Abbach",
        "codes": [
            "93077"
        ]
    },
    {
        "city": "Pentling",
        "codes": [
            "93080"
        ]
    },
    {
        "city": "Obertraubling",
        "codes": [
            "93083"
        ]
    },
    {
        "city": "Wörth an der Donau",
        "codes": [
            "93086"
        ]
    },
    {
        "city": "Alteglofsheim",
        "codes": [
            "93087"
        ]
    },
    {
        "city": "Aufhausen",
        "codes": [
            "93089"
        ]
    },
    {
        "city": "Bach an der Donau",
        "codes": [
            "93090"
        ]
    },
    {
        "city": "Barbing",
        "codes": [
            "93092"
        ]
    },
    {
        "city": "Donaustauf",
        "codes": [
            "93093"
        ]
    },
    {
        "city": "Hagelstadt",
        "codes": [
            "93095"
        ]
    },
    {
        "city": "Köfering",
        "codes": [
            "93096"
        ]
    },
    {
        "city": "Mintraching",
        "codes": [
            "93098"
        ]
    },
    {
        "city": "Mötzing",
        "codes": [
            "93099"
        ]
    },
    {
        "city": "Pfakofen",
        "codes": [
            "93101"
        ]
    },
    {
        "city": "Pfatter",
        "codes": [
            "93102"
        ]
    },
    {
        "city": "Sünching",
        "codes": [
            "93104"
        ]
    },
    {
        "city": "Tegernheim",
        "codes": [
            "93105"
        ]
    },
    {
        "city": "Thalmassing",
        "codes": [
            "93107"
        ]
    },
    {
        "city": "Wiesent",
        "codes": [
            "93109"
        ]
    },
    {
        "city": "Regenstauf",
        "codes": [
            "93128"
        ]
    },
    {
        "city": "Burglengenfeld",
        "codes": [
            "93133"
        ]
    },
    {
        "city": "Lappersdorf",
        "codes": [
            "93138"
        ]
    },
    {
        "city": "Maxhütte-Haidhof",
        "codes": [
            "93142"
        ]
    },
    {
        "city": "Nittenau",
        "codes": [
            "93149"
        ]
    },
    {
        "city": "Nittendorf",
        "codes": [
            "93152"
        ]
    },
    {
        "city": "Hemau",
        "codes": [
            "93155"
        ]
    },
    {
        "city": "Teublitz",
        "codes": [
            "93158"
        ]
    },
    {
        "city": "Sinzing",
        "codes": [
            "93161"
        ]
    },
    {
        "city": "Laaber",
        "codes": [
            "93164"
        ]
    },
    {
        "city": "Falkenstein",
        "codes": [
            "93167"
        ]
    },
    {
        "city": "Bernhardswald",
        "codes": [
            "93170"
        ]
    },
    {
        "city": "Wenzenbach",
        "codes": [
            "93173"
        ]
    },
    {
        "city": "Beratzhausen",
        "codes": [
            "93176"
        ]
    },
    {
        "city": "Altenthann",
        "codes": [
            "93177"
        ]
    },
    {
        "city": "Brennberg",
        "codes": [
            "93179"
        ]
    },
    {
        "city": "Deuerling",
        "codes": [
            "93180"
        ]
    },
    {
        "city": "Duggendorf",
        "codes": [
            "93182"
        ]
    },
    {
        "city": "Kallmünz",
        "codes": [
            "93183"
        ]
    },
    {
        "city": "Michelsneukirchen",
        "codes": [
            "93185"
        ]
    },
    {
        "city": "Pettendorf",
        "codes": [
            "93186"
        ]
    },
    {
        "city": "Pielenhofen",
        "codes": [
            "93188"
        ]
    },
    {
        "city": "Reichenbach",
        "codes": [
            "93189"
        ]
    },
    {
        "city": "Walderbach",
        "codes": [
            "93194"
        ]
    },
    {
        "city": "Wolfsegg",
        "codes": [
            "93195"
        ]
    },
    {
        "city": "Zeitlarn",
        "codes": [
            "93197"
        ]
    },
    {
        "city": "Zell",
        "codes": [
            "93199",
            "95239"
        ]
    },
    {
        "city": "Kelheim",
        "codes": [
            "93309"
        ]
    },
    {
        "city": "Abensberg",
        "codes": [
            "93326"
        ]
    },
    {
        "city": "Neustadt an der Donau",
        "codes": [
            "93333"
        ]
    },
    {
        "city": "Altmannstein",
        "codes": [
            "93336"
        ]
    },
    {
        "city": "Riedenburg",
        "codes": [
            "93339"
        ]
    },
    {
        "city": "Saal an der Donau",
        "codes": [
            "93342"
        ]
    },
    {
        "city": "Essing",
        "codes": [
            "93343"
        ]
    },
    {
        "city": "Ihrlerstein",
        "codes": [
            "93346"
        ]
    },
    {
        "city": "Mindelstetten",
        "codes": [
            "93349"
        ]
    },
    {
        "city": "Painten",
        "codes": [
            "93351"
        ]
    },
    {
        "city": "Rohr in Niederbayern",
        "codes": [
            "93352"
        ]
    },
    {
        "city": "Siegenburg",
        "codes": [
            "93354"
        ]
    },
    {
        "city": "Teugn",
        "codes": [
            "93356"
        ]
    },
    {
        "city": "Train",
        "codes": [
            "93358"
        ]
    },
    {
        "city": "Wildenberg",
        "codes": [
            "93359"
        ]
    },
    {
        "city": "Cham",
        "codes": [
            "93413"
        ]
    },
    {
        "city": "Roding",
        "codes": [
            "93426"
        ]
    },
    {
        "city": "Furth im Wald",
        "codes": [
            "93437"
        ]
    },
    {
        "city": "Kötzting",
        "codes": [
            "93444"
        ]
    },
    {
        "city": "Waldmünchen",
        "codes": [
            "93449"
        ]
    },
    {
        "city": "Neukirchen bei Heilig Blut",
        "codes": [
            "93453"
        ]
    },
    {
        "city": "Traitsching",
        "codes": [
            "93455"
        ]
    },
    {
        "city": "Eschlkam",
        "codes": [
            "93458"
        ]
    },
    {
        "city": "Lam",
        "codes": [
            "93462"
        ]
    },
    {
        "city": "Chamerau",
        "codes": [
            "93466"
        ]
    },
    {
        "city": "Miltach",
        "codes": [
            "93468"
        ]
    },
    {
        "city": "Lohberg",
        "codes": [
            "93470"
        ]
    },
    {
        "city": "Arnbruck",
        "codes": [
            "93471"
        ]
    },
    {
        "city": "Arnschwang",
        "codes": [
            "93473"
        ]
    },
    {
        "city": "Arrach",
        "codes": [
            "93474"
        ]
    },
    {
        "city": "Blaibach",
        "codes": [
            "93476"
        ]
    },
    {
        "city": "Gleißenberg",
        "codes": [
            "93477"
        ]
    },
    {
        "city": "Grafenwiesen",
        "codes": [
            "93479"
        ]
    },
    {
        "city": "Hohenwarth",
        "codes": [
            "93480"
        ]
    },
    {
        "city": "Pemfling",
        "codes": [
            "93482"
        ]
    },
    {
        "city": "Pösing",
        "codes": [
            "93483"
        ]
    },
    {
        "city": "Runding",
        "codes": [
            "93486"
        ]
    },
    {
        "city": "Schönthal",
        "codes": [
            "93488"
        ]
    },
    {
        "city": "Stamsried",
        "codes": [
            "93491"
        ]
    },
    {
        "city": "Treffelstein",
        "codes": [
            "93492"
        ]
    },
    {
        "city": "Waffenbrunn",
        "codes": [
            "93494"
        ]
    },
    {
        "city": "Willmering",
        "codes": [
            "93497"
        ]
    },
    {
        "city": "Zandt",
        "codes": [
            "93499"
        ]
    },
    {
        "city": "Passau",
        "codes": [
            "94032",
            "94034",
            "94036"
        ]
    },
    {
        "city": "Hauzenberg",
        "codes": [
            "94051"
        ]
    },
    {
        "city": "Pocking",
        "codes": [
            "94060"
        ]
    },
    {
        "city": "Waldkirchen",
        "codes": [
            "94065"
        ]
    },
    {
        "city": "Bad Füssing",
        "codes": [
            "94072"
        ]
    },
    {
        "city": "Freyung",
        "codes": [
            "94078"
        ]
    },
    {
        "city": "Fürstenzell",
        "codes": [
            "94081"
        ]
    },
    {
        "city": "Griesbach im Rottal",
        "codes": [
            "94086"
        ]
    },
    {
        "city": "Neureichenau",
        "codes": [
            "94089"
        ]
    },
    {
        "city": "Rotthalmünster",
        "codes": [
            "94094"
        ]
    },
    {
        "city": "Ruhstorf an der Rott",
        "codes": [
            "94099"
        ]
    },
    {
        "city": "Tittling",
        "codes": [
            "94104"
        ]
    },
    {
        "city": "Untergriesbach",
        "codes": [
            "94107"
        ]
    },
    {
        "city": "Wegscheid",
        "codes": [
            "94110"
        ]
    },
    {
        "city": "Hutthurm",
        "codes": [
            "94116"
        ]
    },
    {
        "city": "Jandelsbrunn",
        "codes": [
            "94118"
        ]
    },
    {
        "city": "Salzweg",
        "codes": [
            "94121"
        ]
    },
    {
        "city": "Büchlberg",
        "codes": [
            "94124"
        ]
    },
    {
        "city": "Neuburg am Inn",
        "codes": [
            "94127"
        ]
    },
    {
        "city": "Obernzell",
        "codes": [
            "94130"
        ]
    },
    {
        "city": "Röhrnbach",
        "codes": [
            "94133"
        ]
    },
    {
        "city": "Thyrnau",
        "codes": [
            "94136"
        ]
    },
    {
        "city": "Bayerbach",
        "codes": [
            "94137"
        ]
    },
    {
        "city": "Breitenberg",
        "codes": [
            "94139"
        ]
    },
    {
        "city": "Ering",
        "codes": [
            "94140"
        ]
    },
    {
        "city": "Fürsteneck",
        "codes": [
            "94142"
        ]
    },
    {
        "city": "Grainet",
        "codes": [
            "94143"
        ]
    },
    {
        "city": "Haidmühle",
        "codes": [
            "94145"
        ]
    },
    {
        "city": "Hinterschmiding",
        "codes": [
            "94146"
        ]
    },
    {
        "city": "Kirchham",
        "codes": [
            "94148"
        ]
    },
    {
        "city": "Kößlarn",
        "codes": [
            "94149"
        ]
    },
    {
        "city": "Mauth",
        "codes": [
            "94151"
        ]
    },
    {
        "city": "Neuhaus am Inn",
        "codes": [
            "94152"
        ]
    },
    {
        "city": "Neukirchen vorm Wald",
        "codes": [
            "94154"
        ]
    },
    {
        "city": "Windorf",
        "codes": [
            "94155",
            "94565",
            "94575"
        ]
    },
    {
        "city": "Perlesreut",
        "codes": [
            "94157"
        ]
    },
    {
        "city": "Philippsreut",
        "codes": [
            "94158"
        ]
    },
    {
        "city": "Ringelai",
        "codes": [
            "94160"
        ]
    },
    {
        "city": "Ruderting",
        "codes": [
            "94161"
        ]
    },
    {
        "city": "Saldenburg",
        "codes": [
            "94163"
        ]
    },
    {
        "city": "Sonnen",
        "codes": [
            "94164"
        ]
    },
    {
        "city": "Stubenberg",
        "codes": [
            "94166"
        ]
    },
    {
        "city": "Tettenweis",
        "codes": [
            "94167"
        ]
    },
    {
        "city": "Thurmansbang",
        "codes": [
            "94169"
        ]
    },
    {
        "city": "Regen",
        "codes": [
            "94209"
        ]
    },
    {
        "city": "Zwiesel",
        "codes": [
            "94227"
        ]
    },
    {
        "city": "Viechtach",
        "codes": [
            "94234"
        ]
    },
    {
        "city": "Zachenberg",
        "codes": [
            "94239"
        ]
    },
    {
        "city": "Teisnach",
        "codes": [
            "94244"
        ]
    },
    {
        "city": "Bodenmais",
        "codes": [
            "94249"
        ]
    },
    {
        "city": "Achslach",
        "codes": [
            "94250"
        ]
    },
    {
        "city": "Bayerisch Eisenstein",
        "codes": [
            "94252"
        ]
    },
    {
        "city": "Bischofsmais",
        "codes": [
            "94253"
        ]
    },
    {
        "city": "Böbrach",
        "codes": [
            "94255"
        ]
    },
    {
        "city": "Drachselsried",
        "codes": [
            "94256"
        ]
    },
    {
        "city": "Frauenau",
        "codes": [
            "94258"
        ]
    },
    {
        "city": "Kirchdorf im Wald",
        "codes": [
            "94261"
        ]
    },
    {
        "city": "Kollnburg",
        "codes": [
            "94262"
        ]
    },
    {
        "city": "Langdorf",
        "codes": [
            "94264"
        ]
    },
    {
        "city": "Patersdorf",
        "codes": [
            "94265"
        ]
    },
    {
        "city": "Prackenbach",
        "codes": [
            "94267"
        ]
    },
    {
        "city": "Rinchnach",
        "codes": [
            "94269"
        ]
    },
    {
        "city": "Straubing",
        "codes": [
            "94315"
        ]
    },
    {
        "city": "Bogen",
        "codes": [
            "94327"
        ]
    },
    {
        "city": "Aiterhofen",
        "codes": [
            "94330"
        ]
    },
    {
        "city": "Geiselhöring",
        "codes": [
            "94333"
        ]
    },
    {
        "city": "Hunderdorf",
        "codes": [
            "94336"
        ]
    },
    {
        "city": "Leiblfing",
        "codes": [
            "94339"
        ]
    },
    {
        "city": "Straßkirchen",
        "codes": [
            "94342"
        ]
    },
    {
        "city": "Wiesenfelden",
        "codes": [
            "94344"
        ]
    },
    {
        "city": "Aholfing",
        "codes": [
            "94345"
        ]
    },
    {
        "city": "Ascha",
        "codes": [
            "94347"
        ]
    },
    {
        "city": "Atting",
        "codes": [
            "94348"
        ]
    },
    {
        "city": "Falkenfels",
        "codes": [
            "94350"
        ]
    },
    {
        "city": "Haselbach",
        "codes": [
            "94354"
        ]
    },
    {
        "city": "Kirchroth",
        "codes": [
            "94356"
        ]
    },
    {
        "city": "Konzell",
        "codes": [
            "94357"
        ]
    },
    {
        "city": "Loitzendorf",
        "codes": [
            "94359"
        ]
    },
    {
        "city": "Mitterfels",
        "codes": [
            "94360"
        ]
    },
    {
        "city": "Oberschneiding",
        "codes": [
            "94363"
        ]
    },
    {
        "city": "Parkstetten",
        "codes": [
            "94365"
        ]
    },
    {
        "city": "Perasdorf",
        "codes": [
            "94366"
        ]
    },
    {
        "city": "Perkam",
        "codes": [
            "94368"
        ]
    },
    {
        "city": "Rattenberg",
        "codes": [
            "94371"
        ]
    },
    {
        "city": "Rattiszell",
        "codes": [
            "94372"
        ]
    },
    {
        "city": "Stallwang",
        "codes": [
            "94375"
        ]
    },
    {
        "city": "Sankt Englmar",
        "codes": [
            "94379"
        ]
    },
    {
        "city": "Landau an der Isar",
        "codes": [
            "94405"
        ]
    },
    {
        "city": "Reisbach",
        "codes": [
            "94419"
        ]
    },
    {
        "city": "Arnstorf",
        "codes": [
            "94424"
        ]
    },
    {
        "city": "Eichendorf",
        "codes": [
            "94428"
        ]
    },
    {
        "city": "Pilsting",
        "codes": [
            "94431"
        ]
    },
    {
        "city": "Simbach",
        "codes": [
            "94436"
        ]
    },
    {
        "city": "Mamming",
        "codes": [
            "94437"
        ]
    },
    {
        "city": "Roßbach",
        "codes": [
            "94439"
        ]
    },
    {
        "city": "Plattling",
        "codes": [
            "94447"
        ]
    },
    {
        "city": "Deggendorf",
        "codes": [
            "94469"
        ]
    },
    {
        "city": "Vilshofen",
        "codes": [
            "94474"
        ]
    },
    {
        "city": "Osterhofen",
        "codes": [
            "94486"
        ]
    },
    {
        "city": "Hengersberg",
        "codes": [
            "94491"
        ]
    },
    {
        "city": "Ortenburg",
        "codes": [
            "94496"
        ]
    },
    {
        "city": "Aldersbach",
        "codes": [
            "94501"
        ]
    },
    {
        "city": "Schöllnach",
        "codes": [
            "94508"
        ]
    },
    {
        "city": "Spiegelau",
        "codes": [
            "94518"
        ]
    },
    {
        "city": "Wallersdorf",
        "codes": [
            "94522"
        ]
    },
    {
        "city": "Metten",
        "codes": [
            "94526"
        ]
    },
    {
        "city": "Aholming",
        "codes": [
            "94527"
        ]
    },
    {
        "city": "Aicha vorm Wald",
        "codes": [
            "94529"
        ]
    },
    {
        "city": "Außernzell",
        "codes": [
            "94532"
        ]
    },
    {
        "city": "Buchhofen",
        "codes": [
            "94533"
        ]
    },
    {
        "city": "Eging am See",
        "codes": [
            "94535"
        ]
    },
    {
        "city": "Eppenschlag",
        "codes": [
            "94536"
        ]
    },
    {
        "city": "Fürstenstein",
        "codes": [
            "94538"
        ]
    },
    {
        "city": "Grafling",
        "codes": [
            "94539"
        ]
    },
    {
        "city": "Grattersdorf",
        "codes": [
            "94541"
        ]
    },
    {
        "city": "Haarbach",
        "codes": [
            "94542"
        ]
    },
    {
        "city": "Hofkirchen",
        "codes": [
            "94544"
        ]
    },
    {
        "city": "Hohenau",
        "codes": [
            "94545"
        ]
    },
    {
        "city": "Iggensbach",
        "codes": [
            "94547"
        ]
    },
    {
        "city": "Innernzell",
        "codes": [
            "94548"
        ]
    },
    {
        "city": "Künzing",
        "codes": [
            "94550"
        ]
    },
    {
        "city": "Lalling",
        "codes": [
            "94551"
        ]
    },
    {
        "city": "Mariaposching",
        "codes": [
            "94553"
        ]
    },
    {
        "city": "Neuschönau",
        "codes": [
            "94556"
        ]
    },
    {
        "city": "Niederalteich",
        "codes": [
            "94557"
        ]
    },
    {
        "city": "Niederwinkling",
        "codes": [
            "94559"
        ]
    },
    {
        "city": "Offenberg",
        "codes": [
            "94560"
        ]
    },
    {
        "city": "Oberpöring",
        "codes": [
            "94562"
        ]
    },
    {
        "city": "Otzing",
        "codes": [
            "94563"
        ]
    },
    {
        "city": "Sankt Oswald-Riedlhütte",
        "codes": [
            "94566",
            "94568"
        ]
    },
    {
        "city": "Stephansposching",
        "codes": [
            "94569"
        ]
    },
    {
        "city": "Schaufling",
        "codes": [
            "94571"
        ]
    },
    {
        "city": "Schöfweg",
        "codes": [
            "94572"
        ]
    },
    {
        "city": "Wallerfing",
        "codes": [
            "94574"
        ]
    },
    {
        "city": "Winzer",
        "codes": [
            "94577"
        ]
    },
    {
        "city": "Zenting",
        "codes": [
            "94579"
        ]
    },
    {
        "city": "Selb",
        "codes": [
            "95100"
        ]
    },
    {
        "city": "Rehau",
        "codes": [
            "95111"
        ]
    },
    {
        "city": "Naila",
        "codes": [
            "95119"
        ]
    },
    {
        "city": "Schwarzenbach an der Saale",
        "codes": [
            "95126"
        ]
    },
    {
        "city": "Schwarzenbach am Wald",
        "codes": [
            "95131"
        ]
    },
    {
        "city": "Bad Steben",
        "codes": [
            "95138"
        ]
    },
    {
        "city": "Oberkotzau",
        "codes": [
            "95145"
        ]
    },
    {
        "city": "Selbitz",
        "codes": [
            "95152"
        ]
    },
    {
        "city": "Kirchenlamitz",
        "codes": [
            "95158"
        ]
    },
    {
        "city": "Weißenstadt",
        "codes": [
            "95163"
        ]
    },
    {
        "city": "Marktleuthen",
        "codes": [
            "95168"
        ]
    },
    {
        "city": "Schönwald",
        "codes": [
            "95173"
        ]
    },
    {
        "city": "Konradsreuth",
        "codes": [
            "95176"
        ]
    },
    {
        "city": "Geroldsgrün",
        "codes": [
            "95179"
        ]
    },
    {
        "city": "Döhlau",
        "codes": [
            "95182"
        ]
    },
    {
        "city": "Feilitzsch",
        "codes": [
            "95183"
        ]
    },
    {
        "city": "Gattendorf",
        "codes": [
            "95185"
        ]
    },
    {
        "city": "Höchstädt im Fichtelgebirge",
        "codes": [
            "95186"
        ]
    },
    {
        "city": "Issigau",
        "codes": [
            "95188"
        ]
    },
    {
        "city": "Köditz",
        "codes": [
            "95189"
        ]
    },
    {
        "city": "Leupoldsgrün",
        "codes": [
            "95191"
        ]
    },
    {
        "city": "Regnitzlosau",
        "codes": [
            "95194"
        ]
    },
    {
        "city": "Röslau",
        "codes": [
            "95195"
        ]
    },
    {
        "city": "Schauenstein",
        "codes": [
            "95197"
        ]
    },
    {
        "city": "Thierstein",
        "codes": [
            "95199"
        ]
    },
    {
        "city": "Münchberg",
        "codes": [
            "95213"
        ]
    },
    {
        "city": "Helmbrechts",
        "codes": [
            "95233"
        ]
    },
    {
        "city": "Sparneck",
        "codes": [
            "95234"
        ]
    },
    {
        "city": "Stammbach",
        "codes": [
            "95236"
        ]
    },
    {
        "city": "Weißdorf",
        "codes": [
            "95237"
        ]
    },
    {
        "city": "Kulmbach",
        "codes": [
            "95326"
        ]
    },
    {
        "city": "Mainleus",
        "codes": [
            "95336"
        ]
    },
    {
        "city": "Neuenmarkt",
        "codes": [
            "95339"
        ]
    },
    {
        "city": "Stadtsteinach",
        "codes": [
            "95346"
        ]
    },
    {
        "city": "Thurnau",
        "codes": [
            "95349"
        ]
    },
    {
        "city": "Marktleugast",
        "codes": [
            "95352"
        ]
    },
    {
        "city": "Presseck",
        "codes": [
            "95355"
        ]
    },
    {
        "city": "Grafengehaig",
        "codes": [
            "95356"
        ]
    },
    {
        "city": "Guttenberg",
        "codes": [
            "95358"
        ]
    },
    {
        "city": "Kasendorf",
        "codes": [
            "95359"
        ]
    },
    {
        "city": "Ködnitz",
        "codes": [
            "95361"
        ]
    },
    {
        "city": "Kupferberg",
        "codes": [
            "95362"
        ]
    },
    {
        "city": "Ludwigschorgast",
        "codes": [
            "95364"
        ]
    },
    {
        "city": "Rugendorf",
        "codes": [
            "95365"
        ]
    },
    {
        "city": "Trebgast",
        "codes": [
            "95367"
        ]
    },
    {
        "city": "Untersteinach",
        "codes": [
            "95369"
        ]
    },
    {
        "city": "Bayreuth",
        "codes": [
            "95444",
            "95445",
            "95447",
            "95448"
        ]
    },
    {
        "city": "Bad Berneck im Fichtelgebirg",
        "codes": [
            "95460"
        ]
    },
    {
        "city": "Bindlach",
        "codes": [
            "95463"
        ]
    },
    {
        "city": "Weidenberg",
        "codes": [
            "95466"
        ]
    },
    {
        "city": "Speichersdorf",
        "codes": [
            "95469"
        ]
    },
    {
        "city": "Creußen",
        "codes": [
            "95473"
        ]
    },
    {
        "city": "Kemnath",
        "codes": [
            "95478"
        ]
    },
    {
        "city": "Gefrees",
        "codes": [
            "95482"
        ]
    },
    {
        "city": "Warmensteinach",
        "codes": [
            "95485"
        ]
    },
    {
        "city": "Eckersdorf",
        "codes": [
            "95488"
        ]
    },
    {
        "city": "Mistelgau",
        "codes": [
            "95490"
        ]
    },
    {
        "city": "Ahorntal",
        "codes": [
            "95491"
        ]
    },
    {
        "city": "Bischofsgrün",
        "codes": [
            "95493"
        ]
    },
    {
        "city": "Gesees",
        "codes": [
            "95494"
        ]
    },
    {
        "city": "Goldkronach",
        "codes": [
            "95497"
        ]
    },
    {
        "city": "Harsdorf",
        "codes": [
            "95499"
        ]
    },
    {
        "city": "Heinersreuth",
        "codes": [
            "95500"
        ]
    },
    {
        "city": "Himmelkron",
        "codes": [
            "95502"
        ]
    },
    {
        "city": "Hummeltal",
        "codes": [
            "95503"
        ]
    },
    {
        "city": "Immenreuth",
        "codes": [
            "95505"
        ]
    },
    {
        "city": "Kulmain",
        "codes": [
            "95508"
        ]
    },
    {
        "city": "Marktschorgast",
        "codes": [
            "95509"
        ]
    },
    {
        "city": "Mistelbach",
        "codes": [
            "95511"
        ]
    },
    {
        "city": "Neudrossenfeld",
        "codes": [
            "95512"
        ]
    },
    {
        "city": "Neustadt am Kulm",
        "codes": [
            "95514"
        ]
    },
    {
        "city": "Plankenfels",
        "codes": [
            "95515"
        ]
    },
    {
        "city": "Emtmannsberg",
        "codes": [
            "95517"
        ]
    },
    {
        "city": "Vorbach",
        "codes": [
            "95519"
        ]
    },
    {
        "city": "Marktredwitz",
        "codes": [
            "95615"
        ]
    },
    {
        "city": "Wunsiedel",
        "codes": [
            "95632"
        ]
    },
    {
        "city": "Tirschenreuth",
        "codes": [
            "95643"
        ]
    },
    {
        "city": "Waldsassen",
        "codes": [
            "95652"
        ]
    },
    {
        "city": "Arzberg",
        "codes": [
            "95659"
        ]
    },
    {
        "city": "Mitterteich",
        "codes": [
            "95666"
        ]
    },
    {
        "city": "Bärnau",
        "codes": [
            "95671"
        ]
    },
    {
        "city": "Wiesau",
        "codes": [
            "95676"
        ]
    },
    {
        "city": "Waldershof",
        "codes": [
            "95679"
        ]
    },
    {
        "city": "Bad Alexandersbad",
        "codes": [
            "95680"
        ]
    },
    {
        "city": "Brand",
        "codes": [
            "95682"
        ]
    },
    {
        "city": "Ebnath",
        "codes": [
            "95683"
        ]
    },
    {
        "city": "Fichtelberg",
        "codes": [
            "95686"
        ]
    },
    {
        "city": "Friedenfels",
        "codes": [
            "95688"
        ]
    },
    {
        "city": "Fuchsmühl",
        "codes": [
            "95689"
        ]
    },
    {
        "city": "Hohenberg an der Eger",
        "codes": [
            "95691"
        ]
    },
    {
        "city": "Konnersreuth",
        "codes": [
            "95692"
        ]
    },
    {
        "city": "Mehlmeisel",
        "codes": [
            "95694"
        ]
    },
    {
        "city": "Mähring",
        "codes": [
            "95695"
        ]
    },
    {
        "city": "Nagel",
        "codes": [
            "95697"
        ]
    },
    {
        "city": "Neualbenreuth",
        "codes": [
            "95698"
        ]
    },
    {
        "city": "Neusorg",
        "codes": [
            "95700"
        ]
    },
    {
        "city": "Pechbrunn",
        "codes": [
            "95701"
        ]
    },
    {
        "city": "Plößberg",
        "codes": [
            "95703"
        ]
    },
    {
        "city": "Pullenreuth",
        "codes": [
            "95704"
        ]
    },
    {
        "city": "Schirnding",
        "codes": [
            "95706"
        ]
    },
    {
        "city": "Thiersheim",
        "codes": [
            "95707"
        ]
    },
    {
        "city": "Tröstau",
        "codes": [
            "95709"
        ]
    },
    {
        "city": "Bamberg",
        "codes": [
            "96047",
            "96049",
            "96050",
            "96052"
        ]
    },
    {
        "city": "Hallstadt",
        "codes": [
            "96103"
        ]
    },
    {
        "city": "Ebern",
        "codes": [
            "96106"
        ]
    },
    {
        "city": "Scheßlitz",
        "codes": [
            "96110"
        ]
    },
    {
        "city": "Hirschaid",
        "codes": [
            "96114"
        ]
    },
    {
        "city": "Memmelsdorf",
        "codes": [
            "96117"
        ]
    },
    {
        "city": "Bischberg",
        "codes": [
            "96120"
        ]
    },
    {
        "city": "Litzendorf",
        "codes": [
            "96123"
        ]
    },
    {
        "city": "Maroldsweisach",
        "codes": [
            "96126"
        ]
    },
    {
        "city": "Strullendorf",
        "codes": [
            "96129"
        ]
    },
    {
        "city": "Schlüsselfeld",
        "codes": [
            "96132"
        ]
    },
    {
        "city": "Stegaurach",
        "codes": [
            "96135"
        ]
    },
    {
        "city": "Burgebrach",
        "codes": [
            "96138"
        ]
    },
    {
        "city": "Hollfeld",
        "codes": [
            "96142"
        ]
    },
    {
        "city": "Seßlach",
        "codes": [
            "96145"
        ]
    },
    {
        "city": "Baunach",
        "codes": [
            "96148"
        ]
    },
    {
        "city": "Breitengüßbach",
        "codes": [
            "96149"
        ]
    },
    {
        "city": "Breitbrunn",
        "codes": [
            "96151"
        ]
    },
    {
        "city": "Burghaslach",
        "codes": [
            "96152"
        ]
    },
    {
        "city": "Burgwindheim",
        "codes": [
            "96154"
        ]
    },
    {
        "city": "Buttenheim",
        "codes": [
            "96155"
        ]
    },
    {
        "city": "Ebrach",
        "codes": [
            "96157"
        ]
    },
    {
        "city": "Frensdorf",
        "codes": [
            "96158"
        ]
    },
    {
        "city": "Geiselwind",
        "codes": [
            "96160"
        ]
    },
    {
        "city": "Gerach",
        "codes": [
            "96161"
        ]
    },
    {
        "city": "Kemmern",
        "codes": [
            "96164"
        ]
    },
    {
        "city": "Kirchlauter",
        "codes": [
            "96166"
        ]
    },
    {
        "city": "Königsfeld",
        "codes": [
            "96167"
        ]
    },
    {
        "city": "Lauter",
        "codes": [
            "96169"
        ]
    },
    {
        "city": "Lisberg",
        "codes": [
            "96170"
        ]
    },
    {
        "city": "Oberhaid",
        "codes": [
            "96173"
        ]
    },
    {
        "city": "Pettstadt",
        "codes": [
            "96175"
        ]
    },
    {
        "city": "Pfarrweisach",
        "codes": [
            "96176"
        ]
    },
    {
        "city": "Pommersfelden",
        "codes": [
            "96178"
        ]
    },
    {
        "city": "Rattelsdorf",
        "codes": [
            "96179"
        ]
    },
    {
        "city": "Rauhenebrach",
        "codes": [
            "96181"
        ]
    },
    {
        "city": "Reckendorf",
        "codes": [
            "96182"
        ]
    },
    {
        "city": "Rentweinsdorf",
        "codes": [
            "96184"
        ]
    },
    {
        "city": "Schönbrunn im Steigerwald",
        "codes": [
            "96185"
        ]
    },
    {
        "city": "Stadelhofen",
        "codes": [
            "96187"
        ]
    },
    {
        "city": "Stettfeld",
        "codes": [
            "96188"
        ]
    },
    {
        "city": "Untermerzbach",
        "codes": [
            "96190"
        ]
    },
    {
        "city": "Viereth-Trunstadt",
        "codes": [
            "96191"
        ]
    },
    {
        "city": "Wachenroth",
        "codes": [
            "96193"
        ]
    },
    {
        "city": "Wattendorf",
        "codes": [
            "96196"
        ]
    },
    {
        "city": "Wonsees",
        "codes": [
            "96197"
        ]
    },
    {
        "city": "Zapfendorf",
        "codes": [
            "96199"
        ]
    },
    {
        "city": "Burgkunstadt",
        "codes": [
            "96224"
        ]
    },
    {
        "city": "Staffelstein",
        "codes": [
            "96231"
        ]
    },
    {
        "city": "Ebersdorf bei Coburg",
        "codes": [
            "96237"
        ]
    },
    {
        "city": "Sonnefeld",
        "codes": [
            "96242"
        ]
    },
    {
        "city": "Michelau in Oberfranken",
        "codes": [
            "96247"
        ]
    },
    {
        "city": "Ebensfeld",
        "codes": [
            "96250"
        ]
    },
    {
        "city": "Untersiemau",
        "codes": [
            "96253"
        ]
    },
    {
        "city": "Redwitz an der Rodach",
        "codes": [
            "96257"
        ]
    },
    {
        "city": "Weismain",
        "codes": [
            "96260"
        ]
    },
    {
        "city": "Altenkunstadt",
        "codes": [
            "96264"
        ]
    },
    {
        "city": "Mitwitz",
        "codes": [
            "96268"
        ]
    },
    {
        "city": "Großheirath",
        "codes": [
            "96269"
        ]
    },
    {
        "city": "Grub am Forst",
        "codes": [
            "96271"
        ]
    },
    {
        "city": "Hochstadt am Main",
        "codes": [
            "96272"
        ]
    },
    {
        "city": "Itzgrund",
        "codes": [
            "96274"
        ]
    },
    {
        "city": "Marktzeuln",
        "codes": [
            "96275"
        ]
    },
    {
        "city": "Schneckenlohe",
        "codes": [
            "96277"
        ]
    },
    {
        "city": "Weidhausen bei Coburg",
        "codes": [
            "96279"
        ]
    },
    {
        "city": "Kronach",
        "codes": [
            "96317"
        ]
    },
    {
        "city": "Küps",
        "codes": [
            "96328"
        ]
    },
    {
        "city": "Pressig",
        "codes": [
            "96332"
        ]
    },
    {
        "city": "Ludwigsstadt",
        "codes": [
            "96337"
        ]
    },
    {
        "city": "Stockheim",
        "codes": [
            "96342"
        ]
    },
    {
        "city": "Wallenfels",
        "codes": [
            "96346"
        ]
    },
    {
        "city": "Steinwiesen",
        "codes": [
            "96349"
        ]
    },
    {
        "city": "Wilhelmsthal",
        "codes": [
            "96352"
        ]
    },
    {
        "city": "Tettau",
        "codes": [
            "96355"
        ]
    },
    {
        "city": "Teuschnitz",
        "codes": [
            "96358"
        ]
    },
    {
        "city": "Steinbach am Wald",
        "codes": [
            "96361"
        ]
    },
    {
        "city": "Marktrodach",
        "codes": [
            "96364"
        ]
    },
    {
        "city": "Nordhalben",
        "codes": [
            "96365"
        ]
    },
    {
        "city": "Tschirn",
        "codes": [
            "96367"
        ]
    },
    {
        "city": "Weißenbrunn",
        "codes": [
            "96369"
        ]
    },
    {
        "city": "Coburg",
        "codes": [
            "96450"
        ]
    },
    {
        "city": "Neustadt bei Coburg",
        "codes": [
            "96465"
        ]
    },
    {
        "city": "Rödental",
        "codes": [
            "96472"
        ]
    },
    {
        "city": "Rodach bei Coburg",
        "codes": [
            "96476"
        ]
    },
    {
        "city": "Weitramsdorf",
        "codes": [
            "96479"
        ]
    },
    {
        "city": "Meeder",
        "codes": [
            "96484"
        ]
    },
    {
        "city": "Lautertal",
        "codes": [
            "96486"
        ]
    },
    {
        "city": "Dörfles-Esbach",
        "codes": [
            "96487"
        ]
    },
    {
        "city": "Niederfüllbach",
        "codes": [
            "96489"
        ]
    },
    {
        "city": "Sonneberg",
        "codes": [
            "96515"
        ]
    },
    {
        "city": "Neuhaus-Schierschnitz",
        "codes": [
            "96524"
        ]
    },
    {
        "city": "Effelder-Rauenstein",
        "codes": [
            "96528"
        ]
    },
    {
        "city": "Mengersgereuth-Hämmern",
        "codes": [
            "96529"
        ]
    },
    {
        "city": "Würzburg",
        "codes": [
            "97070",
            "97072",
            "97074",
            "97076",
            "97078",
            "97080",
            "97082",
            "97084"
        ]
    },
    {
        "city": "Ochsenfurt",
        "codes": [
            "97199"
        ]
    },
    {
        "city": "Höchberg",
        "codes": [
            "97204"
        ]
    },
    {
        "city": "Veitshöchheim",
        "codes": [
            "97209"
        ]
    },
    {
        "city": "Uffenheim",
        "codes": [
            "97215"
        ]
    },
    {
        "city": "Gerbrunn",
        "codes": [
            "97218"
        ]
    },
    {
        "city": "Rimpar",
        "codes": [
            "97222"
        ]
    },
    {
        "city": "Zellingen",
        "codes": [
            "97225"
        ]
    },
    {
        "city": "Rottendorf",
        "codes": [
            "97228"
        ]
    },
    {
        "city": "Estenfeld",
        "codes": [
            "97230"
        ]
    },
    {
        "city": "Giebelstadt",
        "codes": [
            "97232"
        ]
    },
    {
        "city": "Randersacker",
        "codes": [
            "97236"
        ]
    },
    {
        "city": "Altertheim",
        "codes": [
            "97237"
        ]
    },
    {
        "city": "Aub",
        "codes": [
            "97239"
        ]
    },
    {
        "city": "Bergtheim",
        "codes": [
            "97241"
        ]
    },
    {
        "city": "Bieberehren",
        "codes": [
            "97243"
        ]
    },
    {
        "city": "Bütthard",
        "codes": [
            "97244"
        ]
    },
    {
        "city": "Eibelstadt",
        "codes": [
            "97246"
        ]
    },
    {
        "city": "Eisenheim",
        "codes": [
            "97247"
        ]
    },
    {
        "city": "Erlabrunn",
        "codes": [
            "97250"
        ]
    },
    {
        "city": "Frickenhausen am Main",
        "codes": [
            "97252"
        ]
    },
    {
        "city": "Gaukönigshofen",
        "codes": [
            "97253"
        ]
    },
    {
        "city": "Gelchsheim",
        "codes": [
            "97255"
        ]
    },
    {
        "city": "Geroldshausen",
        "codes": [
            "97256"
        ]
    },
    {
        "city": "Ippesheim",
        "codes": [
            "97258"
        ]
    },
    {
        "city": "Greußenheim",
        "codes": [
            "97259"
        ]
    },
    {
        "city": "Güntersleben",
        "codes": [
            "97261"
        ]
    },
    {
        "city": "Hausen bei Würzburg",
        "codes": [
            "97262"
        ]
    },
    {
        "city": "Helmstadt",
        "codes": [
            "97264"
        ]
    },
    {
        "city": "Hettstadt",
        "codes": [
            "97265"
        ]
    },
    {
        "city": "Himmelstadt",
        "codes": [
            "97267"
        ]
    },
    {
        "city": "Kist",
        "codes": [
            "97270"
        ]
    },
    {
        "city": "Kleinrinderfeld",
        "codes": [
            "97271"
        ]
    },
    {
        "city": "Kürnach",
        "codes": [
            "97273"
        ]
    },
    {
        "city": "Leinach",
        "codes": [
            "97274"
        ]
    },
    {
        "city": "Margetshöchheim",
        "codes": [
            "97276"
        ]
    },
    {
        "city": "Neubrunn",
        "codes": [
            "97277"
        ]
    },
    {
        "city": "Prosselsheim",
        "codes": [
            "97279"
        ]
    },
    {
        "city": "Retzstadt",
        "codes": [
            "97282"
        ]
    },
    {
        "city": "Riedenheim",
        "codes": [
            "97283"
        ]
    },
    {
        "city": "Röttingen",
        "codes": [
            "97285"
        ]
    },
    {
        "city": "Sommerhausen",
        "codes": [
            "97286"
        ]
    },
    {
        "city": "Theilheim",
        "codes": [
            "97288"
        ]
    },
    {
        "city": "Thüngen",
        "codes": [
            "97289"
        ]
    },
    {
        "city": "Thüngersheim",
        "codes": [
            "97291"
        ]
    },
    {
        "city": "Uettingen",
        "codes": [
            "97292"
        ]
    },
    {
        "city": "Unterpleichfeld",
        "codes": [
            "97294"
        ]
    },
    {
        "city": "Waldbüttelbrunn",
        "codes": [
            "97297"
        ]
    },
    {
        "city": "Zell am Main",
        "codes": [
            "97299"
        ]
    },
    {
        "city": "Kitzingen",
        "codes": [
            "97318"
        ]
    },
    {
        "city": "Albertshofen",
        "codes": [
            "97320"
        ]
    },
    {
        "city": "Volkach",
        "codes": [
            "97332"
        ]
    },
    {
        "city": "Sommerach",
        "codes": [
            "97334"
        ]
    },
    {
        "city": "Dettelbach",
        "codes": [
            "97337"
        ]
    },
    {
        "city": "Marktbreit",
        "codes": [
            "97340"
        ]
    },
    {
        "city": "Obernbreit",
        "codes": [
            "97342"
        ]
    },
    {
        "city": "Iphofen",
        "codes": [
            "97346"
        ]
    },
    {
        "city": "Rödelsee",
        "codes": [
            "97348"
        ]
    },
    {
        "city": "Mainbernheim",
        "codes": [
            "97350"
        ]
    },
    {
        "city": "Wiesentheid",
        "codes": [
            "97353"
        ]
    },
    {
        "city": "Kleinlangheim",
        "codes": [
            "97355"
        ]
    },
    {
        "city": "Prichsenstadt",
        "codes": [
            "97357"
        ]
    },
    {
        "city": "Schwarzach am Main",
        "codes": [
            "97359"
        ]
    },
    {
        "city": "Schweinfurt",
        "codes": [
            "97421",
            "97422",
            "97424"
        ]
    },
    {
        "city": "Haßfurt",
        "codes": [
            "97437"
        ]
    },
    {
        "city": "Werneck",
        "codes": [
            "97440"
        ]
    },
    {
        "city": "Gerolzhofen",
        "codes": [
            "97447"
        ]
    },
    {
        "city": "Arnstein",
        "codes": [
            "97450"
        ]
    },
    {
        "city": "Schonungen",
        "codes": [
            "97453"
        ]
    },
    {
        "city": "Dittelbrunn",
        "codes": [
            "97456"
        ]
    },
    {
        "city": "Hofheim in Unterfranken",
        "codes": [
            "97461"
        ]
    },
    {
        "city": "Niederwerrn",
        "codes": [
            "97464"
        ]
    },
    {
        "city": "Gochsheim",
        "codes": [
            "97469"
        ]
    },
    {
        "city": "Zeil am Main",
        "codes": [
            "97475"
        ]
    },
    {
        "city": "Knetzgau",
        "codes": [
            "97478"
        ]
    },
    {
        "city": "Eltmann",
        "codes": [
            "97483"
        ]
    },
    {
        "city": "Königsberg in Bayern",
        "codes": [
            "97486"
        ]
    },
    {
        "city": "Stadtlauringen",
        "codes": [
            "97488"
        ]
    },
    {
        "city": "Poppenhausen",
        "codes": [
            "97490"
        ]
    },
    {
        "city": "Aidhausen",
        "codes": [
            "97491"
        ]
    },
    {
        "city": "Bergrheinfeld",
        "codes": [
            "97493"
        ]
    },
    {
        "city": "Bundorf",
        "codes": [
            "97494"
        ]
    },
    {
        "city": "Burgpreppach",
        "codes": [
            "97496"
        ]
    },
    {
        "city": "Dingolshausen",
        "codes": [
            "97497"
        ]
    },
    {
        "city": "Donnersdorf",
        "codes": [
            "97499"
        ]
    },
    {
        "city": "Ebelsbach",
        "codes": [
            "97500"
        ]
    },
    {
        "city": "Euerbach",
        "codes": [
            "97502"
        ]
    },
    {
        "city": "Gädheim",
        "codes": [
            "97503"
        ]
    },
    {
        "city": "Geldersheim",
        "codes": [
            "97505"
        ]
    },
    {
        "city": "Grafenrheinfeld",
        "codes": [
            "97506"
        ]
    },
    {
        "city": "Grettstadt",
        "codes": [
            "97508"
        ]
    },
    {
        "city": "Kolitzheim",
        "codes": [
            "97509"
        ]
    },
    {
        "city": "Lülsfeld",
        "codes": [
            "97511"
        ]
    },
    {
        "city": "Michelau im Steigerwald",
        "codes": [
            "97513"
        ]
    },
    {
        "city": "Oberaurach",
        "codes": [
            "97514"
        ]
    },
    {
        "city": "Oberschwarzach",
        "codes": [
            "97516"
        ]
    },
    {
        "city": "Rannungen",
        "codes": [
            "97517"
        ]
    },
    {
        "city": "Riedbach",
        "codes": [
            "97519"
        ]
    },
    {
        "city": "Röthlein",
        "codes": [
            "97520"
        ]
    },
    {
        "city": "Sand am Main",
        "codes": [
            "97522"
        ]
    },
    {
        "city": "Schwanfeld",
        "codes": [
            "97523"
        ]
    },
    {
        "city": "Schwebheim",
        "codes": [
            "97525"
        ]
    },
    {
        "city": "Sennfeld",
        "codes": [
            "97526"
        ]
    },
    {
        "city": "Sulzdorf an der Lederhecke",
        "codes": [
            "97528"
        ]
    },
    {
        "city": "Sulzheim",
        "codes": [
            "97529"
        ]
    },
    {
        "city": "Theres",
        "codes": [
            "97531"
        ]
    },
    {
        "city": "Üchtelhausen",
        "codes": [
            "97532"
        ]
    },
    {
        "city": "Waigolshausen",
        "codes": [
            "97534"
        ]
    },
    {
        "city": "Wasserlosen",
        "codes": [
            "97535"
        ]
    },
    {
        "city": "Wipfeld",
        "codes": [
            "97537"
        ]
    },
    {
        "city": "Wonfurt",
        "codes": [
            "97539"
        ]
    },
    {
        "city": "Bad Neustadt an der Saale",
        "codes": [
            "97616"
        ]
    },
    {
        "city": "Hohenroth",
        "codes": [
            "97618"
        ]
    },
    {
        "city": "Bad Königshofen im Grabfeld",
        "codes": [
            "97631"
        ]
    },
    {
        "city": "Mellrichstadt",
        "codes": [
            "97638"
        ]
    },
    {
        "city": "Oberstreu",
        "codes": [
            "97640"
        ]
    },
    {
        "city": "Ostheim vor der Rhön",
        "codes": [
            "97645"
        ]
    },
    {
        "city": "Nordheim vor der Rhön",
        "codes": [
            "97647"
        ]
    },
    {
        "city": "Fladungen",
        "codes": [
            "97650"
        ]
    },
    {
        "city": "Bischofsheim an der Rhön",
        "codes": [
            "97653"
        ]
    },
    {
        "city": "Bastheim",
        "codes": [
            "97654"
        ]
    },
    {
        "city": "Oberelsbach",
        "codes": [
            "97656"
        ]
    },
    {
        "city": "Sandberg",
        "codes": [
            "97657"
        ]
    },
    {
        "city": "Schönau an der Brend",
        "codes": [
            "97659"
        ]
    },
    {
        "city": "Bad Kissingen",
        "codes": [
            "97688"
        ]
    },
    {
        "city": "Münnerstadt",
        "codes": [
            "97702"
        ]
    },
    {
        "city": "Burkardroth",
        "codes": [
            "97705"
        ]
    },
    {
        "city": "Bad Bocklet",
        "codes": [
            "97708"
        ]
    },
    {
        "city": "Maßbach",
        "codes": [
            "97711"
        ]
    },
    {
        "city": "Oerlenbach",
        "codes": [
            "97714"
        ]
    },
    {
        "city": "Euerdorf",
        "codes": [
            "97717"
        ]
    },
    {
        "city": "Nüdlingen",
        "codes": [
            "97720"
        ]
    },
    {
        "city": "Oberthulba",
        "codes": [
            "97723"
        ]
    },
    {
        "city": "Burglauer",
        "codes": [
            "97724"
        ]
    },
    {
        "city": "Elfershausen",
        "codes": [
            "97725"
        ]
    },
    {
        "city": "Fuchsstadt",
        "codes": [
            "97727"
        ]
    },
    {
        "city": "Ramsthal",
        "codes": [
            "97729"
        ]
    },
    {
        "city": "Gemünden am Main",
        "codes": [
            "97737"
        ]
    },
    {
        "city": "Karlstadt",
        "codes": [
            "97753"
        ]
    },
    {
        "city": "Hammelburg",
        "codes": [
            "97762"
        ]
    },
    {
        "city": "Bad Brückenau",
        "codes": [
            "97769"
        ]
    },
    {
        "city": "Wildflecken",
        "codes": [
            "97772"
        ]
    },
    {
        "city": "Aura im Sinngrund",
        "codes": [
            "97773"
        ]
    },
    {
        "city": "Burgsinn",
        "codes": [
            "97775"
        ]
    },
    {
        "city": "Eußenheim",
        "codes": [
            "97776"
        ]
    },
    {
        "city": "Fellen",
        "codes": [
            "97778"
        ]
    },
    {
        "city": "Geroda",
        "codes": [
            "97779"
        ]
    },
    {
        "city": "Gössenheim",
        "codes": [
            "97780"
        ]
    },
    {
        "city": "Gräfendorf",
        "codes": [
            "97782"
        ]
    },
    {
        "city": "Karsbach",
        "codes": [
            "97783"
        ]
    },
    {
        "city": "Mittelsinn",
        "codes": [
            "97785"
        ]
    },
    {
        "city": "Motten",
        "codes": [
            "97786"
        ]
    },
    {
        "city": "Neuendorf",
        "codes": [
            "97788"
        ]
    },
    {
        "city": "Oberleichtersbach",
        "codes": [
            "97789"
        ]
    },
    {
        "city": "Obersinn",
        "codes": [
            "97791"
        ]
    },
    {
        "city": "Riedenberg",
        "codes": [
            "97792"
        ]
    },
    {
        "city": "Rieneck",
        "codes": [
            "97794"
        ]
    },
    {
        "city": "Schondra",
        "codes": [
            "97795"
        ]
    },
    {
        "city": "Wartmannsroth",
        "codes": [
            "97797"
        ]
    },
    {
        "city": "Zeitlofs",
        "codes": [
            "97799"
        ]
    },
    {
        "city": "Lohr am Main",
        "codes": [
            "97816"
        ]
    },
    {
        "city": "Marktheidenfeld",
        "codes": [
            "97828"
        ]
    },
    {
        "city": "Frammersbach",
        "codes": [
            "97833"
        ]
    },
    {
        "city": "Bischbrunn",
        "codes": [
            "97836",
            "97852"
        ]
    },
    {
        "city": "Erlenbach bei Marktheidenfeld",
        "codes": [
            "97837"
        ]
    },
    {
        "city": "Esselbach",
        "codes": [
            "97839"
        ]
    },
    {
        "city": "Hafenlohr",
        "codes": [
            "97840"
        ]
    },
    {
        "city": "Karbach",
        "codes": [
            "97842"
        ]
    },
    {
        "city": "Neustadt am Main",
        "codes": [
            "97845"
        ]
    },
    {
        "city": "Partenstein",
        "codes": [
            "97846"
        ]
    },
    {
        "city": "Rechtenbach",
        "codes": [
            "97848"
        ]
    },
    {
        "city": "Roden",
        "codes": [
            "97849"
        ]
    },
    {
        "city": "Rothenfels",
        "codes": [
            "97851"
        ]
    },
    {
        "city": "Triefenstein",
        "codes": [
            "97855"
        ]
    },
    {
        "city": "Urspringen",
        "codes": [
            "97857"
        ]
    },
    {
        "city": "Wiesthal",
        "codes": [
            "97859"
        ]
    },
    {
        "city": "Wertheim",
        "codes": [
            "97877"
        ]
    },
    {
        "city": "Kreuzwertheim",
        "codes": [
            "97892"
        ]
    },
    {
        "city": "Külsheim",
        "codes": [
            "97900"
        ]
    },
    {
        "city": "Altenbuch",
        "codes": [
            "97901"
        ]
    },
    {
        "city": "Collenberg",
        "codes": [
            "97903"
        ]
    },
    {
        "city": "Dorfprozelten",
        "codes": [
            "97904"
        ]
    },
    {
        "city": "Faulbach",
        "codes": [
            "97906"
        ]
    },
    {
        "city": "Hasloch",
        "codes": [
            "97907"
        ]
    },
    {
        "city": "Stadtprozelten",
        "codes": [
            "97909"
        ]
    },
    {
        "city": "Lauda-Königshofen",
        "codes": [
            "97922"
        ]
    },
    {
        "city": "Tauberbischofsheim",
        "codes": [
            "97941"
        ]
    },
    {
        "city": "Boxberg",
        "codes": [
            "97944"
        ]
    },
    {
        "city": "Grünsfeld",
        "codes": [
            "97947"
        ]
    },
    {
        "city": "Großrinderfeld",
        "codes": [
            "97950"
        ]
    },
    {
        "city": "Königheim",
        "codes": [
            "97953"
        ]
    },
    {
        "city": "Werbach",
        "codes": [
            "97956"
        ]
    },
    {
        "city": "Wittighausen",
        "codes": [
            "97957"
        ]
    },
    {
        "city": "Assamstadt",
        "codes": [
            "97959"
        ]
    },
    {
        "city": "Bad Mergentheim",
        "codes": [
            "97980"
        ]
    },
    {
        "city": "Weikersheim",
        "codes": [
            "97990"
        ]
    },
    {
        "city": "Creglingen",
        "codes": [
            "97993"
        ]
    },
    {
        "city": "Niederstetten",
        "codes": [
            "97996"
        ]
    },
    {
        "city": "Igersheim",
        "codes": [
            "97999"
        ]
    },
    {
        "city": "Suhl",
        "codes": [
            "98527",
            "98528",
            "98529",
            "98530",
            "98711"
        ]
    },
    {
        "city": "Zella-Mehlis",
        "codes": [
            "98544"
        ]
    },
    {
        "city": "Viernau",
        "codes": [
            "98547"
        ]
    },
    {
        "city": "Schleusingen",
        "codes": [
            "98553"
        ]
    },
    {
        "city": "Benshausen",
        "codes": [
            "98554"
        ]
    },
    {
        "city": "Oberhof",
        "codes": [
            "98559"
        ]
    },
    {
        "city": "Schmalkalden",
        "codes": [
            "98574",
            "98590"
        ]
    },
    {
        "city": "Steinbach-Hallenberg",
        "codes": [
            "98587"
        ]
    },
    {
        "city": "Floh-Seligenthal",
        "codes": [
            "98593"
        ]
    },
    {
        "city": "Trusetal",
        "codes": [
            "98596"
        ]
    },
    {
        "city": "Breitungen / Werra",
        "codes": [
            "98597"
        ]
    },
    {
        "city": "Brotterode",
        "codes": [
            "98599"
        ]
    },
    {
        "city": "Meiningen",
        "codes": [
            "98617"
        ]
    },
    {
        "city": "Römhild",
        "codes": [
            "98631"
        ]
    },
    {
        "city": "Wasungen",
        "codes": [
            "98634"
        ]
    },
    {
        "city": "Hildburghausen",
        "codes": [
            "98646"
        ]
    },
    {
        "city": "Themar",
        "codes": [
            "98660"
        ]
    },
    {
        "city": "Bad Colberg-Heldburg",
        "codes": [
            "98663"
        ]
    },
    {
        "city": "Schleusegrund",
        "codes": [
            "98667"
        ]
    },
    {
        "city": "Veilsdorf",
        "codes": [
            "98669"
        ]
    },
    {
        "city": "Eisfeld",
        "codes": [
            "98673"
        ]
    },
    {
        "city": "Sachsenbrunn",
        "codes": [
            "98678"
        ]
    },
    {
        "city": "Ilmenau",
        "codes": [
            "98693"
        ]
    },
    {
        "city": "Großbreitenbach",
        "codes": [
            "98701"
        ]
    },
    {
        "city": "Langewiesen",
        "codes": [
            "98704"
        ]
    },
    {
        "city": "Gehren",
        "codes": [
            "98708"
        ]
    },
    {
        "city": "Stützerbach",
        "codes": [
            "98714"
        ]
    },
    {
        "city": "Geschwenda",
        "codes": [
            "98716"
        ]
    },
    {
        "city": "Neuhaus am Rennweg",
        "codes": [
            "98724"
        ]
    },
    {
        "city": "Lichte",
        "codes": [
            "98739"
        ]
    },
    {
        "city": "Gräfenthal",
        "codes": [
            "98743"
        ]
    },
    {
        "city": "Oberweißbach / Thüringer Wald",
        "codes": [
            "98744"
        ]
    },
    {
        "city": "Katzhütte",
        "codes": [
            "98746"
        ]
    },
    {
        "city": "Steinheid",
        "codes": [
            "98749"
        ]
    },
    {
        "city": "Erfurt",
        "codes": [
            "99084",
            "99085",
            "99086",
            "99087",
            "99089",
            "99091",
            "99092",
            "99094",
            "99096",
            "99097",
            "99098",
            "99099",
            "99100",
            "99102",
            "99189",
            "99192",
            "99195",
            "99198"
        ]
    },
    {
        "city": "Arnstadt",
        "codes": [
            "99310",
            "99338"
        ]
    },
    {
        "city": "Stadtilm",
        "codes": [
            "99326"
        ]
    },
    {
        "city": "Gräfenroda",
        "codes": [
            "99330"
        ]
    },
    {
        "city": "Ichtershausen",
        "codes": [
            "99334"
        ]
    },
    {
        "city": "Berlstedt",
        "codes": [
            "99439"
        ]
    },
    {
        "city": "Blankenhain",
        "codes": [
            "99444"
        ]
    },
    {
        "city": "Kranichfeld",
        "codes": [
            "99448"
        ]
    },
    {
        "city": "Apolda",
        "codes": [
            "99510"
        ]
    },
    {
        "city": "Bad Sulza",
        "codes": [
            "99518"
        ]
    },
    {
        "city": "Sömmerda",
        "codes": [
            "99610"
        ]
    },
    {
        "city": "Kölleda",
        "codes": [
            "99625"
        ]
    },
    {
        "city": "Buttstädt",
        "codes": [
            "99628"
        ]
    },
    {
        "city": "Weißensee",
        "codes": [
            "99631"
        ]
    },
    {
        "city": "Straußfurt",
        "codes": [
            "99634"
        ]
    },
    {
        "city": "Rastenberg",
        "codes": [
            "99636"
        ]
    },
    {
        "city": "Kindelbrück",
        "codes": [
            "99638"
        ]
    },
    {
        "city": "Sondershausen",
        "codes": [
            "99706"
        ]
    },
    {
        "city": "Ebeleben",
        "codes": [
            "99713"
        ]
    },
    {
        "city": "Greußen",
        "codes": [
            "99718"
        ]
    },
    {
        "city": "Nordhausen",
        "codes": [
            "99734",
            "99735"
        ]
    },
    {
        "city": "Bleicherode",
        "codes": [
            "99752"
        ]
    },
    {
        "city": "Ellrich",
        "codes": [
            "99755"
        ]
    },
    {
        "city": "Sollstedt",
        "codes": [
            "99759"
        ]
    },
    {
        "city": "Niedersachswerfen",
        "codes": [
            "99762"
        ]
    },
    {
        "city": "Heringen / Helme",
        "codes": [
            "99765"
        ]
    },
    {
        "city": "Ilfeld",
        "codes": [
            "99768"
        ]
    },
    {
        "city": "Eisenach",
        "codes": [
            "99817",
            "99819"
        ]
    },
    {
        "city": "Mihla",
        "codes": [
            "99826"
        ]
    },
    {
        "city": "Treffurt",
        "codes": [
            "99830"
        ]
    },
    {
        "city": "Creuzburg",
        "codes": [
            "99831"
        ]
    },
    {
        "city": "Gerstungen",
        "codes": [
            "99834"
        ]
    },
    {
        "city": "Berka / Werra",
        "codes": [
            "99837"
        ]
    },
    {
        "city": "Ruhla",
        "codes": [
            "99842",
            "99843"
        ]
    },
    {
        "city": "Wutha-Farnroda",
        "codes": [
            "99848"
        ]
    },
    {
        "city": "Gotha",
        "codes": [
            "99867",
            "99869"
        ]
    },
    {
        "city": "Waltershausen",
        "codes": [
            "99880"
        ]
    },
    {
        "city": "Ohrdruf",
        "codes": [
            "99885"
        ]
    },
    {
        "city": "Georgenthal / Thüringer Wald",
        "codes": [
            "99887"
        ]
    },
    {
        "city": "Tabarz / Thüringer Wald",
        "codes": [
            "99891"
        ]
    },
    {
        "city": "Friedrichroda",
        "codes": [
            "99894"
        ]
    },
    {
        "city": "Tambach-Dietharz / Thüringen",
        "codes": [
            "99897"
        ]
    },
    {
        "city": "Finsterbergen",
        "codes": [
            "99898"
        ]
    },
    {
        "city": "Bad Langensalza",
        "codes": [
            "99947",
            "99958"
        ]
    },
    {
        "city": "Bad Tennstedt",
        "codes": [
            "99955"
        ]
    },
    {
        "city": "Rodeberg",
        "codes": [
            "99976"
        ]
    },
    {
        "city": "Oberdorla",
        "codes": [
            "99986"
        ]
    },
    {
        "city": "Heyerode",
        "codes": [
            "99988"
        ]
    },
    {
        "city": "Großengottern",
        "codes": [
            "99991"
        ]
    },
    {
        "city": "Schlotheim",
        "codes": [
            "99994"
        ]
    },
    {
        "city": "Weinbergen",
        "codes": [
            "99998"
        ]
    }
]
