import React from "react";

import { Link } from "react-router-dom";
import { heroContentCardProps } from "../../Interfaces/response";

interface props {
  data?: heroContentCardProps;
}
export const AppHeroConentCard: React.FC<props> = ({ data }) => {
  return (
    <React.Fragment>
      {data ? (
        <Link to={data.href} >
          <div data-aos="zoom-in-up" className=" w-full  overflow-hidden h-64 cursor-pointer group perspective">
            <div className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000">
              <div className="absolute backface-hidden  rounded-md  w-full h-full">
                <img alt={``} src={data.img1} className="w-full h-full rounded-md" />
                <div className="w-full h-full rounded-b-md transprent_card px-2 py-2 flex items-end content-end justify-center ">
                  <p className="text-white text-center text-lg font-semibold">{data.title}</p>
                </div>
              </div>
              <div className="absolute my-rotate-y-180 backface-hidden rounded-md  w-full h-full overflow-hidden">
                <img alt={``} src={data.img2} className="w-full h-full rounded-md" />
                <div className="w-full h-full rounded-b-md transprent_card px-2 py-2 flex items-end content-end justify-center ">
                  <p className="text-white text-center text-sm font-normal">{data.description}</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : null}
    </React.Fragment>
  );
};
