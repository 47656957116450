import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Providers";
import { fCurrency } from "../../../Helpers";
import { Link } from "react-router-dom";
// import { AppShippingForm } from "./AppShippingForm";
import { RiArrowRightSLine } from "react-icons/ri";

export const AppSummaryComponent: React.FC = () => {
  const { total, sub_total } = useSelector((state: RootState) => state.order);

  // const [isOpen, setisOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="w-full tracking-normal grid grid-cols-12 py-2 gap-3 border-b border-white">
        <div className="col-span-12 md:col-span-12">
          <span className="text-sm font-bold text-white text-center">
            Cart totals
          </span>
        </div>
      </div>
      <div className="w-full justify-center items-center content-center grid grid-cols-12 py-2 gap-3 border-b border-white">
        <div className="col-span-6">
          <span className="text-sm font-light text-white text-center">
            Subtotal
          </span>
        </div>
        <div className="col-span-6">
          <span className="text-sm font-light text-yellow-600 text-right">
            {fCurrency(Number(Number(total).toFixed(2)))}
          </span>
        </div>
      </div>
      {/* <div className="w-full justify-center items-center content-center grid grid-cols-12 py-2 gap-3 border-b border-white">
        <div className="col-span-12">
          <span className="text-xs font-light text-white text-center">
            Shipping
          </span>
        </div>
        <div className="col-span-12">
          <div className="flex flex-col space-y-1 ">
            <span className="text-xs font-light text-white">
              Enter your address to view shipping options.
            </span>
            <button
              type="button"
              onClick={() => setisOpen(!isOpen)}
              className="cursor-pointer text-left underline text-xs font-bold text-white"
            >
              Calculate shipping
            </button>
            {isOpen && <AppShippingForm />}
          </div>
        </div>
      </div> */}
      <div className="w-full justify-center items-center content-center grid grid-cols-12 py-2 gap-3 border-b border-white">
        <div className="col-span-6">
          <span className="text-xs font-light text-white text-center">
            Total
          </span>
        </div>
        <div className="col-span-6">
          <span className="text-xs font-light">
            <span className="text-yellow-600">
              {fCurrency(Number(Number(sub_total).toFixed(2)))}
            </span>
           
          </span>
        </div>
      </div>
      <div className="w-full flex flex-col my-4">
        <Link
          to={`/checkout`}
          className="px-2 py-3  hover:bg-secondary flex justify-between items-center content-center text-sm text-center font-bold text-white bg-primary outline-none cursor-pointer"
        >
          <span className="flex-grow">Proceed to checkout</span>
          <RiArrowRightSLine />
        </Link>
      </div>
    </React.Fragment>
  );
};
