import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { clearCart } from "../Providers/order";
import { AppInnerPageWrapper } from "../Components/Modules/InnerPageWrapper";
import { AppPlaceOrderSuccessComponent } from "../Components/Ogranisms/PlaceOrder/success";

export const AppPlaceOrderSuccessScreen: React.FC = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const deleteCart = useCallback(() => {
    dispatch(clearCart(undefined));
  }, [dispatch]);

  useEffect(() => {
    deleteCart();
  }, [deleteCart]);

  if (!orderId) {
    <Navigate to={`/online-bestellen`} />;
  }
  return (
    <React.Fragment>
      <AppInnerPageWrapper>
        <AppPlaceOrderSuccessComponent />
      </AppInnerPageWrapper>
    </React.Fragment>
  );
};
