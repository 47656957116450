import React from "react";

import { CiShoppingCart } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { addCart, setIsLunchMenu } from "../../Providers/order";
import { fCurrency } from "../../Helpers";
import { productProps } from "../../Interfaces/response";
interface props {
  product?: productProps;
  IsLunchMenu:boolean
}
export const AppProductListCard: React.FC<props> = ({ product,IsLunchMenu }) => {
  const dispatch = useDispatch()
  const AddCartHandler =()=>{
    dispatch(addCart(product))
    if(IsLunchMenu){
      dispatch(setIsLunchMenu(true))
    }
  }
  return (
    <React.Fragment>
      <div onClick={()=>AddCartHandler()} className="w-full rounded-md cursor-pointer grid grid-cols-12 gap-3 tracking-wider relative px-3 group py-3 bg-transparent hover:bg-white hover:bg-opacity-20 ">
      <div className="absolute top-0 right-0 px-2 hidden group-hover:flex py-2">
         <CiShoppingCart className='w-auto h-6 text-primary' />
         </div>
        <div className="col-span-12 md:col-span-10">
          <div className="flex flex-col space-y-2">
            <h6 className="text-primary text-lg  font-semibold">
              {product?.title}
            </h6>
            <p className="text-sm font-light">{product?.description}</p>
          </div>
        </div>
        <div className="col-span-12 md:col-span-2">
          <p className="text-sm font-normal text-yellow-500 ">
            {fCurrency(Number(product?._regular_price))}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
