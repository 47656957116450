import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AppRoot } from "./Root";
import { AppAuthContextProvider } from "./Providers/auth";
import { UserDataContextProvider } from "./Providers/user-data";
import { Provider as StoreProvider } from "react-redux";
import { rootStore } from "./Providers";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <React.Fragment>
      <AppAuthContextProvider>
        <UserDataContextProvider>
          <StoreProvider store={rootStore}>
            <AppRoot />
          </StoreProvider>
        </UserDataContextProvider>
      </AppAuthContextProvider>
    </React.Fragment>
  </React.StrictMode>
);
