import React from "react";
import { AppInnerPageWrapper } from "../../Components/Modules/InnerPageWrapper";
import { AppUberComponent } from "../../Components/Ogranisms/Restaurant/AppUber";

export const AppUberScreen: React.FC = () => {
  return (
    <React.Fragment>
      <AppInnerPageWrapper>
        <AppUberComponent />
      </AppInnerPageWrapper>
    </React.Fragment>
  );
};
