import React from "react";
import { AppAuthWrapper } from "../../Components/Modules/AuthWrapper";
import { AppLoginFormComponent } from "../../Components/Ogranisms/Auth/AppLoginForm";
import { useAuthContext } from "../../Providers/auth";
import { Navigate } from "react-router-dom";

export const AppLoginScreen: React.FC = () => {
  const { isAuth } = useAuthContext();
  if (isAuth) {
    return <Navigate to="/profile" replace />;
  }
  return (
    <React.Fragment>
      <AppAuthWrapper>
        <AppLoginFormComponent />
      </AppAuthWrapper>
    </React.Fragment>
  );
};
