import React from "react";
import { AppInnerPageWrapper } from "../Components/Modules/InnerPageWrapper";
import { AppCheckoutComponent } from "../Components/Ogranisms/Checkout";
import { useSelector } from "react-redux";
import { RootState } from "../Providers";
import { Navigate } from "react-router-dom";

export const AppCheckoutScreen: React.FC = () => {
  const {products=[]} = useSelector((state:RootState)=>state.order);
  if(products && products.length===0){
    return <Navigate to={`/online-bestellen`} />
  }
  return (
    <AppInnerPageWrapper>
      <React.Fragment>
        <AppCheckoutComponent />
      </React.Fragment>
    </AppInnerPageWrapper>
  );
};
