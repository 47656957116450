import React from "react"
import { AppAuthWrapper } from "../../Components/Modules/AuthWrapper"
import { AppVerifyEmailForm } from "../../Components/Ogranisms/Auth/AppVerifyEmailForm"

export const AppVerifyEmailScreen:React.FC =()=>{
    return(
        <React.Fragment>
            <AppAuthWrapper>
                 <AppVerifyEmailForm />
             </AppAuthWrapper>
        </React.Fragment>
    )
}