import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AppCategoryList } from "../../Atoms/AppCategoryList";

import { categoryProps } from "../../../Interfaces/response";
import { API, APIURLS } from "../../../Services";
import { AppProductByCategoryComponent } from "./ProductByCategory";

export const AppOnelineSellerComponent: React.FC = () => {
  const [categories, setcategories] = useState<categoryProps[]>([]);
  const syncCategories = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.categories.all}`);
    const response: categoryProps[] = data;
    setcategories(response);
  }, []);

  useEffect(() => {
    syncCategories();
  }, [syncCategories]);

  const filteredCategories = useMemo(() => {
    const currentTime: Date = new Date();
    const checkInStart: Date = new Date();
    const checkInEnd: Date = new Date();
    checkInStart.setHours(1, 0, 0);
    checkInEnd.setHours(14, 30, 0);
    if (currentTime >= checkInStart && currentTime <= checkInEnd) {
      return categories;
    } else {
      return categories.filter(
        (category: categoryProps) => category.slug !== "lunch-menu"
      );
    }
  }, [categories]);

  return (
    <React.Fragment>
      <div className="w-full relative overflow-hidden">
        {filteredCategories && filteredCategories.length > 0 ? (
          <React.Fragment>
            <div className="w-full flex flex-col space-y-2">
              <div className="w-full py-1">
                <AppCategoryList categories={filteredCategories} />
              </div>
              {filteredCategories.map((category: categoryProps, index: number) => (
                <div className="w-full" key={index}>
                  <AppProductByCategoryComponent category={category} />
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
