import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { API, APIURLS } from "../../../Services";
import { AxiosResponse } from "axios";
import { useAuthContext } from "../../../Providers/auth";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export const AppContactForm: React.FC = () => {
  const { user } = useAuthContext();
  const {
    control,
    handleSubmit,
    setError,

    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name:
        user && user.first_name && user.last_name
          ? `${user.first_name} ${user.last_name}`
          : "",
      email: user && user.email ? user.email : "",
      phone: user && user.phone ? user.phone : "",
      message: "",
    },
  });

  const onSubmit = (data: any) => {
    try {
      API.post(`${APIURLS.contact}`, data)
        .then((res: AxiosResponse) => {
          if (res.data && res.data.status === 200) {
            toast.success("Sent.");
            setError("message", {
              type: "validate",
              message: "Your mail already sent",
            });
          }
        })
        .catch((err: any) => {
          toast.error(`${err.message}`);
        })
        .finally(() => {
          console.log(`API contact form`);
        });
    } catch (error) {
      toast.error(`Something Wrong!`);
    }
  };
  return (
    <React.Fragment>
      <div data-aos="fade-down" className="w-full">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-12 gap-4"
        >
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="name">Vorname, Nachname *</label>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors && errors.name && errors.name.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.name.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="anazhl_der_personen">Email*</label>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="email"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors && errors.email && errors.email.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.email.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="anazhl_der_personen">Telefon *</label>
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="tel"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors && errors.phone && errors.phone.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.phone.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="message">Nachricht*</label>
              <Controller
                control={control}
                name="message"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={(value) => onChange(value)}
                    />
                    {errors && errors.message && errors.message.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.message.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-row justify-start items-center content-center w-full my-6">
              <button
                disabled={!isValid}
                type="submit"
                className="bg-primary cursor-pointer px-4 py-2 text-white shadow-md text-lg font-bold hover:bg-slate-900 "
              >
                SUMBMIT
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
