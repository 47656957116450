import React, { useMemo } from "react";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";

import {
  RiShoppingBasketLine,
  RiCloseLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Providers";
import { groupBy } from "lodash";

import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { Link } from "react-router-dom";
import { fCurrency } from "../../Helpers";
import { addCart, removeCart, removeCartsByName } from "../../Providers/order";
import { productProps } from "../../Interfaces/response";

import { useUserData } from "../../Providers/user-data";

export const AppAddToCartDrawer: React.FC = () => {
  const {
    products = [],

    coupon_amount,
  } = useSelector((state: RootState) => state.order);
  const calculateTotal = (products: productProps[]): number => {
    if (products) {
      return products.reduce((total, product) => {
        const regularPrice: number = parseFloat(product._regular_price);
        return total + regularPrice;
      }, 0);
    } else {
      return 0;
    }
  };

  const itemTotal: number = useMemo(() => {
    return products.reduce((total, product) => {
      const regularPrice: number = parseFloat(product._regular_price);
      return total + regularPrice;
    }, 0);
  }, [products]);

  const taxTotal: number = useMemo(() => {
    const rate = 0.07;
    return (itemTotal / (rate + 1)) * rate;
  }, [itemTotal]);

  const dispatch = useDispatch();

  const { isOpenAddToCartDrawer, setIsOpenAddToCartDrawer } =
    useUserData();
  const emptyImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18103142/woocommerce-placeholder-450x450.png";
  return (
    <React.Fragment>
      <Drawer
        className="w-full max-w-screen-md min-h-screen overflow-y-auto"
        open={isOpenAddToCartDrawer}
        direction="right"
        enableOverlay
        onClose={() => setIsOpenAddToCartDrawer(!isOpenAddToCartDrawer)}
      >
        <div className="w-full relative min-h-screen  bg-black text-white flex flex-col max-w-screen-md">
          <div className="w-full flex flex-row justify-between items-center content-center">
            <div className="flex-grow px-2 flex justify-center space-x-2  items-center content-center">
              <RiShoppingBasketLine className="w-auto h-8 text-white" />
              <h5 className="text-lg font-bold">Ihr Warenkorb</h5>
            </div>
            <button
              onClick={() => setIsOpenAddToCartDrawer(!isOpenAddToCartDrawer)}
              className="cursor-pointer px-2 py-2"
            >
              <RiCloseLine className="w-auto h-6 text-white" />
            </button>
          </div>

          {products.length > 0 ? (
            <div className="flex space-y-2  flex-col flex-grow  px-2 ">
              <React.Fragment>
                {products && products.length > 0 && (
                  <React.Fragment>
                    {Object.keys(groupBy(products, "id")).map((key, index) => (
                      <div
                        key={index}
                        className="w-full grid grid-cols-12 gap-2 py-2"
                      >
                        <div className="col-span-3  justify-start items-center content-center">
                          <div className="h-full flex justify-center items-center content-center">
                            {groupBy(products, "id")[key][0].img ? (
                              <img
                                src={groupBy(products, "id")[key][0].img}
                                className="w-auto h-10 rounded-md"
                                alt=""
                              />
                            ) : (
                              <img
                                src={emptyImg}
                                className="w-auto h-10 rounded-md"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-span-6">
                          <div className="w-full flex flex-col  space-y-2 justify-center items-center content-center">
                            <p className="text-xs text-center text-white font-bold">
                              {groupBy(products, "id")[key][0].title}
                            </p>
                            <div className="w-full  flex flex-row justify-center items-center content-center">
                              <div
                                onClick={() =>
                                  dispatch(
                                    removeCart(groupBy(products, "id")[key][0])
                                  )
                                }
                                className="px-2 cursor-pointer py-2 justify-center items-center content-center"
                              >
                                <CiSquareMinus className="w-auto h-6" />
                              </div>
                              <div className=" px-2 py-2 justify-center items-center content-center">
                                <p className="text-xs font-bold">
                                  {groupBy(products, "id")[key].length}
                                </p>
                              </div>
                              <div
                                onClick={() =>
                                  dispatch(
                                    addCart(groupBy(products, "id")[key][0])
                                  )
                                }
                                className="px-2 cursor-pointer py-2 justify-center items-center content-center"
                              >
                                <CiSquarePlus className="w-auto h-6" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-3">
                          <div className="w-full flex flex-col justify-end space-y-2 items-center content-center">
                            <button
                              onClick={() =>
                                dispatch(
                                  removeCartsByName(
                                    groupBy(products, "id")[key][0]
                                  )
                                )
                              }
                              className="px-2 py-2 cursor-pointer"
                            >
                              <RiDeleteBinLine className="w-auto h-4 text-white" />
                            </button>
                            <p className="text-xs text-wrap text-yellow-600 font-light">
                              {" "}
                              {fCurrency(
                                calculateTotal(groupBy(products, "id")[key])
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                )}
                <div className="w-full flex flex-col space-y-2 border-t border-white">
                  <div className="w-full flex flex-row justify-between items-center content-center px-2 py-2">
                    <p className="text-xs text-wrap font-bold">Summe :</p>
                    <p className="text-xs text-wrap font-bold">
                      {fCurrency(Number(Number(itemTotal).toFixed(2)))}
                    </p>
                  </div>
                </div>
                {coupon_amount > 0 ? (
                  <React.Fragment>
                    <div className="w-full flex flex-col space-y-2 border-t border-white">
                      <div className="w-full flex flex-row justify-between items-center content-center px-2 py-2">
                        <p className="text-xs text-wrap font-bold">
                          Coupon Total
                        </p>
                        <p className="text-xs text-wrap font-bold">
                          {fCurrency(Number(Number(coupon_amount).toFixed(2)))}
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                <div className="w-full flex flex-col space-y-2 ">
                  <div className="w-full flex flex-row justify-between items-center content-center px-2 py-2">
                    <p className="text-xs text-wrap font-bold">Tax (7% VAT)</p>
                    <p className="text-xs text-wrap font-bold">
                      {fCurrency(Number(taxTotal.toFixed(2)))}
                    </p>
                  </div>
                </div>
                <div className="w-full flex flex-col space-y-2 border-t border-white ">
                  <div className="w-full flex flex-row justify-between items-center content-center px-2 py-2">
                    <p className="text-xs text-wrap font-bold">Gesamtsumme:</p>
                    <p className="text-xs text-wrap font-bold">
                      {fCurrency(Number(itemTotal.toFixed(2)))}
                    </p>
                  </div>
                </div>
              </React.Fragment>
            </div>
          ) : (
            <React.Fragment>
              <h6 className="text-center font-bold text-white text-xs">
                Your cart is empty.
              </h6>
            </React.Fragment>
          )}
          <div className=" w-full">
            {products.length > 0 && (
              <div className="w-full flex flex-col space-y-2 px-2 py-3">
                 <Link
                    
                    to={`/product-by-category/extra`}
                    className="flex px-2 w-full py-2 border flex-col space-y-1 my-2 rounded-sm border-white"
                  >
                    <p className="text-sm font-thin">Add Extra</p>
                    
                  </Link>
                <button
                  onClick={() =>
                    setIsOpenAddToCartDrawer(!isOpenAddToCartDrawer)
                  }
                  className="px-2 py-2 hover:bg-secondary text-xs font-bold text-white bg-primary outline-none cursor-pointer"
                >
                  Weiter Shoppen
                </button>
                <Link
                  onClick={() =>
                    setIsOpenAddToCartDrawer(!isOpenAddToCartDrawer)
                  }
                  to={`/cart`}
                  className="px-2 py-2  hover:bg-secondary text-xs text-center font-bold text-white bg-primary outline-none cursor-pointer"
                >
                  Warenkorb ansehen
                </Link>
                <Link
                  onClick={() =>
                    setIsOpenAddToCartDrawer(!isOpenAddToCartDrawer)
                  }
                  to={`/checkout`}
                  className="px-2 py-2  hover:bg-secondary text-xs text-center font-bold text-white bg-primary outline-none cursor-pointer"
                >
                  Zur Kasse
                </Link>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};
