import React from "react";
import { AppInnerPageWrapper } from "../Components/Modules/InnerPageWrapper";
import { AppContactComponent } from "../Components/Ogranisms/Contact";

export const AppContactScreen: React.FC = () => {
  return (
    <React.Fragment>
      <AppInnerPageWrapper>
        <AppContactComponent />
      </AppInnerPageWrapper>
    </React.Fragment>
  );
};
