import { createBrowserRouter } from "react-router-dom";
import { AppHomeScreen } from "./Home";
import { AppCateringScreen } from "./Catering";
import { AppContactScreen } from "./Contact";
import { AppAboutScreen } from "./Restaurant/About";
import { AppReservationScreen } from "./Reservation";
import { AppRestaurantScreen } from "./Restaurant";
import { AppMenuScreen } from "./Restaurant/Menu";
import { AppQualityScreen } from "./Restaurant/Quanlity";
import { AppOnlineSellerScreen } from "./OnlineSeller";
import { AppUberScreen } from "./Restaurant/Uber";
import { AppImpressumScreen } from "./Impressum";
import { AppJobScreen } from "./Job";
import { AppProductsByCategoryScreen } from "./ProductByCategory";
import { AppAuthScreen } from "./Auth";
import { AppLoginScreen } from "./Auth/login";
import { AppSignupScreen } from "./Auth/signup";
import { AppCheckoutScreen } from "./Checkout";
import { AppCartScreen } from "./Cart";
import { AppPrivacyPolicyScreen } from "./PrivacyPolicy";
import { AppTermandConditionScreen } from "./TermandConditions";
import { AppPaymentSuccessScreen } from "./Payment/success";
import { AppPaymentCancelScreen } from "./Payment/cancel";
import { AppPasswordUpdateScreen } from "./Auth/reset-password";
import { AppGetResetLinkScreen } from "./Auth/get-reset";
import { AppLogoutScreen } from "./Auth/logout";
import { AppPlaceOrderSuccessScreen } from "./PlaceOrderSuccess";
import { AppVerifyEmailScreen } from "./Auth/verify-email";

export const AppRootRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppHomeScreen />,
  },
  {
    path: "/catering",
    element: <AppCateringScreen />,
  },
  {
    path: "/kontakt",
    element: <AppContactScreen />,
  },

  {
    path: "/reservieren",
    element: <AppReservationScreen />,
  },
  {
    path: "/restaurant",
    element: <AppRestaurantScreen />,
  },
  {
    path: "/menu",
    element: <AppMenuScreen />,
  },
  {
    path: "/angebote",
    element: <AppAboutScreen />,
  },

  {
    path: "/aualitat",
    element: <AppQualityScreen />,
  },
  {
    path: "/online-bestellen",
    element: <AppOnlineSellerScreen />,
  },
  {
    path: "/uber-uns",
    element: <AppUberScreen />,
  },
  {
    path: "/impressum",
    element: <AppImpressumScreen />,
  },
  {
    path: "/job",
    element: <AppJobScreen />,
  },
  {
    path: "/product-by-category/:slug",
    element: <AppProductsByCategoryScreen />,
  },
  {
    path: "/profile",
    element: <AppAuthScreen />,
  },
  {
    path: "/login",
    element: <AppLoginScreen />,
  },
  {
    path: "/signup",
    element: <AppSignupScreen />,
  },
  {
    path: "/verify-email",
    element: <AppVerifyEmailScreen />,
  },
  {
    path: "/cart",
    element: <AppCartScreen />,
  },
  {
    path: "/checkout",
    element: <AppCheckoutScreen />,
  },
  {
    path:"/privacy-policy",
    element:<AppPrivacyPolicyScreen />
  },{
    path:"/terms-and-conditions",
    element:<AppTermandConditionScreen />
  },{
    path:'/paypal/success/:orderId',
    element:<AppPaymentSuccessScreen />
  },
  {
    path:'/paypal/cancel',
    element:<AppPaymentCancelScreen />
  },

  {
    path:'/reset-password/:reset_token',
    element:<AppPasswordUpdateScreen />
  },
  {
    path:'/get-reset-link',
    element:<AppGetResetLinkScreen />
  },
  {
    path:'/logout',
    element:<AppLogoutScreen />
  },{
    path:"/place-order-success/:orderId",
    element:<AppPlaceOrderSuccessScreen />
  }
]);
