import React from "react";

export const AppAboutComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-3 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          ANGEBOTE
        </h4>
        <div className="flex w-full flex-col space-y-2 py-4">
          <p className="text-sm md:text-lg text-primary py-3 font-bold tracking-wider">
            Gutscheine
          </p>
          <p className="text-sm font-normal py-2">
            Zum Verschenken erhalten Sie bei uns im Restaurant Gutscheine ab
            einem Wert von 25 Euro.Diese Gutscheine erhalten Sie dann innerhalb
            weniger Minuten per E-mail, Sms, Fax oder klassich per Post.
          </p>
        </div>

        <div className="flex w-full flex-col space-y-2 py-4">
          <p className="text-sm md:text-lg text-primary py-3 font-bold tracking-wider">
            Mittag Angebote
          </p>
          <p className="text-sm font-normal py-2">
            Salat Photo +Soup Photos+ Bentobox Photo+ Cola Photo Von 11:45 bis
            15 Uhr bieten wir jeden Tag ein günstiges dreigängiges Mittagsmenü
            einschließlich eines Softgetränks an.
          </p>
        </div>
        <div className="flex w-full flex-col space-y-2 py-4">
          <p className="text-sm md:text-lg text-primary py-3 font-bold tracking-wider">
            Tagesangebote ab 20 Uhr
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
