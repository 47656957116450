import React from "react";
import { AppContactForm } from "./ContactForm";

export const AppContactComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-3 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          KONTAKT SUSHI AM MAIN
        </h4>
        <div className="w-full grid grid-cols-12 gap-6 mx-auto max-w-screen-lg">
          <div data-aos="fade-right" className="col-span-12 md:col-span-6">
            <div className="flex flex-col w-full space-y-1">
              <h6 className="text-sm font-bold text-primary">ÖFFNUNGSZEITEN</h6>
              <p className="text-sm font-normal py-1">
                Montag-Freitag:11:45-22:00Uhr
              </p>
              <p className="text-sm font-normal py-1">Samstag : (Ruhetag)</p>
              <p className="text-sm font-normal py-1">
                Samstag,Sonntag & Feiertagen:17:00-22:00 Uhr
              </p>
              <p className="text-sm font-normal py-1">
                Hotline :{" "}
                <a href="tel:+6966967554" target="_blank" rel="noreferrer">
                  069 / 6696 7554
                </a>{" "}
              </p>
            </div>
          </div>

          <div data-aos="fade-left" className="col-span-12 md:col-span-6">
            <div className="flex flex-col w-full space-y-1">
              <h6 className="text-sm font-bold text-primary">
                HIER FINDEN SIE UNS
              </h6>
              <p className="text-sm font-normal py-1">
                SUSHI AM MAIN Restaurant
              </p>
              <p className="text-sm font-normal py-1">Reuterweg 61</p>
              <p className="text-sm font-normal py-1">
                D-60323 Frankfurt am Main
              </p>
            </div>
          </div>
          <div className="col-span-12">
            <div className="w-full mb-4">
              <iframe

                title="Sushi AnmainSushi am Main (Reuterweg)"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.138095382113!2d8.667699776887055!3d50.121137811019935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd094cc9165f6d%3A0x72aaf3631aa9765!2sSushi%20am%20Main%20(Reuterweg)!5e0!3m2!1sen!2smm!4v1709645093484!5m2!1sen!2smm"
                className="w-full h-96 border-0"
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-span-12">
            <AppContactForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
