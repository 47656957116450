import React from "react";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { RiCloseLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { Popover } from "@headlessui/react";
import { useAuthContext } from "../../Providers/auth";

interface props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppMobileDrawer: React.FC<props> = ({ isOpen, setIsOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isAuth } = useAuthContext();
  return (
    <React.Fragment>
      <Drawer
        className="w-full max-w-screen-sm"
        open={isOpen}
        direction="right"
        lockBackgroundScroll
        onClose={() => setIsOpen(!isOpen)}
      >
        <div className="w-full space-y-8 min-h-screen flex bg-black flex-col max-w-screen-sm">
          <div className="w-full flex flex-row justify-end items-center content-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="cursor-pointer px-2 py-2"
            >
              <RiCloseLine className="w-auto h-6 text-slate-700" />
            </button>
          </div>
          <img
            src="https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/19155306/sushiammain-reuterweg-1.png"
            alt=""
            className="w-auto h-16 object-contain"
          />
          <div className="flex space-y-2 flex-col justify-start mx-auto">
            <Link
              to={`/`}
              className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
            >
              Home
            </Link>
            <Popover className="relative">
                {({ open }) => (
                  <React.Fragment>
                    <Popover.Button
                      onClick={() =>
                        pathname.includes("restaurant")
                          ? null
                          : navigate("/restaurant")
                      }
                      className="flex group flex-row space-x-3 justify-start items-center content-center"
                    >
                      <span className="text-slate-300 group-hover:text-white text-sm uppercase font-semibold">
                        Restaurant
                      </span>
                      {open ? (
                        <CiCircleMinus className="text-slate-300 group-hover:text-white w-auto h-6" />
                      ) : (
                        <CiCirclePlus className="text-slate-300 group-hover:text-white w-auto h-6" />
                      )}
                    </Popover.Button>

                    <Popover.Panel className="absolute  z-10">
                      <div className="flex flex-col space-y-2 px-2 py-2 bg-black">
                        <Link
                          to={`/menu`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Menu
                        </Link>
                        <Link
                          to={`/uber-uns`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Über uns
                        </Link>
                        <Link
                          to={`/angebote`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Angebote
                        </Link>
                        <Link
                          to={`/aualitat`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Qualität
                        </Link>
                      </div>

                      <img src="/solutions.jpg" alt="" />
                    </Popover.Panel>
                  </React.Fragment>
                )}
              </Popover>
            <Link
              to={`/catering`}
              className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
            >
              Catering
            </Link>
            <Link
              to={`/online-bestellen`}
              className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
            >
              Online Bestellen
            </Link>
            <Link
              to={`/reservieren`}
              className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
            >
              Reservieren
            </Link>
            <Link
              to={`/kontakt`}
              className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
            >
              Kontakt
            </Link>
            {isAuth ? (
                <Popover className="relative">
                  {({ open }) => (
                    <React.Fragment>
                      <Popover.Button
                        
                        className="flex group flex-row space-x-3 justify-start items-center content-center"
                      >
                        <span className="text-slate-300 group-hover:text-white text-sm uppercase font-semibold">
                          Account
                        </span>
                        {open ? (
                          <CiCircleMinus className="text-slate-300 group-hover:text-white w-auto h-6" />
                        ) : (
                          <CiCirclePlus className="text-slate-300 group-hover:text-white w-auto h-6" />
                        )}
                      </Popover.Button>

                      <Popover.Panel className="absolute  z-10 bg-black px-2 py-2">
                        <div className="flex flex-col space-y-3 pt-2">
                          <Link
                            to={`/profile`}
                            className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                          >
                           Account Setting
                          </Link>
                          <Link
                            to={`/logout`}
                            className="text-slate-300 hover:underline hover:text-white text-xs font-semibold"
                          >
                           Logout
                          </Link>
                          
                        </div>

                        <img src="/solutions.jpg" alt="" />
                      </Popover.Panel>
                    </React.Fragment>
                  )}
                </Popover>
              ) : (
                <Link
                  to={`/login`}
                  className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
                >
                  Login
                </Link>
              )}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};
