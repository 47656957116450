import React from "react";

export const AppImpressumComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-1 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          IMPRINT
        </h4>
        <p className="text-sm font-normal py-2">Angaben gemäß § 5 TMG:</p>
        <p className="text-sm font-normal py-2">Sushi am Main</p>
        <p className="text-sm font-normal py-2">Reuterweg. 61</p>
        <p className="text-sm font-normal py-2">D-60323 Frankfurt am Main</p>
        <p className=" text-lg font-semibold py-2">
          Vertreten durch Geschäftsführer:
        </p>
        <p className=" text-lg font-semibold py-2">Frau Yway Yway Maw</p>
        <ul className="text-sm list-disc list-inside font-normal py-2">
          <li>Telefon: +49 (0) 69/6696 7554</li>
          <li>E-mail: service@sushiammain.de</li>
        </ul>

        <p className=" text-lg font-semibold py-2">Umsatzsteuer-ID:</p>
        <p className="text-sm font-normal py-2">
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
        </p>
        <p className="text-sm font-normal py-2">
          Umsatzsteuer-ID-Nr. 013 884 63765
        </p>
        <p className=" text-lg font-semibold py-2">Aufsichtsbehörde:</p>
        <p className="text-sm font-normal py-2">Frankfurt am Main</p>
        <p className="text-sm font-normal py-2">
          Quelle: Erstellt mit dem Impressum-Generator von
          http://www.e-recht24.de .
        </p>
        <p className="text-sm font-normal py-2">Haftungsausschluss:</p>
        <p className=" text-lg font-semibold py-2">Haftung für Inhalte</p>
        <p className="text-sm font-normal py-2">
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 to 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die aur eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
        <p className=" text-lg font-semibold py-2">Haftung für Links</p>
        <p className="text-sm font-normal py-2">
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <p className=" text-lg font-semibold py-2">Urheberrecht</p>
        <p className="text-sm font-normal py-2">
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p className=" text-lg font-semibold py-2">Datenschutz</p>
        <p className="text-sm font-normal py-2">
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben.
        </p>
        <p className="text-sm font-normal py-2">
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <p className="text-sm font-normal py-2">
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
          ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
          von Werbeinformationen, etwa durch Spam-Mails, vor.
        </p>
        <p className=" text-lg font-semibold py-2">
          Datenschutzerklärung für die Nutzung von Facebook-Plugins
          (Like-Button)
        </p>
        <p className="text-sm font-normal py-2">
          Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, 1601
          South California Avenue, Palo Alto, CA 94304, USA integriert. Die
          Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem
          „Like-Button“ („Gefällt mir“) auf unserer Seite. Eine Übersicht über
          die Facebook-Plugins finden Sie hier:
          http://developers.facebook.com/docs/plugins/.
        </p>
        <p className="text-sm font-normal py-2">
          Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
          Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
          Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse
          unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“
          anklicken während Sie in Ihrem Facebook-Account eingeloggt sind,
          können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil
          verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem
          Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter
          der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie
          deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu
          finden Sie in der Datenschutzerklärung von facebook unter
          http://de-de.facebook.com/policy.php Wenn Sie nicht wünschen, dass
          Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen
          kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
        </p>
        <p className=" text-lg font-semibold py-2">
          Datenschutzerklärung für die Nutzung von Google Analytics
        </p>
        <p className="text-sm font-normal py-">
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung dieser Website
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
          auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb
          von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          zuvor gekürzt.
        </p>
        <p className="text-sm font-normal py-">
          Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
          Google in den USA übertragen und dort gekürzt. Im Auftrag des
          Betreibers dieser Website wird Google diese Informationen benutzen, um
          Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
          Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
          anderen Daten von Google zusammengeführt.
        </p>
        <p className="text-sm font-normal py-2">
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch das Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:
          <a href=" http://tools.google.com/dlpage/gaoptout?hl=de.">
            {" "}
            http://tools.google.com/dlpage/gaoptout?hl=de.
          </a>
        </p>
        <p className=" text-lg font-semibold py-2">
          Datenschutzerklärung für die Nutzung von Google Adsense
        </p>
        <p className="text-sm font-normal">
          Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von
          Werbeanzeigen der Google Inc. („Google“). Google AdSense verwendet
          sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
          und die eine Analyse der Benutzung der Website ermöglicht. Google
          AdSense verwendet auch so genannte Web Beacons (unsichtbare Grafiken).
          Durch diese Web Beacons können Informationen wie der Besucherverkehr
          auf diesen Seiten ausgewertet werden.
        </p>
        <p className="text-sm font-normal">
          Die durch Cookies und Web Beacons erzeugten Informationen über die
          Benutzung dieser Website (einschließlich Ihrer IP-Adresse) und
          Auslieferung von Werbeformaten werden an einen Server von Google in
          den USA übertragen und dort gespeichert. Diese Informationen können
          von Google an Vertragspartner von Google weiter gegeben werden. Google
          wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten
          Daten zusammenführen.
        </p>
        <p className="text-sm font-normal">
          Sie können die Installation der Cookies durch eine entsprechende
          Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website voll umfänglich nutzen können. Durch die
          Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über
          Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
          Weise und zu dem zuvor benannten Zweck einverstanden.
        </p>
        <p className=" text-lg font-semibold py-2">
          Datenschutzerklärung für die Nutzung von Google +1
        </p>
        <p className="text-sm font-normal">
          Erfassung und Weitergabe von Informationen:
        </p>
        <p className="text-sm font-normal">
          Mithilfe der Google +1-Schaltfläche können Sie Informationen weltweit
          veröffentlichen. über die Google +1-Schaltfläche erhalten Sie und
          andere Nutzer personalisierte Inhalte von Google und unseren Partnern.
          Google speichert sowohl die Information, dass Sie für einen Inhalt +1
          gegeben haben, als auch Informationen über die Seite, die Sie beim
          Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise zusammen
          mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in
          Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen
          auf Websites und Anzeigen im Internet eingeblendet werden.
        </p>
        <p className="text-sm font-normal">
          Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die
          Google-Dienste für Sie und andere zu verbessern. Um die Google
          +1-Schaltfläche verwenden zu können, benötigen Sie ein weltweit
          sichtbares, öffentliches Google-Profil, das zumindest den für das
          Profil gewählten Namen enthalten muss. Dieser Name wird in allen
          Google-Diensten verwendet. In manchen Fällen kann dieser Name auch
          einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten über
          Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils
          kann Nutzern angezeigt werden, die Ihre E-Mail-Adresse kennen oder
          über andere identifizierende Informationen von Ihnen verfügen.
        </p>
        <p className="text-sm font-normal">
          Verwendung der erfassten Informationen:
        </p>
        <p className="text-sm font-normal">
          Neben den oben erläuterten Verwendungszwecken werden die von Ihnen
          bereitgestellten Informationen gemäß den geltenden
          Google-Datenschutzbestimmungen genutzt. Google veröffentlicht
          möglicherweise zusammengefasste Statistiken über die +1-Aktivitäten
          der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie etwa
          Publisher, Inserenten oder verbundene Websites.
        </p>
        <p className=" text-lg font-semibold py-2">
          Datenschutzerklärung für die Nutzung von Twitter
        </p>
        <p className="text-sm font-normal py-2">
          Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden.
          Diese Funktionen werden angeboten durch die Twitter Inc., Twitter,
          Inc. 1355 Market St, Suite 900, San Francisco, CA 94103, USA. Durch
          das Benutzen von Twitter und der Funktion „Re-Tweet“ werden die von
          Ihnen besuchten Webseiten mit Ihrem Twitter-Account verknüpft und
          anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter
          übertragen.
        </p>

        <p className="text-sm font-normal py-2">
          Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
          vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter
          erhalten. Weitere Informationen hierzu finden Sie in der
          Datenschutzerklärung von Twitter unter http://twitter.com/privacy.
          Ihre Datenschutzeinstellungen bei Twitter können Sie in den
          Konto-Einstellungen unter http://twitter.com/account/settings ändern.
          Quellenangaben: Disclaimer eRecht24, eRecht24 Facebook
          Datenschutzerklärung, Google Analytics Datenschutzerklärung, Google
          Adsense Datenschutzerklärung, Google +1 Bedingungen, Twitter
          Bedingungen
        </p>
      </div>
    </React.Fragment>
  );
};
