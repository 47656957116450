import React from "react";
import { Link } from "react-router-dom";
interface props {
  children: React.ReactNode;
}
export const AppAuthWrapper: React.FC<props> = ({ children }) => {
  const bannerImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/10/17074855/ann_header-1.jpg";
  const footerImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/10/17074901/ann_footer.jpg";
  return (
    <React.Fragment>
      <div data-aos="fade-down" className="w-full bg-black flex justify-center items-center content-center min-h-screen">
        <div className="w-full max-w-md  transform overflow-hidden  bg-black relative  text-left align-middle shadow-xl transition-all">
          <Link className="w-full" to={`/`}>
          <img
            src={bannerImg}
            className="w-full h-auto object-contain object-center"
            alt=""
          />
          </Link>
          <div className=" px-2 py-4 w-full flex flex-col space-y-3">
            {children}
          </div>
          <img
            src={footerImg}
            className="w-full h-auto object-contain object-center"
            alt=""
          />
        </div>
      </div>
    </React.Fragment>
  );
};
