import React, { useState } from "react";
import {
  announcementProps,
  paymentProps,
  productProps,
  settingKeyValueObjProps,
  shippingProps,
} from "../Interfaces/response";
import { getAnnouncShowT } from "../Helpers";

interface userDataContextType {
  products: productProps[];
  setProducts: React.Dispatch<React.SetStateAction<productProps[]>>;
  settting?: settingKeyValueObjProps;
  setSetting: React.Dispatch<
    React.SetStateAction<settingKeyValueObjProps | undefined>
  >;
  isOpenAddToCartDrawer: boolean;
  setIsOpenAddToCartDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isNoticeBoxOpen: boolean;
  setIsNoticeBoxOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shippings: shippingProps[];
  setShipping: React.Dispatch<React.SetStateAction<shippingProps[]>>;
  payments: paymentProps[];
  setPayments: React.Dispatch<React.SetStateAction<paymentProps[]>>;
  announcement?: announcementProps;
  setAnnouncement: React.Dispatch<
    React.SetStateAction<announcementProps | undefined>
  >;
  extra_products: productProps[];
  setExtraproducts: React.Dispatch<React.SetStateAction<productProps[]>>;
}

const UserDataContext = React.createContext<userDataContextType | undefined>(
  undefined
);

export const useUserData = (): userDataContextType => {
  const context = React.useContext(UserDataContext);
  if (!context) {
    throw new Error(
      `useUserDataContext must be within a UserDataContextProvider`
    );
  } else {
    return context;
  }
};

interface props {
  children: React.ReactNode;
}

export const UserDataContextProvider: React.FC<props> = ({ children }) => {
  const isShowAnnouncement:boolean = getAnnouncShowT()
  const [products, setproducts] = useState<productProps[]>([]);
  const [setting, setsetting] = useState<settingKeyValueObjProps>();
  const [isOpenAddToCartDrawer, setisOpenAddToCartDrawer] = useState(false);
  const [isNoticeBoxOpen, setisNoticeBoxOpen] = useState<boolean>(isShowAnnouncement);
  const [shippings, setshippings] = useState<shippingProps[]>([]);
  const [payments, setpayments] = useState<paymentProps[]>([]);
  const [anouncement, setanouncement] = useState<announcementProps>();
  const [extraProducts, setextraProducts] = useState<productProps[]>([]);
  return (
    <React.Fragment>
      <UserDataContext.Provider
        value={{
          products: products,
          setProducts: setproducts,
          settting: setting,
          setSetting: setsetting,
          setIsOpenAddToCartDrawer: setisOpenAddToCartDrawer,
          isOpenAddToCartDrawer: isOpenAddToCartDrawer,
          isNoticeBoxOpen: isNoticeBoxOpen,
          setIsNoticeBoxOpen: setisNoticeBoxOpen,
          shippings: shippings,
          setShipping: setshippings,
          payments: payments,
          setPayments: setpayments,
          announcement: anouncement,
          setAnnouncement: setanouncement,
          setExtraproducts: setextraProducts,
          extra_products: extraProducts,
        }}
      >
        {children}
      </UserDataContext.Provider>
    </React.Fragment>
  );
};
