import React from "react";
import { AppHeroConentCard } from "../../Atoms/AppHeroContentCard";

export const AppHomeComponent: React.FC = () => {
 
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="flex min-h-screen justify-center items-center content-center relative">
          <div className="w-full max-w-screen-lg grid grid-cols-12 gap-3">
            <div className="col-span-12 md:col-span-4">
              <div className="w-full group flex flex-col space-y-2 justify-center content-center items-center">
                <div>
                  <img
                    
                    className="w-auto h-36"
                    src="https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/19155306/sushiammain-reuterweg-1.png"
                    alt=""
                  />
                </div>
                <div className="w-full flex flex-col space-y-2 justify-center items-center content-center">
                  <img
                    data-aos="fade-right"
                    className="w-auto h-72 animate-none group-hover:animate-bounce"
                    src="https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18110923/sushi-boat.png"
                    alt=""
                  />
                  <div className="flex flex-col space-y-2 justify-center items-center content-center">
                    <p className=" text-center text-sm font-normal text-slate-100">
                      Mo – Fr ( 11:45 – 22:00) Samstag, Sonntag und
                    </p>
                    <p className="text-center text-sm font-normal text-slate-100">
                      Feiertage : ( 17:00 – 22:00 )
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-8">
              <div className="w-full grid grid-cols-12">
                <div className="col-span-12 md:col-span-6">
                  <AppHeroConentCard
                    data={{
                      title: "Sushi Restaurant​",
                      description:
                        "Traditionelles Japanisches Sushi-Restaurant",
                      img1: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18111618/Restaurant%E2%80%8B.jpg",
                      img2: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18124002/restraurant_2.jpg",
                      href: "/restaurant",
                    }}
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <AppHeroConentCard
                    data={{
                      title: "Events & Catering",
                      description:
                        "Event Catering Angebote mit japanischen Spezialitäten",
                      img1: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/23064930/catering_service_2.jpg",
                      img2: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/11/15165937/catering_2-1.jpg",
                      href: "/catering",
                    }}
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <AppHeroConentCard
                    data={{
                      title: "RESERVATION",
                      description:
                        "Sehr gerne können Sie Online bei uns eine Reservierung machen",
                      img1: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18114133/reservation.jpg",
                      img2: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18120114/reservation_2.jpg",
                      href: "/reservieren",
                    }}
                  />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <AppHeroConentCard
                    data={{
                      title: "Abholen & Liefer Service",
                      description:
                        "Bestellen Sie Online oder telefonisch – Wir liefern zu Ihnen nach Hause",
                      img1: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/23064510/lefier_service.jpg",
                      img2: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/18111934/delivery.jpg",
                      href: "/online-bestellen",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};
