import Axios from "axios";
import { AppConfig } from "../Config";

export const API = Axios.create({
  baseURL: AppConfig.apiPath,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem(`sushi_auth_token`)}`,
  },
});

API.interceptors.request.use((config) => {
  document.body.classList.add("loader");
  return config;
});

API.interceptors.response.use(
  (response) => {
    document.body.classList.remove("loader");
    if (response.config.url === APIURLS.auth.login) {
      if (
        response.status === 201 &&
        response.data &&
        response.data.access_token
      ) {
        localStorage.setItem("sushi_auth_token", response.data.access_token);
      }
    }
    if (response.config.url === APIURLS.auth.signup) {
      if (
        response.status === 201 &&
        response.data &&
        response.data.access_token
      ) {
        localStorage.setItem("sushi_auth_token", response.data.access_token);
      }
    }
    if (response.config.url === APIURLS.auth.get_profile) {
    }

    return response;
  },
  (error) => {
    document.body.classList.remove("loader");
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        localStorage.removeItem("sushi_auth_token");

        console.log("Unauthorized error.");
      } else if (status === 400) {
        console.log("Bad Request error");
      }
    } else if (error.request) {
      console.log("No response received. Request:", error.request);
    } else {
      console.log("Error setting up the request:", error.message);
    }

    return Promise.reject(error);
  }
);

export const APIURLS = {
  auth: {
    login: "/auth/login",
    signup: "/auth/signup",
    update_profile: "/auth/update",
    get_profile: "/auth/profile",
    get_reset_link: "/auth/reset-password",
    update_password: "/auth/update-password",
    verify:"/auth/verify"
  },
  users: {
    all: "/users",
    create: "/users",
    update: "/users",
    delete: "/users",
    get: "/users",
  },
  categories: {
    all: "/categories/forCustomers",
    create: "/categories",
    update: "/categories",
    delete: "/categories",
    get: "/categories",
  },
  products: {
    all: "/products/all",
    create: "/products",
    get: "/products/single",
    update: "/products",
    delete: "/products",
    get_extra_products: "/products/extra_products",
  },
  reservations: {
    all: "/reservations",
    create: "/reservations",
    update: "/reservations",
    get: "/reservations",
    by_user: "/reservations/get_reservations_by_user",
    destroy: "/reservations",
  },
  orders: {
    create: "/orders",
    update: "/orders",
    all: "/orders",
    by_user: "/orders/by_user",
    get: "/orders",
    delete: "/orders",
  },
  carts: {
    all: "/carts",
    by_user: "/carts/get_carts_by_user",
    get: "/carts",
    create: "/carts",
    update: "/carts",
    delete: "/carts",
  },
  payments: {
    all: "/payments",
    get: "/payments",
    create: "/payments",
    update: "/payments",
    delete: "/payments",
  },
  shipping: {
    all: "/shippings",
    get: "/shippings",
    create: "/shippings",
    update: "/shippings",
    delete: "/shippings",
  },
  languages: {
    all: "/langs",
    get: "/langs",
    create: "/langs",
    update: "/langs",
    delete: "/langs",
  },
  uploads: {
    all: "/uploads",
    create: "/uploads",
    delete: "/uploads",
  },
  paypal: {
    create_payment: "/paypal/payment",
    create_payout: "/paypal/payout",
    success: "/paypal/success",
    cancel: "/paypal/cancel",
  },
  settings: {
    all: "/settings",
    create: "/settings",
    update: "/settings",
    delete: "/settings",
    for_user: "/settings/settings_for_users",
  },

  announcements: {
    all: "/announcements",
    create: "/announcements",
    update: "/announcements",
    delete: "/announcements",
    get: "/announcements/single",
    for_today: "/announcements/for_today",
  },

  coupons: {
    all: "/coupons",
    create: "/coupons",
    update: "/coupons",
    delete: "/coupons",
    get: "/coupons/find_one_by_id",
    for_today: "/coupons/find_one_by_code",
    apply: "/coupons/find_one_by_code",
  },
  contact: "/mail/contact",
};
