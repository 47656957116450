import React, { useState } from "react";
import { AppPageWrapper } from "./PageWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CiCircleMinus, CiCirclePlus, CiMenuBurger } from "react-icons/ci";
import { Popover } from "@headlessui/react";
import { AppMobileDrawer } from "./MobileNav";
import { useAuthContext } from "../../Providers/auth";
import { useUserData } from "../../Providers/user-data";

interface props {
  children: React.ReactNode;
}
export const AppInnerPageWrapper: React.FC<props> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, setisOpen] = useState<boolean>(false);
  const { isAuth } = useAuthContext();
  const {settting} = useUserData()
  return (
    <React.Fragment>
      <AppPageWrapper>
        <div className="w-full relative flex text-white flex-col space-y-2">
          <div className="w-full hidden md:flex mx-auto max-w-screen-xl px-3 flex-col md:flex-row justify-between items-end content-end py-2">
            <Link to={`/`}>
              <img
                src={settting?.LOGO}
                alt=""
                className="w-auto h-20"
              />
            </Link>
            <div className="flex flex-row justify-end tracking-wider space-x-5 items-center content-center">
              <Link
                to={`/`}
                className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
              >
                Home
              </Link>
              <Popover className="relative">
                {({ open }) => (
                  <React.Fragment>
                    <Popover.Button
                      onClick={() =>
                        pathname.includes("restaurant")
                          ? null
                          : navigate("/restaurant")
                      }
                      className="flex group flex-row space-x-3 justify-start items-center content-center"
                    >
                      <span className="text-slate-300 group-hover:text-white text-sm uppercase font-semibold">
                        Restaurant
                      </span>
                      {open ? (
                        <CiCircleMinus className="text-slate-300 group-hover:text-white w-auto h-6" />
                      ) : (
                        <CiCirclePlus className="text-slate-300 group-hover:text-white w-auto h-6" />
                      )}
                    </Popover.Button>

                    <Popover.Panel className="absolute  z-10">
                      <div className="flex flex-col space-y-2 px-2 py-2 bg-black">
                        <Link
                          to={`/menu`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Menu
                        </Link>
                        <Link
                          to={`/uber-uns`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Über uns
                        </Link>
                        <Link
                          to={`/angebote`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Angebote
                        </Link>
                        <Link
                          to={`/aualitat`}
                          className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                        >
                          Qualität
                        </Link>
                      </div>

                      <img src="/solutions.jpg" alt="" />
                    </Popover.Panel>
                  </React.Fragment>
                )}
              </Popover>
              <Link
                to={`/catering`}
                className="text-slate-300 text-nowrap hover:text-white text-sm uppercase font-semibold"
              >
                Catering
              </Link>
              <Link
                to={`/online-bestellen`}
                className="text-slate-300 text-nowrap hover:text-white text-sm uppercase font-semibold"
              >
                Online Bestellen
              </Link>
              <Link
                to={`/reservieren`}
                className="text-slate-300 text-nowrap hover:text-white text-sm uppercase font-semibold"
              >
                Reservieren
              </Link>
              <Link
                to={`/kontakt`}
                className="text-slate-300 text-nowrap hover:text-white text-sm uppercase font-semibold"
              >
                Kontakt
              </Link>
              {isAuth ? (
                <Popover className="relative">
                  {({ open }) => (
                    <React.Fragment>
                      <Popover.Button
                        
                        className="flex group flex-row space-x-3 justify-start items-center content-center"
                      >
                        <span className="text-slate-300 group-hover:text-white text-sm uppercase font-semibold">
                          Account
                        </span>
                        {open ? (
                          <CiCircleMinus className="text-slate-300 group-hover:text-white w-auto h-6" />
                        ) : (
                          <CiCirclePlus className="text-slate-300 group-hover:text-white w-auto h-6" />
                        )}
                      </Popover.Button>

                      <Popover.Panel className="absolute  z-50 bg-black px-2 py-2">
                        <div className="flex flex-col space-y-3 pt-2">
                          <Link
                            to={`/profile`}
                            className="text-slate-300 text-nowrap hover:underline hover:text-white text-xs font-semibold"
                          >
                           Account Setting
                          </Link>
                          <Link
                            to={`/logout`}
                            className="text-slate-300 hover:underline hover:text-white text-xs font-semibold"
                          >
                           Logout
                          </Link>
                          
                        </div>

                        <img src="/solutions.jpg" alt="" />
                      </Popover.Panel>
                    </React.Fragment>
                  )}
                </Popover>
              ) : (
                <Link
                  to={`/login`}
                  className="text-slate-300 hover:text-white text-sm uppercase font-semibold"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
          <div className="w-full flex md:hidden mx-auto max-w-screen-xl px-3 flex-row justify-between items-center content-center py-2">
            <div className="flex flex-row justify-end items-center content-center">
              <button
                onClick={() => setisOpen(!isOpen)}
                className="select-none px-2 py-2 group cursor-pointer"
              >
                <CiMenuBurger className="w-auto h-8 text-slate-200 group-hover:text-white " />
              </button>
            </div>
            <Link to={`/`}>
              <img
                src="https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/19155306/sushiammain-reuterweg-1.png"
                alt=""
                className="w-auto h-20"
              />
            </Link>
          </div>
          <div className="max-w-screen-xl w-full mx-auto px-8  flex flex-col">
            {children}
          </div>
        </div>
      </AppPageWrapper>
      <AppMobileDrawer isOpen={isOpen} setIsOpen={setisOpen} />
    </React.Fragment>
  );
};
