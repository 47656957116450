import React from "react";

interface menuProps {
  href: string;
  img: string;
}

interface props {
  menu?: menuProps;
}
export const AppMenuCard: React.FC<props> = ({ menu }) => {
  return (
    <React.Fragment>
      {menu && (
        <div className="w-full" data-aos="zoom-out-up">
            <a target='_blank' rel="noreferrer"  href={menu.href} className="w-full relative">
          <img
            src={menu.img}
            className="w-full shadow-md  h-auto object-cover object-center"
            alt=""
          />
        </a>
        </div>
      )}
    </React.Fragment>
  );
};
