import React from "react";

export const AppTermandConditionComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-1 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          Terms and Conditions
        </h4>
        <p className="text-sm font-normal py-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, quis
          obcaecati voluptas rem voluptates accusamus nulla cupiditate fugit, id
          consectetur laudantium! Laboriosam odio qui natus, in ullam dolore
          officia unde?
        </p>
        <p className="text-sm font-normal py-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, quis
          laudantium! Quas, quasi saepe. A dolores repellendus asperiores ullam
          tempore expedita consequatur excepturi eveniet, harum aliquam, est
          impedit repellat commodi.
        </p>
        <p className="text-sm font-normal py-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
          architecto nostrum soluta dolor reprehenderit error exercitationem
          quia sit, molestias possimus esse repellat fuga ut aperiam pariatur in
          quos distinctio suscipit.
        </p>
        <p className="text-sm font-normal py-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam
          voluptate ducimus reprehenderit, atque earum hic quo voluptatem cum
          voluptas porro quaerat reiciendis, dignissimos commodi quis sint vel
          rerum ea vero.
        </p>
      </div>
    </React.Fragment>
  );
};
