import React, { useCallback, useEffect, useState } from "react";
import { API, APIURLS } from "../../../../Services";
import { useAuthContext } from "../../../../Providers/auth";
import { reservationProps } from "../../../../Interfaces/response";
import { AxiosResponse } from "axios";
import { AppReservationCard } from "../../../Atoms/AppReservationCard";
import { CiBookmark } from "react-icons/ci";

export const AppUserReservationsComponent: React.FC = () => {
  const { accessToken } = useAuthContext();
  const [reservations, setreservations] = useState<reservationProps[]>([]);
  const syncReservations = useCallback(() => {
    API.get(`${APIURLS.reservations.by_user}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setreservations(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("USER ORDERS API DONE");
      });
  }, [accessToken]);

  useEffect(() => {
    syncReservations();
  }, [syncReservations]);
  return (
    <React.Fragment>
      <div className="w-full flex flex-col space-y-2 px-4 py-4">
        {reservations && reservations.length > 0 ? (
          <React.Fragment>
            <div className="w-full grid grid-cols-12 gap-6">
              {reservations.map((o: reservationProps, i: number) => (
                <div key={i} className="col-span-12 md:col-span-6">
                  <AppReservationCard reservation={o} />
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="w-full flex flex-col space-y-2 justify-center items-center content-center h-96">
              <CiBookmark className="w-auto h-12 text-primary" />
              <span className="text-lg font-bold tracking-widest text-white">
                No Reservations
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
