import React, { useCallback, useEffect } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { orderFormProps } from "../../../Interfaces/response";
import {  CiDeliveryTruck } from "react-icons/ci";
import { IoStorefrontOutline } from "react-icons/io5";

interface props {
  control: Control<orderFormProps, any>;

  watch: UseFormWatch<orderFormProps>;
  setValue: UseFormSetValue<orderFormProps>;
  errors: FieldErrors<orderFormProps>;
}
export const AppOrderTypeComponent: React.FC<props> = ({
  control,
  watch,
  setValue,
 
}) => {
  const orderType = watch("_order_type");

  const syncOrderType = useCallback(() => {
    if (orderType === "collection") {
      setValue("shipppingId", undefined);
    }
  }, [orderType, setValue]);

  useEffect(() => {
    syncOrderType();
  }, [syncOrderType]);

  return (
    <React.Fragment>
      <div
        data-aos="fade-up"
        className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
      >
        <div className="w-full col-span-12">
          <h6 className="text-lg font-bold tracking-wider">
            Wie möchten Sie Ihre Bestellung erhalten?
          </h6>
        </div>
        <div className="col-span-12 md:col-span-6">
          <Controller
            control={control}
            name="_order_type"
            render={({
              field: { onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <button
                  type="button"
                  onClick={(e) => onChange("delivery")}
                  ref={ref}
                  className={`w-full px-2 py-2 rounded-full flex flex-row justify-between items-center content-center text-white ${
                    value === "delivery" ? "bg-primary" : "bg-secondary"
                  }`}
                >
                  <CiDeliveryTruck className="w-auto h-10 ml-2 " />
                  <span className="text-sm uppercase font-bold tracking-widest flex-grow">
                    Lieferung
                  </span>
                </button>
              </React.Fragment>
            )}
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Controller
            control={control}
            name="_order_type"
            render={({
              field: { onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <button
                  type="button"
                  onClick={(e) => {
                    onChange("collection");
                  }}
                  ref={ref}
                  className={`w-full px-2 py-2 rounded-full flex flex-row justify-between items-center content-center text-white ${
                    value === "collection" ? "bg-primary" : "bg-secondary"
                  }`}
                >
                  <IoStorefrontOutline className="w-auto h-10 ml-2 " />
                  <span className="text-sm uppercase font-bold tracking-widest flex-grow">
                    Abholung
                  </span>
                </button>
              </React.Fragment>
            )}
          />
        </div>
       
      
      </div>
    </React.Fragment>
  );
};
