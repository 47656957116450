import React from 'react'
import { AppInnerPageWrapper } from '../Components/Modules/InnerPageWrapper'
import { AppJobComponent } from '../Components/Ogranisms/Job'

export const AppJobScreen:React.FC =()=>{
    return(
        <React.Fragment>
            <AppInnerPageWrapper>
                <AppJobComponent />
            </AppInnerPageWrapper>
        </React.Fragment>
    )
}