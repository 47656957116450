import React from "react";

export const AppJobComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-1 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-2 font-bold tracking-wider  underline">
          STELLENANGEBOTE
        </h4>
        <p className="text-lg font-normal py-2">KÜCHEN HILFE (M/W)</p>
        <p className="text-sm font-normal py-2">
          Zur Verstärkung unserer motivierten Küchencrew suchen wir Mitarbeiter
          für Mittag oder Abend als Teilzeitjob und Aushilfe Job. Bewerbungen
          bitte an service@sushiammain.de
        </p>
        <p className="text-lg font-normal py-2">LIEFERFAHER GESUCHT (M/W)</p>
        <p className="text-sm font-normal py-2">
          Wir suchen für unser Team freundliche Lieferfahrer mit Erfahrung.
          Bewerbungen bitte an service@sushiammain.de
        </p>
      </div>
    </React.Fragment>
  );
};
