import React from "react";

import { CiShoppingCart } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { addCart, setIsLunchMenu } from "../../Providers/order";
import { fCurrency } from "../../Helpers";
import { productProps } from "../../Interfaces/response";

interface props {
  product?: productProps;
  IsLunchMenu:boolean
}
export const AppProductCard: React.FC<props> = ({ product,IsLunchMenu }) => {
  const dispatch = useDispatch()
  const AddCartHandler =()=>{
    dispatch(addCart(product))
    if(IsLunchMenu){
      dispatch(setIsLunchMenu(true))
    }
  }
  return (
    <React.Fragment>
      <div onClick={()=>AddCartHandler()} className="tracking-wider relative flex group cursor-pointer flex-col justify-center items-center rounded-md content-center space-y-2 px-3 py-3 bg-transparent hover:bg-white hover:bg-opacity-20 w-full">
         <div className="absolute top-0 right-0 px-2 hidden group-hover:flex py-2">
         <CiShoppingCart className='w-auto h-6 text-primary' />
         </div>
        <img
          src={product?.img}
          className="w-auto h-32 object-cover object-center"
          alt=""
        />
        <p className="text-primary font-bold text-sm">{product?.title}</p>
        <p className="text-white font-light text-xs text-center">
          {product?.description}
        </p>
        <p className="text-yellow-600 font-normal text-sm">
         {fCurrency(Number(product?._regular_price))}
        </p>
      </div>
    </React.Fragment>
  );
};
