import React from "react";
import { DemoData } from "../../../Demo";
import { AppCateringCard } from "../../Atoms/AppCateringCard";
import { CiChat2 } from "react-icons/ci";
import { Link } from "react-router-dom";

export const AppCateringComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-3 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider underline">
          CATERING
        </h4>
        <div className="w-full mx-auto max-w-screen-lg flex-col space-y-2">
          <h5 className="text-center text-sm font-bold mb-8">
            Herzlich Willkommen bei Sushi am Main Catering und Partyservice
          </h5>
          <div className="w-full flex flex-col space-y-6 ">
            {DemoData.caterings.map((catering, index) => (
              <AppCateringCard
                key={index}
                catering={{
                  index: index,
                  title: catering.title,
                  img: catering.img,
                  description: catering.description,
                }}
              />
            ))}
          </div>
        </div>
        <div className="flex w-full justify-center items-center content-center px-4 py-6">
          <Link
            to={`/kontakt`}
            className="px-4 py-2 cursor-pointer space-x-2 hover:bg-slate-600 bg-primary text-white shadow-md rounded-full flex flex-row justify-between items-center content-center"
          >
            <CiChat2 className="text-white w-auto h-6" />
            <span className='flex-grow text-xs font-normal text-white'>
                Kontakt
            </span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
