import React from "react";

export const AppUberComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-3 mx-auto max-w-screen-xl px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          UBER UNS
        </h4>
        <p className="text-sm font-normal py-2">
          In 1995 our first restaurant “Sushi am Main” was opened in
          Feuerbachstrasse. One of the early sushi restaurants in Frankfurt with
          45 seats in traditional Japanese style was built in a
          Wilhelminian-style building in Westend. In the cozy and stylish
          restaurant, lunch and dinner menus were offered very successfully
          right from the start. Stimulated by the success, we opened a second
          sushi restaurant with 42 seats in Reuterweg 61 in 2009.
        </p>
        <p className="text-sm font-normal py-2">
          In our restaurants we primarily offer traditional healthy Japanese
          cuisine with sushi, miso and other Japanese soups, tempura, gyoza
          (filled dumplings) and authentic Japanese washoku cuisine. In
          addition, warm rice and pasta dishes with chicken or other meat are
          part of our diverse range. Of course we also have a large selection of
          vegetarian dishes, e.g. Kiku-Sushi, Nobu-Sushi, veg. Tempura or
          various pasta and rice dishes.
        </p>
        <p className="text-sm font-normal py-2">
          All sushi dishes are freshly prepared in front of your eyes by our
          sushi master at the bar and served on traditional Japanese dishes at
          the table. Without exception, we only use the freshest fish and
          ingredients, which we process and prepare with the greatest care
          without the use of glutamate. We also attach great importance to the
          appealing visual design of our dishes served by our friendly service.
          In our restaurants you will also find a large selection of drinks,
          including Japanese drinks such as sake, Japanese and German beers,
          German and international wines, spirits and soft drinks. We also have
          the usual hot drinks such as tea (various Japanese teas, jasmine tea,
          olong tea, ginger tea) and coffee on offer.
        </p>
        <p className="text-sm font-normal py-2">
          Our whole team is always trying to make your visit to our restaurants
          a pleasant experience for you.
        </p>
      </div>
    </React.Fragment>
  );
};
