import React from "react";
import { AppInnerPageWrapper } from "../../Components/Modules/InnerPageWrapper";
import { useAuthContext } from "../../Providers/auth";
import { Navigate } from "react-router-dom";
import { AppUserComponent } from "../../Components/Ogranisms/Auth/Profile";

export const AppAuthScreen: React.FC = () => {
  const { isAuth } = useAuthContext();
  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }
  return (
    <React.Fragment>
      <AppInnerPageWrapper>
        <AppUserComponent />
      </AppInnerPageWrapper>
    </React.Fragment>
  );
};
