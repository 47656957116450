import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { generateTimeSlots } from "../../../Helpers";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { API, APIURLS } from "../../../Services";
import toast from "react-hot-toast";
import { CiBookmarkPlus } from "react-icons/ci";
import { useAuthContext } from "../../../Providers/auth";
import { AppUserLoginBox } from "../../Atoms/AppUserLoginBox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
export const AppReserveForm: React.FC = () => {
  const { user, isAuth, accessToken } = useAuthContext();
  const [isOpen, setisOpen] = useState(!isAuth);
  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      name:
        user && user?.first_name && user?.last_name
          ? user.first_name + " " + user.last_name
          : "",
      resv_date: "",
      resv_time: "",
      anazhl_der_personen: "",
      kind: "",
      email: user?.email ? user.email : "",
      phone: user?.phone ? user.phone : "",
      message: "",
      status: "pending",
    },
  });

  const navigate  = useNavigate()

  const syncUserData = useCallback(() => {
    if (user) {
      setValue("email", user.email ? user.email : "");
      setValue("phone", user.phone ? user.phone : "");
      setValue(
        "name",
        user.first_name && user.last_name
          ? `${user.first_name} ${user.last_name}`
          : ""
      );
    }
  }, [setValue, user]);

  useEffect(() => {
    syncUserData();
  }, [syncUserData]);

  const slots = generateTimeSlots();
  const onSubmit = (data: any) => {
    try {
      API.post(`${APIURLS.reservations.create}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((res) => {
          if (res.data) {
            toast.success(`Reservation sent successfully.`, {
              icon: <CiBookmarkPlus className="w-auto h-8 text-green-900" />,
              className: "text-sm font-thin",
            });
            navigate('/')
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log(`Sent reservation API`);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <AppUserLoginBox isOpen={isOpen} setIsOpen={setisOpen} />
      <div data-aos="fade-down" className="w-full ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-12 gap-4"
        >
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="name">Vorname, Nachname *</label>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors && errors.name && errors.name.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.name.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="flex flex-col space-y-1">
              <label htmlFor="resv_date"> Datum*</label>
              <Controller
                control={control}
                name="resv_date"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <DatePicker
                      inputRef={ref}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className="text-slate-900"
                      minDate={new Date()}
                    />
                    {errors && errors.resv_date && errors.resv_date.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.resv_date.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-4"></div>
          <div className="col-span-12 md:col-span-4">
            <div className="flex flex-col space-y-1">
              <label htmlFor="resv_time"> Uhrzeit * *</label>
              <Controller
                control={control}
                name="resv_time"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <select
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-select  border-white "
                    >
                      {slots.map((s, i) => (
                        <option className="text-slate-900" key={i} value={s}>
                          {s}
                        </option>
                      ))}
                    </select>
                    {errors && errors.resv_time && errors.resv_time.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.resv_time.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="anazhl_der_personen">Anzahl der Personen*</label>
              <Controller
                control={control}
                name="anazhl_der_personen"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors &&
                    errors.anazhl_der_personen &&
                    errors.anazhl_der_personen.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.anazhl_der_personen.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="kind">(Kind)*</label>
              <Controller
                control={control}
                name="kind"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors && errors.kind && errors.kind.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.kind.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="anazhl_der_personen">Email*</label>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="email"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors && errors.email && errors.email.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.email.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="anazhl_der_personen">Telefon *</label>
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="tel"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-transparent rounded-md form-input border-white "
                    />
                    {errors && errors.phone && errors.phone.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.phone.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="flex flex-col space-y-1">
              <label htmlFor="message">Nachricht*</label>
              <Controller
                control={control}
                name="message"
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <ReactQuill
                      theme="snow"
                      
                      value={value}
                      onChange={(value) => onChange(value)}
                    />
                    {errors && errors.message && errors.message.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.message.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="flex flex-row justify-start items-center content-center w-full my-6">
            <button
              type="submit"
              className="bg-primary cursor-pointer px-4 py-2 text-white shadow-md text-lg font-bold hover:bg-slate-900 "
            >
              ABSENDEN
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
