import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useUserData } from "../../Providers/user-data";
import moment from "moment";
import { SetAnnounceShowT } from "../../Helpers";

export const AppNoticeBox: React.FC = () => {
  const bannerImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/10/17074855/ann_header-1.jpg";
  const footerImg =
    "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/10/17074901/ann_footer.jpg";
  const { isNoticeBoxOpen, setIsNoticeBoxOpen, announcement } = useUserData();
  const fromTime1 = moment().set("hour", 0).set("minute", 0).set("second", 0);
  const toTime1 = moment().set("hour", 11).set("minute", 30).set("second", 0);
  const now = moment();
  const fromTime2 = moment().set("hour", 15).set("minute", 0).set("second", 0);
  const toTime2 = moment().set("hour", 18).set("minute", 0).set("second", 0);

  return (
    <React.Fragment>
      {now.isBetween(fromTime1, toTime1) ||
      now.isBetween(fromTime2, toTime2) ? (
        <React.Fragment>
          {announcement ? (
            <React.Fragment>
              <Transition appear show={isNoticeBoxOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-50"
                  onClose={() =>{ setIsNoticeBoxOpen(!isNoticeBoxOpen);SetAnnounceShowT()}}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-sm  transform overflow-hidden  bg-white relative  text-left align-middle shadow-xl transition-all">
                          <button
                            onClick={() => { setIsNoticeBoxOpen(!isNoticeBoxOpen);SetAnnounceShowT()}}
                            className="absolute top-0 right-0 px-2 py-2 cursor-pointer outline-none"
                          >
                            <RiCloseLine className="w-auto h-6 text-white" />
                          </button>
                          <img
                            src={bannerImg}
                            className="w-full h-auto object-contain object-center"
                            alt=""
                          />

                          <div className="px-2 py-2">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: announcement.html_body,
                              }}
                            ></div>
                          </div>
                          <img
                            src={footerImg}
                            className="w-full h-auto object-contain object-center"
                            alt=""
                          />
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
