export const DemoData = {
  caterings: [
    {
      title: "Wir beraten Sie gerne",
      description: `Ob – bei Ihrer Firmen oder Familienfeier,– bei einer Privatparty oder Tagung,wir liefern Ihnen dazu unsere japanischen Spezialitäten.`,
      img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/03105643/catering_3.png",
    },
    {
      title: "Wir bieten Ihnen:",
      description: `Sushi & Sashimi Spezialiäten
      verschiedene japanische Vorspeisen (Fingerfood)
      Nudel- und Reisgerichte
      spezielle Getränke`,
      img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/03110215/catering_1.png",
    },
    {
      title: "",
      description: `
      Wir beraten Sie gerne für eine individuelle Planung, wobei  Menüs und Ablauf auf Ihre Wünsche abgestimmt werden können . Wir kümmern uns auf Wunsch auch um Teller, Service, besondere Zutaten / Kreationen, Sind Sie an unserem Angebot interessiert, dann senden sie uns eine unverbindliche Anfrage per E-Mail oder per Kontaktformular.`,
      img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/03110313/catering_2.png",
    },
  ],
  productsByCategories: [
    {
      name: "VORSPEISEN",
      slug: "vorspeisen",
      list: true,
      products: [
        {
          name: "110. SUSHI AM MAIN SALAT",
          description: "(Gemischte Salat mit unserer köstlichen Sauce) 6,10,a",
          img: "",
          price: 590,
        },
        {
          name: "112. CHICKEN TERIYAKI SALAT",
          img: "",
          price: 990,
          description:
            "(Gegrilltes Hühnerfleisch mit Teriyaki Sauce auf Salat) 6,10,f",
        },
        {
          name: "113. LACHS TERIYAKI SALAT",
          img: "",
          price: 990,
          description:
            "(Knusprig gegrilltes Lachs mit Sauce auf grüne Salat) 4,6,10,f",
        },
        {
          name: "114. TAKOYAKI 5 Stk.",
          img: "",
          price: 790,
          description: "(Oktopus in Teigkugel) 3,7,f",
        },
        {
          name: "115. TINTENFISCH SALAT",
          description: "(scharfe Tintenfisch Salat) 6,9,11,a,e,f",
          img: "",
          price: 400,
        },
        {
          name: "117. TORI KARAAGE",
          img: "",
          price: 750,
          description: "(Japanische frittiertes Hühnerfleisch) 3,7,f",
        },
        {
          name: "118. SPICY EDAMAME",
          img: "",
          price: 490,
          description: "(Japanische Grüne Sojabohnen)  6",
        },
        {
          name: "119.KIMCHI SALAT",
          img: "",
          price: 400,
          description: "(fementierte Chinakohl Salat) 6.9.11.a.e.f",
        },
        {
          name: "120. TERIYAKI CHICKEN",
          description:
            "(gegrilltes Hühnerfleisch mit teriyakisauce ohne Reis) 6,10,f",
          img: "",
          price: 690,
        },
        {
          name: "127. GYOZA 6 St.",
          img: "",
          price: 610,
          description: "(Handgemachter original Jap. Fleischtaschen) 1,7,f",
        },
      ],
    },
    {
      name: "PARTY SUSHI",
      slug: "party-sushi",
      list: false,
      products: [
        {
          name: "51.PARTY-1",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/17103004/party-1.png",
          price: 2490,
          description: "(8 Nigiri, 12 Maki, 6 Cal.Rolls)",
        },
        {
          name: "52.PARTY-2",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/17103207/party-2.png",
          price: 3490,
          description: "(11 Nigiri, 6 Maki, 6 Cal.Rolls, 4 Cal.Inside-out)",
        },
        {
          name: "53.PARTY-3",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/17103249/party-3.png",
          price: 3490,
          description: "(8 Nigiri, 36 Maki, 6 Cal.Rolls, 4 Cal.Inside-out)",
        },
        {
          name: "54.PARTY-4",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/08/17103348/party-4.png",
          price: 5790,
          description: "(14 Nigiri, 18 Maki, 6 Cal.Rolls, 6 Cal.Inside-out)",
        },
        {
          name: "55.Combo Party 1",
          img: "https://reuterweg.sushiammain.de/wp-content/uploads/2021/08/combo-party1.png",
          price: 3000,
          description:
            "(1b,c,d,f, 6 Stück Sashimi, 2 Stück Nigiri, 4 Teile Special Roll, 6 Teile Maki)",
        },
        {
          name: "56. Combo Party 2",
          img: "https://reuterweg.sushiammain.de/wp-content/uploads/2021/08/combo-party2.png",
          price: 5700,
          description:
            "(bcdf, 12 Stück Sashimi, 4 Stück Nigiri, 16 Teile Special Roll, 12 Teile Maki)",
        },
        {
          name: "57. Combo Party 3",
          img: "https://reuterweg.sushiammain.de/wp-content/uploads/2021/08/combo-party3.png",
          price: 7800,
          description:
            "(b,c,d,f, 18 Stück Sashimi, 6 Stück Nigiri, 24 Teile Special Roll, 18 Teile Maki)",
        },
        {
          name: "58. Combo Party 4",
          img: "https://reuterweg.sushiammain.de/wp-content/uploads/2021/10/combo-party4.png",
          price: 10900,
          description:
            "(b,c,d,f, 24 Stück Sashimi, 8 Stück Nigiri, 32 Teile Special Roll, 24 Teile Maki)",
        },
      ],
    },
    {
      name: "SUSHI SETS",
      slug: "sushi-sets",
      list: false,
      products: [
        {
          name: "38.Riko Sushi",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04133012/38-Riko_Sushi.png",
          price: 1990,
          description: "(4 Nigiri,4 Spicy Tartar Roll,6 Maki)",
        },
        {
          name: "39. MO MO SUSHI",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04133124/39.png",
          price: 1450,
          description: "(4 Nigiri, 6 Panko Maki)",
        },
        {
          name: "40. KIKU SUSHI (Vegetarisch)",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04133341/40.png",
          price: 1280,
          description: "(4 Nigiri, 4 Futo)",
        },
        {
          name: "41. YUKI SUSHI",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04133554/41.png",
          price: 1280,
          description: "(4 Nigiri, 8 Maki)",
        },
        {
          name: "42. NOBU SUSHI (Vegetarisch)",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04133743/42.png",
          price: 1280,
          description: "(2 Nigiri, 4 Futo Maki, 6 Maki)",
        },
        {
          name: "43. KEN SUSHI",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04133949/43-Ken_Sushi.png",
          price: 1280,
          description: "(5 Nigiri,6 Maki)",
        },
        {
          name: "44. TAMA SUSHI",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04134202/44-TamaSushi.png",
          price: 1390,
          description: "(3 Nigiri,1 Tartar Gunkan,4 Tuna Salad Maki)",
        },
        {
          name: "45. DELUXE SUSHI",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04134346/45.png",
          price: 1800,
          description: "(7 Nigiri, 6 Maki)",
        },
        {
          name: "46. MIX SASHIMI-SUSHI",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04134506/46.png",
          price: 2750,
          description: "",
        },
        {
          name: "47. UME SUSHI",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04134704/47.png",
          price: 1990,
          description: "(6 Nigiri, 6 Maki)",
        },
        {
          name: "48. Salmon Lovers",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04134838/48-Salmon_Lovers.png",
          price: 2090,
          description: "(3 Sashimi,3 Nigiri, 4 Spicy Roll, 6 Maki)",
        },
        {
          name: "sushi-item-title plist_pname",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04135056/49-Tuna_Lovers.png",
          price: 2390,
          description: "(3 Sashimi,3 Nigiri, 4 Spicy Roll, 6 Maki)",
        },
        {
          name: "50. Bara Sushi",
          img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/04135244/50-Bara_Sushi.png",
          price: 1990,
          description: "(4 Out Roll,4 Spicy Tartar Roll, 6 Maki)",
        },
      ],
    },
    {
      name: "SPECIAL ROLLS",
      slug: "special-rolls",
      list: false,
      products: [],
    },
    {
      name: "NIGIRI",
      slug: "nigiri",
      list: false,
      products: [],
    },
    {
      name: "MAKI",
      slug: "maki",
      list: false,
      products: [],
    },
    {
      name: "SASHIMI",
      slug: "sashimi",
      list: false,
      products: [],
    },
    {
      name: "SUPPEN",
      slug: "suppen",
      list: false,
      products: [],
    },
    {
      name: "DON (Reisgerichte)",
      slug: "don-reisgerichte",
      list: false,
      products: [],
    },
    {
      name: "GETRÄNKE",
      slug: "getranke",
      list: false,
      products: [],
    },
    {
      name: "LUNCH MENÜ",
      slug: "lunch-menu",
      list: false,
      products: [],
    },
    {
      name: "DESSERT",
      slug: "dessert",
      list: false,
      products: [],
    },
    {
      name: "Extra",
      slug: "extra",
      list: false,
      products: [],
    },
  ],
  deliveryFees: [
    {
      calc_shipping_postcode: "60325",
      calc_shipping_cost: 1,
      calc_shipping_name:"60325",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60323",
      calc_shipping_cost: 1,
      calc_shipping_name:"60323",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60322",
      calc_shipping_cost: 1,
      calc_shipping_name:"60322",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60318",
      calc_shipping_cost: 1,
      calc_shipping_name:"60318",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60306",
      calc_shipping_cost: 1,
      calc_shipping_name:"60306",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60308",
      calc_shipping_cost: 1,
      calc_shipping_name:"60308",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60318",
      calc_shipping_cost: 2,
      calc_shipping_name:"60318",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60310",
      calc_shipping_cost: 2,
      calc_shipping_name:"60310",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60313",
      calc_shipping_cost: 2,
      calc_shipping_name:"60313",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60311",
      calc_shipping_cost: 2,
      calc_shipping_name:"60311",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60329",
      calc_shipping_cost: 2,
      calc_shipping_name:"60329",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60320",
      calc_shipping_cost: 2,
      calc_shipping_name:"60320",
      calc_shipping_extra:0
    },
    {
      calc_shipping_postcode: "60316",
      calc_shipping_cost: 2,
      calc_shipping_name:"60316 (+ 0.5€ Extra)",
      calc_shipping_extra:0.5
    },
    {
      calc_shipping_postcode: "60431",
      calc_shipping_cost: 2,
      calc_shipping_name:"60431 Bockenheim (+ 0.5€ Extra)",
      calc_shipping_extra:0.5
    },
    {
      calc_shipping_postcode: "60486",
      calc_shipping_cost: 2,
      calc_shipping_name:"60486 (+ 0.5€ Extra)",
      calc_shipping_extra:0.5
    },
    {
      calc_shipping_postcode: "60487",
      calc_shipping_cost: 2,
      calc_shipping_name:"60487 Bockenheim (+ 0.5€ Extra)",
      calc_shipping_extra:0.5
    },
  ],
};
