import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import { orderFormProps } from "../../../Interfaces/response";
import { useUserData } from "../../../Providers/user-data";
import { Link } from "react-router-dom";

interface props {
  control: Control<orderFormProps, any>;
  watch: UseFormWatch<orderFormProps>;
  errors: FieldErrors<orderFormProps>;
}
export const AppPaymentFormComponent: React.FC<props> = ({
  watch,
  control,
  errors,
}) => {
  const { payments } = useUserData();
  return (
    <React.Fragment>
      <React.Fragment>
        <div
          data-aos="fade-up"
          className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
        >
          <div className="w-full col-span-12">
            <h6 className="text-lg font-bold tracking-wider">
              Wie möchten Sie zahlen?
            </h6>
          </div>
          {payments.map((p, i) => (
            <div key={i} className="col-span-12 md:col-span-4">
              <Controller
                control={control}
                name="paymentId"
                rules={{
                  required: {
                    value: true,
                    message: "Lassen Sie es nicht leer. Bitte wählen Sie eines aus.",
                  },
                }}
                render={({
                  fieldState: { error },
                  field: { onBlur, onChange, ref, value },
                }) => (
                  <React.Fragment>
                    <button
                      ref={ref}
                      onBlur={onBlur}
                      onClick={() => onChange(p.id)}
                      type="button"
                      className={`w-full px-2 border-2 rounded-md  py-2 text-white flex justify-center items-center content-center flex-col space-y-1 ${
                        watch("paymentId") === p.id
                          ? "border-primary"
                          : "border-secondary"
                      }`}
                    >
                      <img src={p.logo} alt="logo" className="w-auto h-12" />
                      <span
                        className={`text-sm font-bold px-2 py-2 rounded-md tracking-widest ${
                          watch("paymentId") === p.id
                            ? "bg-primary"
                            : "bg-secondary"
                        } `}
                      >
                        {p.title}
                      </span>
                    </button>
                  </React.Fragment>
                )}
              />
            </div>
          ))}
          <div className="col-span-12">
            {errors && errors.paymentId && errors.paymentId.message ? (
              <React.Fragment>
                <span className="text-xs text-primary font-light">
                  {errors.paymentId.message}
                </span>
              </React.Fragment>
            ) : null}
          </div>

          <div className="col-span-12">
            {watch("paymentId") ? (
              <React.Fragment>
                <Controller
                  control={control}
                  name="is_accept"
                  rules={{
                    required: {
                      value: true,
                      message:
                        "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-row space-x-2 justify-start items-center content-center ">
                        <label
                          className="text-sm font-thin tracking-widest text-white text-pretty"
                          htmlFor="is_accept"
                        >
                          <input
                            onBlur={onBlur}
                            ref={ref}
                            checked={Boolean(value)}
                            onChange={(e) => onChange(e.target.checked)}
                            type="checkbox"
                            className="form-checkbox mr-3"
                          />
                          Ich habe die Allgemeinen{" "}
                          <Link
                            className="text-yellow-400 text-xs underline underline-offset-4"
                            to={`/terms-and-conditions`}
                            target="_blank"
                          >
                            Geschäftsbedingungen
                          </Link>{" "}
                          gelesen und stimme ihnen zu
                        </label>
                      </div>
                    </React.Fragment>
                  )}
                />
              </React.Fragment>
            ) : null}
          </div>
          <div className="col-span-12">
            {errors && errors.is_accept && errors.is_accept.message ? (
              <React.Fragment>
                <span className="text-xs text-primary font-light">
                  {errors.is_accept.message}
                </span>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};
