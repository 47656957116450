import React from 'react';
import { AppInnerPageWrapper } from '../Components/Modules/InnerPageWrapper';
import { AppPrivacyPolicyComponent } from '../Components/Ogranisms/PrivacyPolicy';

export const AppPrivacyPolicyScreen:React.FC =()=>{
    return(
        <React.Fragment>
             <AppInnerPageWrapper>
                 <React.Fragment>
                     <AppPrivacyPolicyComponent />
                 </React.Fragment>
             </AppInnerPageWrapper>
        </React.Fragment>
    )
}