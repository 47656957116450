import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { API, APIURLS } from "../../../Services";
import { AxiosError } from "axios";
import { useAuthContext } from "../../../Providers/auth";

export const AppLoginFormComponent: React.FC = () => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const navigate = useNavigate();
  const { setUser, setIsAuth, setAccessToken } = useAuthContext();
  const [searchParams] = useSearchParams();
  const path = searchParams.get("path");

  const onSubmit = async (formData: any) => {
    try {
      const { data } = await API.post(`${APIURLS.auth.login}`, formData);
      if (data && data.access_token && data.user) {
        setIsAuth(true);
        setUser(data.user);
        setAccessToken(data.access_token);
        if (path) {
          navigate(path, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 400) {
        if (axiosError.message) {
          setError("password", {
            type: "validate",
            message: axiosError.message,
          });
        }
      }
      if (axiosError.response?.status === 401) {
        setError("password", {
          type: "validate",
          message: `Incorrect credentials. Please double-check your email and password.`,
        });
      }
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" px-2 py-4 w-full flex flex-col text-white space-y-3"
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="anazhl_der_personen">Email*</label>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="email"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-black  form-input border-white "
                />
                {errors && errors.email && errors.email.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.email.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="anazhl_der_personen">Password*</label>
          <Controller
            control={control}
            name="password"
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="password"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-black form-input border-white "
                />
                {errors && errors.password && errors.password.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.password.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-row justify-start items-center content-center w-full my-6">
          <button
            disabled={!isValid}
            type="submit"
            className="bg-primary  w-full cursor-pointer px-4 py-2 text-white shadow-md text-lg font-bold hover:bg-slate-900 "
          >
            LOGIN
          </button>
        </div>
        <div className="flex flex-row  justify-start items-center content-center w-full pt-6">
          <p className="text-xs font-light">
            If you don't have an account?{" "}
            <Link className="text-xs text-primary underline" to={`/signup`}>
              Signup
            </Link>{" "}
            here
          </p>
        </div>

        <div className="flex flex-row justify-start items-center content-center w-full pt-3">
          <p className="text-xs font-light">
            To reset your password, please click on the following link:
            <Link
              className="text-xs text-primary underline"
              to={`/get-reset-link`}
            >
              Reset Password
            </Link>{" "}
            here
          </p>
        </div>
      </form>
    </React.Fragment>
  );
};
