import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { orderFormProps } from "../../../Interfaces/response";

interface props {
  control: Control<orderFormProps, any>;
  watch: UseFormWatch<orderFormProps>;
}
export const AppPickupForm: React.FC<props> = ({ control, watch }) => {
  return (
    <React.Fragment>
      {watch("_order_type") === "collection" ? (
        <div
          data-aos="fade-up"
          className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
        >
          <div className="w-full col-span-12">
            <h6 className="text-lg font-bold tracking-wider">Kontaktdafen</h6>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-1">
              <label htmlFor="info_first_name">Vorname * </label>
              <Controller
                control={control}
                name="info_first_name"
                rules={{
                  required: {
                    value: true,
                    message: "Bitte lassen Sie es nicht leer.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full  bg-transparent  text-white form-input border-white "
                    />
                    {errors &&
                    errors.info_first_name &&
                    errors.info_first_name.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.info_first_name.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-1">
              <label htmlFor='info_last_name'>Nachname * </label>
              <Controller
                control={control}
                name='info_last_name'
                rules={{
                  required: {
                    value: true,
                    message: "Bitte lassen Sie es nicht leer.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full  bg-transparent  text-white form-input border-white "
                    />
                    {errors &&
                    errors.info_last_name &&
                    errors.info_last_name.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.info_last_name.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-1">
              <label htmlFor='info_phone'>Telefon * </label>
              <Controller
                control={control}
                name='info_phone'
                rules={{
                  required: {
                    value: true,
                    message: "Bitte lassen Sie es nicht leer.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full  bg-transparent  text-white form-input border-white "
                    />
                    {errors &&
                    errors.info_phone &&
                    errors.info_phone.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.info_phone.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-1">
              <label htmlFor='info_email'>Email * </label>
              <Controller
                control={control}
                name='info_email'
                rules={{
                  required: {
                    value: true,
                    message: "Bitte lassen Sie es nicht leer.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="email"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full  bg-transparent  text-white form-input border-white "
                    />
                    {errors &&
                    errors.info_email &&
                    errors.info_email.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.info_email.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};
