import React, { useCallback, useEffect, useState } from "react";
import { OrderDetailsProps } from "../../../../Interfaces/response";
import { API, APIURLS } from "../../../../Services";
import { useAuthContext } from "../../../../Providers/auth";
import { CiShoppingCart } from "react-icons/ci";
import { AppOrderCard } from "../../../Atoms/AppOrderCard";

export const AppUserOrderComponent: React.FC = () => {
  const [orders, setorders] = useState<OrderDetailsProps[]>([]);
  const { accessToken } = useAuthContext();
  const syncOrders = useCallback(() => {
    API.get(`${APIURLS.orders.by_user}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.data) {
          setorders(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("USER ORDERS API DONE");
      });
  }, [accessToken]);

  useEffect(() => {
    syncOrders();
  }, [syncOrders]);

  return (
    <React.Fragment>
      <div className="w-full flex flex-col space-y-2 px-4 py-4">
        {orders && orders.length > 0 ? (
          <React.Fragment>
            <div className="w-full grid grid-cols-12 gap-6">
              {orders.map((o: OrderDetailsProps, i: number) => (
                <div key={i} className="col-span-12 md:col-span-6">
                  <AppOrderCard order={o} />
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="w-full flex flex-col space-y-2 justify-center items-center content-center h-96">
              <CiShoppingCart className="w-auto h-12 text-primary" />
              <span className="text-lg font-bold tracking-widest text-white">
                No Orders
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
