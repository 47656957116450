import React from 'react';
import { AppInnerPageWrapper } from '../Components/Modules/InnerPageWrapper';
import { AppCartComponent } from '../Components/Ogranisms/Cart';

export const AppCartScreen:React.FC =()=>{
    return(
        <AppInnerPageWrapper>
             <React.Fragment>
                 <AppCartComponent />
             </React.Fragment>
        </AppInnerPageWrapper>
    )
}