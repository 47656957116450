import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { API, APIURLS } from "../../../Services";
import { AxiosError } from "axios";

export const AppSignupFormComponent: React.FC = () => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      role: "user",
      first_name: "",
      last_name: "",
    },
  });
  const navigate = useNavigate();
 
  
  const onSubmit = async (formData: any) => {
    try {
      const { data } = await API.post(`${APIURLS.auth.signup}`, formData);
      if(data){
        navigate(`/verify-email?email=${formData.email}`)
      }
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 400) {
        if (axiosError.message) {
          setError("password", {
            type: "validate",
            message:
              axiosError.message + " Your email address is already used.",
          });
        }
      }
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" px-2 py-4 w-full flex flex-col text-white space-y-3"
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="name">Vorname*</label>
          <Controller
            control={control}
            name="first_name"
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-transparent  form-input border-white "
                />
                {errors && errors.first_name && errors.first_name.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.first_name.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="name">Familienname, Nachname*</label>
          <Controller
            control={control}
            name="last_name"
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-transparent  form-input border-white "
                />
                {errors && errors.last_name && errors.last_name.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.last_name.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="anazhl_der_personen">Email*</label>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="email"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full focus:bg-black bg-black  form-input border-white "
                />
                {errors && errors.email && errors.email.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.email.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="anazhl_der_personen">Password*</label>
          <Controller
            control={control}
            name="password"
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="password"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full focus:bg-black bg-black  form-input border-white "
                />
                {errors && errors.password && errors.password.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.password.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-row justify-start items-center content-center w-full my-6">
          <button
            disabled={!isValid}
            type="submit"
            className="bg-primary  w-full cursor-pointer px-4 py-2 text-white shadow-md text-lg font-bold hover:bg-slate-900 "
          >
            SIGNUP
          </button>
        </div>
        <div className="flex flex-row justify-start items-center content-center w-full my-6">
          <p className="text-xs font-light">
            If you have an account?{" "}
            <Link className="text-xs text-primary underline" to={`/login`}>
              Login
            </Link>{" "}
            here
          </p>
        </div>
      </form>
    </React.Fragment>
  );
};
