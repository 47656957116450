import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AppInnerPageWrapper } from "../Components/Modules/InnerPageWrapper";
import { AppProductByCategoryComponent } from "../Components/Ogranisms/OnlineSeller/ProductByCategory";
import { AppCategoryList } from "../Components/Atoms/AppCategoryList";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import { categoryProps } from "../Interfaces/response";
import { API, APIURLS } from "../Services";

export const AppProductsByCategoryScreen: React.FC = () => {
  const params: any = useParams();
  const [categories, setcategories] = useState<categoryProps[]>();
  const syncCategories = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.categories.all}`);
    const response: categoryProps[] = data;
    setcategories(response);
  }, []);

  useEffect(() => {
    syncCategories();
  }, [syncCategories]);
  const category: categoryProps | undefined = useMemo(() => {
    if (params && params.slug && categories && categories.length > 0) {
      return find(categories, (c) => c.slug === params.slug);
    } else {
      return undefined;
    }
  }, [params,categories]);
  return (
    <React.Fragment>
      {categories && categories.length > 0 ? (
        <React.Fragment>
          <AppInnerPageWrapper>
            <AppCategoryList categories={categories} />
            {category && <AppProductByCategoryComponent category={category} />}
          </AppInnerPageWrapper>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};
