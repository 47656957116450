import React from "react";
import { CiClock1 } from "react-icons/ci";
import { Link } from "react-router-dom";

export const AppPaymentCancelScreen: React.FC = () => {
  return (
    <React.Fragment>
      <div className="payment-cancel-container">
        <CiClock1 className="cancel-icon" />
        <h2 className="cancel-heading">Payment Canceled</h2>
        <p className="cancel-message">
          Your payment was canceled. If you have any questions, please contact
          support.
        </p>
        <Link to="/" className="home-link">
          <button className="home-button">Go to Home</button>
        </Link>
      </div>
    </React.Fragment>
  );
};
