import React, { useCallback, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { AppRootRouter } from "./Routes";
import { useDispatch } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import { Toaster } from "react-hot-toast";
import { useAuthContext } from "./Providers/auth";
import { API, APIURLS } from "./Services";
import { cartProps, productProps, settingProps } from "./Interfaces/response";
import { each } from "lodash";
import { addExistingCarts } from "./Providers/order";
import { useUserData } from "./Providers/user-data";

export const AppRoot: React.FC = () => {
  const { accessToken, setIsAuth, setUser } = useAuthContext();
  const {
    setSetting,
    setPayments,
    setShipping,
    setAnnouncement,
    setExtraproducts,
  } = useUserData();
  const syncProfile = useCallback(async () => {
    if (accessToken) {
      const { data } = await API.get(`${APIURLS.auth.get_profile}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (data) {
        setUser(data);
        setIsAuth(true);
      }
    }
  }, [accessToken, setIsAuth, setUser]);
  useEffect(() => {
    syncProfile();
  }, [syncProfile]);
  const dispatch = useDispatch();
  const syncUserData = useCallback(async () => {
    if (accessToken) {
      const { data } = await API.get(`${APIURLS.carts.by_user}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (data) {
        const reponse: cartProps[] = data;
        let products: productProps[] = [];
        each(reponse, (cart: cartProps) => {
          products.concat(cart.products);
        });
        if (products.length > 0) {
          dispatch(addExistingCarts(products));
        }
      }
    }
  }, [accessToken, dispatch]);

  const syncPayments = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.payments.all}`);
    if (data) {
      setPayments(data);
    }
  }, [setPayments]);

  const syncShipping = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.shipping.all}`);
    if (data) {
      setShipping(data);
    }
  }, [setShipping]);

  useEffect(() => {
    syncPayments();
  }, [syncPayments]);

  useEffect(() => {
    syncShipping();
  }, [syncShipping]);

  const syncSetting = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.settings.for_user}`);
    const response: settingProps[] = data;
    let settingObj: any = {};
    each(response, (setting) => {
      settingObj[setting.key] = setting.value;
    });

    setSetting(settingObj);
  }, [setSetting]);

  useEffect(() => {
    syncSetting();
  }, [syncSetting]);

  useEffect(() => {
    syncUserData();
  }, [syncUserData]);




  const syncAnouncement = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.announcements.for_today}`);
    setAnnouncement(data);
  }, [setAnnouncement]);

  useEffect(() => {
    syncAnouncement();
  }, [syncAnouncement]);

  AOS.init();
  return (
    <React.Fragment>
      <Toaster />
      <RouterProvider router={AppRootRouter} />
    </React.Fragment>
  );
};
