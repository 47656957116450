import React from "react";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { orderFormProps } from "../../../Interfaces/response";

interface props {
  control: Control<orderFormProps, any>;
  watch: UseFormWatch<orderFormProps>;
}
export const AppYourInfoComponent: React.FC<props> = ({ control }) => {
  return (
    <React.Fragment>
      <div
        data-aos="fade-up"
        className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
      >
        <div className="w-full col-span-12">
          <h6 className="text-lg font-bold tracking-wider">
            Zusätzliche Info:(Optional)
          </h6>
        </div>
        
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="datum">Datum</label>
            <Controller
              control={control}
              name='datum'
             
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type='date'
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full form-input text-sm pb-3 bg-white/90  text-black border-black"
                  />
                 
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="uhrzeit">Uhrzeit</label>
            <Controller
              control={control}
              name='uhrzeit'
             
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type='time'
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full form-input text-sm pb-3 bg-white/90  text-black border-black"
                  />
                  
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-12">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_email"> Vermerk der Bestellung</label>
            <Controller
              control={control}
              name="order_comments"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <textarea
                    rows={6}
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  form-textarea  border-white "
                  />
                 
                </React.Fragment>
              )}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
