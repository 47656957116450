import React from "react";
import { Link } from "react-router-dom";

export const AppPageFooter: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full py-2 bg-black rounded-lg text-white ">
        <div className="w-full grid mx-auto grid-cols-12 gap-3 max-w-screen-xl px-2 py-2">
          <div className="col-span-12 md:col-span-4">
            <div className="w-full flex justify-start flex-col px-2 py-2 space-y-2">
              <h6 className="w-full border-b border-primary rounded-bl-xl px-4 text-sm font-semibold py-1">
                Feuerbachstraße 1
              </h6>
              <p className="text-xs font-normal px-4 ">
                D-60325 Frankfurt am Main
              </p>
              <p className="text-xs font-normal px-4 ">
                Mo – Fr ( 11:45 – 14:45 & 18:00 – 22:00)
              </p>
              <p className="text-xs font-normal px-4 ">
                Samstag: ( 18:00 – 22:00 )
              </p>
              <p className="text-xs font-normal px-4 ">
                Sonntag und Feiertage : ( Ruhetag )
              </p>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="w-full flex justify-start flex-col px-2 py-2 space-y-2">
              <h6 className="w-full border-b border-primary rounded-bl-xl px-4 text-sm font-semibold py-1">
                Reuterweg 61
              </h6>

              <p className="text-xs font-normal px-4 ">
                D-60323 Frankfurt am Main
              </p>
              <p className="text-xs font-normal px-4 ">
                Mo – Fr ( 11:45 – 22:00)
              </p>
              <p className="text-xs font-normal px-4 ">
                Samstag,Sonntag und Feiertage : ( 17:30 – 22:00 )
              </p>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4 ">
            <div className="w-full flex justify-start flex-col px-2 py-2 space-y-2">
              <h6 className="w-full border-b border-primary rounded-bl-xl px-4 text-sm font-semibold py-1">
                Nützlicher
              </h6>
              <Link
                to={`/impressum`}
                className="text-slate-300  px-4 text-sm font-normal hover:underline hover:text-slate-50"
              >
                Impressum
              </Link>
              <Link
                to={`/job`}
                className="text-slate-300 px-4 text-sm font-normal hover:underline hover:text-slate-50"
              >
                Job
              </Link>

              <Link
                to={`/kontakt`}
                className="text-slate-300 px-4 text-sm font-normal hover:underline hover:text-slate-50"
              >
                Kontakt
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
