import React from "react";
import { reservationProps } from "../../Interfaces/response";
import { CiClock1 } from "react-icons/ci";

interface props {
  reservation?: reservationProps;
}

export const AppReservationCard: React.FC<props> = ({ reservation }) => {
  return (
    <React.Fragment>
      {reservation ? (
        <React.Fragment>
          <div className="w-ful bg-secondary  order_border">
            <div className="w-full px-2 py-2 flex flex-col space-y-2 ">
              <div className="flex flex-row w-full justify-between items-center content-center">
                <h6 className="text-lg font-bold tracking-widest bg-black rounded-full px-3 py-1">
                  {reservation?.id}
                </h6>
                <div className="flex flex-row justify-end items-center content-center space-x-2">
                  <CiClock1 className="text-white w-auto h-4" />
                  <span className="text-xs font-thin text-white">
                    {`${reservation.resv_date} - ${reservation.resv_time}`}
                  </span>
                </div>
              </div>
              <div className="w-full py-2 border-t border-black">
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin">
                      Vorname, Nachname *
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        {reservation.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin">
                      Anzahl der Personen*
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        {reservation.anazhl_der_personen}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin">
                    (Kind)*
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        {reservation.kind}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin">
                    Email*
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        {reservation.email}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin">
                    Telefon *
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        {reservation.phone}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin">
                    Nachricht*
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        {reservation.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
