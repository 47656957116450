import React  from "react";
import { AppInnerPageWrapper } from "../Components/Modules/InnerPageWrapper";
import { AppImpressumComponent } from "../Components/Ogranisms/Impressum";

export const AppImpressumScreen:React.FC =()=>{
    return(
        <React.Fragment>
            <AppInnerPageWrapper>
             <AppImpressumComponent />
            </AppInnerPageWrapper>
        </React.Fragment>
    )
}