import React from "react";
import { AppMenuCard } from "../../Atoms/AppMenuCard";

export const AppMenuComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col space-y-6 max-w-screen-md mx-auto">
        <div data-aos="fade-right" className="w-full pb-12 flex flex-col space-y-3">
          <h5 className="text-lg font-bold underline ">RESTAURANT MENU</h5>
          <h6 className="text-sm font-bold  text-center">
            Hier finden Sie unser Speiseangebot.
          </h6>
        </div>
        <div className="w-full max-w-screen-md mx-auto grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-6">
            <AppMenuCard
              menu={{
                img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/03120436/Menu_Book_Icon.jpg",
                href: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/11/06171249/Reuterweg_Menu_compressed.pdf",
              }}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <AppMenuCard
              menu={{
                img: "https://reuterweg-sushiammain.s3.eu-central-1.amazonaws.com/wp-content/uploads/2021/09/03120452/Flyer_Icon-1.jpg",
                href: "https://drive.google.com/file/d/1r_RYGnpM6GxpNI5WEcChnCn5aPhKlW9n/view?usp=sharing",
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
