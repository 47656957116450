import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { orderFormProps, shippingProps } from "../../../Interfaces/response";
import { AppShippingDeliveryBox } from "../../Atoms/AppShippingPickupDeliveryBox";
import { useUserData } from "../../../Providers/user-data";
import { find } from "lodash";
import { AppShippingPickupBox } from "../../Atoms/AppShippingPickupBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../Providers";
import { AppDeliveryNoticeBox } from "../../Atoms/AppMinOrderDialog";

interface props {
  control: Control<orderFormProps, any>;
  watch: UseFormWatch<orderFormProps>;
  setValue: UseFormSetValue<orderFormProps>;
}
export const AppShippingFormComponent: React.FC<props> = ({
  control,
  watch,
  setValue,
}) => {
  const [isOpenDelivery, setisOpenDelivery] = useState<boolean>(false);
  const [isOpenPickup, setisOpenPickup] = useState<boolean>(false);
  const [isOpeN, setisOpeN] = useState(false);
  const { settting } = useUserData();
  const mOrderAmount = settting?.DELIVERY_MIN_ORDER_AMOUNT
    ? Number(settting.DELIVERY_MIN_ORDER_AMOUNT)
    : 0;
  const { total } = useSelector((state: RootState) => state.order);
  const orderType = watch("_order_type");
  const syncOrderType = useCallback(() => {
    if (orderType === "collection") {
      setValue("shipppingId", undefined);
      setisOpenPickup(true);
    } else if (orderType === "delivery") {
      if (total < mOrderAmount) {
        setisOpeN(true)
        setisOpenDelivery(false);
        setValue('_order_type','')
      } else {
        setisOpenDelivery(true);
        setisOpeN(false);
      }
    }
  }, [orderType, setValue, mOrderAmount, total]);

  useEffect(() => {
    syncOrderType();
  }, [syncOrderType]);

  const { shippings } = useUserData();
  const shippingId = watch("shipppingId");
  const shippingPlaceholder = useMemo(() => {
    if (shippingId) {
      const shipping = find(
        shippings,
        (s: shippingProps) => s.id === Number(shippingId)
      );
      if (shipping) {
        if (Number(shipping.calc_shipping_extra) > 0) {
          return `${shipping.code} - ${shipping.calc_shipping_cost} €  +  ${shipping.calc_shipping_extra} €`;
        } else {
          return `${shipping.code} - ${shipping.calc_shipping_cost} €`;
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [shippingId, shippings]);
  return (
    <React.Fragment>
      {watch("_order_type") === "delivery" ? (
        <React.Fragment>
          <div
            data-aos="fade-up"
            className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
          >
            <div className="w-full col-span-12">
              <h6 className="text-lg font-bold tracking-wider">
                Lieferadresse
              </h6>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_name">Name * </label>
                <Controller
                  control={control}
                  name="shipping_name"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_name &&
                      errors.shipping_name.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_name.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_firma_name">
                  Firma Name(Optional){" "}
                </label>
                <Controller
                  control={control}
                  name="shipping_firma_name"
                  rules={{
                    required: {
                      value: false,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_firma_name &&
                      errors.shipping_firma_name.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_firma_name.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_klingelname">Klingelname * </label>
                <Controller
                  control={control}
                  name="shipping_klingelname"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_klingelname &&
                      errors.shipping_klingelname.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_klingelname.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_etage">Etage * </label>
                <Controller
                  control={control}
                  name="shipping_etage"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_etage &&
                      errors.shipping_etage.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_etage.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_street">Straße * </label>
                <Controller
                  control={control}
                  name="shipping_street"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_street &&
                      errors.shipping_street.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_street.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_house_number">Hausnummer * </label>
                <Controller
                  control={control}
                  name="shipping_house_number"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_house_number &&
                      errors.shipping_house_number.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_house_number.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1 w-full ">
                <label htmlFor="">PLZ *</label>
                <Controller
                  control={control}
                  name="shipppingId"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        placeholder={shippingPlaceholder}
                        readOnly
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        onClick={() => setisOpenDelivery(!isOpenDelivery)}
                        className="w-full  bg-transparent  text-white  form-input border-white "
                      />
                      {errors &&
                      errors.shipppingId &&
                      errors.shipppingId.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipppingId.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_city">Ort * </label>
                <Controller
                  control={control}
                  name="shipping_city"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_city &&
                      errors.shipping_city.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_city.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_phone">Telefon * </label>
                <Controller
                  control={control}
                  name="shipping_phone"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_phone &&
                      errors.shipping_phone.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_phone.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex flex-col space-y-1">
                <label htmlFor="shipping_email">Email * </label>
                <Controller
                  control={control}
                  name="shipping_email"
                  rules={{
                    required: {
                      value: true,
                      message: "Bitte lassen Sie es nicht leer.",
                    },
                  }}
                  render={({
                    field: { onBlur, onChange, value, ref },
                    formState: { errors },
                  }) => (
                    <React.Fragment>
                      <input
                        type="text"
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        className="w-full  bg-transparent  text-white form-input border-white "
                      />
                      {errors &&
                      errors.shipping_email &&
                      errors.shipping_email.message ? (
                        <React.Fragment>
                          <span className="text-xs text-primary font-light">
                            {errors.shipping_email.message}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
          </div>

          <AppShippingDeliveryBox
            watch={watch}
            setIsOpen={setisOpenDelivery}
            isOpen={isOpenDelivery}
            setValue={setValue}
          />
         
        </React.Fragment>
      ) : null}
      <AppShippingPickupBox setIsOpen={setisOpenPickup} isOpen={isOpenPickup} />
      <AppDeliveryNoticeBox isOpen={isOpeN} setIsOpen={setisOpeN} />
    </React.Fragment>
  );
};
