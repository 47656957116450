import React from "react";
import { AppItemTable } from "./AppItemTable";
import { AppSummaryComponent } from "./Summary";

export const AppCartComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full max-w-screen-xl px-4 py-4 tracking-wider mx-auto flex flex-col space-y-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          CART
        </h4>
        <div className="w-full grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-9">
            <AppItemTable />
          </div>
          <div className="col-span-12 md:col-span-3">
            <AppSummaryComponent />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
