import React, {  useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { categoryProps } from "../../Interfaces/response";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
interface props {
  categories: categoryProps[];
}
export const AppCategoryList: React.FC<props> = ({ categories }) => {
  const { pathname } = useLocation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
 

  return (
    <React.Fragment>
      <div className="w-full relative">
        <button
          onClick={() =>
            scrollContainerRef.current
              ? (scrollContainerRef.current.scrollLeft -= 50)
              : console.log("scroll_to_left")
          }
          type="button"
          className="absolute z-30 px-2 py-2 h-full justify-center items-center content-center bg-black bg-opacity-55 bottom-px -left-2"
        >
          <RiArrowLeftSLine className="w-auto h-8 text-white" />
        </button>
        <button
          onClick={() =>
            scrollContainerRef.current
              ? (scrollContainerRef.current.scrollLeft += 50)
              : console.log("scroll_to_left")
          }
          type="button"
          className="absolute z-30 px-2 py-2 h-full justify-center items-center content-center bg-black bg-opacity-55  bottom-px -right-2"
        >
          <RiArrowRightSLine className="w-auto h-8 text-white" />
        </button>

        <div
          ref={scrollContainerRef}
          className="w-full flex-row relative space-x-3 whitespace-nowrap overflow-hidden overflow-x-scroll px-8 py-5 scroll-m-0"
        >
          <Link to={`/online-bestellen`} className=" ">
            <span
              className={`text-sm px-5 py-2  font-semibold rounded-3xl ${
                pathname === "/online-bestellen"
                  ? " bg-white text-primary"
                  : " bg-primary text-white"
              } hover:bg-white hover:text-primary `}
            >
              ALL
            </span>
          </Link>
          {categories.map((c, i) => (
            <Link key={i} to={`/product-by-category/${c.slug}`}>
              <span
                className={`text-sm px-5 py-2 font-semibold rounded-3xl ${
                  pathname === `/product-by-category/${c.slug}`
                    ? " bg-white text-primary"
                    : " bg-primary text-white"
                } hover:bg-white hover:text-primary `}
              >
                {c.name}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
