import React from "react";

export const AppQualityComponent: React.FC = () => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col tracking-wider space-y-3 mx-auto max-w-screen-lg px-4 py-2">
        <h4 className="text-lg md:text-xl py-4 font-bold tracking-wider  underline">
          QUALITAT
        </h4>
        <p className="text-sm font-normal py-2">
          Ohne Ausnahme werden von uns nur die frischsten Fische und Zutaten
          benutzt, die von uns mit größter Sorgfalt ohne Verwendung von Glutamat
          verarbeitet und zubereitet werden. Alle Sushi Gerichte werden von
          unserem Sushimeister an der Bar vor Ihren Augen frisch zubereitet.
          Sehr viel Wert legen wir auch auf die ansprechende optische Gestaltung
          unserer Speisen, die von unserem freundlichen Service und auf
          traditionellem japanischem Geschirr am Tisch serviert werden.
        </p>
        <p className="text-sm font-normal py-2">
          Wir bieten in unseren Restaurants die traditionelle gesunde japanische
          Küche mit Sushi, Miso und andere japanische Suppen, Tempura,
          selbstgemachte Gyoza (gefüllte Teigtaschen) und die authentische
          japanische Washoku-Küche an. Daneben gehören auch warme Reis- und
          Nudelgerichte mit Hähnchen oder anderem Fleisch zu unserem
          vielfältigen Angebot. Selbstverständlich haben wir auch eine große
          Auswahl an vegetarischen Gerichten, z.B. Kiku-Sushi, Nobu-Sushi, veg.
          Tempura oder verschiedene Nudel- und Reisgerichte. Sowohl für den
          Fischliebhaber als auch für den reinen Vegetarier haben wir eine
          vielfältige Auswahl an frischen Produkten.
        </p>
        <p className="text-sm font-normal py-2">
          In unseren Restaurants finden Sie auch eine große Auswahl an
          Getränken, die sowohl japanische Getränke wie Sake, japanische und
          deutsche Biere, deutsche und internationale Weine, Sprituosen und
          Softgetränke umfasst. Außerdem haben wir die üblichen Warmgetränke wie
          Tee (verschiedene japanische Tees, Jasmintee, Olong Tee, Ingwer Tee)
          und Kaffee im Angebot.
        </p>
        <p className="text-sm font-normal py-2">
          Sie können unsere Speisen und Getränke auch gerne telefonisch oder
          ONLINE bestellen und sich nach hause liefern lassen oder in unseren
          Restaurants abholen.
        </p>
        <p className="text-sm font-normal py-2"></p>
      </div>
    </React.Fragment>
  );
};
