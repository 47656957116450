import React from 'react';
import { AppInnerPageWrapper } from '../Components/Modules/InnerPageWrapper';
import { AppCateringComponent } from '../Components/Ogranisms/Catering';

export const AppCateringScreen:React.FC =()=>{
    return(
        <React.Fragment>
            <AppInnerPageWrapper>
               <AppCateringComponent />
            </AppInnerPageWrapper>
        </React.Fragment>
    )
}