import React from 'react';
import { AppInnerPageWrapper } from '../../Components/Modules/InnerPageWrapper';
import { AppAboutComponent } from '../../Components/Ogranisms/Restaurant/AppAbout';

export const AppAboutScreen:React.FC =()=>{
    return(
        <React.Fragment>
            <AppInnerPageWrapper>
                <AppAboutComponent />
            </AppInnerPageWrapper>
        </React.Fragment>
    )
}