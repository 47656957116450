import React from 'react';
import { AppAuthWrapper } from '../../Components/Modules/AuthWrapper';
import { AppSignupFormComponent } from '../../Components/Ogranisms/Auth/AppSignupForm';

export const AppSignupScreen:React.FC =()=>{
    return(
        <React.Fragment>
             <AppAuthWrapper>
                 <AppSignupFormComponent />
             </AppAuthWrapper>
        </React.Fragment>
    )
}