import React, { useState } from "react";
import { AppUserUpdateFormComponent } from "./AppUpdateForm";
import { AppUserOrderComponent } from "./AppUserOrder";
import { AppUserReservationsComponent } from "./AppUserReservations";
import { AppUserCartsComponent } from "./AppUserCart";
import { CiBookmarkPlus, CiShoppingCart, CiUser } from "react-icons/ci";

export const AppUserComponent: React.FC = () => {
  const [currentTab, setcurrentTab] = useState<
    "update_form" | "order" | "reservations" | "cart"
  >("update_form");
  return (
    <React.Fragment>
      <div className="w-full rounded-sm shadow-md bg-black  grid grid-cols-12 gap-3 px-2 py-2">
        <div className="col-span-12 md:col-span-3 h-full border-r border-secondary">
          <div className="w-full flex px-4 py-4 flex-row md:flex-col space-x-2 md:space-x-0 space-y-0 md:space-y-4 overflow-x-scroll md:overflow-hidden">
            <button
              onClick={() => setcurrentTab("update_form")}
              className={`text-sm uppercase text-nowrap hover:bg-primary px-4 py-3 rounded-full font-bold flex justify-between items-center text-white content-center flex-row ${
                currentTab === "update_form" ? " bg-primary" : " bg-secondary"
              }`}
            >
              <CiUser className="w-auto h-6" />
              <span className="flex-grow text-nowrap">Account Setting</span>
            </button>
            <button
              onClick={() => setcurrentTab("order")}
              className={`text-sm uppercase hover:bg-primary px-4 py-3 rounded-full font-bold flex justify-between items-center text-white content-center flex-row ${
                currentTab === "order" ? " bg-primary" : " bg-secondary"
              }`}
            >
              <CiShoppingCart className="w-auto h-6" />
              <span className="flex-grow text-nowrap">Your Orders</span>
            </button>
            <button
              onClick={() => setcurrentTab("reservations")}
              className={`text-sm uppercase hover:bg-primary px-4 py-3 rounded-full font-bold flex justify-between items-center text-white content-center flex-row ${
                currentTab === "reservations" ? " bg-primary" : " bg-secondary"
              }`}
            >
              <CiBookmarkPlus className="w-auto h-6" />
              <span className="flex-grow text-nowrap">Your Reservations</span>
            </button>
          </div>
        </div>
        <div className="col-span-12 md:col-span-9">
          {currentTab === "update_form" ? (
            <React.Fragment>
              <AppUserUpdateFormComponent />
            </React.Fragment>
          ) : currentTab === "order" ? (
            <React.Fragment>
              <AppUserOrderComponent />
            </React.Fragment>
          ) : currentTab === "reservations" ? (
            <React.Fragment>
              <AppUserReservationsComponent />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AppUserCartsComponent />
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};