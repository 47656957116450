import React from "react";
import { AppPageWrapper } from "../Components/Modules/PageWrapper";
import { AppHomeComponent } from "../Components/Ogranisms/Home/AppHome";

export const AppHomeScreen: React.FC = () => {
  return (
    <AppPageWrapper withFooter={false}>
      <AppHomeComponent />
    </AppPageWrapper>
  );
};
