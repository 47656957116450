import React, { useEffect } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { orderFormProps } from "../../../Interfaces/response";
import { find } from "lodash";
import {
  germanPostalCodes,
  postCodesProps,
} from "../../../Config/germany_postal_codes";
interface props {
  control: Control<orderFormProps, any>;
  watch: UseFormWatch<orderFormProps>;
  setValue: UseFormSetValue<orderFormProps>;
}
export const AppBillingFormComponent: React.FC<props> = ({
  control,
  watch,
  setValue,
}) => {
  const handleIsBillingAddressAreShipping = (isShipping: boolean) => {
    setValue("isSameBillingandShipping", isShipping);
    if (isShipping) {
      setValue("billing_name", watch("shipping_name"));
      setValue("billing_firma_name", watch("shipping_firma_name"));
      setValue("billing_street", watch("shipping_street"));
      setValue("billing_house_number", watch("shipping_house_number"));
      setValue("billing_phone", watch("shipping_phone"));
      setValue(
        "billing_zip",
        watch("shipping_city") ? watch("shipping_city").split(",")[0] : ""
      );
      setValue(
        "billing_city",
        watch("shipping_city") ? watch("shipping_city").split(",")[1] : ""
      );
    }
  };
  const code = watch('billing_zip')
  useEffect(() => {
    if (code) {
      const cityObj = find(germanPostalCodes, (postal: postCodesProps) =>
        postal.codes.includes(code)
      );
     
      setValue('billing_city', cityObj ? `${cityObj.city}` : "");
    }
  }, [code,setValue]);

  return (
    <React.Fragment>
      <div
        data-aos="fade-up"
        className="w-full grid grid-cols-12 gap-4 py-6 px-6 rounded-md border border-dashed border-primary border-opacity-25"
      >
        <div className="w-full col-span-12">
          <h6 className="text-lg font-bold tracking-wider">Rechnungsadresse</h6>
        </div>
        {watch("_order_type") === "delivery" ? (
          <div className="col-span-12 md:col-span-12">
            <div className="flex flex-row w-full  space-x-2 justify-start items-center content-center">
              <Controller
                control={control}
                name="isSameBillingandShipping"
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="checkbox"
                      ref={ref}
                      checked={watch("isSameBillingandShipping")}
                      onChange={(e) =>
                        handleIsBillingAddressAreShipping(e.target.checked)
                      }
                      onBlur={onBlur}
                      className="form-checkbox"
                    />
                    {errors &&
                    errors.isSameBillingandShipping &&
                    errors.isSameBillingandShipping.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.isSameBillingandShipping.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
              <label htmlFor="isSameBillingandShipping">
                Ist die Lieferadresse?
              </label>
            </div>
          </div>
        ) : null}
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_name">Name (optional)</label>
            <Controller
              control={control}
              name="billing_name"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  text-white form-input border-white "
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_etage">Firma Name (optional)</label>
            <Controller
              control={control}
              name="billing_firma_name"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  text-white form-input border-white "
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_street">Straße (optional)</label>
            <Controller
              control={control}
              name="billing_street"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  text-white form-input border-white "
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_house_number">Hausnummer (optional)</label>
            <Controller
              control={control}
              name="billing_house_number"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  text-white form-input border-white "
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_zip">PLZ (optional)</label>
            <Controller
              control={control}
              name="billing_zip"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  text-white form-input border-white "
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_city">Ort (optional)</label>
            <Controller
              control={control}
              name="billing_city"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  text-white form-input border-white "
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col space-y-1">
            <label htmlFor="billing_phone">Telefon (optional)</label>
            <Controller
              control={control}
              name="billing_phone"
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="text"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full  bg-transparent  text-white form-input border-white "
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
