import React from "react";
import { AppPaymentSuccessComponent } from "../../Components/Ogranisms/Payment/success";
import { AppInnerPageWrapper } from "../../Components/Modules/InnerPageWrapper";

export const AppPaymentSuccessScreen: React.FC = () => {
  return (
    <React.Fragment>
      <AppInnerPageWrapper>
      <AppPaymentSuccessComponent />
      </AppInnerPageWrapper>
    </React.Fragment>
  );
};
