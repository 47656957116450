import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RiCloseLine } from "react-icons/ri";
import { fCurrency } from "../../Helpers";
import { useUserData } from "../../Providers/user-data";
import { useSelector } from "react-redux";
import { RootState } from "../../Providers";
interface props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AppShippingPickupBox: React.FC<props> = ({
  isOpen,
  setIsOpen,
}) => {
  const { total } = useSelector((state: RootState) => state.order);
  const {settting} = useUserData()
  return (
    <React.Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(!isOpen)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden  bg-white relative  text-left align-middle shadow-xl transition-all">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="absolute top-0 right-0 px-2 py-2 cursor-pointer outline-none"
                  >
                    <RiCloseLine className="w-auto h-6 text-white" />
                  </button>

                  <div className="px-4  py-4 w-full bg-primary text-white">
                    <h6 className="font-semibold text-lg md:text-xl">
                      Für Abholkunden
                    </h6>
                  </div>
                  
                  <div className=" px-2 py-4 w-full flex flex-col space-y-3">
                     <p className="text-sm md:text-lg font-light">
                  {   settting &&
        settting.MIN_COLLECTION_DISCOUNT &&
        settting.MAX_COLLECTION_DISCOUNT &&
        settting.COLLECTION_DISCOUNT_TYPE &&
        settting.COLLECTION_DISCOUNT &&
        total > Number(settting.MIN_COLLECTION_DISCOUNT) ? (
          <React.Fragment>
            <div data-aos="fade-up" className="col-span-12">
              <p>
                {`Ab ${fCurrency(Number(Number(total).toFixed(2)))} ${
                  settting.COLLECTION_DISCOUNT
                } ${
                  settting.COLLECTION_DISCOUNT_TYPE === "PERCENTAGE" ? "%" : "€"
                } Rabattdelivery.`}
              </p>
            </div>
          </React.Fragment>
        ) : null}
                     </p>
                  </div>
                  <div className="px-2 py-2 w-full bg-black text-white">
                    <h6 className="font-light text-sm ">
                    Zubereitungszeit ca. 30 Minuten
                    </h6>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};
