import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RiCloseLine } from "react-icons/ri";
import { find, groupBy } from "lodash";
import { orderFormProps, shippingProps } from "../../Interfaces/response";
import { useUserData } from "../../Providers/user-data";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import {
  germanPostalCodes,
  postCodesProps,
} from "../../Config/germany_postal_codes";
interface props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  watch: UseFormWatch<orderFormProps>;
  setValue: UseFormSetValue<orderFormProps>;
}
export const AppShippingDeliveryBox: React.FC<props> = ({
  isOpen,
  setIsOpen,
  setValue,
  watch,
}) => {
  const { shippings } = useUserData();
  const [code, setCode] = useState("");
  useEffect(() => {
    if (code) {
      const cityObj = find(germanPostalCodes, (postal: postCodesProps) =>
        postal.codes.includes(code)
      );
      setValue("shipping_city", cityObj ? `${code},${cityObj.city}` : "");
    }
  }, [code,setValue]);

  return (
    <React.Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(!isOpen)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-screen-sm transform overflow-hidden  bg-white relative  text-left align-middle shadow-xl transition-all">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="absolute top-0 right-0 px-2 py-2 cursor-pointer outline-none"
                  >
                    <RiCloseLine className="w-auto h-6 text-white" />
                  </button>
                  <div className="px-4 py-4 w-full bg-primary text-white">
                    <h6 className="font-semibold text-lg md:text-xl">
                      Für die Lieferung wählen Sie bitte Postleitzahl.
                    </h6>
                  </div>

                  <div className=" px-2 py-2 w-full grid grid-cols-12 gap-4">
                    {Object.keys(groupBy(shippings, "calc_shipping_cost")).map(
                      (key, index) => (
                        <div key={index} className="col-span-12 md:col-span-6">
                          <div className="w-full flex flex-col space-y-2 px-2 py-2">
                            <h6 className="text-sm  font-semibold md:text-lg">
                              Deliver Fee {key}€
                            </h6>
                          </div>
                          <ul className="list-disc list-inside">
                            {groupBy(shippings, "calc_shipping_cost")[key].map(
                              (shipping: shippingProps, i: number) => (
                                <li
                                  onClick={() => {
                                    setValue("shipppingId", shipping.id);
                                    setIsOpen(!isOpen);
                                    setCode(shipping.code);
                                  }}
                                  className={`cursor-pointer hover:text-primary underline ${
                                    watch("shipppingId") === shipping.id
                                      ? "text-primary"
                                      : "text-secondary"
                                  }`}
                                  key={i}
                                >
                                  {shipping.name}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )
                    )}
                  </div>
                  <div className="px-2 py-2 w-full bg-black text-white">
                    <h6 className="font-light text-sm ">
                      Zubereitungszeit 45-60 Minuten ( Fr., Sa.& So. kann es
                      etwas länger dauern)
                    </h6>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};
