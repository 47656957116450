import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API, APIURLS } from "../../../Services";
import { AxiosError, AxiosResponse } from "axios";
import { useAuthContext } from "../../../Providers/auth";

export const AppVerifyEmailForm: React.FC = () => {
  const [params] = useSearchParams();
  const email = params.get("email");
  const navigate = useNavigate();
  const { setIsAuth, setAccessToken, setUser } = useAuthContext();
  const [errorMessage, seterrorMessage] = useState("");
  const [code, setcode] = useState("");
  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      seterrorMessage("");
      API.post(`${APIURLS.auth.verify}`, {
        email: email,
        verifyCode: code,
      })
        .then((res: AxiosResponse) => {
          if (
            res.data &&
            res.data.access_token &&
            res.data.user &&
            res.data.status &&
            res.data.status === 200
          ) {
            setIsAuth(true);
            setUser(res.data.user);
            setAccessToken(res.data.access_token);
            navigate("/");
          } else if (
            res.data &&
            res.data.status &&
            res.data.status === 400 &&
            res.data.message
          ) {
            seterrorMessage(res.data.message);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={(e) => SubmitForm(e)}
        className=" px-2 py-4 w-full flex flex-col text-white space-y-3"
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="name">Enter Verification Code*</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setcode(e.target.value)}
            className="w-full bg-transparent  form-input border-white "
          />
          {errorMessage ? (
            <React.Fragment>
              <span className="text-xs text-primary font-light">
                {errorMessage}
              </span>
            </React.Fragment>
          ) : null}
        </div>
        <div className="flex flex-row justify-start items-center content-center w-full my-6">
          <button
            disabled={code === ""}
            type="submit"
            className="bg-primary  w-full cursor-pointer px-4 py-2 text-white shadow-md text-lg font-bold hover:bg-slate-900 "
          >
            Verify
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};
