import React from 'react';
import { AppInnerPageWrapper } from '../Components/Modules/InnerPageWrapper';
import { AppReservationComponent } from '../Components/Ogranisms/Reservation';

export const AppReservationScreen:React.FC =()=>{
    return(
        <React.Fragment>
             <AppInnerPageWrapper>
                <AppReservationComponent />
             </AppInnerPageWrapper>
        </React.Fragment>
    )
}